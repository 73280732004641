import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '../../Common/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';

const StyledTableCell = withStyles(theme => ({
  head: {
    padding: '10px 7px',
    color: theme.palette.secondary.light,
    fontSize: 12,
    lineHeight: 1.25,
    borderBottom: 'none',
    width: 'auto',
    '&:first-child': {
      paddingLeft: 15,
      width: 110,
    },
    '&:last-child': {
      width: 100,
    },
  },
  body: {
    fontSize: 14,
    lineHeight: 1.26,
    padding: '15px 7px',
    [theme.breakpoints.up('lg')]: {
      border: 'none',
      backgroundColor: 'rgba(154,165,177,0.1)',
    },
    '&:first-child': {
      paddingLeft: 15,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&.divider': {
      border: 'none',
      paddingTop: 8,
      paddingBottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('lg')]: {
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      width: '100%',
    },
  },
}));

//Mobile table row
const ActivityTableRowMobile = ({row, column0, columnN, columns}) => {
  //collapse cells opened
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <StyledTableRow className="d-lg-none">
        <StyledTableCell scope="row">
          {typeof column0.renderCell === 'function'
            ? column0.renderCell(row[column0.name])
            : row[column0.name]}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {typeof columnN.renderCell === 'function'
            ? columnN.renderCell(row[columnN.name])
            : row[columnN.name]}
        </StyledTableCell>
        <StyledTableCell className="text-end">
          <IconButton variant="action" size="xs" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell className="py-0 border-bottom-0" colSpan={3}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="border-bottom pb-3"
          >
            {columns.map((column, index) =>
              index !== 0 && index !== columns.length - 1 ? (
                <div className="row pt-2" key={index}>
                  <div className="col-5">
                    <Typography
                      variant="subtitle2"
                      className="text-placeholder"
                    >
                      {column.label}
                    </Typography>
                  </div>
                  <div className="col-7">
                    {typeof column.renderCell === 'function' ? (
                      column.renderCell(row[column.name])
                    ) : (
                      <Typography variant="body2">
                        {row[column.name]}
                      </Typography>
                    )}
                  </div>
                </div>
              ) : null
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

/* Activity List */
const ActivityList = ({columns, rows}) => {
  const classes = useStyles();

  const column0 = columns[0];
  const columnN = columns[columns.length - 1];

  return (
    <TableContainer component="div">
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          {/*Desktop header*/}
          <TableRow className="d-none d-lg-table-row">
            {columns.map((column, index) => (
              <StyledTableCell
                key={index}
                style={{width: column.width ? column.width : 'initial'}}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
          {/*Mobile header*/}
          <TableRow className="d-lg-none">
            {/*First head*/}
            <StyledTableCell>{column0.label}</StyledTableCell>
            {/*Last head*/}
            <StyledTableCell colSpan={2} style={{width: '1%'}}>
              {columnN.label}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Fragment key={index}>
              {/*Desktop row*/}
              <StyledTableRow className="d-none d-lg-table-row">
                {columns.map(({name, renderCell}, _) => (
                  <StyledTableCell key={name} scope="row">
                    {typeof renderCell === 'function'
                      ? renderCell(row[name])
                      : row[name]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              {/*add divider between rows if it's not a last row*/}
              {index < rows.length - 1 && (
                <StyledTableRow className="d-none d-lg-table-row">
                  <StyledTableCell
                    className="divider"
                    colSpan={columns.length}
                  />
                </StyledTableRow>
              )}
              {/*Mobile row*/}
              <ActivityTableRowMobile
                row={row}
                column0={column0}
                columnN={columnN}
                columns={columns}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ActivityList.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};

export default ActivityList;
