import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../constants/actionTypes';

export type SnackbarVariant = 'success' | 'info' | 'warning' | 'error';

export type SnackbarArgs = {
  message: string;
  variant?: SnackbarVariant;
  options?: {
    variant: SnackbarVariant;
    autoHideDuration?: number;
    anchorOrigin?: {
      vertical: 'top' | 'bottom';
      horizontal: 'right' | 'left';
    };
    key: string;
  };
};

export const enqueueSnackbar = (notification: SnackbarArgs) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      options: {
        variant: notification?.variant,
        ...notification.options,
      },
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const enqueueSnackbarError = (notification: SnackbarArgs) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      options: {
        ...notification.options,
        variant: 'error',
      },
      key: key || new Date().getTime() + Math.random(),
    },
  };
};
