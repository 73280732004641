import React from 'react';

import {Box, IconButton, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {useState} from 'react';
import {useEffect} from 'react';
import CustomObjectFieldGroup from '../CustomObjectFieldGroup';
import {useIntl} from 'react-intl';

const useRemoveBtnStyles = makeStyles(theme => ({
  root: {
    background: '#FF6571',
    padding: 1,
    '&:hover, &:active': {
      background: '#FF6571',
    },
  },
  label: {
    '& svg': {
      fontSize: 18,
      color: theme.palette.background.paper,
    },
  },
}));

const useStyles = makeStyles(theme => ({
  addBtn: {
    color: theme.palette.primary.main,
    border: 'none',
    background: 'none',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    padding: 0,
    '& svg': {
      marginRight: 6,
      marginLeft: -4,
      fontSize: 14,
    },
  },
}));

const CaCollectionFieldArray = props => {
  const {label, customObject, fields, onChange, ...coProps} = props;
  const [changed, setChanged] = useState(0);
  const [newValue, setNewValue] = useState(null);
  const removeBtnClasses = useRemoveBtnStyles();
  const classes = useStyles();
  const intl = useIntl();

  const handleAdd = () => {
    fields.push({});
    setChanged(true);
  };

  const handleRemove = index => {
    fields.remove(index);
    setChanged(true);
  };

  const handleItemChange = (itemValue, index) => {
    const newFields = fields.getAll();
    newFields[index] = itemValue;
    setChanged(true);
    setNewValue(newFields);
  };

  useEffect(() => {
    // add initial data if there are not fields
    if (fields.length === 0) {
      // handleAdd();
    }
    if (changed && onChange) {
      if (newValue) {
        onChange(newValue);
      } else {
        onChange(fields.getAll());
      }
      setChanged(false);
      setNewValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, changed, newValue]);

  return (
    <Box>
      <span className="small-label">{label}</span>
      {fields.map((item, index) => {
        return (
          <div className="row g-0 align-items-center" key={index}>
            <div className="col-12 col-sm">
              <CustomObjectFieldGroup
                {...coProps}
                name={`${item}`}
                customObject={customObject}
                onChange={newValue => handleItemChange(newValue, index)}
              />
            </div>
            <div
              className="col-12 col-sm text-end"
              style={{minWidth: 30, maxWidth: 30}}
            >
              <IconButton
                onClick={() => handleRemove(index)}
                classes={removeBtnClasses}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          </div>
        );
      })}
      <button type="button" onClick={handleAdd} className={classes.addBtn}>
        <AddIcon />
        {intl.formatMessage({id: 'actions.new'})} {(label ?? '').toLowerCase()}
      </button>
    </Box>
  );
};

CaCollectionFieldArray.propTypes = {
  customObject: PropTypes.object.isRequired,
};

export default CaCollectionFieldArray;
