import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash.get';
import {FormattedMessage, useIntl} from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {PlusIcon, MinusIcon} from '../../constants/images';
import Typography from '@material-ui/core/Typography';
import {Close as CloseIcon} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {Collapse} from 'reactstrap';
import Button from '../Common/Button';
import {FILTER_ICON} from '../../constants/images';
import {reduxForm} from 'redux-form';
import DynamicField from '../Form/Fields/DynamicField';
import {useHistory, useLocation} from 'react-router-dom';
import {inputTypes} from '../../constants/enums';
import {isEqual} from 'lodash';
import {Paper} from '@material-ui/core';
import {isEmpty} from '../../utils/functions';

//Filter styles
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    overflow: 'hidden',

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '21.4%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 51,
      paddingBottom: 0,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
  closeWrapper: {
    padding: '0px 40px',
  },
  [theme.breakpoints.up('lg')]: {
    form: {
      overflow: 'auto',
    },
  },
}));

/**
 * Collapsible filter component
 * @constructor
 */
const CollapsibleFilterBlock = ({
  title,
  className,
  active,
  children,
  isOpen,
  onToggle,
  formName,
  filterName,
}) => {
  const values = useSelector(state =>
    get(state, `form.${formName}.values`, {})
  );

  const isActive = useMemo(() => {
    if (active) {
      return true;
    }
    const val = get(values, filterName);
    return Array.isArray(val) ? val.length > 0 : val;
  }, [active, values]);

  //Expand/collapse block
  const toggle = useCallback(() => {
    onToggle && onToggle(!isOpen);
  }, [onToggle, isOpen]);

  return (
    <div
      className={clsx(
        'card collapsable-block',
        isOpen && 'expanded',
        className
      )}
    >
      <div className="card-header" onClick={toggle}>
        <h6
          className={clsx('card-title', {
            'font-weight-bold': isOpen,
            'text-app-primary': isActive,
          })}
        >
          {title}
        </h6>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="card-body">{children}</div>
      </Collapse>
    </div>
  );
};

export const FILTERS_SEARCH_KEY = 'filters';

/**
 * Filter component
 * @param props
 * @constructor
 */
let Filter = props => {
  const {
    width,
    opened,
    items,
    onClose,
    onFilter,
    onClear,
    ready,
    bindLocation,
    currentValues,
    onSilentFilterChange,
    initialValues,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  // get form values from redux-form reducer
  const values = useSelector(state =>
    get(state, `form.${props.form}.values`, {})
  );
  const registeredFields = useSelector(state =>
    get(state, `form.${props.form}.registeredFields`, {})
  );
  // active filters number state
  const [activeFilters, setActiveFilters] = useState(0);
  const [openedFilter, setOpenedFilter] = useState();
  const history = useHistory();
  const location = useLocation();

  /**
   * Count active filters from `vals`
   * Empty arrays are considered inactive
   *
   * @param {Object} vals    Key/value object where key if the filter name
   * @return {number}        Number of active filters
   */
  const countActiveFilters = (vals = {}) => {
    const activeFilter = key => {
      const val = get(vals, key);
      if (Array.isArray(val)) {
        return val.length > 0;
      } else if (typeof val === 'object') {
        return Object.keys(val).length > 0;
      }

      return !!val;
    };

    return vals && registeredFields
      ? Object.keys(registeredFields).filter(activeFilter).length
      : 0;
  };

  const updateLocation = useCallback(
    (values, time = 0) => {
      const filters = values;
      const searchParams = new URLSearchParams(location.search);
      // update search params
      searchParams.delete(FILTERS_SEARCH_KEY);
      if (Object.keys(filters).length > 0) {
        const values = filters;

        //Clean empty filter
        if (typeof values === 'object' && Object.keys(values).length > 0) {
          for (const key of Object.keys(values)) {
            if (isEmpty(values[key])) {
              delete values[key];
            }
          }
        }
        searchParams.set(FILTERS_SEARCH_KEY, JSON.stringify(values));
      }
      if (time) {
        searchParams.set('time', time.toString());
      }
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const apply = useCallback(
    (values, activeFilters) => {
      if (bindLocation) {
        updateLocation(values, new Date().getTime());
      } else {
        onFilter && onFilter(values, activeFilters);
      }
    },
    [updateLocation]
  );

  // clear button click callback
  const clearFilters = useCallback(() => {
    props.initialize(props.initialValues, Object.keys(registeredFields));
    props.reset();
    // Reset search keyword when clear filters
    onClear();
    // on reset, the form values will be the same as initialValues
    // Call onFilter prop with initialValues/empty object
    apply(props.initialValues ?? {}, countActiveFilters(props.initialValues));
  }, [initialValues, countActiveFilters]);

  // apply button click callback
  const applyFilters = useCallback(() => {
    // Call onFilter prop with filter values
    apply(values, activeFilters);
  }, [values, activeFilters]);

  /**
   * Will be only used if `bindLocation` prop is set to `true`
   * @param {object} values
   * @param time
   */

  // Update account filters count on filter form values changes
  useEffect(() => {
    setActiveFilters(countActiveFilters(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!bindLocation) {
      return;
    }
    const searchParams = new URLSearchParams(history.location.search);
    const fieldsReady =
      Object.keys(items).length === Object.keys(registeredFields).length;
    if (fieldsReady && ready) {
      try {
        let filterValues = {};
        if (searchParams.has(FILTERS_SEARCH_KEY)) {
          filterValues = JSON.parse(searchParams.get(FILTERS_SEARCH_KEY));
        }
        const timeDiff =
          new Date().getTime() -
          (searchParams.has('time') ? +searchParams.get('time') : 0);
        const changed =
          !currentValues ||
          !isEqual(currentValues, filterValues) ||
          timeDiff < 500;
        if (!isEqual(values, filterValues) || changed) {
          props.initialize(filterValues, Object.keys(registeredFields));
          if (!(changed && filterValues)) {
            onSilentFilterChange &&
              onSilentFilterChange(
                filterValues,
                countActiveFilters(filterValues)
              );
          }
        }
        if (changed && filterValues) {
          onFilter && onFilter(filterValues, countActiveFilters(filterValues));
        }
      } catch (e) {
        console.warn('Unable to parse filters from url search params');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredFields, items, location, history.location.search]);

  //Render filter content inside collapsible block
  const renderFilterSubItems = useCallback(filterItem => {
    const {title, inputType, subItems, ...others} = filterItem;
    return (
      <DynamicField
        label={title}
        items={subItems}
        inputType={inputType}
        {...others}
        maxDepth={1}
      />
    );
  }, []);

  const isActive = useCallback(filterName => {
    const val = get(values, filterName);
    return Array.isArray(val) ? val.length > 0 : val;
  }, []);

  const getBlockClassName = useCallback(item => {
    if (item.inputType === inputTypes.ADVANCED_NUMBER) {
      return 'quantity_select';
    } else {
      return '';
    }
  }, []);

  const renderItems = useMemo(() => {
    return items.map((item, key) => (
      <CollapsibleFilterBlock
        key={key}
        filterName={item.name}
        formName={props.form}
        active={isActive(item.name)}
        {...item}
        className={clsx(
          item.blockClassName,
          getBlockClassName(item),
          'filter-item-' + item.inputType
        )}
        isOpen={openedFilter === item.name}
        onToggle={isOpen => setOpenedFilter(isOpen ? item.name : null)}
      >
        {renderFilterSubItems(item)}
      </CollapsibleFilterBlock>
    ));
  }, [items, openedFilter, setOpenedFilter]);

  return (
    <Drawer
      anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={onClose}
    >
      <Paper className={classes.closeWrapper} elevation={0}>
        {/* Close action button */}
        <Button
          link
          light
          title={intl.formatMessage({id: 'actions.close'})}
          onClick={onClose}
          icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
        />
      </Paper>
      <form className={'position-relative h-100 ' + classes.form}>
        {/* Filter block content */}
        <div className="filter-content">
          {/* Filter header */}
          <div className="filter-header border-bottom">
            {/* Filter title */}
            <div className="filter-header-title d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {FILTER_ICON}
                <Typography variant="h5">
                  <FormattedMessage id="actions.filter" />
                </Typography>
              </div>
              {activeFilters > 0 && (
                <div className="filter-counter-indicator text-center">
                  {activeFilters}
                </div>
              )}
              {/*  Use 'invisible' class if you need to hide this indicator */}
            </div>
          </div>
          {/* Filter items collapsible */}
          <div className="filter-items-container filter-items-container-lg flex-fill">
            {renderItems}
          </div>
          {/* Filter actions */}
          <div className="filter-bottom-actions filter-bottom-actions-lg d-flex align-items-center justify-content-center">
            {/*Clear*/}
            <Button
              link
              title={intl.formatMessage({id: 'actions.clear'})}
              type="button"
              onClick={clearFilters}
            />
            {/*Apply*/}
            <Button
              primary
              title={intl.formatMessage({id: 'actions.apply'})}
              type="button"
              onClick={applyFilters}
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  /**
   * TRUE if filter block is shown
   */
  opened: PropTypes.bool,
  /**
   * Filtration items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Redux-form field name
       */
      name: PropTypes.string.isRequired,
      /**
       * The displayed filter title
       */
      title: PropTypes.string,
      /**
       * Used for select (drop-list), checkbox group, radio group or something similar
       */
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
        })
      ),
      /**
       * The filter item input types
       */
      inputType: PropTypes.oneOf([
        /** Render a single checkbox */
        inputTypes.CHECKBOX,
        /** Render a group of checkbox filter, subItems is required here */
        inputTypes.CHECKBOXES,
        /** Render a select filter, subItems is required here */
        inputTypes.SELECT,
        /** Render two datepickers, value structure will be an object with two keys: `from` and `to` */
        inputTypes.DATE_BETWEEN,
        /** Render a SearchTextField */
        inputTypes.SEARCH,
        /** Render a two datepickers or one period select and one input */
        inputTypes.DATE_SELECT,
        /** Render radio buttons for each subitem in subItems */
        inputTypes.RADIO,
        /** Render an AdvancedNumberField */
        inputTypes.ADVANCED_NUMBER,
        /** Autocomplete field */
        inputTypes.AUTOCOMPLETE,
        inputTypes.AUTOCOMPLETE2,
      ]),
    })
  ),
  /**
   * onFilter callback, fired when `clear` or `apply` buttons are clicked
   * the callback parameters will be the filters value
   */
  onFilter: PropTypes.func,
  /**
   * Flag indicating whether the filter is ready or not
   * This is used to perform actions when ready
   * like reading location search and triggering filter change
   */
  ready: PropTypes.bool,
  /**
   * Flag indicating whether the filter should get/set location search
   * If true, it will update the location search on apply/clear
   * and location search changes will update the filter values too
   * meaning that user will be able to go back/forward and the filters will change
   * accordingly
   * Default: false
   */
  bindLocation: PropTypes.bool,
  currentValues: PropTypes.object,
  onSilentFilterChange: PropTypes.func,
};

Filter.defaultProps = {
  ready: false,
  bindLocation: false,
};

// the form name can be overridden by providing a "form" prop to Filter
// useful to avoid having the multiple filters with the same name
Filter = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(Filter);

export default withWidth()(Filter);
