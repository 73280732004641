import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Field} from 'redux-form';
import InputField from '../../../Form/Fields/InputField';
import SelectField from '../../../Form/Fields/SelectField';
import HintTooltip from '../../../Common/HintTooltip';
import {formatMoney, normalizeMoneyFormat} from '../../../../utils/functions';

const printRow = props => {
  const {
    fullClassName,
    titleWrapperClassName,
    className = '',
    title,
    value,
    edit,
    fieldName,
    type,
    currency,
    items = [],
    tooltip,
    tooltipId,
    tooltipContent,
  } = props;

  return type === 'Select' ? (
    <div className="row mb-3 pb-1">
      <div className="col-12 mb-2">
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </div>
      <div className="col-12">
        <Field
          name={fieldName}
          component={SelectField}
          items={items}
          outlined
        />
      </div>
    </div>
  ) : (
    <div
      className={
        fullClassName
          ? fullClassName
          : clsx('row align-items-center justify-content-between', className)
      }
    >
      <div
        className={
          titleWrapperClassName
            ? titleWrapperClassName
            : clsx(edit ? 'col-12 mb-2' : 'col')
        }
      >
        <Typography
          variant={edit ? 'subtitle2' : 'body2'}
          color={edit ? 'textSecondary' : 'textPrimary'}
          component={tooltip && 'span'}
          className={tooltip && 'me-1'}
        >
          {title}
        </Typography>
        {tooltip && (
          <HintTooltip id={tooltipId} gray={false} content={tooltipContent} />
        )}
      </div>
      <div className={clsx(edit ? 'col-12' : 'col-auto')}>
        {edit ? (
          currency ? (
            <div className="edit-wrapper">
              <Field
                name={fieldName}
                component={InputField}
                outlined
                addon={currency}
                addonPosition="start"
                format={formatMoney}
                normalize={normalizeMoneyFormat}
              />
            </div>
          ) : (
            <div className="edit-wrapper">
              <Field name={fieldName} component={InputField} outlined />
            </div>
          )
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </div>
    </div>
  );
};

export default printRow;
