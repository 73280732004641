import React from 'react';
import {injectIntl} from 'react-intl';
import {reduxForm, Field, FieldArray} from 'redux-form';
import InputField from '../Form/Fields/InputField';
import SelectField from '../Form/Fields/SelectField';
import Modal from '../Widgets/Modal';
import IconButton from '../Common/IconButton';
import {
  ArrowForwardIcon,
  ColumnDelete,
  PlusIconDanger,
} from '../../constants/images';
import Button from '../Common/Button';

//Competitions fields array
const renderCompetitions = ({intl, label, fields, meta: {touched, error}}) => (
  <div className="form-group mb-lg-4">
    <span className="small-label">{label}</span>
    <ul className="list-unstyled mb-0">
      {fields.map((field, index) => (
        <li key={index}>
          <div className="row gx-fan-10 align-items-center pb-2">
            <div className="col">
              <Field
                name={`${field}.competition`}
                component={SelectField}
                outlined
                items={[
                  {label: 'Competition 1', value: 0},
                  {label: 'Competition 2', value: 1},
                  {label: 'Competition 3', value: 2},
                ]}
              />
            </div>
            <div className="col-auto">
              <IconButton
                size="xs"
                variant="filled"
                onClick={() => fields.remove(index)}
              >
                <ColumnDelete style={{fontSize: 20}} />
              </IconButton>
            </div>
          </div>
        </li>
      ))}
      <li>
        {/*New competition*/}
        <Button
          small
          link
          danger
          icon={<PlusIconDanger />}
          title={intl.formatMessage({
            id: 'dashboard.events.list.team_tab.details.new_competition',
          })}
          onClick={() => fields.push({})}
        />
        {touched && error && <span className="error">{error}</span>}
      </li>
    </ul>
  </div>
);

/* Add team popup */
let TeamDetailsPopup = props => {
  const {intl, opened, handleClose, handleSubmit} = props;

  const submit = () => {};

  return (
    <Modal
      width={328}
      opened={opened}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'dashboard.events.list.team_tab.details.title_add_team',
      })}
      saveTitle={intl.formatMessage({id: 'actions.add'})}
    >
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <div className="row row-cols-1 g-fan-25 mb-2">
          <div className="col">
            {/*Team name*/}
            <Field
              name="name"
              component={InputField}
              outlined
              label={`${intl.formatMessage({id: 'dashboard.events.list.team_tab.details.team_name'})}*`}
              externalLabel
            />
          </div>
          <div className="col">
            {/*Sport type*/}
            <Field
              name="type"
              component={SelectField}
              outlined
              label={`${intl.formatMessage({id: 'dashboard.events.list.team_tab.details.sport_type'})}*`}
              externalLabel
              items={[
                {label: 'Footbal', value: '0'},
                {label: 'Rugby', value: '1'},
                {label: 'Tennis', value: '2'},
              ]}
            />
          </div>
          <div className="col">
            {/*Landing page URL*/}
            <Field
              name="url"
              component={InputField}
              outlined
              label={intl.formatMessage({
                id: 'dashboard.events.list.team_tab.details.landing_page_url',
              })}
              externalLabel
              addon={
                <IconButton size="xs" variant="action">
                  <ArrowForwardIcon style={{fontSize: 8}} />
                </IconButton>
              }
              addonPosition="end"
            />
          </div>
          {/*Competition*/}
          <div className="col">
            <FieldArray
              name="competitions"
              label={`${intl.formatMessage({id: 'dashboard.events.list.team_tab.details.competition'})}*`}
              component={renderCompetitions}
              intl={intl}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

const validate = values => {
  const errors = {};
  return errors;
};

// connect component to redux-form
TeamDetailsPopup = reduxForm({
  form: 'TeamDetailsForm',
  validate,
  initialValues: {
    competitions: [{}, {}, {}],
  },
})(TeamDetailsPopup);

export default injectIntl(TeamDetailsPopup);
