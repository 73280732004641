/**
 * Manage connections to/from Browser's Local Storage
 *
 * This file have relation with src/store/configureStore.js
 * Because we have to tell the store the way that we handle state
 */

import {omit} from 'lodash';
import {authSlice} from './slices/authSlice';

/**
 * Load state to Browser's local storage
 * @returns {*}
 */
export const loadState = () => {
  try {
    //Try to get the state from local storage
    const state = localStorage.getItem('state');
    //parse state from string to object
    const serializeState = JSON.parse(state);
    const preLoadedState = {auth: {}, form: {}, userPreferencesReducer: {}};

    //Test if there is saved state for auth reducer
    if (serializeState) {
      if (serializeState.auth) {
        preLoadedState.auth = serializeState.auth;
      }
      if (serializeState.userPreferencesReducer) {
        preLoadedState.userPreferencesReducer =
          serializeState.userPreferencesReducer;
      }
      if (serializeState[authSlice.name]) {
        preLoadedState[authSlice.name] = omit(serializeState[authSlice.name], [
          'user',
        ]);
      }
    }

    return preLoadedState;
  } catch (err) {
    console.warn('Unable to load persisted state', err);
    return undefined;
  }
};

/**
 * Save state to Browser's local storage
 * @param state
 */
export const saveState = state => {
  try {
    //Serialize the current state
    const serializeState = JSON.stringify(state);
    //Try to save in local storage
    localStorage.setItem('state', serializeState);
  } catch (err) {
    console.warn('Unable to save state', err);
  }
};
