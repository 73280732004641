import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  competitionNames: [],
};

export default function competitionReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.API_COMPETITION_AUTOCOMPLETE_REQUESTED:
      return {
        ...state,
        competitionNames: [],
      };
    case actionTypes.API_COMPETITION_AUTOCOMPLETE_SUCCEEDED:
      return {
        ...state,
        competitionNames: action.payload.competitionNames,
      };
    default:
      return state;
  }
}
