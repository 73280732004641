import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import Button from '../Common/Button';
import React from 'react';

const buttonStyles = theme => ({
  width: '100%',
  maxWidth: 131,
  boxShadow: 'none !important',
  justifyContent: 'center !important',
  '&.textDanger': {
    color: theme.palette.danger.main,
  },
});

const useDialogStyles = makeStyles(theme => ({
  paper: ({width}) => ({
    width: width,
    maxWidth: '100%',
    borderRadius: 8,
  }),
}));

const useStyles = makeStyles(theme => ({
  actions: {
    padding: '16px 24px',
  },
  button: buttonStyles(theme),
}));

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onCancel: () => void;
  onOk: () => void;
  children?: React.ReactNode;
  DialogContentProps?: any;
}

/**
 * Display a confirm dialog with a cancel and an OK buttons
 * It takes the same props as Material UI Dialog with extra props that are defined in `propTypes`
 *
 * @param {*} props
 */
const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    open,
    onClose,
    title,
    onCancel,
    children,
    onOk,
    DialogContentProps,
    ...other
  } = props;
  const intl = useIntl();
  const classes = useStyles(props);
  const dialogClasses = useDialogStyles(props);

  return (
    <Dialog open={open} onClose={onClose} classes={dialogClasses} {...other}>
      <DialogTitle disableTypography>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      {children && (
        <DialogContent {...DialogContentProps}>{children}</DialogContent>
      )}
      <DialogActions className={classes.actions}>
        <Button
          link
          className={clsx(classes.button, 'textDanger')}
          title={
            props.cancelTitle ?? intl.formatMessage({id: 'actions.cancel'})
          }
          onClick={onCancel}
        />
        <Button
          primary
          className={classes.button}
          title={props.okTitle ?? intl.formatMessage({id: 'actions.ok'})}
          onClick={onOk}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  /**
   * Same as MUI Dialog.onClose, Whether the dialog is displayed or not
   */
  open: PropTypes.bool.isRequired,
  /**
   * Same as MUI Dialog.onClose, dialog close callback
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Confirm dialog title
   */
  title: PropTypes.string.isRequired,
  /**
   * cancel button click callback
   */
  onCancel: PropTypes.func,
  /**
   * confirm (OK) button click callback
   */
  onOk: PropTypes.func,
  /**
   * Props passed to DialogContent
   */
  DialogContentProps: PropTypes.any,
  /**
   * Dialog size, default to 320px
   */
  width: PropTypes.any,
};

ConfirmDialog.defaultProps = {
  width: 320,
};

export default ConfirmDialog;
