import rootReducer from '../reducers/rootReducer';
import createSagaMiddleware, {END} from 'redux-saga';
import sagas from '../sagas';
import {saveState} from './localStorage';
import {saleApi} from '../services/saleApi';
import {teamMemberApi} from '../services/teamMemberApi';
import {configureStore} from '@reduxjs/toolkit';
import {authApi} from '../services/authApi';
import {authSlice} from './slices/authSlice';
import {omit} from 'lodash';
import {roleApi} from '../services/roleApi';
import listenerMiddlewares from './listenerMiddleware';
import {noteApi} from '../features/Note/noteApi';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  ...listenerMiddlewares.map(lm => lm.middleware),
  sagaMiddleware,
  saleApi.middleware,
  authApi.middleware,
  teamMemberApi.middleware,
  roleApi.middleware,
  noteApi.middleware,
];

/**
 * This function is responsible to configure the PROD store
 * @param persistedState
 * @returns {Store<any, Action<any>> & Store<S & {}, A> & {dispatch: any}}
 */
function configureStoreProd(persistedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    devTools: false,
    preloadedState: persistedState,
  });

  //Subscribe saveState function as a listener
  //saveState function will be called every time the state change
  store.subscribe(() => {
    saveState({
      auth: store.getState().auth,
      userPreferencesReducer: store.getState().userPreferencesReducer,
      // do not save user, it will be fetched when the application starts
      [authSlice.name]: omit(store.getState()[authSlice.name], ['user']),
    });
  });

  sagaMiddleware.run(sagas);
  store.close = () => store.dispatch(END);

  return store;
}

/**
 * This function is responsible to configure the DEV store
 * @param persistedState
 * @returns {any}
 */
function configureStoreDev(persistedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    devTools: true,
    preloadedState: persistedState,
    // composeEnhancers(applyMiddleware(...middlewares))
  });

  //Subscribe saveState function as a listener
  //saveState function will be called every time the state change
  store.subscribe(() => {
    saveState({
      auth: store.getState().auth,
      userPreferencesReducer: store.getState().userPreferencesReducer,
      // do not save user, it will be fetched when the application starts
      [authSlice.name]: omit(store.getState()[authSlice.name], ['user']),
    });
  });

  sagaMiddleware.run(sagas);

  store.close = () => store.dispatch(END);

  return store;
}
//Check if we are in DEV or PROD and configure the corresponding store
const configureStoreFn =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export default configureStoreFn;
