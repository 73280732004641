import {cloneDeep} from 'lodash';
import * as types from '../constants/actionTypes';

const defaultState = {
  loading: false,
  error: null,
  addresses: [],
};

export default function userAddressReducer(state = defaultState, action) {
  switch (action.type) {
    case types.API_USER_ADDRESS_DETAILS_REQUESTED:
    case types.API_USER_ADDRESS_DELETE_REQUESTED:
    case types.API_USER_ADDRESS_DEFAULT_REQUESTED:
    case types.API_USER_ADDRESS_CREATE_REQUESTED:
    case types.API_USER_ADDRESS_EDIT_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.API_USER_ADDRESS_DETAILS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        addresses: action.payload.addresses,
      };
    case types.API_USER_ADDRESS_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        addresses: [],
        error: action.payload.error,
      };
    case types.API_USER_ADDRESS_DELETE_SUCCEEDED:
      // don't mutate state copy old address state
      const oldAddressState = cloneDeep(state.addresses);
      const updatedState = oldAddressState.filter(
        item => item['@id'] !== action.payload.id
      );
      return {
        ...state,
        loading: false,
        addresses: updatedState,
      };
    case types.API_USER_ADDRESS_DELETE_FAILED:
    case types.API_USER_ADDRESS_DEFAULT_FAILED:
    case types.API_USER_ADDRESS_CREATE_FAILED:
    case types.API_USER_ADDRESS_EDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.API_USER_ADDRESS_DEFAULT_SUCCEEDED:
      // don't mutate state copy old address state
      const oldAddress = cloneDeep(state.addresses);
      const newState = oldAddress.map(item => {
        if (item['@id'] === action.payload.id) {
          item = action.payload.data;
        } else {
          item.isDefault = false;
        }
        return item;
      });
      return {
        ...state,
        addresses: newState,
        loading: false,
      };
    case types.API_USER_ADDRESS_CREATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        addresses: [action.payload.data, ...state.addresses],
      };
    case types.API_USER_ADDRESS_EDIT_SUCCEEDED:
      const copiedAddress = cloneDeep(state.addresses);
      const index = copiedAddress.findIndex(
        where => where['@id'] === action.payload.data['@id']
      );
      if (index !== null) {
        copiedAddress[index] = action.payload.data;
      }
      return {
        ...state,
        loading: false,
        addresses: copiedAddress,
      };
    default:
      return state;
  }
}
