import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Card, CardBody, CardText, CardTitle} from 'reactstrap';
import theme from '../../constants/theme';
import {makeStyles} from '@material-ui/core';
import Dropdown from '../Common/Dropdown';
import Button from '../Common/Button';
import clsx from 'clsx';
import {injectIntl} from 'react-intl';
import {TreePoints} from '../../constants/images';

/**
 * Notification item
 */
const NofificationItem = ({
  intl,
  rootClassName = '',
  innerClassName = '',
  short = false,
  color,
  subject,
  message,
  link,
  createdAt,
  actionsShown,
  isUnread,
  onRead = () => {},
  onDelete = () => {},
}) => {
  //Get the notification color value
  const getColor = color => {
    return theme.palette[color]?.main || theme.palette.secondary.main;
  };

  //Styles
  const useStyles = makeStyles(theme => ({
    root: {
      position: 'relative',
      border: 'none',
      borderRadius: 0,
      '& .card-body': {
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
        borderLeftColor: getColor(color),
        padding: '15px 25px',
        [theme.breakpoints.down('md')]: {
          padding: '0 0 12px 0',
          borderLeftWidth: 0,
        },
        '& .card-title': {
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 5,
          '& h6': {
            lineHeight: 1.2,
          },
        },
        '& .card-text': {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        },
        '& .actions': {
          '& .MuiButtonBase-root': {
            minWidth: 'unset',
            marginLeft: 3,
            marginRight: 3,
          },
        },
      },
      '& .unread-mark': {
        position: 'absolute',
        left: 12,
        top: 3,
        color: theme.palette.danger.main,
        fontSize: 27,
        fontStyle: 'normal',
      },
    },
  }));

  const classes = useStyles();

  //Read state
  const [unread, setUnread] = useState(isUnread);

  //Mark as read
  const markAsRead = () => {
    setUnread(false);
    onRead();
  };

  return (
    <Card className={clsx(classes.root, rootClassName, {unread: unread})}>
      {!short && unread && (
        <i className="d-none d-lg-block unread-mark">&bull;</i>
      )}
      <CardBody className={innerClassName}>
        {!short && (
          <CardTitle>
            <Typography variant="h6" className="font-weight-bold mb-0">
              {subject}
            </Typography>
            <Typography variant="subtitle2" className="ps-2">
              5 hours ago
            </Typography>
          </CardTitle>
        )}
        <CardText tag="div">
          <div className="pe-3">{message}</div>
          {!short && (
            <Dropdown
              menuIcon={
                <TreePoints
                  style={{
                    fontSize: 14,
                    paddingTop: 9,
                    color: theme.palette.secondary.light,
                  }}
                />
              }
              items={[
                {
                  label: intl.formatMessage({id: 'notifications.mark_read'}),
                  onClick: markAsRead,
                },
                {
                  label: intl.formatMessage({id: 'actions.delete'}),
                  onClick: onDelete,
                },
              ]}
            />
          )}
        </CardText>
        {!short && actionsShown && (
          <div className="actions d-flex flex-nowrap">
            <a href={link} className="text-dark cursor-pointer me-1">
              {intl.formatMessage({id: 'notifications.view_detail'})}
            </a>
            |
            <Button
              link
              title={
                <Typography
                  variant="body2"
                  color="primary"
                  className="text-decoration-underline"
                >
                  {intl.formatMessage({id: 'actions.accept'})}
                </Typography>
              }
              onClick={() => {}}
            />
            |
            <Button
              link
              title={
                <Typography
                  variant="body2"
                  className="text-decoration-underline text-danger"
                >
                  {intl.formatMessage({id: 'actions.decline'})}
                </Typography>
              }
              onClick={() => {}}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

NofificationItem.propTypes = {
  /**
   * Card element class name
   */
  rootClassName: PropTypes.string,
  /**
   * Card body element class name
   */
  innerClassName: PropTypes.string,
  /**
   * Short mode (see the message only)
   */
  short: PropTypes.bool,
  /**
   * Notification is unreaded
   */
  isUnread: PropTypes.bool,
  /**
   * Notification subject
   */
  subject: PropTypes.string,
  /**
   * Notification message
   */
  message: PropTypes.any.isRequired,
  /**
   * Notification creation date/time
   */
  createdAt: PropTypes.string,
  /**
   * Show actions
   */
  actionsShown: PropTypes.bool,
  /**
   * Notification colour
   */
  color: PropTypes.oneOf(['primary', 'warning', 'pink', 'blue', 'purple']),
  /**
   * Notification link
   */
  link: PropTypes.string,
  /**
   * Mark as read callback
   */
  onRead: PropTypes.func,
  /**
   * Delete action callback
   */
  onDelete: PropTypes.func,
};

export default injectIntl(NofificationItem);
