import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import InputField from '../components/Form/Fields/InputField';
import {Button, Card} from '@material-ui/core';
import {FANPASS_LOGO_IMAGE} from '../constants/images';
import validate from './validate';
import {useLazyGetMeQuery, useLoginMutation} from '../services/authApi';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {enqueueSnackbarError} from '../actions/snackbarActions';
import {
  logout,
  selectTargetPath,
  updateToken,
  updateUser,
} from '../store/slices/authSlice';
import {useHistory} from 'react-router-dom';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {OLD_ADMIN_PATH} from '../constants/urls';
import {useIntl} from 'react-intl';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomButton from '../components/Common/Button';
import TwoFaQrCode from './TwoFaQrCode';
import TwoFaCodeVerification from './TwoFaCodeVerification';

export type LoginFormData = {
  email: string;
  password: string;
};

export type LoginProps = {} & InjectedFormProps<LoginFormData, any>;

export enum LoginStep {
  LoginForm,
  QrCode,
  TwoFaCode,
}

const Login = ({handleSubmit}: LoginProps) => {
  const [login, {data, isSuccess, isLoading, isError, error}] =
    useLoginMutation();
  const [getMe, {data: me, isSuccess: meIsSuccess}] = useLazyGetMeQuery();
  const dispatch = useDispatch();
  const targetPath = useSelector(selectTargetPath);
  const history = useHistory();
  const intl = useIntl();
  const [step, setStep] = useState(LoginStep.LoginForm);
  const [qrCode, setQrCode] = useState<string | undefined>();

  const onSubmit = (values: LoginFormData) => {
    const {email, password} = values;
    login({email, password});
  };

  useEffect(() => {
    if (isSuccess && data) {
      const {token, twoFactorComplete, login: isLoginSuccess, qrCode} = data;

      if (isLoginSuccess && !twoFactorComplete) {
        setQrCode(qrCode);
        setStep(qrCode ? LoginStep.QrCode : LoginStep.TwoFaCode);
      } else if (token) {
        // save the token
        dispatch(updateToken({token}));
        getMe({token});
      }
    }
  }, [data, dispatch, getMe, isSuccess]);

  useEffect(() => {
    if (isError) {
      const message = get(error, 'data.message', get(error, 'error')) as any;
      dispatch(enqueueSnackbarError({message}));
    }
  }, [dispatch, error, isError]);

  useEffect(() => {
    if (meIsSuccess) {
      // make sure me is an admin account
      if (me?.is_admin) {
        dispatch(updateUser({user: me}));
        // redirect to targetPath or /
        history.push(targetPath ?? '/');
      } else {
        dispatch(
          enqueueSnackbarError({message: 'Please login with an admin account'})
        );
        dispatch(logout());
      }
    }
  }, [dispatch, history, me, meIsSuccess, targetPath]);

  const handleVerificationSuccess = useCallback(
    ({token}) => {
      dispatch(updateToken({token}));
      getMe({token});
    },
    [dispatch, getMe]
  );

  return (
    <>
      {step === LoginStep.LoginForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-6 col-lg-5 col-xxl-4">
                <div className="vh-100 d-flex align-items-center">
                  <div className="w-100">
                    <Card className="p-4 p-xl-5">
                      <div className="mb-5 text-center">
                        <img
                          src={FANPASS_LOGO_IMAGE}
                          alt="admin logo"
                          style={{maxWidth: '100%'}}
                        />
                      </div>
                      <div className="form-group">
                        <span className="small-label">Email</span>
                        <Field
                          name="email"
                          component={InputField}
                          // component="input"
                          outlined
                          className="input-full-width fan-material-input"
                        />
                      </div>
                      <div className="form-group">
                        <span className="small-label">Password</span>
                        <Field
                          name="password"
                          component={InputField}
                          type="password"
                          outlined
                          className="input-full-width fan-material-input"
                        />
                      </div>
                      <div className="mb-5"></div>
                      <Button
                        type="submit"
                        variant="contained"
                        className="w-100
                        text-uppercase"
                        color="primary"
                        disabled={isLoading}
                      >
                        Login
                      </Button>
                    </Card>
                    <CustomButton
                      link
                      component="a"
                      href={OLD_ADMIN_PATH}
                      icon={<KeyboardBackspaceIcon />}
                      title={intl.formatMessage({id: 'actions.backOldAdmin'})}
                      className="mt-2 text-muted d-xl-inline-flex"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {step === LoginStep.QrCode && (
        <TwoFaQrCode
          onNextClick={() => setStep(LoginStep.TwoFaCode)}
          qrCodeProps={{
            src: qrCode,
          }}
        />
      )}
      {step === LoginStep.TwoFaCode && (
        <TwoFaCodeVerification
          onCancelClick={() => setStep(LoginStep.LoginForm)}
          onLoginSuccess={handleVerificationSuccess}
        />
      )}
    </>
  );
};

export default reduxForm({
  form: Login.name,
  validate,
})(Login);
