import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {hideSpinner, showSpinner} from '../../actions/spinnerActions';
import {downloadEticket, downloadMticket} from '../../services/saleApi';
import {useSelector} from 'react-redux';
import {selectToken} from '../../store/slices/authSlice';
import {AxiosResponse} from 'axios';
import {enqueueSnackbarError} from '../../actions/snackbarActions';

function resolveBlob(response: AxiosResponse<Blob>, download = false) {
  const headerval = response.headers['content-disposition'];
  if (headerval != null) {
    let filename = headerval
      .split(';')[1]
      .split('=')[1]
      .replace('"', '')
      .replace('"', '');
    filename = decodeURI(filename);
    // const url = window.URL.createObjectURL(new Blob([response.data]));
    const url = window.URL.createObjectURL(response.data);
    // const pdfWindow = window.open();
    // pdfWindow!.location.href = url;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    if (download) {
      link.setAttribute('download', filename);
    }
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  } else {
    handleKnownException(response);
  }
}

function handleKnownException(response: AxiosResponse<Blob>) {
  const reader = new FileReader();
  reader.onload = function () {
    if (reader.result != null) {
      const responseData = JSON.parse(reader.result as any);
      if (responseData.code === 500) {
        throw new Error(responseData.msg);
      }
    }
  };
  reader.readAsText(response.data);
}

export type UseSaleUtilsReturn = {
  getEticket: (id: number, download?: boolean) => void;
  getMticket: (id: number, download?: boolean) => void;
};

export default function useSaleUtils(): UseSaleUtilsReturn {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const getFile = useCallback(
    async (id: number, getFileFn: Function, download?: boolean) => {
      try {
        dispatch(showSpinner());
        const res = await getFileFn.call(null, {id, token});
        dispatch(hideSpinner());
        resolveBlob(res, download);
      } catch (e) {
        dispatch(hideSpinner());
        dispatch(enqueueSnackbarError({message: e.message}));
      }
    },
    [dispatch, token]
  );

  const getEticket = useCallback(
    async (id: number, download?: boolean) =>
      getFile(id, downloadEticket, download),
    [getFile]
  );

  const getMticket = useCallback(
    async (id: number, download?: boolean) =>
      getFile(id, downloadMticket, download),
    [getFile]
  );

  const res = useMemo<UseSaleUtilsReturn>(
    () => ({
      getEticket,
      getMticket,
    }),
    [getEticket, getMticket]
  );

  return res;
}
