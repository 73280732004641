import React from 'react';
import RadioGroup from '../../Common/RadioGroup';
import {FormHelperText} from '@material-ui/core';
import {touch} from 'redux-form';
import {useDispatch} from 'react-redux';

interface RadioGroupFieldProps<Item extends Record<string, any>> {
  input: any;
  currentValue?: any;
  meta?: any;
  items: Item[];
  radioGroupProps?: any;
  labelField?: keyof Item | 'label';
  valueField?: keyof Item | 'value';
  ignoreReduxForm?: boolean;
}

function RadioGroupField<Item extends Record<string, any>>(
  props: RadioGroupFieldProps<Item>
) {
  const {
    input: {value, onChange, ...restInput},
    currentValue,
    meta = {},
    items,
    radioGroupProps,
    labelField = 'label',
    valueField = 'value',
    ignoreReduxForm,
    ...custom
  } = props;
  const {touched, error} = meta;
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched && !ignoreReduxForm) {
      dispatch(touch(meta.form, props.input.name));
    }
    onChange(event.target.value);
  };

  return (
    <>
      <RadioGroup
        items={items.map(item => ({
          label: item[labelField],
          value: item[valueField],
        }))}
        {...custom}
        {...restInput}
        radioGroupProps={{
          ...radioGroupProps,
          onChange: handleChange,
          value: value ?? currentValue,
        }}
        formControlProps={{
          error: error && touched,
        }}
        infoText={touched && error && <FormHelperText>{error}</FormHelperText>}
      />
    </>
  );
}

export default RadioGroupField;
