import React, {ChangeEvent, forwardRef, MouseEventHandler} from 'react';
import clsx from 'clsx';
import MaterialInput from '../../Common/MaterialInput';
import TextField from '../../Common/TextField';
import {useDispatch} from 'react-redux';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import {touch} from 'redux-form';
import {Control} from 'react-hook-form';

type InputFieldProps = Omit<TextFieldProps, 'onChange' | 'defaultValue'> & {
  inputClass?: string;
  excludeLabel?: boolean;
  label?: string;
  controlClass?: string;
  outlineGroupClass?: string;
  externalLabel?: boolean;
  formGroupClass?: string;
  disableUnderline?: boolean;
  errorHelperText?: boolean;
  outlined?: boolean;
  ignoreDispatch?: boolean;
  defaultValue?: string;
  control?: Control<any, any>;
  addonPosition?: 'start' | 'end';
  addon?: JSX.Element;
  onAddonClick?: MouseEventHandler;
  input?: {
    onChange?: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
    value?: string | null;
    name?: string;
    onBlur?: (event: React.FocusEvent<EventTarget>) => void;
    onFocus?: (event: React.FocusEvent<EventTarget>) => void;
  };
  meta?: {
    touched?: boolean;
    error?: string;
    form?: string;
  };
  wrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    'data-testid'?: string;
  };
};

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props: InputFieldProps, ref) => {
    const {
      inputClass,
      excludeLabel,
      label,
      controlClass = '',
      outlineGroupClass = 'mb-0',
      externalLabel,
      formGroupClass = 'form-group text-left',
      input,
      meta,
      disableUnderline = false,
      errorHelperText = true,
      outlined = false,
      ignoreDispatch,
      wrapperProps,
      ...custom
    } = props;

    const dispatch = useDispatch();
    const extra = {label};
    if (excludeLabel || externalLabel) {
      delete extra.label;
    }

    const onInputChange = (event: ChangeEvent<HTMLInputElement> | string) => {
      if (
        !meta?.touched &&
        !ignoreDispatch &&
        meta?.form &&
        props.input?.name
      ) {
        dispatch(touch(meta.form, props.input.name));
      }
      if (input?.onChange) {
        input.onChange(event);
      }
    };

    return (
      <div
        className={clsx(formGroupClass, {
          [outlineGroupClass]: outlined,
          'form-group-invalid': meta?.touched && meta?.error,
        })}
        {...wrapperProps}
      >
        {outlined && externalLabel && label && (
          <span className="small-label pb-2">{label}</span>
        )}
        {outlined ? (
          <TextField
            className={clsx(controlClass, inputClass)}
            onChange={
              onInputChange as (event: ChangeEvent<HTMLInputElement>) => void
            }
            helperText={errorHelperText && meta?.touched ? meta?.error : null}
            error={meta?.touched && !!meta?.error}
            {...custom}
            name={input?.name}
            value={input?.value}
            {...extra}
            onBlur={input?.onBlur}
            onFocus={input?.onFocus}
            variant="outlined"
            ref={ref}
          />
        ) : (
          <MaterialInput
            className={clsx(controlClass, inputClass)}
            disableUnderline={disableUnderline}
            onChange={onInputChange as (event: string) => void}
            helperText={errorHelperText && meta?.touched ? meta?.error : null}
            error={meta?.touched && !!meta?.error}
            {...custom}
            name={input?.name}
            value={input?.value}
            {...extra}
            onBlur={input?.onBlur}
            onFocus={input?.onFocus}
            ref={ref}
          />
        )}
        {/* Show error here if errorHelperText is false */}
        {!errorHelperText && meta?.touched && meta?.error && (
          <span className="error">{meta?.error}</span>
        )}
      </div>
    );
  }
);

export default InputField;
