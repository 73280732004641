import PropTypes from 'prop-types';
import Modal from '../../Widgets/Modal';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {useIntl} from 'react-intl';

const DeleteConfirmationModal = ({opened, onAccept, onClose}) => {
  const intl = useIntl();

  return (
    <Modal
      opened={opened}
      width={328}
      saveTitle={intl.formatMessage({id: 'actions.yes'})}
      cancelTitle={intl.formatMessage({id: 'actions.no'})}
      title={intl.formatMessage({id: 'actions.confirmation_popup.areYouSure'})}
      onOk={onAccept}
      handleClose={onClose}
    >
      <Typography variant="body2">
        {intl.formatMessage({id: 'actions.confirmation_popup.description'})}
      </Typography>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  opened: PropTypes.bool,
};

export default DeleteConfirmationModal;
