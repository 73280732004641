import {call, put} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as userActivityApi from '../connectivity/userActivity.api';
import {handleException} from './utils';

// Get user activity
export function* doGetUserActivity(action) {
  try {
    const data = yield call(userActivityApi.getUserActivity, action.payload);
    yield put({
      type: types.API_USER_ACTIVITY_SUCCEEDED,
      payload: {data},
    });
  } catch (error) {
    yield handleException({
      type: types.API_USER_ACTIVITY_FAILED,
      error,
    });
  }
}
