import clsx from "clsx";
import { FocusEventHandler, HTMLProps, KeyboardEvent, useMemo } from "react";

export type VerificationInputProps = {
  value: string;
  onChange: (newValue: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: (newValue: string) => void;
  error?: string;
};

const InputItem = (props: HTMLProps<HTMLInputElement>) => (
  <input
    className="form-control code-input"
    autoComplete="off"
    type="text"
    id="code-2"
    size={1}
    maxLength={1}
    {...props}
  />
);

const VerificationInput = ({
  value = '',
  onChange,
  onFocus,
  onBlur,
  error,
}: VerificationInputProps) => {
  const values = useMemo<(string|null)[]>(() => new Array(6).fill('').map((v, i) => (value && value[i]) ?? ''), [value]);

  const changeHandler = useMemo(() => (i: number) => (event: KeyboardEvent<HTMLInputElement>) => {
    const newValue = new Array(6).fill(' ').map((v, i) => (value && value[i]) ?? v);
    let updated = false;
    let nextIndex = null;

    if (/^\d$/.test(event.key)) {
      newValue[i] = event.target ? String(event.key)[0] : ' ';
      updated = true;
      nextIndex = i < 5 ? (i + 1) : null;
    // backspace
    } else if (event.keyCode === 8) {
      newValue[i] = ' ';
      updated = true;
      nextIndex = i > 0 ? (i - 1) : null;
    // left and right arrow keys
    } else if ([37, 39].includes(event.keyCode)) {
      nextIndex = event.keyCode === 37 && i > 0 ? (i - 1) : (i < 5 && event.keyCode === 39 ? (i + 1) : null);
    }

    if (nextIndex !== null) {
      const nextEl = document.getElementById(`verification-code-${nextIndex + 1}`);
      if (nextEl && nextEl instanceof HTMLInputElement) {
        nextEl.focus();
        setTimeout(() => nextEl.select(), 10);
      }
    }
    if (updated) {
      onChange(newValue.join('').trimEnd());
    }
  }, [onChange, value]);

  return (
    <div className="fields-block">
      {/* Verification code */}
      <div className={clsx('input-code-container', {'has-error': !!error})}>
        <div className="parts part-1">
          <InputItem
            id="verification-code-1"
            // autoFocus
            value={values[0] ?? undefined}
            onKeyDown={changeHandler(0)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
          <InputItem
            id="verification-code-2"
            value={values[1] ?? undefined}
            onKeyDown={changeHandler(1)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
          <InputItem
            id="verification-code-3"
            value={values[2] ?? undefined}
            onKeyDown={changeHandler(2)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
        </div>
        <span className="separator">―</span>
        <div className="parts part-2">
          <InputItem
            id="verification-code-4"
            value={values[3] ?? undefined}
            onKeyDown={changeHandler(3)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
          <InputItem
            id="verification-code-5"
            value={values[4] ?? undefined}
            onKeyDown={changeHandler(4)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
          <InputItem
            id="verification-code-6"
            value={values[5] ?? undefined}
            onKeyDown={changeHandler(5)}
            onFocus={onFocus}
            onBlur={() => onBlur && onBlur(value)}
          />
        </div>
      </div>
      <div className="errors-block">
        {/* Authentication errors */}
        {error && <p className="err-msg">{error}</p>}
      </div>
    </div>
  );
};

export default VerificationInput;
