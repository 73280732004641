import React from 'react';
import * as PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import useDateHelper from '../../hooks/utils/useDateHelper';

const EventDate = props => {
  const {event, dateFormat} = props;
  const dateHelper = useDateHelper();
  const intl = useIntl();

  //If no event the return null
  if (!event) {
    return null;
  }

  // Display 'Date to be confirmed' for postponed event
  if (event?.postponed === true) {
    return intl.formatMessage({id: 'dashboard.events.date_to_be_confirmed'});
  }

  //Display event formatted date
  return dateHelper.format(event?.date, dateFormat);
};

EventDate.propTypes = {
  event: PropTypes.object,
  dateFormat: PropTypes.string,
};

EventDate.defaultProps = {
  dateFormat: 'DD MMM YYYY',
};

export default React.memo(EventDate);
