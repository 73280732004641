import {useDispatch, useSelector} from 'react-redux';
import {API_USER_BALANCE_REQUESTED} from '../../constants/actionTypes';
import {selectToken} from '../../store/slices/authSlice';

/**
 *  Get user's activity
 *
 * @param {string} id user id
 */
export default function useUserBalance(id) {
  const token = useSelector(selectToken);
  const {balance, loading} = useSelector(state => state.userBalanceReducer);

  const dispatch = useDispatch();

  // Get User Balance
  const getUserBalance = () => {
    dispatch({
      type: API_USER_BALANCE_REQUESTED,
      payload: {token, id},
    });
  };

  return {
    getUserBalance,
    balance,
    loading,
  };
}
