export enum TeamPermission {
  /**
   * See Team list
   */
  Index = 'admin_see_team_list',

  /**
   * Add Team members
   */
  Add = 'admin_add_team_member',

  /**
   * Edit Team members
   */
  Edit = 'admin_edit_team_member',

  /**
   * Enable or disable 2FA
   */
  EnableDisable2Fa = 'admin_enable_disable_2fa',

  /**
   * Reset 2FA
   */
  Reset2Fa = 'admin_reset_2fa',

  /**
   * Suspend Team members
   */
  Suspend = 'admin_suspend_team_member',

  /**
   * Delete Team members
   */
  Delete = 'admin_delete_team_member',
}
