import { Box, Button, Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FallbackProps } from 'react-error-boundary';

const ErrorPage = ({ error }: FallbackProps) => (
  <Container style={{ maxHeight: '100vh' }}>
    <Box py={3}>
      <Alert severity="error">
        <Typography variant="h5">{error.message}</Typography>
        <Box pt={3}>
          <pre>{error.stack}</pre>
        </Box>
      </Alert>
    </Box>
  </Container>
);

export default ErrorPage;
