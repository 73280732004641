import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '../../Common/Checkbox';
import IconButton from '../../Common/IconButton';

/**
 * Header of Data Table
 * @param props
 * @returns {*}
 * @constructor
 */
const DataTableHead = props => {
  const {
    canSelect,
    headCells,
    actionButton,
    wrappedRow,
    canPauseStart,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  /**
   * Sort table by column
   * @param property
   * @returns {Function}
   */
  const createSortHandler = useCallback(
    property => event => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  const columns = useMemo(() => {
    return headCells.map(
      (
        {
          name,
          collapse,
          disablePadding,
          label,
          numeric,
          sortable = true,
          width = undefined,
        },
        index
      ) => (
        <TableCell
          key={name}
          className={clsx(
            collapse ? 'd-none d-lg-table-cell' : 'd-table-cell text-nowrap',
            'overflow-hidden',
            classes.headCell,
            !canSelect && 'pb-lg-3'
          )}
          align={numeric ? 'right' : 'left'}
          padding={disablePadding ? 'none' : null}
          sortDirection={orderBy === name ? order : false}
          style={
            width
              ? {
                  width: width,
                }
              : {}
          }
        >
          {sortable ? (
            <TableSortLabel
              className={clsx(
                'd-block text-truncate',
                numSelected < rowCount && 'color-disabled',
                !canSelect && index === 0 && 'ps-lg-1 '
              )}
              active={orderBy === name}
              direction={orderBy === name ? order : 'asc'}
              onClick={createSortHandler(name)}
            >
              {label}
              {/* Ordered by this column ? */}
              {orderBy === name && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          ) : (
            <TableSortLabel
              className={clsx({'color-disabled': numSelected < rowCount})}
            >
              {label}
            </TableSortLabel>
          )}
        </TableCell>
      )
    );
  }, [
    canSelect,
    classes.headCell,
    classes.visuallyHidden,
    createSortHandler,
    headCells,
    numSelected,
    order,
    orderBy,
    rowCount,
  ]);

  return (
    <TableHead>
      <TableRow>
        {/* Select All checkbox */}
        {canSelect && (
          <TableCell className={classes.headCell} padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
        )}
        {/* Columns */}
        {columns}
        {/* Show action button on header (mobile only) */}
        <TableCell
          align="right"
          className={clsx(
            classes.headCell,
            'd-table-cell',
            {'d-lg-none': !wrappedRow},
            'action-wrapper'
          )}
          colSpan={1}
          style={{width: canPauseStart ? 85 : ''}}
        >
          {actionButton ? (
            <IconButton {...actionButton} className="m-0 ml-auto">
              {actionButton.children}
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * Table head description array
   */
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * column name
       */
      name: PropTypes.string.isRequired,
      /**
       * Column is numeric (if TRUE it will be right aligned)
       */
      numeric: PropTypes.bool,
      /**
       * If TRUE then left padding is not added
       */
      disablePadding: PropTypes.bool,
      /**
       * Column text label
       */
      label: PropTypes.string.isRequired,
      /**
       * Cell rendering procedure
       */
      renderValue: PropTypes.func,
      /**
       * Make this field collapsed in mobile view
       */
      collapse: PropTypes.bool,
    })
  ),
  /**
   * Selected rows number
   */
  numSelected: PropTypes.number.isRequired,
  /**
   * Sort column callback
   */
  onRequestSort: PropTypes.func.isRequired,
  /**
   * Header ceckbox handler
   */
  onSelectAllClick: PropTypes.func.isRequired,
  /**
   * Order direction
   */
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  /**
   * Order column
   */
  orderBy: PropTypes.string.isRequired,
  /**
   * Number of rows
   */
  rowCount: PropTypes.number.isRequired,
};

export default DataTableHead;
