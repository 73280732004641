import {makeStyles} from '@material-ui/core';
import {KeyboardTimePicker} from '@material-ui/pickers';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {CLOCK_ICON} from '../../../constants/images';
import {PICKER_ROOT_STYLES} from '../../../constants/theme';

const useStyles = makeStyles(theme => ({
  root: PICKER_ROOT_STYLES,
}));

const TimePickerField = props => {
  const {
    label,
    excludeLabel,
    input: {name, value, onChange},
    outlined,
    format,
    outputFormat,
    meta,
    InputProps,
    ...others
  } = props;
  const [val, setVal] = useState(null);
  const {touched, error} = meta;
  const extra = {label};
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [opened, setOpened] = useState(false);

  if (excludeLabel) {
    delete extra.label;
  }

  if (outlined) {
    extra.inputVariant = 'outlined';
    extra.size = 'small';
  }

  const handleChange = newValue => {
    setVal(newValue);
  };

  const handleClose = arg => {
    if (val) {
      const formatted = val.format(outputFormat);
      if (formatted !== value) {
        onChange(formatted);
      }
    }
    others.onClose && others.onClose(arg);
    setOpen(false);
    setTimeout(() => setOpened(false), 1000);
  };

  const handleOpen = arg => {
    others.onOpen && others.onOpen(arg);
    setOpen(true);
  };

  useEffect(() => {
    if (value && moment(value, outputFormat).isValid()) {
      setVal(moment(value, outputFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!opened && focused) {
      setOpened(true);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  return (
    <KeyboardTimePicker
      keyboardIcon={CLOCK_ICON}
      value={val}
      classes={classes}
      fullWidth={props.fullWidth}
      {...others}
      InputProps={{
        ...InputProps,
        name,
        onFocus: e => {
          InputProps.onFocus && InputProps.onFocus(e);
          setFocused(true);
        },
        onBlur: e => {
          InputProps.onBlur && InputProps.onBlur(e);
          setFocused(false);
        },
      }}
      autoOk={false}
      onChange={handleChange}
      {...(touched && error ? {helperText: error, error: true} : {})}
      {...extra}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

TimePickerField.defaultProps = {
  fullWidth: true,
  variant: 'inline',
  outputFormat: 'HH:mm',
  InputProps: {},
};

export default TimePickerField;
