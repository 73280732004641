import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

/**
 * Dashboard top paper wrapper with shadow
 * @param children
 * @param others
 */
const TopPaper = ({children, ...others}) => {
  //custom styles
  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(6.5),
      marginBottom: theme.spacing(3.5),
      marginRight: -15,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.text.border,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: 'transparent',
        borderBottomStyle: 'none',
        boxShadow: 'none',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Paper classes={classes} square elevation={5} {...others}>
      {children}
    </Paper>
  );
};

TopPaper.propTypes = {
  /**
   * Content
   */
  children: PropTypes.any,
};

export default TopPaper;
