import {
  TRANSACTIONS_TYPE,
  TRANSACTIONS_TYPE_ENTITY,
} from '../constants/transactions';

export function parseKeywordSearchValue(q) {
  if (!q) {
    return q;
  }
  let res = [q];
  const pattern = new RegExp(q, 'i');

  if (q && q.length > 1) {
    const labels = Object.values(TRANSACTIONS_TYPE).filter(label =>
      pattern.test(label)
    );
    const values = Object.keys(TRANSACTIONS_TYPE).filter(value =>
      labels.includes(TRANSACTIONS_TYPE[value])
    );
    const entities = values.map(value => TRANSACTIONS_TYPE_ENTITY[value]);

    if (entities.length > 0) {
      res = res.concat(entities);
    }
  }

  return res;
}

export function parseSort(sort) {
  const res = sort;
  if (sort && sort.by === 'description') {
    sort.by = 'eventName';
  }

  return res;
}
