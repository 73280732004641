import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { buildBaseQuery } from "./functions";
import { API_BASE_URL } from "../constants/urls";
import { Role } from "../components/Settings/types";

export const roleApi = createApi({
  baseQuery: buildBaseQuery(`${API_BASE_URL}/api`),
  endpoints: (builder) => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: '/admin/roles',
      }),
      transformResponse: (res: { result: Role[]}): Role[] => res.result,
    }),
  }),
});

export const {
  useGetRolesQuery,
} = roleApi;
