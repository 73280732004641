import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useIntl} from 'react-intl';

const TotalPenalty = props => {
  const {maxPenaltyAmount, totalPenalty} = props;
  const intl = useIntl();
  return (
    <div className="mx-2 mx-lg-0">
      <div className="row align-items-center bg-primary-light rounded py-2 mb-1">
        <div className="col py-1">
          <Typography color="primary" className="mb-1 font-weight-bold">
            {intl.formatMessage({id: 'dashboard.sales.penalty.penalty_total'})}
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
          >{`${intl.formatMessage({id: 'dashboard.sales.penalty.max_penalty_amount'})}: ${maxPenaltyAmount}`}</Typography>
        </div>
        <div className="col-auto">
          <Typography variant="h4" color="primary">
            {totalPenalty}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TotalPenalty;
