import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

const SalesSummaryRow = ({label, value, bold, ...other}) => (
  <div className="row gx-1 pt-2 pb-1" {...other}>
    <div className="col">
      <Typography variant="body2" className={clsx({'font-weight-bold': bold})}>
        {label}
      </Typography>
    </div>
    <div className="col-auto">
      <Typography variant="body2" className={clsx({'font-weight-bold': bold})}>
        {value}
      </Typography>
    </div>
  </div>
);

SalesSummaryRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bold: PropTypes.bool,
};

SalesSummaryRow.defaultProps = {
  bold: false,
};

export default SalesSummaryRow;
