import {KeyboardDateTimePicker} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {CalendarIcon} from '../../../constants/images';
import {makeStyles} from '@material-ui/core';
import {PICKER_ROOT_STYLES} from '../../../constants/theme';

export const OUTPUT_FORMAT = 'YYYY-MM-DD HH:mm';

const useStyles = makeStyles(theme => ({
  root: PICKER_ROOT_STYLES,
}));

/**
 * Redux-form datetime picker field
 * @param {*} props
 */
const DateTimePickerField = props => {
  const {
    label,
    excludeLabel,
    placeholder,
    format,
    outputFormat,
    outlined,
    input: {name, value, onChange},
    meta,
    InputProps,
    ...others
  } = props;
  const {touched, error} = meta;
  const [val, setVal] = useState(null);
  const classes = useStyles();
  const extra = {label};
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [opened, setOpened] = useState(false);

  if (excludeLabel) {
    delete extra.label;
  }

  if (outlined) {
    extra.inputVariant = 'outlined';
    extra.size = 'small';
  }

  const handleChange = newValue => {
    setVal(newValue);
  };

  const handleClose = arg => {
    if (val) {
      const formatted = val.format(outputFormat);
      if (formatted !== value) {
        onChange(formatted);
      }
    }
    others.onClose && others.onClose(arg);
    setOpen(false);
    setTimeout(() => setOpened(false), 1000);
  };

  const handleOpen = arg => {
    others.onOpen && others.onOpen(arg);
    setOpen(true);
  };

  useEffect(() => {
    if (value && moment(value, outputFormat).isValid()) {
      setVal(moment(value, outputFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!opened && focused) {
      setOpened(true);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  return (
    <KeyboardDateTimePicker
      fullWidth={props.fullWidth}
      variant="inline"
      placeholder={placeholder}
      keyboardIcon={CalendarIcon}
      classes={classes}
      {...others}
      InputLabelProps={{
        ...others.InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        name,
        onFocus: e => {
          InputProps.onFocus && InputProps.onFocus(e);
          setFocused(true);
        },
        onBlur: e => {
          InputProps.onBlur && InputProps.onBlur(e);
          setFocused(false);
        },
      }}
      value={val}
      autoOk={false} // redux-form onChange is fired onClose
      onChange={handleChange}
      {...extra}
      {...(touched && error ? {helperText: error, error: true} : {})}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

DateTimePickerField.propTypes = {
  excludeLabel: PropTypes.bool,
  outlined: PropTypes.bool,
};

DateTimePickerField.defaultProps = {
  fullWidth: true,
  outputFormat: OUTPUT_FORMAT,
  outlined: false,
  InputProps: {},
};

export default DateTimePickerField;
