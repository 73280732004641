import React from 'react';
import {makeStyles} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import {RadioProps} from '@material-ui/core/Radio/Radio';

const useStyles = makeStyles(theme => ({
  root: {},
  checked: {},
  icon: {
    width: 11,
    height: 11,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='12px' height='12px' viewBox='0 0 12 12'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW---UI-style-guideline' transform='translate(-207.000000, -1128.000000)' fill='%23FFFFFF' stroke='%23B8B8B8'%3E%3Ccircle id='Oval-Copy-3' cx='213' cy='1134' r='5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
    'input:disabled ~ &': {
      boxShadow: 'none',
      borderRadius: 11,
      background: (theme.palette.text as any).border,
    },
  },
  checkedIcon: {
    width: 11,
    height: 11,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 62 (91390) - https://sketch.com --%3E%3Ctitle%3EGroup 11%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW_Admin_User_Recipient-View-Profile_Recipient' transform='translate(-399.000000, -650.000000)'%3E%3Cg id='Group-11' transform='translate(400.000000, 651.000000)'%3E%3Ccircle id='Oval-Copy-2' stroke='%2321B59B' fill='%23FFFFFF' cx='5' cy='5' r='5'%3E%3C/circle%3E%3Ccircle id='Oval-Copy-2' fill='%2321B59B' cx='5' cy='5' r='3'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
  },
}));

const CustomRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: classes.root,
      }}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={classes.checkedIcon} />}
      {...props}
    />
  );
};

export default CustomRadio;
