import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import TopPaper from '../Common/TopPaper';
import Typography from '@material-ui/core/Typography';
import Tabs from '../Common/Tabs';
import {
  TRANSACTIONS_PAYMENT_PATH,
  TRANSACTIONS_PAYOUT_PATH,
} from '../../constants/urls';
import {useHistory, useRouteMatch} from 'react-router-dom';

const PAYOUTS_TAB = 'payouts';
const PAYMENTS_TAB = 'payments';

const TransactionHeader = props => {
  const intl = useIntl();
  const isPayoutList = useRouteMatch(TRANSACTIONS_PAYOUT_PATH);
  const activeTab = useMemo(
    () => (isPayoutList ? PAYOUTS_TAB : PAYMENTS_TAB),
    [isPayoutList]
  );
  const history = useHistory();

  //Tabs items
  const tabs = [
    {
      label: intl.formatMessage({id: 'dashboard.transactions.tab_payment'}),
      value: PAYMENTS_TAB,
    },
    {
      label: intl.formatMessage({id: 'dashboard.transactions.tab_payout'}),
      value: PAYOUTS_TAB,
    },
  ];

  const toggle = useCallback(
    tab => {
      let url = TRANSACTIONS_PAYMENT_PATH;
      if (tab === PAYOUTS_TAB) {
        url = TRANSACTIONS_PAYOUT_PATH;
      }
      history.push(url);
    },
    [history]
  );

  return (
    <TopPaper>
      <div className="row header-row">
        <div className="col">
          <Typography
            variant="h3"
            className="font-weight-bolder text-center text-lg-start mb-lg-3"
          >
            {intl.formatMessage({
              id: 'dashboard.transactions.transaction_list',
            })}
          </Typography>
        </div>
      </div>
      {tabs.length > 1 && (
        <div className="row">
          <div className="col">
            {/* Tabs */}
            <Tabs
              className="mb-0"
              indicators={false}
              value={activeTab}
              tabs={tabs}
              onChange={toggle}
            />
          </div>
        </div>
      )}
    </TopPaper>
  );
};

export default TransactionHeader;
