import {useSelector, useDispatch} from 'react-redux';
import {API_ADMIN_USER_DETAIL_REQUESTED} from '../../constants/actionTypes';

const useFetchUser = userId => {
  const dispatch = useDispatch();
  // fetch user details from store
  const {user, loaded} = useSelector(state => state.userReducer);
  // dispatch action to saga
  const getUserData = () => {
    dispatch({
      type: API_ADMIN_USER_DETAIL_REQUESTED,
      payload: {
        userId,
      },
    });
  };

  return {loaded, user, getUserData};
};
export default useFetchUser;
