import React, {useEffect, useState} from 'react';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {CalendarIcon} from '../../../constants/images';
import {PICKER_ROOT_STYLES} from '../../../constants/theme';

/* Styles Datepicker input */
const useStyles = makeStyles(theme => ({
  root: {
    '&.outlined': PICKER_ROOT_STYLES,
    '&:not(.outlined)': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.light,
        fontWeight: 'normal',
        marginBottom: 0,
        // left: 10,
        // top: -14
        '&:not([data-shrink="true"])': {
          fontSize: 14,
        },
      },
      '& .MuiButtonBase-root': {
        '&:focus': {
          outline: 'none',
        },
      },
      '&.outlined': {
        border: `1px solid ${theme.palette.text.border}`,
      },
    },
  },
  input: {
    // apply border and padding only when there is not underline
    '&:not(.MuiInput-underline)': {
      border: 'none',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },
  popover: {
    '& .MuiPaper-root': {
      marginTop: 10,
      marginLeft: 20,
      boxShadow: theme.shadows[2],
    },
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: 181,
      maxWidth: 'unset',
      minHeight: 196,
      overflowY: 'hidden',
      borderRadius: 4,
    },
    '& .MuiPickersCalendar-transitionContainer': {
      maxWidth: 'unset',
      minHeight: 155,
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      padding: 5,
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiPickersDay-day': {
      width: 25,
      height: 25,
      borderRadius: 0,
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      width: 25,
    },
  },
}));

/* Date picker */
const DatePicker = props => {
  const {
    onChange,
    InputProps,
    InputLabelProps,
    PopoverProps,
    outlined,
    ...others
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [opened, setOpened] = useState(false);

  const handleChange = dateValue => {
    onChange(
      dateValue && dateValue.isValid() ? dateValue.format(props.format) : null
    );
  };

  const handleClose = e => {
    others.onClose && others.onClose(e);
    setOpen(false);
    setTimeout(() => setOpened(false), 1000);
  };

  const handleOpen = e => {
    others.onOpen && others.onOpen(e);
    setOpen(true);
  };

  useEffect(() => {
    if (!opened && focused) {
      setOpened(true);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  return (
    <KeyboardDatePicker
      autoOk={props.autoOk}
      fullWidth={props.fullWidth}
      variant={props.variant}
      format={props.format}
      value={props.value}
      keyboardIcon={<CalendarIcon style={{fontSize: 12}} />}
      onChange={handleChange}
      InputProps={{
        disableUnderline: outlined,
        ...InputProps,
        className: clsx(
          {[classes.input]: outlined},
          InputProps && InputProps.className
        ),
        onFocus: e => {
          InputProps.onFocus && InputProps.onFocus(e);
          setFocused(true);
        },
        onBlur: e => {
          InputProps.onBlur && InputProps.onBlur(e);
          setFocused(false);
        },
      }}
      PopoverProps={{
        className: classes.popover,
        elevation: 0,
        ...PopoverProps,
      }}
      {...others}
      className={clsx(classes.root, {outlined}, props.className)}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};

DatePicker.defaultProps = {
  format: 'MMMM D, YYYY',
  variant: 'inline',
  autoOk: true,
  fullWidth: true,
  InputProps: {},
  InputLabelProps: {},
  PopoverProps: {},
  outlined: true,
  disableToolbar: true,
};

export default DatePicker;
