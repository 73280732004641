import React, {useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Box, Typography} from '@material-ui/core';
import InputField from '../../Form/Fields/InputField';
import {
  DELIVERY_MARK_ICON,
  INSTANT_MARK_ICON,
  PREMIUM_MARK_ICON,
  PRO_MARK_ICON,
  TRUST_MARK_ICON,
} from '../../../constants/images';
import CustomSwitch from '../../Common/Switch';
import {connect, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  email,
  phoneNumber,
  required,
  validateBeforeSubmit,
} from '../../../validation/validators';
import {API_ADMIN_USER_EDIT_DETAIL_REQUESTED} from '../../../constants/actionTypes';
import CustomGenderRadioGroup from '../../Common/CustomGenderSwitch';
import PermissionContainer from '../../Common/PermissionContainer';
import CustomAutocomplete from '../../Common/CustomAutocomplete';
import {getYearsBetweenDates} from '../../../constants/dates';
import ConfirmationPopup from '../../Sales/Details/Popups/ConfirmationPopup';
import {checkExistingEmail} from '../../../connectivity/user.api';
import useProgressModal from '../../../hooks/app/useProgressModal';
import {selectToken} from '../../../store/slices/authSlice';
import usePermission from '../../../Auth/usePermission';

const validate = values => {
  const errors = {};
  // validate required fields and values
  errors.firstname = required(values.firstname);
  errors.lastname = required(values.lastname);
  errors.email = required(values.email) || email(values.email);
  errors.phoneNumber = phoneNumber(
    values.phoneNumber,
    'The phone number format is invalid'
  );
  return errors;
};

let ProfileDetailsGeneral = props => {
  const token = useSelector(selectToken);
  const {intl, user, change} = props;
  const dispatch = useDispatch();
  // Manage Switch state
  const [options, setOptions] = useState({
    isTrustableSeller: user.isTrustableSeller,
    isCustomDelivery: user.isCustomDelivery,
    isPremiumSeller: user.isPremiumSeller,
    isFanpassPro: user.isFanpassPro,
    isInstantConfirm: user.isInstantConfirm,
  });
  const [gender, setGender] = useState(user.gender);

  // checks the possible values of locale and auto fill the locale input
  let localeValue = '';
  if (user.locale && user.locale === 'en_GB') localeValue = 'English';
  else if (user.locale && user.locale === 'fr_FR') localeValue = 'French';
  const [locale, setLocale] = useState(localeValue || '');

  // set birth year
  const [birthYear, setBirthYear] = useState(user.birthyear || '');

  // show confirmation popup to delete existing user
  const [eraseExistingUserEmailPopoup, setEraseExistingUserEmailPopoup] =
    useState(false);

  // set new email
  const [email, setEmail] = useState(user.email);
  // the id of the user thqt we will erase email for
  const [existingUserId, setExistingUserId] = useState(null);

  // progress modal
  const {show: showSpinner, hide: hideSpinner} = useProgressModal();

  const {hasAnyPermission} = usePermission();
  const hasEditPermission = hasAnyPermission(['admin_edit_user']);

  // dispatch redux saga action
  const dispatchAction = values => {
    if (hasEditPermission) {
      dispatch({
        type: API_ADMIN_USER_EDIT_DETAIL_REQUESTED,
        payload: {
          ...values,
          userId: user.id,
        },
      });
    }
  };

  // handle gender change
  const handleGenderChange = val => {
    setGender(val);
    // update gender
    const payload = {gender: val};
    dispatchAction(payload);
  };

  // handle locale change
  const defaultLanguageChange = val => {
    setLocale(val);
    const localData =
      val === 'English' ? 'en_GB' : val === 'French' ? 'fr_FR' : '';
    // update locale
    const payload = {locale: localData};
    if (val !== '' && val !== null) {
      dispatchAction(payload);
    }
  };
  // handle birthYear change
  const handleBirthYearChange = val => {
    setBirthYear(val);
    // update birthYear
    const payload = {birthyear: val};
    if (val !== '' && val !== null) {
      dispatchAction(payload);
    }
  };

  // Update a switch when toggle
  const handleOptionCheck = (val, option) => {
    if (option === 'isTrustableSeller') {
      setOptions({
        ...options,
        isTrustableSeller: val,
      });
    } else if (option === 'isCustomDelivery') {
      setOptions({
        ...options,
        isCustomDelivery: val,
      });
    } else if (option === 'isPremiumSeller') {
      setOptions({
        ...options,
        isPremiumSeller: val,
      });
    } else if (option === 'isFanpassPro') {
      setOptions({
        ...options,
        isFanpassPro: val,
      });
    } else if (option === 'isInstantConfirm') {
      setOptions({
        ...options,
        isInstantConfirm: val,
      });
    }
    // update the options that change
    const data = {...options};
    data[option] = val;
    dispatchAction(data);
  };

  // Handle submit of data for various events
  const submit = values => {
    // validate and make sure empty fields are not submitted
    const errors = validate(values);
    validateBeforeSubmit(errors);

    // update fields
    dispatchAction(values);
  };

  // get list of years from 1900 -> (currentyear - 18)
  const yearsOptions = getYearsBetweenDates();

  const handleEmailChange = async e => {
    const newEmail = e.target.value;

    if (user.email !== newEmail) {
      // set the new email
      setEmail(newEmail);

      // send request to check if the email already exist in DB
      let existingUser = null;

      // show loading spinner
      showSpinner();

      // do request
      existingUser = await checkExistingEmail({
        id: user.id,
        email: newEmail,
        token,
      });
      //
      setExistingUserId(existingUser?.id);

      // hide loading spinner
      hideSpinner();

      // if user exist, show confirmation popup
      if (existingUser) {
        setEraseExistingUserEmailPopoup(true);
      } else {
        // else, submit the form to update the user
        const payload = {email: newEmail};
        dispatchAction(payload);
      }
    }
  };

  const handleAcceptEraseUserEmail = e => {
    // hide popup
    setEraseExistingUserEmailPopoup(false);

    // update email
    const payload = {email: email};
    dispatchAction(payload);
  };

  const handleDeclineEraseUserEmail = () => {
    change('email', user.email);
  };

  return (
    <PermissionContainer permissions={['admin_listing_user_details']}>
      {/*Set earase existing user Popup*/}
      <ConfirmationPopup
        open={eraseExistingUserEmailPopoup}
        setOpen={setEraseExistingUserEmailPopoup}
        onAccept={handleAcceptEraseUserEmail}
        onClose={handleDeclineEraseUserEmail}
        description={intl.formatMessage(
          {
            id: 'dashboard.user_details.actions.erase_existing_user_email',
          },
          {
            link: `<a target='_blank' href='/admin-dashboard/users/${existingUserId}'>${existingUserId}</a>`,
          }
        )}
      />
      <div className="row gx-3">
        {/* Left Part */}
        <div className="col-lg-4">
          <form>
            <div className="card details-card h-100">
              <div className="card-body">
                {/*First name*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.first_name',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="firstname"
                    outlined
                    onBlur={props.handleSubmit(submit)}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Last name*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.last_name',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="lastname"
                    outlined
                    onBlur={props.handleSubmit(submit)}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Email address*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.email_address',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    name="email"
                    type="email"
                    outlined
                    onBlur={handleEmailChange}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Phone number*/}
                <div className="form-group static">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.phone_number',
                    })}
                  </span>
                  <Field
                    component={InputField}
                    outlined
                    name="phoneNumber"
                    formGroupClass="form-group static"
                    onBlur={props.handleSubmit(submit)}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Gender*/}
                <div className="form-group static">
                  <CustomGenderRadioGroup
                    name="gender"
                    legend={intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.gender',
                    })}
                    items={[
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_general.male',
                        }),
                        value: 'mr',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_general.female',
                        }),
                        value: 'miss',
                      },
                    ]}
                    onChange={handleGenderChange}
                    value={gender}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/*Date of birth*/}
                <div className="form-group date-input">
                  <span className="small-label">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.date_birth',
                    })}
                  </span>
                  <CustomAutocomplete
                    options={yearsOptions}
                    name="birthYear"
                    label={intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.date_birth',
                    })}
                    changeHandler={handleBirthYearChange}
                    value={birthYear}
                    disabled={!hasEditPermission}
                  />
                </div>
                {/* Default language */}
                <div className="form-group mb-0">
                  <Box pb={1}>
                    <span className="small-label pb-0">
                      <FormattedMessage id="dashboard.user_details.tab_general.default_language" />
                    </span>
                  </Box>
                  <CustomAutocomplete
                    options={['English', 'French']}
                    name="locale"
                    label={intl.formatMessage({
                      id: 'dashboard.user_details.tab_general.default_language',
                    })}
                    changeHandler={defaultLanguageChange}
                    value={locale}
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {/*Center Part*/}
        <div className="col-lg-4">
          <div className="card details-card mt-3 mt-lg-0">
            <div className="card-body">
              {/*Trusted seller*/}
              <div className="row align-items-center pb-3 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="mark">{TRUST_MARK_ICON}</span>
                    <Typography variant="body2" className="ms-2">
                      {intl.formatMessage({
                        id: 'dashboard.sales.marks.trusted_seller',
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="col-auto">
                  {/*Pass the options for trusted and update trusted options when switch is toggled*/}
                  <CustomSwitch
                    isChecked={options.isTrustableSeller}
                    handleOptionCheck={handleOptionCheck}
                    option="isTrustableSeller"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
              {/*Custom delivery*/}
              <div className="row align-items-center pb-3 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="mark">{DELIVERY_MARK_ICON}</span>
                    <Typography variant="body2" className="ms-2">
                      {intl.formatMessage({
                        id: 'dashboard.sales.marks.custom_delivery',
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="col-auto">
                  {/*Pass the options for delivery and update delivery options when switch is toggled*/}
                  <CustomSwitch
                    isChecked={options.isCustomDelivery}
                    handleOptionCheck={handleOptionCheck}
                    option="isCustomDelivery"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
              {/*Premium events*/}
              <div className="row align-items-center pb-3 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="mark">{PREMIUM_MARK_ICON}</span>
                    <Typography variant="body2" className="ms-2">
                      {intl.formatMessage({
                        id: 'dashboard.sales.marks.premium_seller',
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="col-auto">
                  {/*Pass the options for premium and update premium option when switch is toggled*/}
                  <CustomSwitch
                    isChecked={options.isPremiumSeller}
                    handleOptionCheck={handleOptionCheck}
                    option="isPremiumSeller"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
              {/*Pro features*/}
              <div className="row align-items-center pb-3 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="mark">{PRO_MARK_ICON}</span>
                    <Typography variant="body2" className="ms-2">
                      {intl.formatMessage({
                        id: 'dashboard.sales.marks.pro_features',
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="col-auto">
                  {/*Pass the options for pro and update pro option when switch is toggled*/}
                  <CustomSwitch
                    isChecked={options.isFanpassPro}
                    handleOptionCheck={handleOptionCheck}
                    option="isFanpassPro"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
              {/*Instant confirmation*/}
              <div className="row align-items-center pb-3 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <span className="mark">{INSTANT_MARK_ICON}</span>
                    <Typography variant="body2" className="ms-2">
                      {intl.formatMessage({
                        id: 'dashboard.sales.marks.instant_confirmation',
                      })}
                    </Typography>
                  </div>
                </div>
                <div className="col-auto">
                  {/*Pass the options for instant and update instant option when switch is toggled*/}
                  <CustomSwitch
                    isChecked={options.isInstantConfirm}
                    handleOptionCheck={handleOptionCheck}
                    option="isInstantConfirm"
                    disabled={!hasEditPermission}
                  />
                </div>
              </div>
              {/*Created at*/}
              <div className="form-group static">
                <span className="small-label">
                  {intl.formatMessage({
                    id: 'dashboard.user_details.tab_general.created_at',
                  })}
                </span>
                {/* format createdAt date */}
                <Typography variant="body2">
                  {moment(user.createdAt).isValid()
                    ? `${moment(user.createdAt).format('MMM DD, YYYY - hh:mm')} (GMT)`
                    : '-'}
                </Typography>
              </div>
              {/*Last log in*/}
              <div className="form-group mb-0">
                <span className="small-label">
                  {intl.formatMessage({
                    id: 'dashboard.user_details.tab_general.last_login',
                  })}
                </span>
                {/* format last login date */}
                <Typography variant="body2">
                  {moment(user.lastLogin).isValid()
                    ? `${moment(user.lastLogin).format('MMM DD, YYYY - hh:mm')} (GMT)`
                    : '-'}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PermissionContainer>
  );
};

// Validate user props
ProfileDetailsGeneral.propTypes = {
  user: PropTypes.object,
};

ProfileDetailsGeneral = reduxForm({
  form: 'ProfileDetailsGeneralForm',
  enableReinitialize: true,
  validate,
})(ProfileDetailsGeneral);

// Pass user details to form as initial values
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      firstname: ownProps.user.firstname,
      lastname: ownProps.user.lastname,
      email: ownProps.user.email,
      phoneNumber: ownProps.user.phoneNumber,
    },
  };
};

export default connect(mapStateToProps)(injectIntl(ProfileDetailsGeneral));
