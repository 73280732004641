import * as PropTypes from 'prop-types';
import {customAttributeType as types} from '../../constants/enums';
import HintTooltip from '../Common/HintTooltip';
import CustomAttributeField from './CustomAttributeField';

const CustomObjectFieldGroup = props => {
  const {customObject, name, tooltip, label, DynamicFieldProps} = props;
  const {customAttributes} = customObject;
  const unsupportedCaTypes = [types.OBJECT, types.IMAGE, types.COLLECTION];

  if (customAttributes.length === 0) {
    return null;
  }

  const builderConfig = {
    namePrefix: `${name}.`,
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <span className="small-label">{label}</span>
        {tooltip && name && (
          <HintTooltip
            className="hint-button pb-1"
            id={name.replace(/[.[\]]/g, '-')}
            content={tooltip}
          />
        )}
      </div>
      <div className="row">
        {Array.isArray(customAttributes) &&
          customAttributes.map((ca, index) => (
            <CustomAttributeField
              key={index}
              customAttribute={ca}
              builderParams={[builderConfig, unsupportedCaTypes]}
              DynamicFieldProps={DynamicFieldProps}
            />
          ))}
      </div>
    </div>
  );
};

CustomObjectFieldGroup.propTypes = {
  customObject: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
};

CustomObjectFieldGroup.defaultProps = {};

export default CustomObjectFieldGroup;
