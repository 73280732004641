import {call, cancelled, delay, put} from '@redux-saga/core/effects';
import axios from 'axios';
import * as urls from '../constants/urls';
import * as actionTypes from '../constants/actionTypes';
import {get as apiGet} from '../connectivity/api';
import {addAuthToken, handleException} from './utils';
import {
  getUserDetails,
  updateUserDetail,
  suspendUser,
  unSuspendUser,
  sendGuestEmail,
  sendResetPasswordEmail,
  deleteUser,
  checkExistingEmail,
  listTeamMembers,
} from '../connectivity/user.api';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';

export function* doGetUserAutocomplete(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    yield delay(200);
    let queries = '?term=' + (action.payload.term ?? '');
    if (action.payload.type) {
      queries += `&type=${action.payload.type}`;
    }
    const url = urls.API_USER_AUTOCOMPLETE_URL + queries;
    const response = yield call(axios.get, url, {
      cancelToken: cancelSource.token,
    });

    yield put({
      type: actionTypes.API_USER_AUTOCOMPLETE_SUCCEEDED,
      payload: {userNames: response.data},
    });
  } catch (error) {
    console.warn('user autocomplete request failed', error);
    yield put({
      type: actionTypes.API_USER_AUTOCOMPLETE_FAILED,
      payload: {error},
    });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

/**
 * Get User's payment method list
 *
 * @param action
 */
export function* doGetUserPaymentMethods(action) {
  try {
    const {userId, token} = yield addAuthToken(action.payload);
    if (!userId) {
      throw new Error('userId is required for payment method list');
    }

    // Call to API
    const data = yield apiGet({
      url: `${urls.API_V2_GET_PAYMENT_METHODS_URL}?user=${userId}`,
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_USER_PAYMENT_METHOD_LIST_SUCCEEDED,
      payload: {items: data['hydra:member'], seller: action.payload.seller},
    });
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_USER_PAYMENT_METHOD_LIST_FAILED,
    });
  }
}

/**
 * Get User's Details
 *
 * @param action
 */
export function* doGetAdminUserDetails(action) {
  try {
    const {userId, token} = yield addAuthToken(action.payload);
    if (!userId) {
      throw new Error('userId is required for user details');
    }

    // Call to API
    const data = yield call(getUserDetails, {id: userId, token});

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_USER_DETAIL_SUCCEEDED,
      payload: {user: data},
    });
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_USER_DETAIL_FAILED,
    });
  }
}

/**
 * Update User's Details
 *
 * @param action
 */
export function* doUpdateUserDetails(action) {
  try {
    const data = yield call(
      updateUserDetail,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: actionTypes.API_ADMIN_USER_EDIT_DETAIL_SUCCEEDED,
      payload: {user: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({id: 'dashboard.users.messages.user_updated'}),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    for (const err of error.data) {
      const message = Object.values(err)[0];
      yield handleException({
        error,
        type: actionTypes.API_ADMIN_USER_EDIT_DETAIL_FAILED,
        customMessage: message,
      });
    }
  }
}

/**
 * Suspend User
 *
 * @param action
 */
export function* doSuspendUser(action) {
  try {
    const data = yield call(suspendUser, yield addAuthToken(action.payload));
    yield put({
      type: actionTypes.API_ADMIN_SUSPEND_USER_SUCCEEDED,
      payload: {user: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.suspend_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SUSPEND_USER_FAILED,
    });
  }
}

/**
 * Unsuspend User
 *
 * @param action
 */
export function* doUnSuspendUser(action) {
  try {
    const data = yield call(unSuspendUser, yield addAuthToken(action.payload));
    yield put({
      type: actionTypes.API_ADMIN_UNSUSPEND_USER_SUCCEEDED,
      payload: {user: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.unsuspend_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_UNSUSPEND_USER_FAILED,
    });
  }
}

/**
 * Resend Guest email
 *
 * @param action
 */
export function* doResendGuestEmail(action) {
  try {
    const data = yield call(sendGuestEmail, yield addAuthToken(action.payload));
    yield put({
      type: actionTypes.API_ADMIN_SEND_GUEST_EMAIL_SUCCEEDED,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.guest_email_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SEND_GUEST_EMAIL_FAILED,
    });
  }
}

/**
 * Send Reset Password Email
 *
 * @param action
 */
export function* doSendPasswordResetEmail(action) {
  try {
    const data = yield call(
      sendResetPasswordEmail,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_SUCCEEDED,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.reset_password_email_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_FAILED,
    });
  }
}

/**
 * Delete User
 *
 * @param action
 */
export function* doDeleteUserData(action) {
  try {
    const data = yield call(deleteUser, yield addAuthToken(action.payload));
    yield put({
      type: actionTypes.API_ADMIN_DELETE_USER_SUCCEEDED,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.delete_user_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
    yield call(action.payload.callback);
  } catch (error) {
    yield handleException({
      error,
      customMessage: error.response?.data?.message,
      type: actionTypes.API_ADMIN_DELETE_USER_FAILED,
    });
  }
}

/**
 * Update User's Details - Check existing email
 *
 * @param action
 */
export function* doCheckExistingEmail(action) {
  try {
    const data = yield call(
      checkExistingEmail,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: actionTypes.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_SUCCEEDED,
      payload: {existingUser: data},
    });
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_FAILED,
    });
  }
}

export function* doListTeamMembers() {
  try {
    const data = yield call(
      listTeamMembers,
      yield addAuthToken(action.payload)
    );

    yield put({
      type: actionTypes.API_ADMIN_USER_LIST_SUCCEEDED,
      payload: {data: data},
    });
  } catch (error) {
    yield put({
      type: actionTypes.API_ADMIN_USER_LIST_FAILED,
      payload: {error},
    });
  }
}
