import {cloneDeep} from 'lodash';
import moment from 'moment';
import {useCallback} from 'react';

import {inputTypes} from '../../constants/enums';
import useDateHelper from '../utils/useDateHelper';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 *
 * @param {string} version The api version that will be used
 * @returns
 */
export default function useFilters(version, {dateVersion} = {}) {
  const dateHelper = useDateHelper();

  const formatDateFilter = useCallback(
    (key, filterValue) => {
      const formatted = {};
      if (dateVersion === 'v2') {
        let start, end;
        if (filterValue.period && filterValue.periodNumber) {
          end = dateHelper.format(new Date());
          start = dateHelper.format(
            moment().subtract(filterValue.periodNumber, filterValue.period)
          );
        } else if (filterValue.from || filterValue.to) {
          end = filterValue.to;
          start = filterValue.from;
        }
        if (start) {
          formatted[`${key}[after]`] = dateHelper.format(
            moment(start).startOf('day'),
            DATE_TIME_FORMAT
          );
        }
        if (end) {
          formatted[`${key}[before]`] = dateHelper.format(
            moment(end).endOf('day'),
            DATE_TIME_FORMAT
          );
        }
      } else {
        if (filterValue.period && filterValue.periodNumber) {
          formatted[`${key}[last][period]`] = filterValue.period;
          formatted[`${key}[last][number]`] = filterValue.periodNumber;
        } else if (filterValue.from || filterValue.to) {
          if (filterValue.from) {
            formatted[`${key}[range][start]`] = filterValue.from;
          }
          if (filterValue.to) {
            formatted[`${key}[range][end]`] = filterValue.to;
          }
        }
      }

      return formatted;
    },
    [dateHelper]
  );

  const formatNumberFilter = useCallback(
    (field, filterValue) => {
      const formattedNumber = {};
      if (filterValue.min && filterValue.max) {
        if (version === 'v2') {
          formattedNumber[`${field}[between]`] =
            `${filterValue.min}..${filterValue.max}`;
        } else {
          formattedNumber[`${field}[range][min]`] = filterValue.min;
          formattedNumber[`${field}[range][max]`] = filterValue.max;
        }
      } else if (filterValue.eq && version === 'v2') {
        let min = filterValue.eq,
          max = min;
        // enable float search if value is not a decimal
        if (String(filterValue.eq).indexOf('.') === -1) {
          min = +`${min}.00`;
          max = +`${max}.99`;
        }
        formattedNumber[`${field}[between]`] = `${min}..${max}`;
      } else if (filterValue.lt || filterValue.gt || filterValue.eq) {
        const keys = ['eq', 'gt', 'lt'];
        // just take the first found
        for (const key of keys) {
          if (filterValue[key]) {
            formattedNumber[`${field}[${key}]`] = filterValue[key];
            break;
          }
        }
      }

      return formattedNumber;
    },
    [version]
  );

  const formatFilters = useCallback(
    (allFilters, filterItems) => {
      const numberFields = [];
      const dateFields = [];

      for (const filterItem of filterItems) {
        if ([inputTypes.DATE_SELECT].indexOf(filterItem.inputType) > -1) {
          dateFields.push(filterItem);
        } else if (
          [inputTypes.ADVANCED_NUMBER].indexOf(filterItem.inputType) > -1
        ) {
          numberFields.push(filterItem);
        }
      }

      if (!allFilters) {
        return {};
      }

      /**
       * used to hold formatted filter values as key/value
       * @type {Object}
       */
      let formatted = cloneDeep(allFilters);

      // format date fields
      for (const field of dateFields) {
        const fieldName = field.name;
        const outputName = field.outputName ?? fieldName;
        if (allFilters && allFilters[fieldName]) {
          formatted = {
            ...formatted,
            ...formatDateFilter(outputName, allFilters[fieldName]),
          };
          delete formatted[fieldName];
        }
      }

      // format date number
      for (const field of numberFields) {
        const fieldName = field.name;
        const outputName = field.outputName ?? fieldName;
        if (allFilters && allFilters[fieldName]) {
          const formattedNumberFilter = formatNumberFilter(
            outputName,
            allFilters[fieldName]
          );
          if (typeof formattedNumberFilter === 'object') {
            formatted = {
              ...formatted,
              ...formattedNumberFilter,
            };
            delete formatted[fieldName];
          } else {
            formatted[outputName] = formattedNumberFilter;
          }
        }
      }

      // add unhandled outputName
      filterItems
        .filter(
          item =>
            !dateFields.includes(item) &&
            !numberFields.includes(item) &&
            item.outputName
        )
        .forEach(item => {
          if (formatted[item.name] !== undefined) {
            formatted[item.outputName] = formatted[item.name];
            delete formatted[item.name];
          }
        });

      return formatted;
    },
    [formatDateFilter, formatNumberFilter]
  );

  return {
    formatFilters,
  };
}
