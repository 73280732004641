export const TRANSACTIONS_TYPE = {
  Transaction: 'Payment',
  SalePenalty: 'Penalty',
  BuyerRefund: 'Refund',
  SellerCharge: 'Seller payment',
  PlatformRefund: 'Refund',
};

export const TRANSACTIONS_TYPE_ENTITY = {
  Transaction: '\\Fanpass\\TransactionBundle\\Entity\\Transaction',
  SalePenalty: '\\Fanpass\\TransactionBundle\\Entity\\SalePenalty',
  BuyerRefund: '\\Fanpass\\TransactionBundle\\Entity\\BuyerRefund',
  SellerCharge: '\\Fanpass\\TransactionBundle\\Entity\\SellerCharge',
  PlatformRefund: '\\Fanpass\\TransactionBundle\\Entity\\PlatformRefund',
};

export const TRANSACTION_REFUND_REASONS = [
  {
    label: 'Access denied',
    value: 'access-denied',
  },
  {
    label: 'Ticket not delivered',
    value: 'ticket-not-delivered',
  },
  {
    label: 'Event cancelled',
    value: 'event-cancelled',
  },
  {
    label: 'Event postponed',
    value: 'event-postponed',
  },
  {
    label: 'Ticket does not match the order',
    value: 'ticket-mismatch',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
