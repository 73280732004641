import React, {useMemo} from 'react';
import {injectIntl} from 'react-intl';
import {reduxForm, reset} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import PrintRow from './AddPenaltyPopupRow';
import TotalPenaltyRow from './AddPenaltyPopupTotalRow';
import get from 'lodash.get';
import getSymbolFromCurrency from 'currency-symbol-map';

import Modal from '../../../Widgets/Modal';
import {
  required,
  lessOrEqualThan,
  validateBeforeSubmit,
} from '../../../../validation/validators';
import {
  formatCurrencyValue,
  formatMoney,
  isEmpty,
  normalizeMoneyFormat,
} from '../../../../utils/functions';
import * as actionTypes from '../../../../constants/actionTypes';
import {
  calculateMaxPenaltyAmount,
  getSellingPrice,
} from '../../../../utils/saleHelper';

const validate = (values, {intl, sale}) => {
  const errors = {};
  //Amount
  const maxPenaltyAmount = calculateMaxPenaltyAmount(sale);
  const {currentAmount, futureAmount, description} = values;
  errors.description = required(
    description,
    intl.formatMessage({id: 'validation.error.required'})
  );

  //One of current/future amount is required
  if (isEmpty(currentAmount) && isEmpty(futureAmount)) {
    errors.currentAmount = required(
      currentAmount,
      intl.formatMessage({id: 'validation.error.required'})
    );
    errors.futureAmount = required(
      currentAmount,
      intl.formatMessage({id: 'validation.error.required'})
    );
  }

  if (!errors.currentAmount && maxPenaltyAmount !== null) {
    const currentAmountError = lessOrEqualThan(
      +(currentAmount || 0),
      maxPenaltyAmount,
      intl.formatMessage({id: 'dashboard.sales.penalty.errors.amount_too_high'})
    );
    if (currentAmountError) {
      errors.currentAmount = currentAmountError;
    }
  }

  //Future amount not permitted if currentAmount < maxPenaltyAmount
  if (!isEmpty(futureAmount) && +(currentAmount || 0) < maxPenaltyAmount) {
    errors.futureAmount = intl.formatMessage({
      id: 'dashboard.sales.penalty.errors.amount_future_sale',
    });
  }
  return errors;
};

/* Add Penalty popup */
let AddPenaltyPopup = props => {
  const {opened, handleClose, handleSubmit, intl, sale} = props;
  const formValues = useSelector(state =>
    get(state, 'form.AddPenaltyPopupForm.values', {})
  );
  const dispatch = useDispatch();

  const [totalPenalty, setTotalPenalty] = React.useState(0);
  const [currentCurrencySymbol, setCurrentCurrencySymbol] = React.useState('£');

  // Clear form and close modal
  const closeModal = () => {
    dispatch(reset('AddPenaltyPopupForm'));
    handleClose();
  };

  // This useEffect is use to set information about currency and max price amount
  React.useEffect(() => {
    // Set the currency symbol related to this sale
    sale?.ticket?.currency &&
      setCurrentCurrencySymbol(getSymbolFromCurrency(sale.ticket.currency));
  }, [sale]);

  // This UseEffect is used to get information from the form
  React.useEffect(() => {
    let tempTotalPenalty = 0;
    // In case of amount exist normalize the formatted as money value
    // This is made because the useEffect happens before the normalize inside the Redux-Form field
    formValues.currentAmount &&
      (tempTotalPenalty += Number(
        normalizeMoneyFormat(formatMoney(formValues.currentAmount))
      ));
    formValues.futureAmount &&
      (tempTotalPenalty += Number(
        normalizeMoneyFormat(formatMoney(formValues.futureAmount))
      ));
    setTotalPenalty(tempTotalPenalty);
  }, [formValues]);

  /**
   * Function to submit the information to API
   **/
  const submit = async values => {
    const errors = validate(values, props);
    validateBeforeSubmit(errors);

    dispatch({
      type: actionTypes.API_ADMIN_SALE_ADD_PENALTY_REQUESTED,
      payload: {
        values: {
          description: values.description,
          currentAmount: parseFloat(values.currentAmount) || 0,
          futureAmount: parseFloat(values.futureAmount) || 0,
        },
        sale,
      },
    });
  };

  //Amount
  const sellingPriceFormatted = useMemo(
    () => `£${formatCurrencyValue(getSellingPrice(sale), [sale])}`,
    [sale]
  );
  const maxPenaltyAmount = useMemo(
    () => calculateMaxPenaltyAmount(sale),
    [sale]
  );
  const maxPenaltyAmountFormatted = useMemo(
    () => `£${formatCurrencyValue(maxPenaltyAmount)}`,
    [maxPenaltyAmount]
  );
  const totalPenaltyFormatted = `£${formatCurrencyValue(totalPenalty)}`;

  return (
    <Modal
      width={400}
      opened={opened}
      title={intl.formatMessage({id: 'dashboard.sales.penalty.add_penalty'})}
      saveTitle={intl.formatMessage({id: 'actions.add'})}
      handleClose={closeModal}
      onOk={handleSubmit(submit)}
    >
      {opened ? (
        <form>
          {/*Selling price*/}
          <PrintRow
            className={'mb-3 pb-1'}
            title={intl.formatMessage({
              id: 'dashboard.sales.penalty.selling_price',
            })}
            value={sellingPriceFormatted}
            type={'Input'}
            currency={currentCurrencySymbol}
          />
          {/*Penalty amount*/}
          <PrintRow
            className={'mb-2 pb-1'}
            title={intl.formatMessage({
              id: 'dashboard.sales.penalty.penalty_amount_current_sale',
            })}
            value={''}
            edit={true}
            fieldName={'currentAmount'}
            type={'Input'}
            fieldProps={maxPenaltyAmount > 0 ? {} : {disabled: true}}
            currency={currentCurrencySymbol}
            note={intl.formatMessage({
              id: 'dashboard.sales.penalty.penalty_amount_tooltip',
            })}
            appName={intl.formatMessage({id: 'app_name'})}
          />
          {/*Penalty total*/}
          <TotalPenaltyRow
            maxPenaltyAmount={maxPenaltyAmountFormatted}
            totalPenalty={totalPenaltyFormatted}
          />
          {/*Penalty amount*/}
          <PrintRow
            className={'pt-2 pb-3 mb-1'}
            title={intl.formatMessage({
              id: 'dashboard.sales.penalty.penalty_amount_future_sale',
            })}
            value={''}
            edit={true}
            fieldName={'futureAmount'}
            type={'Input'}
            currency={currentCurrencySymbol}
            note={intl.formatMessage({
              id: 'dashboard.sales.penalty.penalty_amount_futures_tooltip',
            })}
            appName={intl.formatMessage({id: 'app_name'})}
          />
          {/*Description*/}
          <PrintRow
            className={'pt-2 pb-3 mb-1'}
            title={intl.formatMessage({
              id: 'dashboard.sales.penalty.description',
            })}
            value={''}
            fieldName={'description'}
            type={'TextArea'}
          />
        </form>
      ) : null}
    </Modal>
  );
};

// connect component to redux-form
AddPenaltyPopup = reduxForm({
  form: 'AddPenaltyPopupForm',
  validate,
})(AddPenaltyPopup);

export default injectIntl(AddPenaltyPopup);
