import {makeStyles} from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  root: {
    height: 5,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

/**
 * Linear progress bar component
 * @param props
 * @returns {*}
 * @constructor
 */
const ProgressBar = props => {
  const classes = useStyles();

  return (
    <LinearProgress
      classes={classes}
      color="primary"
      variant="determinate"
      {...props}
    />
  );
};

/* See: https://material-ui.com/api/linear-progress/ */
ProgressBar.propTypes = {};

export default ProgressBar;
