import {get} from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import useSearchParams from '../../hooks/utils/useSearchParams';
import {getUrlSearchParams} from '../../utils/filters';
import SearchTextFieldF from './Fields/SearchTextField';

/** search field name */
export const KEYWORD_FIELD = 'q';

/**
 * KeywordSearchForm that can be used to display a search field bound to redux-form
 *
 * @param {*} props
 */
let KeywordSearchForm = (
  {
    form,
    dirty,
    anyTouched,
    handleSubmit,
    dispatch,
    onSearch,
    change,
    updateUrlOnChange = true,
    additionalParams,
  },
  ref
) => {
  const intl = useIntl();
  const history = useHistory();
  const values = useSelector(state => get(state, `form.${form}.values`));
  const [searchParams, setSearchParams] = useSearchParams();

  // update value from location
  useEffect(() => {
    const nextQ = searchParams.get(KEYWORD_FIELD) ?? '';
    const currentQ = (values && values[KEYWORD_FIELD]) ?? '';
    if (nextQ !== currentQ) {
      dispatch(change(KEYWORD_FIELD, nextQ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getNextQueryParams = useCallback(
    (searchValue = '', addAdditionalParams = true) => {
      const p = getUrlSearchParams(searchParams);
      p.set(KEYWORD_FIELD, searchValue);
      if (addAdditionalParams && additionalParams) {
        Object.keys(additionalParams).forEach(k =>
          p.set(k, additionalParams[k])
        );
      }

      return p;
    },
    [additionalParams, searchParams]
  );

  const updateUrl = useCallback(
    (searchValue = '', addAdditionalParams = true) => {
      const currentParams = getUrlSearchParams(searchParams);
      const p = getNextQueryParams(searchValue, addAdditionalParams);
      setSearchParams(p, currentParams);
    },
    [getNextQueryParams, searchParams, setSearchParams]
  );

  // update url on value change
  useEffect(() => {
    if (updateUrlOnChange) {
      const id = setTimeout(() => {
        if (dirty || anyTouched) {
          updateUrl(values && values[KEYWORD_FIELD], false);
        }
      }, [250]);

      return () => clearTimeout(id);
    }
    return () => {};
  }, [dirty, updateUrl, values, anyTouched, updateUrlOnChange]);

  const onSubmit = useCallback(
    v => {
      updateUrl(v && v[KEYWORD_FIELD]);
      const p = getNextQueryParams(v && v[KEYWORD_FIELD]);
      onSearch && onSearch(v, p);
    },
    [onSearch, updateUrl]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100">
      <Field
        name={KEYWORD_FIELD}
        component={SearchTextFieldF}
        clearable
        placeholder={intl.formatMessage({id: 'actions.search'})}
        buttonProps={{
          onClick: () => onSearch(values),
          onClear: () => {
            dispatch(change(KEYWORD_FIELD, ''));
            updateUrl('');
            onSearch({[KEYWORD_FIELD]: ''});
          },
        }}
      />
    </form>
  );
};

// bind to redux-form
KeywordSearchForm = reduxForm({
  form: 'KeywordSearchForm',
})(KeywordSearchForm);

export default KeywordSearchForm;
