import React, {FC} from 'react';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import Chip from '../Common/Chip';
import Button from '../Common/Button';
import {getParamsFromUrl, getUrlSearchParams} from '../../utils/filters';
import {FILTERS_APPLIED_SEARCH_KEY, FILTERS_SEARCH_KEY} from './Filters';
import useSearchParams from '../../hooks/utils/useSearchParams';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: theme.spacing(0.5),
    rowGap: theme.spacing(1),
    margin: theme.spacing(2, 0, 2.5),
    '& > *': {
      margin: 0,
      maxWidth: '100%',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '60%',
      },
    },
    '& > h6': {
      color: theme.palette.success.main,
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 0),
    },
  },
  clearButton: {
    minWidth: theme.spacing(5.9),
    marginLeft: theme.spacing(0.5),
    '& .MuiButton-label': {
      fontSize: 14,
      color: theme.palette.secondary.light,
    },
  },
}));

interface Item {
  id: string;
  title: string;
  values: string[];
}

interface ActiveFiltersProps {
  items: Item[];
  onFilterRemoved?: () => void;
  clearAdditionalParams?: Record<string, string>;
}

const ActiveFilters: FC<ActiveFiltersProps> = ({
  items,
  onFilterRemoved,
  clearAdditionalParams,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const onDelete = (filterId: string) => {
    // Get current filter values from URL and remove the filterId
    const currentFilters = {...getParamsFromUrl(searchParams).filterValues};
    delete currentFilters[filterId];

    const currentSearchParams = getUrlSearchParams(searchParams);

    // Update URL Parameters
    const p = getUrlSearchParams(searchParams);
    p.set(FILTERS_SEARCH_KEY, JSON.stringify(currentFilters));

    // @ts-ignore
    setSearchParams(p, currentSearchParams);

    // Refresh results list
    onFilterRemoved && onFilterRemoved();
  };

  const onClearAll = () => {
    const p = getUrlSearchParams(searchParams);
    // Remove filters from URL
    p.delete(FILTERS_SEARCH_KEY);
    // Remove applied filters flag from URL
    p.delete(FILTERS_APPLIED_SEARCH_KEY);

    // Clear additional params
    if (clearAdditionalParams) {
      Object.keys(clearAdditionalParams).forEach(k =>
        p.set(k, clearAdditionalParams[k])
      );
    }

    // @ts-ignore
    setSearchParams(p, null);

    // Refresh results list
    onFilterRemoved && onFilterRemoved();
  };

  // @ts-ignore
  return (
    <>
      {items && items.length > 0 && (
        <div className={classes.root}>
          <Typography variant="h6" className="font-weight-bold">
            <FormattedMessage id="actions.showing" />:
          </Typography>
          {items.map((item, index) => {
            const value = item.values.join(', ');
            return (
              <Chip
                key={index}
                label={`${item.title}: ${value}`}
                onDelete={() => onDelete(item.id)}
              />
            );
          })}
          <Button
            link
            title={intl.formatMessage({id: 'actions.clear_all'})}
            className={clsx(classes.clearButton, 'no-underlined')}
            onClick={onClearAll}
          />
        </div>
      )}
    </>
  );
};

export default ActiveFilters;
