import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Select from '../../Common/Select';
import BaseInput from '../../Common/BaseInput';

const AdvancedNumberField = props => {
  const {
    input: {value, onChange},
  } = props;
  const intl = useIntl();
  //Selected option state
  const [option, setOption] = useState('');
  const [input, setInput] = useState('');
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');

  const keyMap = {
    is_equal: 'eq',
    is_greater: 'gt',
    is_less: 'lt',
  };

  const handleChange = (event, key) => {
    const {min, max} = value;
    const inputValue = event.target.value;
    let newValue = {};
    if (option === 'is_between') {
      newValue = {min, max, [key]: inputValue};
      if (key === 'min') {
        setInput1(inputValue);
      } else {
        setInput2(inputValue);
      }
    } else {
      newValue = {[key]: inputValue};
      setInput(inputValue);
    }
    onChange({...newValue, option});
  };

  useEffect(() => {
    if (option && value) {
      if (option === 'is_between') {
        onChange({min: input1, max: input2, option});
      } else {
        onChange({[keyMap[option]]: input, option});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    const {option: initialOption} = value;

    if (!option && initialOption) {
      setOption(initialOption);
    }

    if (option === 'is_between') {
      setInput1(value && value.min);
      setInput2(value && value.max);
    } else if (Object.keys(keyMap).indexOf(option) > -1) {
      setInput(value && value[keyMap[option]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="quantity-selects-wrapper">
      {/* Options selector */}
      <Select
        value={option || ''}
        placeholder={intl.formatMessage({
          id: 'dashboard.filters.filter_format',
        })}
        items={[
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_equal'}),
            value: 'is_equal',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_greater'}),
            value: 'is_greater',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_less'}),
            value: 'is_less',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_between'}),
            value: 'is_between',
          },
        ]}
        onChange={value => setOption(value)}
      />

      {option && (
        <div className="inputs">
          {option === 'is_between' ? (
            <div className="between-wrapper">
              <BaseInput
                className="num-input"
                value={input1}
                onChange={e => handleChange(e, 'min')}
                placeholder={intl.formatMessage({id: 'dashboard.filters.from'})}
              />
              <BaseInput
                className="num-input"
                value={input2}
                onChange={e => handleChange(e, 'max')}
                placeholder={intl.formatMessage({id: 'dashboard.filters.to'})}
              />
            </div>
          ) : (
            <BaseInput
              value={input}
              onChange={e => handleChange(e, keyMap[option])}
              className="num-input"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AdvancedNumberField;
