import React, {cloneElement, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '../Common/IconButton';

//Seller Marks popover
const StyledPopover = withStyles(theme => ({
  paper: {
    position: 'relative',
    display: 'inline-flex',
    marginTop: 13,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[4],
    overflow: 'visible',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: -8,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: 'transparent transparent #282B3E transparent',
    },
  },
}))(Popover);

//Event Mark
const EventMark = ({id, icon, iconSize, text}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      marginRight: 2,
      '& .MuiButtonBase-root': {
        borderRadius: 4,
        '& .MuiSvgIcon-root': {
          width: iconSize,
          height: iconSize,
        },
      },
    },
    popover: {
      pointerEvents: 'none',
    },
  }));

  const classes = useStyles();
  //Anchor
  const [anchorEl, setAnchorEl] = useState(null);

  //Popover open
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  //Popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <IconButton
        variant="filled"
        size="xs"
        onHover={handlePopoverOpen}
        onLeave={handlePopoverClose}
      >
        {cloneElement(icon, {style: {width: iconSize, height: iconSize}})}
      </IconButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="d-flex align-items-center">
          <div className="d-inline-flex">
            {cloneElement(icon, {style: {width: 18, height: 18}})}
          </div>
          <Typography variant="body2" className="ps-1">
            {text}
          </Typography>
        </div>
      </StyledPopover>
    </div>
  );
};

export default EventMark;
