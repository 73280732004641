import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withSnackbar} from 'notistack';
import {removeSnackbar} from '../../actions/snackbarActions';
import NotifierChildren from './NotifierChildren';

let displayed = [];

const Notifier = props => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    store => store.snackbarReducer.notifications || []
  );
  const {enqueueSnackbar, closeSnackbar} = props;

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({key, message, options = {}, dismissed = false}) => {
      const {variant = 'success'} = options;

      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(
        <NotifierChildren id={key} message={message} variant={variant} />,
        {
          key,
          preventDuplicate: true,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        }
      );

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default withSnackbar(Notifier);
