import {call, put} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as userBankApi from '../connectivity/userBank.api';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';
import {addAuthToken, handleException} from './utils';

// List user banks actions
export function* doGetUserBankDetails(action) {
  try {
    const data = yield call(
      userBankApi.getUserBanks,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_BANK_DETAILS_SUCCEEDED,
      payload: {data},
    });
  } catch (error) {
    yield handleException({
      type: types.API_USER_BANK_DETAILS_FAILED,
      error,
    });
  }
}

// delete user bank action
export function* doDeleteUserBank(action) {
  try {
    yield call(userBankApi.deleteUserBank, yield addAuthToken(action.payload));
    yield put({
      type: types.API_USER_BANK_DELETE_SUCCEEDED,
      payload: {id: action.payload.id},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.delete_bank_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_BANK_DELETE_FAILED,
      error,
      customMessage: error?.response?.data.message,
    });
  }
}

// set bank as default action
export function* doSetAsDefault(action) {
  try {
    yield call(userBankApi.setAsDefault, yield addAuthToken(action.payload));
    yield put({
      type: types.API_USER_BANK_DEFAULT_SUCCEEDED,
      payload: {id: action.payload.id},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.default_bank_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_BANK_DEFAULT_FAILED,
      error,
      customMessage: error?.response?.data.message,
    });
  }
}

// create new bank action
export function* doCreateUserBank(action) {
  try {
    yield call(userBankApi.createNewBank, yield addAuthToken(action.payload));
    // get the bank list
    const data = yield call(
      userBankApi.getUserBanks,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_BANK_CREATE_SUCCEEDED,
      payload: {data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.actions.new_bank_success',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_BANK_CREATE_FAILED,
      error,
      customMessage: error?.response?.data.message,
    });
  }
}
