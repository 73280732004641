const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default function useCountryHelper() {
  const getCountryName = countryCode => {
    return countries.getName(countryCode, 'en');
  };

  return {
    getCountryName,
  };
}
