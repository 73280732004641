import {
  API_ROLE_LIST_SUCCEEDED,
  API_ROLE_LIST_FAILED,
  API_ROLE_DELETE_SUCCEEDED,
  API_ROLE_EDIT_FAILED,
  API_ROLE_EDIT_SUCCEEDED,
  API_ROLE_DELETE_FAILED,
  API_ROLE_CREATE_SUCCEEDED,
  API_ROLE_CREATE_FAILED,
} from '../constants/actionTypes';

const defaultState = {
  items: [],
  loaded: false,
  success: null,
};

export default function roleReducer(state = defaultState, action) {
  switch (action.type) {
    case API_ROLE_LIST_SUCCEEDED:
      return {
        ...state,
        items: action.payload.result,
        loaded: true,
      };
    case API_ROLE_LIST_FAILED:
      return {
        ...state,
      };

    case API_ROLE_CREATE_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        success: true,
      };
    case API_ROLE_CREATE_FAILED:
      return {
        ...state,
        success: false,
      };

    case API_ROLE_EDIT_SUCCEEDED:
      const updatedRole = action.payload.result;
      return {
        ...state,
        items: state.items.map(item =>
          item.id === updatedRole.id ? updatedRole : item
        ),
        loaded: true,
        success: true,
      };
    case API_ROLE_EDIT_FAILED:
      return {
        ...state,
        success: false,
      };

    case API_ROLE_DELETE_SUCCEEDED:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id),
        loaded: true,
      };
    case API_ROLE_DELETE_FAILED:
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
}
