import React from 'react';
import Box from '@material-ui/core/Box';
import {injectIntl, FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import StateIndicator from '../Common/StateIndicator';
import Dropdown from '../Common/Dropdown';
import ArrowLink from '../Common/ArrowLink';
import Avatar from '../Common/Avatar';
import {CalendarGrayIcon, ListingGrayIcon} from '../../constants/images';
import {IMG_BASE_URL} from '../../constants/urls';
import ListingDetailsForm from './ListingDetailsForm';

/* Listing Details page */
const ListingDetails = ({intl}) => {
  return (
    <div className="details-page listing">
      {/*Top header*/}
      <div className="container-fluid top-block bg-white">
        <div className="details-header">
          <div className="row title-block">
            <div className="col">
              <div className="details-header-part text-center text-lg-start">
                <Typography variant="h3" className="d-inline-flex d-lg-none">
                  <span>
                    {intl.formatMessage({
                      id: 'dashboard.listings.details.title',
                    })}
                  </span>
                  <StateIndicator
                    label="Paused"
                    color="gray"
                    className="d-inline-flex ms-2"
                  />
                </Typography>
                <Box
                  className="d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start"
                  mb={1.5}
                >
                  <Typography variant="h3" className="me-2">
                    {intl.formatMessage({
                      id: 'dashboard.listings.details.title',
                    })}
                  </Typography>
                  <Box sx={{mt: 0.3}}>
                    <StateIndicator label="Paused" color="gray" />
                  </Box>
                </Box>
                <div className="row gx-3 align-items-center justify-content-center justify-content-lg-start">
                  {/*Avatar / User name*/}
                  <div className="col-12 col-lg-auto text-center">
                    <div className="d-inline-flex align-items-center pb-2 pb-lg-0 mx-auto">
                      <Avatar size="xs10" color="pink" alt="MC" />
                      <ArrowLink
                        className="ms-2"
                        title="Missie Clotilda"
                        url="http://link"
                      />
                    </div>
                  </div>
                  {/*Listing ID*/}
                  <div className="col-auto">
                    <div className="d-flex align-items-center pb-2 pb-lg-0">
                      <ListingGrayIcon
                        style={{fontSize: 17, marginRight: 10}}
                      />
                      <Typography
                        variant="body2"
                        className="text-nowrap text-muted"
                      >
                        {intl.formatMessage({
                          id: 'dashboard.listings.details.listing_id',
                        })}
                      </Typography>
                      <ArrowLink
                        className="ms-1"
                        title="#FA3A98"
                        url="http://link"
                      />
                    </div>
                  </div>
                  {/*Created at*/}
                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      <CalendarGrayIcon
                        style={{fontSize: 15, marginRight: 10}}
                      />
                      <Typography
                        variant="body2"
                        className="text-nowrap text-muted"
                      >
                        {intl.formatMessage(
                          {id: 'dashboard.listings.details.created_at'},
                          {date: '23/04/2021'}
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Actions */}
            <div className="col-lg-auto">
              <div className="d-flex justify-content-center justify-content-lg-end action-buttons pt-3 pt-lg-0">
                <a href="#" className="text-dark">
                  {intl.formatMessage({id: 'actions.save'})}
                </a>
                <a href="#" className="text-dark">
                  {intl.formatMessage({id: 'actions.delete'})}
                </a>
                <Dropdown
                  title={intl.formatMessage({id: 'actions.more_actions'})}
                  items={[
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.user_details.actions.reset_password',
                      }),
                      onClick: () => {},
                    },
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.user_details.actions.resend_guest_email',
                      }),
                      onClick: () => {},
                    },
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.user_details.actions.suspend_user',
                      }),
                      onClick: () => {},
                    },
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.user_details.actions.unsuspend_user',
                      }),
                      onClick: () => {},
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Content*/}
      <div className="container ms-lg-0">
        <div className="content-block">
          <div className="row gx-fan-20">
            <div className="col-lg-4">
              {/*Event details*/}
              <div className="card details-card mb-3">
                <div className="card-body">
                  <Typography variant="h4" className="mb-3">
                    <FormattedMessage id="dashboard.listings.details.event_details" />
                  </Typography>
                  <div className="form-group static">
                    <Typography variant="subtitle2" className="text-muted mb-1">
                      <FormattedMessage id="dashboard.listings.details.event_title" />
                    </Typography>
                    <Typography variant="body2">Arsenal - Tottenham</Typography>
                  </div>
                  <div className="form-group static">
                    <Typography variant="subtitle2" className="text-muted mb-1">
                      <FormattedMessage id="dashboard.listings.details.venue" />
                    </Typography>
                    <Typography variant="body2">
                      Emirates Stadium, London, UK
                    </Typography>
                  </div>
                  <div className="form-group static">
                    <Typography variant="subtitle2" className="text-muted mb-1">
                      <FormattedMessage id="dashboard.listings.details.event_date" />
                    </Typography>
                    <Typography variant="body2">November 23, 2021</Typography>
                  </div>
                  <div className="form-group mb-0">
                    <Typography variant="subtitle2" className="text-muted mb-1">
                      <FormattedMessage id="dashboard.listings.details.event_time" />
                    </Typography>
                    <Typography variant="body2">15:00</Typography>
                  </div>
                </div>
              </div>
              {/*Seating plan*/}
              <div className="card details-card">
                <div className="card-body">
                  <Typography variant="h4" className="mb-3">
                    <FormattedMessage id="dashboard.listings.details.seating_plan" />
                  </Typography>
                  <img
                    className="img-fluid"
                    alt=""
                    src={`${IMG_BASE_URL}seating-plan.png`}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {/*Details form*/}
              <ListingDetailsForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ListingDetails);
