import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontWeight: 'bold',
    height: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&.primary-light': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.secondary-light': {
      backgroundColor: '#EDEDED',
      color: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: 'normal',
      minWidth: '40%',
      height: 23,
    },
  },
  label: {
    padding: 0,
  },
}));

const IndicatorValue = ({variant, value}) => {
  const classes = useStyles();

  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      className={variant === 'primary' ? 'primary-light' : 'secondary-light'}
      label={value}
    />
  );
};

IndicatorValue.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  value: PropTypes.string,
};

export default IndicatorValue;
