import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import cx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Col, Row} from 'reactstrap';
import {isEmpty, isEqual} from 'lodash';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '../../Common/Checkbox';
import Paper from '@material-ui/core/Paper';
import Button from '../../Common/Button';
import Chip from '../../Common/Chip';
import {textStyles} from '../../Common/TextField';
import {ArrowDownIcon} from '../../../constants/images';
import {AutocompleteProps} from '@material-ui/lab';

/**
 * AutocompleteMultiple Option Item
 */
export type AmOptionItem = {
  title: string;
  value: string | number;
};

/* Styles */
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiInputBase-formControl': {
        padding: '4px 5px',
        '& .MuiAutocomplete-input': {
          padding: '4px 5px',
          textAlign: 'start',
        },
      },
    },
  },
  endAdornment: {
    right: '10px !important',
    top: '50%',
    transform: 'translateY(-50%)',
    marginTop: -1,
  },
  popper: {
    boxShadow: 'none',
    marginTop: 5,
    '& .MuiPaper-root': {
      borderRadius: 8,
      boxShadow: theme.shadows[8],
    },
  },
  listbox: {
    maxHeight: 256,
    paddingTop: 15,
    paddingBottom: 20,
  },
  option: {
    padding: '0 20px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'inherit',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'inherit',
    },
    '& .MuiCheckbox-root': {
      marginLeft: -10,
      marginTop: -5,
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  tag: {
    margin: 0,
  },
}));

interface AutocompleteMultipleProps {
  options: AmOptionItem[];
  values: AmOptionItem[];
  className?: string;
  placeholder?: string;
  onChange: (newValue: AmOptionItem[]) => void;
  canSelect: boolean;
  autocompleteProps?: Partial<
    AutocompleteProps<AmOptionItem, true, true, false>
  >;
  chipIdProp?: string;
  blurOnChange?: boolean;
}

const AutocompleteMultiple = (props: AutocompleteMultipleProps) => {
  const {
    id = 'id-autocomplete',
    className = '',
    error = '',
    values,
    onChange,
    options,
    placeholder,
    canEdit = true,
    autocompleteProps,
    chipIdProp = 'id',
    blurOnChange,
    canSelect,
  } = props;

  const intl = useIntl();
  //open state
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const textClasses = textStyles();
  //values
  const [currentValues, setCurrentValues] = useState(values ?? []);
  //search popup field
  const inputRef = useRef<HTMLInputElement | null>(null);

  // sync currentValues and values
  useEffect(() => {
    if (!isEqual(values, currentValues)) {
      setCurrentValues(values);
    }
  }, [values]);

  // Clear click handler
  const handleClearBtnClick = e => {
    if (blurOnChange) {
      (document.activeElement as any)?.blur();
    }
    e.stopPropagation();
    setCurrentValues([]);
    onChange([]);
    setIsOpen(false);
  };

  // Apply click handler
  const handleApplyBtnClick = e => {
    if (blurOnChange) {
      (document.activeElement as any)?.blur();
    }
    e.stopPropagation();
    setIsOpen(false);
    onChange(currentValues);
  };

  // Prevent input blur when interacting with the combobox
  const handleMouseDown = event => {
    if (event.target.getAttribute('id') !== id) {
      event.preventDefault();
    }
  };

  // Focus the input when interacting with the combobox
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Chip on delete click action
  const handleDelete = removeId => {
    const newValus = currentValues.filter(v => v[chipIdProp] !== removeId);
    setCurrentValues(newValus);
    onChange(newValus);
  };

  return (
    <div className={className}>
      {canSelect && (
        <Autocomplete
          id={id}
          disabled={!canEdit}
          multiple
          classes={classes}
          value={currentValues}
          open={isOpen}
          openOnFocus
          options={options}
          disableClearable
          disableCloseOnSelect
          popupIcon={<ArrowDownIcon style={{width: 9, height: 6}} />}
          getOptionLabel={option => option.title}
          renderOption={(option, {selected}) => (
            <Row className="align-items-center gx-0">
              <Col xs="auto">
                <Checkbox
                  checked={
                    selected ||
                    currentValues.find(item => item.value === option.value)
                  }
                />
              </Col>
              <Col>
                <Typography variant="body2">{option.title}</Typography>
              </Col>
            </Row>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              classes={textClasses}
              error={!!error}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                width: '100%',
              }}
            />
          )}
          renderTags={() => null}
          PaperComponent={props => (
            <Paper
              autoFocus={false}
              className={cx({'d-none': !isOpen})}
              onMouseDown={handleMouseDown}
              onClick={handleClick}
            >
              {props.children}
              <div className="px-3 pb-3 pt-vtl-15">
                <div className="row gx-2 align-items-center justify-content-end">
                  <div className="col-auto">
                    <Button
                      link
                      title={intl.formatMessage({id: 'actions.clear'})}
                      onClick={handleClearBtnClick}
                    />
                  </div>
                  <div className="col-auto">
                    <Button
                      className="green"
                      title={intl.formatMessage({id: 'actions.apply'})}
                      onClick={handleApplyBtnClick}
                    />
                  </div>
                </div>
              </div>
            </Paper>
          )}
          // Toggle open state
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          // onChange
          onChange={(event, value) => {
            setCurrentValues(value);
          }}
          {...autocompleteProps}
        />
      )}
      {values?.length > 0 && (
        <div className="mt-2">
          <div className={classes.tags}>
            {values.map((item, index) => (
              <Chip
                key={index}
                label={item.title}
                className={classes.tag}
                onDelete={
                  canSelect ? () => handleDelete(item[chipIdProp]) : undefined
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

AutocompleteMultiple.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  canEdit: PropTypes.bool,
};

export default AutocompleteMultiple;
