import {put} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';

/**
 * Add a snackbar message to a queue
 * @param messages
 * @param variant
 * @param options
 */
export function* doEnqueueSnackBar(messages, variant, options = {}) {
  let key = new Date().getTime() + Math.random();
  if (options && options.key) {
    key = options.key;
  }

  // change expired jwt message and set a key to use for all expired jwt notification
  if (messages === 'Expired JWT Token') {
    messages = 'Your session has expired';
    key = 'jwt_expired';
  }

  const notification = {
    message: messages,
    options: {
      ...options,
      variant: variant,
      autoHideDuration: options.autoHideDuration || 10000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
  };
  yield put({
    type: types.ENQUEUE_SNACKBAR,
    notification: {
      key,
      ...notification,
    },
  });
}

/**
 * Enqueue snackbar notification from a component
 *
 * @param action
 */
export function* doEnqueueSnackBarFromComponent(action) {
  const {messages, variant, options, dismissOthers} = action.payload;

  //Close other snackbars
  if (dismissOthers) {
    yield put({
      type: types.CLOSE_SNACKBAR,
      dismissAll: true,
    });
  }
  yield doEnqueueSnackBar(messages, variant, options);
}

/**
 * Add a centered snackbar message to a queue
 * @param messages
 * @param variant
 * @param options
 */
export function* doEnqueueCenteredSnackBar(messages, variant, options) {
  const notification = {
    message: messages,
    options: {
      ...options,
      variant: variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
  };
  yield put({
    type: types.ENQUEUE_SNACKBAR,
    notification: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
  });
}
