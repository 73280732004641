import {SubmissionError} from 'redux-form';

/**
 * Check if the value is set
 * @param value
 * @param message
 * @returns undefined|string
 */
export const required = (value, message) =>
  !value ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'string' && value.trim() === '')
    ? message || 'This is required'
    : undefined;

/**
 * Check if boolean has value
 *
 * @param value
 * @param message
 * @returns {*}
 */
export const booleanRequired = (value, message) =>
  [true, false, 'true', 'false'].indexOf(value) > -1
    ? undefined
    : message || 'This is required';

/**
 * Check if the value is a valid email
 * @param value
 * @param message
 * @returns {*}
 */
export const email = (value, message) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? message || 'Wrong email format'
    : undefined;

/**
 *  Check if 2 values are equal
 * @param value1
 * @param value2
 * @param message
 * @returns {undefined}
 */
export const valuesMismatches = (value1, value2, message) =>
  value1 !== value2 ? message || 'Values mismatch' : undefined;

/**
 * Check if the text is shortest than min
 * @param min
 * @returns {function(*=, *): undefined}
 */
export const tooShort = min => (value, message) =>
  value && value.trim().length < min
    ? message || 'This value is too short'
    : undefined;

/**
 * Check if the text is longest than max
 * @param max
 * @returns {function(*=, *): undefined}
 */
export const tooLong = max => (value, message) =>
  value && value.trim().length > max
    ? message || 'This value is too long'
    : undefined;

/**
 * Check if array length is shortest than min
 * @param min
 * @returns {function(*=, *): undefined}
 */
export const tooShortArray = min => (value, message) =>
  Array.isArray(value) && value.length < min
    ? message || 'This value is too short'
    : undefined;

/**
 * Check if array length longest than max
 * @param max
 * @returns {function(*=, *): undefined}
 */
export const tooLongArray = max => (value, message) =>
  Array.isArray(value) && value.length > max
    ? message || 'This value is too long'
    : undefined;

/**
 * Check if a value is an alphanumeric including characters with accents
 * @param value
 * @param message
 * @returns {undefined}
 */
export const alphanumeric = (value, message) =>
  value && !/^[\w àéêèëôöûüîïŷ]*$/.test(value)
    ? message || 'Only alphanumeric characters allowed'
    : undefined;

/**
 * Check if a value is a number
 * @param value
 * @param message
 * @returns {undefined}
 */
export const number = (value, message) =>
  value && isNaN(Number(value)) && !/^[0-9.,]+$/.test(value)
    ? message || 'This value is not a valid number'
    : undefined;

/**
 * Check if a value is a decimal
 * @param value
 * @param precision
 * @param message
 * @returns {undefined}
 */
export const decimal = (value, precision, message) =>
  value &&
  !isNaN(Number(value)) &&
  !new RegExp(`^[0-9]+([.,][0-9]{1,${precision}})?$`).test(value)
    ? message || 'This value is not a valid number'
    : undefined;

/**
 * Check if a value is Integer
 * @param value
 * @param message
 * @returns {*}
 */
export const integer = (value, message) =>
  value && !Number.isInteger(+value)
    ? message || 'This value is not a valid integer'
    : undefined;

/**
 * Check if a value is positive
 * @param value
 * @param message
 * @returns {*}
 */
export const positive = (value, message) =>
  value && !(Number(value) > 0)
    ? message || 'This value cannot be negative or zero'
    : undefined;

/**
 * Check if a value is positive or 0
 * @param value
 * @param message
 * @returns {*}
 */
export const positiveOrZero = (value, message) =>
  value && !(Number(value) >= 0)
    ? message || 'This value must be equal to or greater than zero'
    : undefined;
/**
 * Check if a value1 is less than value2
 * @param value1
 * @param value2
 * @param message
 * @returns {*}
 */
export const lessThan = (value1, value2, message) =>
  !isNaN(+value1) && !isNaN(+value2)
    ? +value1 >= +value2
      ? message || `This value should be less than ${+value2}`
      : undefined
    : undefined;

/**
 * Check if string1 does not start with
 * @param string1
 * @param string2
 * @param message
 * @returns {*}
 */
export const notStartsWith = (string1, string2, message) =>
  string1 && string1.startsWith(string2) ? message : undefined;

/**
 * Check if string1 does not end with
 * @param string1
 * @param string2
 * @param message
 * @returns {*}
 */
export const notEndsWith = (string1, string2, message) =>
  string1 && string1.endsWith(string2) ? message : undefined;

/**
 * Check if string1 does not contain string2
 * @param string1
 * @param string2
 * @param message
 * @returns {undefined}
 */
export const notContains = (string1, string2, message) =>
  string1 && string1.includes(string2) ? message : undefined;

/**
 * Validate password
 */
export const password = (value, message) =>
  !/.*[0-9].*/.test(value)
    ? message || 'Password must include at least 1 number'
    : undefined;
export const passwordMatch = (value1, value2, message) =>
  value1 !== value2 ? message || "Password don't match" : undefined;

//Validate a url with/without http(s)
const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const url = (value, message) =>
  !urlRegex.test(value) && value
    ? message || 'This is a wrong format'
    : undefined;

//Validate phone number
// const phoneRegex = /(^[0-9]{3}[0-9]{3}[0-9]{4}$)|(^[+][1][0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^[+][1][(][0-9]{3}[)][0-9]{3}-[0-9]{4}$)|(^[+][1][(][0-9]{3}[)][0-9]{3}[0-9]{4}$)/;
const phoneRegex = /^\+?\d{7,}$/;
export const phoneNumber = (value, message) => {
  return !phoneRegex.test(value.replace(/[ ().-]/g, ''))
    ? message || 'This is a wrong format'
    : undefined;
};

//Validate IntlPhoneNumber field
export const intlPhoneNumber = (value, message) =>
  !value.isValid ? (message ? message : 'This is a wrong format') : undefined;

const addressKeys = [
  'formattedAddress',
  'street',
  'location',
  'county',
  'city',
  'postcode',
  'state',
  'country',
];
export const apiAddress = (value, message) =>
  value && typeof value === 'object' && addressKeys.every(v => value[v])
    ? undefined
    : message || 'Invalid address...';

export const regExpValidate = (value, regexp, message) =>
  !regexp.test(value) && value
    ? message || 'This is a wrong format'
    : undefined;

export function validateBeforeSubmit(errors) {
  if (!errors) {
    return;
  }

  //Test if form is valid and throw Redux Form SubmissionError if there is errors
  Object.keys(errors).forEach(key => {
    if (errors[key]) {
      throw new SubmissionError({
        [key]: errors[key],
      });
    }
  });
}

/**
 * This function filters error object and an array of errors by
 * removing undefined properties (witch means valid fields)
 * @param obj
 * @param name
 */
export function getErrorFieldNames(obj, name = '') {
  try {
    const errorArr = [];
    if (typeof obj === 'object') {
      errorArr.push(
        Object.keys(obj)
          .map(key => {
            const next = obj[key];
            if (next) {
              if (typeof next === 'string') {
                return name + key;
              }
              // Keep looking
              if (next.map) {
                errorArr.push(
                  next
                    .map((item, index) =>
                      getErrorFieldNames(item, `${name}${key}[${index}].`)
                    )
                    .filter(o => o)
                );
              }
            }
            return null;
          })
          .filter(o => o)
      );
    }
    return flatten(errorArr);
  } catch (e) {
    console.error(e);
  }

  return flatten([]);
}

function flatten(arr) {
  return arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );
}

/**
 * Scroll to the first error after a failed form submission
 * @param errors
 */
export function scrollToFirstError(config = {}) {
  return errors => {
    const errorFields = getErrorFieldNames(errors);
    // Using breakable for loop
    for (let i = 0; i < errorFields.length; i++) {
      const fieldName = errorFields[i];
      // Checking if the marker exists in DOM
      const elements = Array.from(
        document.querySelectorAll(`[name="${fieldName}"]`)
      ).filter(el => el.nodeName !== 'META');
      const element = elements.length > 0 ? elements[0] : null;
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        break;
      }
    }
  };
}
