import React from 'react';
import Dropdown from '../../Common/Dropdown';
import {useIntl} from 'react-intl';
import {
  ADMIN_ADD_PENALTY,
  ADMIN_CANCEL_SALES,
  ADMIN_CREATE_PAYMENT,
  ADMIN_PAUSE_RESUME_PAYOUT,
  ADMIN_REFUND_SALES,
  ADMIN_SET_AS_CANCELLED_BOTH,
  ADMIN_SET_AS_DELIVERED,
} from '../../../constants/permissions';
import usePermission from '../../../Auth/usePermission';

/**
 * This function will show the dropDown with the actions in the details of the sellers
 **/
const Actions = props => {
  const {hasPermissions} = usePermission();
  const intl = useIntl();
  const {
    setCancelPopup,
    setAsCancelledPopup,
    setRefundPopup,
    setPenaltyPopup,
    setPaymentPopup,
    createPaymentEnabled,
    setAsDeliveredPopup,
    allowCancel,
    allowAsCancelled,
    allowPenalty,
    allowRefund,
    allowCreatePayment,
    allowAsDelivered,
    onPausePayout,
    onResumePayout,
  } = props;

  // Options created to use inside the dropdown
  const optionCancel = hasPermissions(ADMIN_CANCEL_SALES) && {
    label: intl.formatMessage({id: 'dashboard.sales.details.cancel_sales'}),
    onClick: () => setCancelPopup(true),
  };
  const optionSetAsCancelled = hasPermissions(ADMIN_SET_AS_CANCELLED_BOTH) && {
    label: intl.formatMessage({
      id: 'dashboard.sales.details.set_as_cancelled_both',
    }),
    onClick: () => setAsCancelledPopup(true),
  };
  const optionSetAsDelivered = hasPermissions(ADMIN_SET_AS_DELIVERED) && {
    label: intl.formatMessage({id: 'dashboard.sales.details.set_as_delivered'}),
    onClick: () => setAsDeliveredPopup(true),
  };
  const optionAddPenalty = hasPermissions(ADMIN_ADD_PENALTY) && {
    label: intl.formatMessage({id: 'dashboard.sales.penalty.add_penalty'}),
    onClick: () => setPenaltyPopup(true),
  };
  const optionRefund = hasPermissions(ADMIN_REFUND_SALES) && {
    label: intl.formatMessage({id: 'dashboard.sales.refund.refund'}),
    onClick: () => setRefundPopup(true),
  };
  const optionCreateNewPayment = hasPermissions(ADMIN_CREATE_PAYMENT) && {
    label: intl.formatMessage({
      id: 'dashboard.sales.new_payment_create.create_new_payment',
    }),
    onClick: () => setPaymentPopup(true),
    disabled: !createPaymentEnabled,
  };
  const optionPausePayout = hasPermissions(ADMIN_PAUSE_RESUME_PAYOUT) && {
    label: intl.formatMessage({id: 'dashboard.transactions.payout.pause'}),
    onClick: onPausePayout,
  };
  const optionResumePayout = hasPermissions(ADMIN_PAUSE_RESUME_PAYOUT) && {
    label: intl.formatMessage({id: 'dashboard.transactions.payout.resume'}),
    onClick: onResumePayout,
  };

  const items = [];

  //Add Cancel if allowed
  allowCancel && items.unshift(optionCancel);

  // Add option refund if allowed
  allowRefund && items.push(optionRefund);

  //Add option set as cancelled
  allowAsCancelled && items.push(optionSetAsCancelled);

  //Add option set as delivered
  allowAsDelivered && items.push(optionSetAsDelivered);

  //Pause/Resume payout
  // allowPausePayout && items.push(optionPausePayout);
  // allowResumePayout && items.push(optionResumePayout);

  // Add option penalty if allowed
  allowPenalty && items.push(optionAddPenalty);

  // Add option create payment if allowed
  allowCreatePayment && items.push(optionCreateNewPayment);

  return (
    <Dropdown
      title={intl.formatMessage({id: 'dashboard.sales.details.actions'})}
      items={items.filter(Boolean)}
    />
  );
};

export default Actions;
