import axios from 'axios';
import {API_SALES_URL, API_V2_SALE_DETAILS_URL} from '../constants/urls';
import {truncateSaleAmounts} from '../utils/saleHelper';
import {get} from './api';

export async function getSaleList(payload) {
  const searchParams = new URLSearchParams();
  const {q, filters, sort, page, rowsPerPage, cancelToken} = payload;
  if (q) {
    searchParams.append('q', q);
  }

  if (sort && sort.by && sort.direction) {
    searchParams.append('orderBy', sort.by);
    searchParams.append('orderDirection', sort.direction);
  }

  if (page && rowsPerPage) {
    searchParams.append('page', page);
    searchParams.append('limit', rowsPerPage);
  }

  // filters
  if (filters && typeof filters === 'object') {
    for (const filterName in filters) {
      const value = filters[filterName];
      if (Array.isArray(value)) {
        for (const i in value) {
          searchParams.append(`${filterName}[]`, value[i]);
        }
      } else {
        searchParams.append(filterName, value);
      }
    }
  }

  const response = await axios.get(
    API_SALES_URL + '?' + searchParams.toString(),
    {cancelToken}
  );

  return {
    ...response.data,
    items: (response.data?.items ?? []).map(truncateSaleAmounts),
  };
}

export async function getSaleDetails({token, id}) {
  const data = await get({
    url: API_V2_SALE_DETAILS_URL.replace(':id', id),
    token,
    config: {
      ldjson: true,
    },
  });

  return truncateSaleAmounts(data);
}
