import {cloneDeep} from 'lodash';
import * as types from '../constants/actionTypes';

const defaultState = {
  loading: false,
  error: null,
  banks: [],
};

export default function userBankReducer(state = defaultState, action) {
  const {type, payload} = action;
  switch (type) {
    case types.API_USER_BANK_DETAILS_REQUESTED:
    case types.API_USER_BANK_DEFAULT_REQUESTED:
    case types.API_USER_BANK_DELETE_REQUESTED:
    case types.API_USER_BANK_CREATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.API_USER_BANK_DETAILS_SUCCEEDED:
    case types.API_USER_BANK_CREATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        banks: payload.data,
      };
    case types.API_USER_BANK_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        banks: [],
      };
    case types.API_USER_BANK_DEFAULT_FAILED:
    case types.API_USER_BANK_DELETE_FAILED:
    case types.API_USER_BANK_CREATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.API_USER_BANK_DEFAULT_SUCCEEDED:
      const oldBanks = cloneDeep([...state.banks]);
      const newBankData = oldBanks.map(item => {
        if (item.id === payload.id) {
          item.is_default = true;
        } else {
          item.is_default = false;
        }
        return item;
      });
      return {
        ...state,
        banks: newBankData,
        loading: false,
      };
    case types.API_USER_BANK_DELETE_SUCCEEDED:
      return {
        ...state,
        banks: state.banks.filter(where => where.id !== payload.id),
        loading: false,
      };
    default:
      return state;
  }
}
