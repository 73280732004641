import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {injectIntl} from 'react-intl';
import AutocompleteField from '../../Form/Fields/AutocompleteField';
import InputField from '../../Form/Fields/InputField';
import CheckboxField from '../../Form/Fields/CheckboxField';
import {
  APP_BANK_ACCOUNT_COUNTRIES,
  APP_BANK_ACCOUNT_CURRENCIES,
} from '../../../constants/currencies';
import {validateBeforeSubmit, required} from '../../../validation/validators';
import * as actionTypes from '../../../constants/actionTypes';
import CustomModal from '../../Widgets/Modal';
import {cloneDeep} from 'lodash';

const validate = values => {
  const errors = {};
  // make sure bank name is not empty
  errors.name = required(values.name);
  // make sure iban number is not empty and it's number
  errors.account_number = required(values.account_number);
  // make sure country is not empty
  errors.country = required(values.country);
  // make sure currency is not empty
  errors.currency = required(values.currency);
  return errors;
};

/**
 * Bank Add/Edit form
 * @returns {*}
 * @constructor
 */
let BankForm = props => {
  //Translations
  const {intl, opened, handleClose, isNew, handleSubmit, user} = props;
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState('');

  // hold the state of selected country
  const onCountryChange = value => {
    setSelectedCountry(value);
  };

  const submit = formValues => {
    const values = cloneDeep(formValues);
    // validate and make sure empty fields are not submitted
    const errors = validate(values);
    validateBeforeSubmit(errors);

    // check if is_default is not checked then assign default value of false
    if (!values.is_default) {
      values.is_default = false;
    }
    // dispatch action to save bank detail
    dispatch({
      type: actionTypes.API_USER_BANK_CREATE_REQUESTED,
      payload: {
        ...values,
        id: user.id,
      },
    });
    // close modal
    handleClose();
  };

  // country options
  const countryOptions = Object.keys(APP_BANK_ACCOUNT_COUNTRIES).map(key => ({
    label: APP_BANK_ACCOUNT_COUNTRIES[key],
    value: key,
  }));

  // currency options
  const currencyOptions = Object.keys(APP_BANK_ACCOUNT_CURRENCIES).map(key => ({
    label: APP_BANK_ACCOUNT_CURRENCIES[key],
    value: key,
  }));

  return (
    <CustomModal
      width={328}
      title={intl.formatMessage({
        id: isNew
          ? 'dashboard.user_details.tab_finance.add_bank_account_title'
          : 'dashboard.user_details.tab_finance.edit_bank_account',
      })}
      saveTitle={intl.formatMessage({id: 'actions.add'})}
      opened={opened}
      handleClose={handleClose}
      onOk={handleSubmit(submit)}
    >
      {opened ? (
        <form>
          {/*Country*/}
          <div className="form-group static">
            <Field
              name="country"
              component={AutocompleteField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.country',
              })}
              outlined
              externalLabel
              options={countryOptions}
              onChange={onCountryChange}
            />
          </div>
          {/*Currency*/}
          <div className="form-group static">
            <Field
              name="currency"
              component={AutocompleteField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.currency',
              })}
              outlined
              externalLabel
              options={currencyOptions}
            />
          </div>
          {/*Bank name*/}
          <div className="form-group static">
            <Field
              name="name"
              component={InputField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_finance.bank_name',
              })}
              externalLabel
              outlined
              placeholder="Bank name"
            />
          </div>
          {/*Swift number*/}
          {selectedCountry &&
          (selectedCountry === 'US' || selectedCountry === 'GB') ? (
            <div className="form-group static">
              <Field
                name="routing_number"
                component={InputField}
                label={intl.formatMessage({
                  id:
                    selectedCountry === 'US'
                      ? 'dashboard.user_details.tab_finance.routing_number'
                      : selectedCountry === 'GB'
                        ? 'dashboard.user_details.tab_finance.sort_code'
                        : '',
                })}
                externalLabel
                outlined
                placeholder={intl.formatMessage({
                  id:
                    selectedCountry === 'US'
                      ? 'dashboard.user_details.tab_finance.routing_number'
                      : selectedCountry === 'GB'
                        ? 'dashboard.user_details.tab_finance.sort_code'
                        : '',
                })}
              />
            </div>
          ) : null}
          {/*IBAN number*/}
          <div className="form-group static">
            <Field
              name="account_number"
              component={InputField}
              label={intl.formatMessage({
                id:
                  selectedCountry === 'GB'
                    ? 'dashboard.user_details.tab_finance.account_number'
                    : 'dashboard.user_details.tab_finance.iban_number',
              })}
              externalLabel
              outlined
              placeholder={intl.formatMessage({
                id:
                  selectedCountry === 'GB'
                    ? 'dashboard.user_details.tab_finance.account_number'
                    : 'dashboard.user_details.tab_finance.iban_number',
              })}
            />
          </div>
          {/*Set as default address*/}
          <div className="form-group edit-address-checkbox-row">
            <Field
              name="is_default"
              component={CheckboxField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_finance.set_as_default',
              })}
            />
          </div>
        </form>
      ) : null}
    </CustomModal>
  );
};

BankForm = reduxForm({
  form: 'BankForm',
  enableReinitialize: true,
  validate,
})(BankForm);

const mapStateToProps = (state, {bank = null}) => {
  const initialValues = {};

  return {
    initialValues,
  };
};

BankForm.propTypes = {
  /**
   * If not null, the address to be edited
   */
};

// connect to redux store and inject intl
export default connect(mapStateToProps)(injectIntl(BankForm));
