import React, {useState} from 'react';
import clsx from 'clsx';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core/styles';
import {CalendarIcon} from '../../constants/images';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.border,
    '& .MuiFormLabel-root': {
      color: theme.palette.secondary.light,
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 0,
      left: 10,
      top: -14,
    },
    '& .MuiButtonBase-root': {
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiInputAdornment-root ': {
      '& svg.MuiSvgIcon-root': {
        fontSize: 12,
      },
    },
    '&.outlined': {
      backgroundColor: 'transparent',
      '& .MuiFormLabel-root': {
        left: 0,
      },
      '& .MuiInputAdornment-root ': {
        '& .MuiIconButton-root': {
          padding: 6,
          marginRight: 3,
        },
        '& svg.MuiSvgIcon-root': {
          fontSize: 15,
        },
      },
    },
  },
  input: {
    border: 'none',
    padding: '3px 3px 3px 10px',
    marginTop: '0 !important',
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
    '&.outlined': {
      border: 'inherit',
      padding: '0 0 3px 0',
    },
  },
  popover: {
    '& .MuiPaper-root': {
      marginTop: 10,
      marginLeft: 20,
      boxShadow: theme.shadows[2],
    },
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: 181,
      maxWidth: 'unset',
      minHeight: 196,
      overflowY: 'hidden',
      borderRadius: 4,
    },
    '& .MuiPickersCalendar-transitionContainer': {
      maxWidth: 'unset',
      minHeight: 155,
    },
    '& .MuiToolbar-root': {
      display: 'none',
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      padding: 5,
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiPickersDay-day': {
      width: 25,
      height: 25,
      borderRadius: 0,
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      width: 25,
    },
  },
}));

interface CustomDatePickerProps extends KeyboardDatePickerProps {
  placeholder: string;
  outlined?: boolean;
  dateFormat?: string;
  defaultValue: ParsableDate;
  onChange: (value: MaterialUiPickersDate | null) => void;
}

export const CustomDatePicker = ({
  placeholder,
  outlined = false,
  dateFormat = 'MMMM D YYYY',
  defaultValue = {},
  onChange,
  ...other
}: CustomDatePickerProps) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const onInputChange = (value: MaterialUiPickersDate | null) => {
    setSelectedDate(value);
    onChange(value);
  };

  const noShrink = !outlined;

  // show placeholder only when the current value is empty
  const label = !selectedDate ? placeholder : '';

  return (
    <KeyboardDatePicker
      fullWidth
      className={clsx(classes.root, outlined && 'outlined')}
      variant="inline"
      format={dateFormat}
      label={noShrink ? label : placeholder}
      value={selectedDate}
      keyboardIcon={<CalendarIcon />}
      onChange={onInputChange}
      InputProps={{
        className: clsx(classes.input, outlined && 'outlined'),
        color: 'secondary',
        disableUnderline: noShrink,
      }}
      InputLabelProps={{
        shrink: noShrink ? false : !!selectedDate,
      }}
      PopoverProps={{
        className: classes.popover,
        elevation: 0,
      }}
      {...other}
    />
  );
};

export default CustomDatePicker;
