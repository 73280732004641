import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Routes from './components/Routes/containers/Routes';
import theme from './constants/theme';
import Notifier from './components/Widgets/Notifier';
import ProgressDialog from './components/Widgets/ProgressDialog';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Auth/Login';
import FetchUser from './Auth/FetchUser';
import AxiosAuthorizationToken from './Auth/AxiosAuthorizationToken';
import {RouteProps} from 'react-router';

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Notifier />
      <ProgressDialog />
      <FetchUser />
      <AxiosAuthorizationToken />
      <>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              path=""
              component={Routes as unknown as RouteProps['component']}
            />
          </Switch>
        </BrowserRouter>
      </>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default App;
