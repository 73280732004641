import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/styles';
import {injectIntl} from 'react-intl';
import IconButton from '../../Common/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover/Popover';
import {SELLER_MARKS} from '../../../constants/enums';
import clsx from 'clsx';
import {
  DELIVERY_MARK_ICON,
  INSTANT_MARK_ICON,
  PREMIUM_MARK_ICON,
  PRO_MARK_ICON,
  TRUST_MARK_ICON,
} from '../../../constants/images';

//Seller Marks popover
const StyledPopover = withStyles(theme => ({
  paper: {
    position: 'relative',
    display: 'inline-flex',
    marginTop: 13,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[4],
    overflow: 'visible',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: -8,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: 'transparent transparent #282B3E transparent',
    },
  },
}))(Popover);

//Seller Mark
const SellerMark = ({id, icon, iconSize, text}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      marginRight: 2,
      '& .MuiButtonBase-root': {
        borderRadius: 4,
        '& .MuiSvgIcon-root': {
          width: iconSize,
          height: iconSize,
        },
      },
    },
    popover: {
      pointerEvents: 'none',
    },
  }));

  const classes = useStyles();
  //Anchor
  const [anchorEl, setAnchorEl] = useState(null);

  //Popover open
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  //Popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <IconButton
        variant="filled"
        size="xs"
        onHover={handlePopoverOpen}
        onLeave={handlePopoverClose}
      >
        {icon}
      </IconButton>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="d-flex align-items-start">
          {icon}
          <Typography variant="body2" className="ms-1">
            {text}
          </Typography>
        </div>
      </StyledPopover>
    </div>
  );
};

//Row with seller's marks, it renders only existing marks with tooltips
const SellerMarks = ({intl, items, id = '', iconSize = 20, className = ''}) =>
  items && items.length > 0 ? (
    <div className={clsx('d-flex align-items-center', className)}>
      {items.includes(SELLER_MARKS.MARK_TRUSTED) && (
        <SellerMark
          id={`trusted_mark_${id}`}
          icon={TRUST_MARK_ICON}
          iconSize={iconSize}
          text={intl.formatMessage({
            id: 'dashboard.sales.marks.trusted_seller',
          })}
        />
      )}
      {items.includes(SELLER_MARKS.MARK_DELIVERY) && (
        <SellerMark
          id={`delivery_mark_${id}`}
          icon={DELIVERY_MARK_ICON}
          iconSize={iconSize}
          text={intl.formatMessage({
            id: 'dashboard.sales.marks.custom_delivery',
          })}
        />
      )}
      {items.includes(SELLER_MARKS.MARK_PREMIUM) && (
        <SellerMark
          id={`premium_mark_${id}`}
          icon={PREMIUM_MARK_ICON}
          iconSize={iconSize}
          text={intl.formatMessage({
            id: 'dashboard.sales.marks.premium_seller',
          })}
        />
      )}
      {items.includes(SELLER_MARKS.MARK_PRO) && (
        <SellerMark
          id={`pro_mark_${id}`}
          icon={PRO_MARK_ICON}
          iconSize={iconSize}
          text={intl.formatMessage({id: 'dashboard.sales.marks.pro_features'})}
        />
      )}
      {items.includes(SELLER_MARKS.MARK_INSTANT) && (
        <SellerMark
          id={`instant_mark_${id}`}
          icon={INSTANT_MARK_ICON}
          iconSize={iconSize}
          text={intl.formatMessage({
            id: 'dashboard.sales.marks.instant_confirmation',
          })}
        />
      )}
    </div>
  ) : null;

export default injectIntl(SellerMarks);
