import {useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {API_USER_AUTOCOMPLETE_REQUESTED} from '../../constants/actionTypes';
import {isEmpty} from '../../utils/functions';

export default function useUserAutocomplete(type = null) {
  const userNamesSelector = useCallback(
    state => state.userReducer.userNames,
    []
  );
  const dispatch = useDispatch();

  const getUserNames = useCallback(
    term => {
      //Trigger search upon 2 characters or more
      if (!isEmpty(term) && term.length > 1) {
        const payload = {term};
        if (type) {
          payload['type'] = type;
        }
        dispatch({
          type: API_USER_AUTOCOMPLETE_REQUESTED,
          payload,
        });
      }
    },
    [dispatch]
  );

  return {userNamesSelector, getUserNames};
}
