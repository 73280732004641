import React, {Fragment} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '../Common/StyledDrawer';
import Button from '../Common/Button';
import {CheckmarkIcon, SettingsIcon} from '../../constants/images';
import {useHistory} from 'react-router-dom';
import NotificationItem from '../Widgets/NotificationItem';
import * as urls from '../../constants/urls';

/* Notifications */
const NotificationsPopup = props => {
  const {opened, onClose} = props;

  const intl = useIntl();
  //Router
  const history = useHistory();

  return (
    <Drawer className="notifications-popup" open={opened} onClose={onClose}>
      <Fragment>
        <Box pb={0.9}>
          <Typography variant="h5">
            <FormattedMessage id="dashboard.menu.dropdown.notifications" />
          </Typography>
        </Box>
        {/*Mark all as read / Settings*/}
        <div className="d-flex align-items-center pb-2">
          <Button
            link
            icon={
              <CheckmarkIcon
                style={{fontSize: 14, marginTop: 2, marginRight: 5}}
              />
            }
            title={
              <Typography color="textPrimary" variant="subtitle2">
                {intl.formatMessage({id: 'actions.mark_all_read'})}
              </Typography>
            }
            onClick={() => {}}
          />
          <Button
            className="ps-4"
            link
            icon={<SettingsIcon style={{fontSize: 16, color: '#21B59B'}} />}
            title={
              <Typography color="textPrimary" variant="subtitle2">
                {intl.formatMessage({id: 'actions.settings'})}
              </Typography>
            }
            onClick={() => {
              //close popup
              onClose();
              //Takes the user to the Notification preference
              history.push(urls.ACCOUNT_SETTINGS + '/notifications');
            }}
          />
        </div>
        {/*Notification List*/}
        <NotificationItem
          rootClassName="border rounded-sm"
          isUnread
          color="purple"
          subject="New order"
          message="Buyer ordered 6 products in Order ID 389"
        />
        <NotificationItem
          rootClassName="border rounded-sm"
          isUnread
          color="pink"
          subject="Payment failed"
          message="Buyer’s payment for Order ID 839 failed"
        />
        <NotificationItem
          rootClassName="border rounded-sm"
          isUnread
          color="blue"
          subject="Out of stock"
          message="A product run out of stock"
        />
        <NotificationItem
          rootClassName="border rounded-sm"
          actionsShown
          color="warning"
          subject="New product"
          message="A product run out of stock"
        />
        <NotificationItem
          rootClassName="border rounded-sm"
          actionsShown
          color="primary"
          subject="Order created"
          message="Order ID 201 was created"
        />
      </Fragment>
    </Drawer>
  );
};

export default NotificationsPopup;
