import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Field} from 'redux-form';
import SelectField from '../../../Form/Fields/SelectField';

const DropdownField = props => {
  const {title, fieldName, items = [], containerClass} = props;
  const className = containerClass
    ? 'mx-2 mx-lg-0 ' + containerClass
    : 'mx-2 mx-lg-0';
  return (
    <div className={className}>
      <div
        className={clsx('row align-items-center justify-content-between mt-3')}
      >
        <div className="col-lg-5">
          <Typography variant="body2">{title}</Typography>
        </div>
        <div className="col-lg-7">
          <Field
            name={fieldName}
            component={SelectField}
            items={items}
            outlined
          />
        </div>
      </div>
    </div>
  );
};

DropdownField.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fieldName: PropTypes.string,
  items: PropTypes.array,
};

export default DropdownField;
