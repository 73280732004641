import * as types from '../constants/actionTypes';

const defaultState = {
  loading: false,
  error: null,
  activity: {
    payments: [],
    transfers: [],
    payouts: [],
  },
};

export default function userActivityReducer(state = defaultState, action) {
  const {type, payload} = action;
  switch (type) {
    case types.API_USER_ACTIVITY_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.API_USER_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        activity: payload.data,
      };
    case types.API_USER_ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        activity: {
          payments: [],
          transfers: [],
          payouts: [],
        },
      };
    default:
      return state;
  }
}
