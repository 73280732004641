import React from 'react';

import SearchTextInput from '../../Common/SearchTextField';

const SearchTextFieldF = props => {
  const {input, meta, ...custom} = props;
  // const {touched, error} = meta;

  return <SearchTextInput {...custom} {...input} />;
};

export default SearchTextFieldF;
