import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import SearchTextField from './Fields/SearchTextField';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';
import {APP_SET_SEARCH_REQUESTED} from '../../constants/actionTypes';
import {useHistory, useLocation} from 'react-router-dom';

/**
 * SearchForm that can be used to display a search field bound to redux-form
 * This component can also dispatch `APP_SET_SEARCH_REQUESTED` when `dispatchAction` is set to `true`
 * Read propTypes for more info
 *
 * @param {*} props
 */
const SearchForm = forwardRef((props, ref) => {
  const {
    search,
    onChange,
    dispatchAction,
    actionPayload,
    successType,
    SearchProps,
    delay,
    onClick,
    onReady,
    loading,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const history = useHistory();
  const location = useLocation();

  const dispatchSearchValue = searchValue => {
    dispatch({
      delay,
      type: APP_SET_SEARCH_REQUESTED,
      payload: {
        search: searchValue,
        ...actionPayload,
      },
      successType,
    });
  };

  useImperativeHandle(ref, () => ({
    clearKeyword() {
      setValue('');
      dispatchSearchValue('');
    },
  }));

  useEffect(() => {
    if (value !== search) {
      setValue(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('q')) {
      setValue(params.get('q'));
      dispatchSearchValue(params.get('q'));
    }
    onReady && onReady(params.get('q') ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.has('q')) {
      setValue('');
      dispatchSearchValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    onChange && onChange(value);
    if (dispatchAction) {
      if (!successType) {
        console.error(
          'You need to provided `successType` prop if you enable `dispatchAction`'
        );
      } else {
        const key = props.form + 'intervalId';
        if (window[key]) {
          clearTimeout(window[key]);
          window[key] = null;
        }
        window[key] = setTimeout(() => {
          if (value !== null) {
            const params = new URLSearchParams(window.location.search);
            params.set('q', value);
            history.replace(window.location.pathname + '?' + params.toString());
          }
        }, delay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSearchClear = () => {
    setValue('');
  };

  // Keyword search Submit only with ENTER KEY
  const onSearchKeyUp = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      dispatchSearchValue(value);
    }
  };

  return (
    <SearchTextField
      value={value}
      clearable
      onChange={v => setValue(v.target.value)}
      placeholder={intl.formatMessage({id: 'actions.search'})}
      {...SearchProps}
      buttonProps={{
        onClick,
        onClear: onSearchClear,
        ...(SearchProps && SearchProps.buttonProps),
      }}
      readOnly={!!loading}
      onKeyUp={onSearchKeyUp}
    />
  );
});

SearchForm.propTypes = {
  search: PropTypes.string,
  /**
   * Props that will be passed to the `search` field
   */
  SearchProps: PropTypes.object,
  /**
   * Callback called when form values change
   */
  onChange: PropTypes.func,
  /**
   * Whether dispatch an action when values change or not
   * If set to `true`, `successType` will be required
   */
  dispatchAction: PropTypes.bool,
  /**
   * Extra payload sent if dispatchAction is set to true
   */
  actionPayload: PropTypes.object,
  /**
   * The action type to dispatch when values change
   */
  successType: PropTypes.string,
  /**
   * The delay before successType is dispatched
   */
  delay: PropTypes.number,
  /**
   * Search button onClick handler
   */
  onClick: PropTypes.func,
  /**
   * Function fired when the form is ready, after parsing search value from location
   */
  onReady: PropTypes.func,
  /**
   * Search is loading so term cannot be  changed
   */
  loading: PropTypes.bool,
};

SearchForm.defaultPrps = {
  delay: 180,
};

export default SearchForm;
