import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BaseInput from '../../Common/BaseInput';
import {PlusIconDanger} from '../../../constants/images';
import {EditIconGreen} from '../../../constants/images';
import clsx from 'clsx';

/* Styles */
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  select: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[0],
    borderRadius: 4,
    border: `1px solid ${theme.palette.text.border}`,
    padding: theme.spacing(0),
    '& .MuiSelect-root': {
      fontSize: 14,
      backgroundColor: 'inherit',
      padding: theme.spacing(0.75, 3.2, 0.75, 1),
      boxShadow: 'none',
    },
    '& .MuiSelect-icon': {
      right: 10,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  paper: {
    boxShadow: theme.shadows[8],
    marginTop: theme.spacing(1),
    '& > ul': {
      padding: theme.spacing(1.25, 0),
    },
  },
  menuItem: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(0.75, 2),
    minHeight: 'unset',
    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
      backgroundColor: 'transparent',
      '&:not(.new):after': {
        content: '""',
        position: 'absolute',
        right: theme.spacing(2),
        top: '50%',
        transform: 'translateY(-50%)',
        width: 13,
        height: 13,
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 15 11' aria-hidden='true' style='font-size: 14px; margin-top: 2px; margin-right: 5px;'%3E%3Cg transform='translate(-698.000000, -320.000000)' fill='%2321B59B'%3E%3Cpath d='M712.672387,320.309183 C712.256435,319.896939 711.596976,319.896939 711.181088,320.309183 L703.353358,328.341614 L699.818969,324.714733 C699.392515,324.292164 698.71312,324.304231 698.301256,324.741773 C697.899581,325.168601 697.899581,325.845177 698.301256,326.272005 L702.594534,330.677555 C703.013692,331.107482 703.69315,331.107482 704.112246,330.677555 L712.698737,321.866585 C713.110538,321.429043 713.098652,320.731817 712.672387,320.309183' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/svg%3E\")",
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      },
    },
  },
}));

/* Select component with checkmark of selected option */
const EditableSelect = props => {
  const {id, options, value, handleChange, addTitle, onAddClick, ...others} =
    props;
  const classes = useStyles();

  const [currentValue, setCurrentValue] = useState(value);

  const onChange = event => {
    const value = event.target.value;
    if (value === 'new') {
      return;
    }
    setCurrentValue(value);
    handleChange(value);
  };

  return (
    <FormControl className={classes.root}>
      <Select
        id={id}
        labelId={`${id}-label`}
        variant="outlined"
        className={classes.select}
        input={<BaseInput />}
        IconComponent={() => <EditIconGreen className="MuiSelect-icon" />}
        MenuProps={{
          elevation: 0,
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          classes: {
            paper: classes.paper,
          },
        }}
        value={currentValue}
        onChange={onChange}
        {...others}
      >
        {[
          ...options.map(({label, value}) => (
            <MenuItem key={value} value={value} className={classes.menuItem}>
              <Typography variant="body2" className="text-truncate pe-3">
                {label}
              </Typography>
            </MenuItem>
          )),
          <MenuItem
            key="new"
            value="new"
            className={clsx(classes.menuItem, 'd-flex align-items-center new')}
          >
            <PlusIconDanger />
            <Typography variant="subtitle2" className="text-fan-primary ms-2">
              {addTitle}
            </Typography>
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
};

export default EditableSelect;
