import React, {useEffect, useState} from 'react';
import {reduxForm} from 'redux-form';
import {connect, useDispatch} from 'react-redux';
import {injectIntl, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Button, TabPane} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ActivityList from './ActivityList';
import StateIndicator from '../../Common/StateIndicator';
import ArrowLink from '../../Common/ArrowLink';
import HintTooltip from '../../Common/HintTooltip';
import {Field} from 'redux-form';
import InputField from '../../Form/Fields/InputField';
import CheckboxField from '../../Form/Fields/CheckboxField';
import RadioGroupField from '../../Form/Fields/RadioGroupField';
import TextField from '../../Common/TextField';
import Modal from '../../Widgets/Modal';
import useUserActivity from '../../../hooks/user/useUserActivity';
import useUserBalance from '../../../hooks/user/useUserBalance';
import {API_ADMIN_USER_EDIT_DETAIL_REQUESTED} from '../../../constants/actionTypes';
import {
  validateBeforeSubmit,
  isNumber,
  booleanRequired,
} from '../../../validation/validators';
import {ReleaseIcon, WithdrawIcon} from '../../../constants/images';

// Data validation
const validate = values => {
  const errors = {};
  // validate required fields and values
  errors.commission = isNumber(values.commission);
  errors.fixedTransactionFee = isNumber(values.fixedTransactionFee);
  errors.defaultCommission = booleanRequired(values.defaultCommission);
  return errors;
};

// Format number to be accepted by the api validation
// By default is strins, needs to be parsed into float
// To allow french number, replace ',' by '.' before parsing
const formatFloatNumber = value => {
  if (value) {
    return parseFloat(value.toString().replace(',', '.'));
  } else {
    return null;
  }
};

//Activity tab panel
const ActivityTabPanel = props => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activity-tabpanel-${index}`}
      aria-labelledby={`activity-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const tabsStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: '0 -10px 10px -10px',
    minHeight: 'unset',
    overflow: 'visible',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '-30px',
      right: '-30px',
      bottom: 0,
      height: 1,
      backgroundColor: theme.palette.text.border,
    },
    '& .MuiTab-root': {
      padding: '10px',
      minHeight: 'unset',
      minWidth: 'unset',
      opacity: 1,
      [theme.breakpoints.down('lg')]: {
        flex: 1,
      },
      '& .MuiTab-wrapper': {
        fontWeight: 'normal',
        lineHeight: 1.27,
        color: theme.palette.secondary.light,
      },
      '&.Mui-selected': {
        '& .MuiTab-wrapper': {
          fontFamily: 'Averta Semibold',
          color: theme.palette.text.primary,
        },
      },
    },
  },
  indicator: {
    display: 'none',
  },
}));

//Activity block
const ActivityBlock = props => {
  const {user} = props;
  const {activity, getUserActivity} = useUserActivity(user.id);
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const classes = tabsStyles();

  // fetch user activity
  useEffect(() => {
    getUserActivity();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="card details-card mx-2 mt-0 mb-3">
      <div className="card-body overflow-hidden">
        <Typography variant="h4">
          {intl.formatMessage({
            id: 'dashboard.user_details.tab_finance.activity',
          })}
        </Typography>
        <Tabs
          classes={classes}
          value={value}
          onChange={handleChange}
          aria-label="activity-block-tabs"
        >
          <Tab
            aria-label="payments"
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_finance.payments',
            })}
          />
          <Tab
            aria-label="transfers"
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_finance.transfers',
            })}
          />
          <Tab
            aria-label="payouts"
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_finance.payouts_tab',
            })}
          />
        </Tabs>
        <ActivityTabPanel value={value} index={0}>
          {/*Payments*/}
          <ActivityList
            columns={[
              {
                name: 'amount',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.amount',
                }),
              },
              {
                name: 'stripe_id',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.stripe_id',
                }),
                width: '35%',
              },
              {
                name: 'sales_id',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.sales_id',
                }),
                renderCell: obj => <ArrowLink {...obj} />,
              },
              {
                name: 'date',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.date',
                }),
              },
              {
                name: 'status',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.status',
                }),
                renderCell: obj => <StateIndicator {...obj} />,
                width: '130px',
              },
            ]}
            rows={activity.payments}
          />
        </ActivityTabPanel>
        {/*Transfers*/}
        <ActivityTabPanel value={value} index={1}>
          <ActivityList
            columns={[
              {
                name: 'amount',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.amount',
                }),
                width: '130px',
              },
              {
                name: 'stripe_id',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.stripe_id',
                }),
              },
              {
                name: 'date',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.date',
                }),
                width: '130px',
              },
            ]}
            rows={activity.transfers}
          />
        </ActivityTabPanel>
        {/*Payouts*/}
        <ActivityTabPanel value={value} index={2}>
          <ActivityList
            columns={[
              {
                name: 'payout_id',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.payout_id',
                }),
                renderCell: obj => <ArrowLink {...obj} />,
              },
              {
                name: 'amount',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.amount',
                }),
              },
              {
                name: 'ext_account',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.external_account',
                }),
                width: '180px',
              },
              {
                name: 'init_date',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.initiated_date',
                }),
              },
              {
                name: 'est_arrival',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.est_arrival',
                }),
              },
              {
                name: 'status',
                label: intl.formatMessage({
                  id: 'dashboard.user_details.tab_finance.status',
                }),
                renderCell: obj => <StateIndicator {...obj} />,
              },
            ]}
            rows={activity.payouts}
          />
        </ActivityTabPanel>
      </div>
    </div>
  );
};

/* User details - Finance/Fee Section */
let FinanceDetailsFee = props => {
  const {user} = props;
  const dispatch = useDispatch();
  const {balance, getUserBalance} = useUserBalance(user.id);
  //Translations
  const {intl} = props;
  //Modal shown
  const [modalShown, setModalShown] = useState(null);
  // User payout mode
  const [payoutMode, setPayoutMode] = useState(user.payoutMode);
  // Track the state of defaultCommission checkbox to disable/enable commission and fixedTransactionFee fields
  const [commissionFieldsDisabled, setCommissionFieldsDisabled] = useState(
    user.defaultCommission
  );

  // fetch user activity
  useEffect(() => {
    getUserBalance();
  }, []);

  // Handle submit of data for various events
  const submit = values => {
    // validate data before submit
    const errors = validate(values);
    validateBeforeSubmit(errors);
    // update fields
    dispatchAction({
      commission: formatFloatNumber(values.commission),
      fixedTransactionFee: formatFloatNumber(values.fixedTransactionFee),
      defaultCommission: values.defaultCommission,
    });
  };

  // dispatch redux saga action
  const dispatchAction = values => {
    dispatch({
      type: API_ADMIN_USER_EDIT_DETAIL_REQUESTED,
      payload: {
        ...values,
        userId: user.id,
      },
    });
  };
  // handle payoutMode change
  const handlePayoutModeChange = val => {
    setPayoutMode(val);
    // update payoutMode
    const payload = {payoutMode: val};
    dispatchAction(payload);
  };

  // handle defaultCommission change
  const handleDefaultCommissionChange = e => {
    setCommissionFieldsDisabled(e.currentTarget.checked);
    // update defaultCommission
    const payload = {defaultCommission: e.currentTarget.checked};
    dispatchAction(payload);
  };

  //Open/close modal
  const openModal = type => setModalShown(type);
  const closeModal = () => setModalShown(null);

  return (
    <TabPane tabId="fee">
      {/*Modals*/}
      {/*Edit reserve*/}
      <Modal
        width={328}
        opened={modalShown === 'edit'}
        handleClose={closeModal}
        title={intl.formatMessage({
          id: 'dashboard.user_details.tab_finance.edit_reserve',
        })}
        saveTitle={intl.formatMessage({id: 'actions.save'})}
      >
        <Field
          name="reserve_amount"
          component={InputField}
          outlined
          externalLabel
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_finance.percentage',
          })}
          addon="%"
          addonPosition="end"
        />
      </Modal>
      {/*Release reserve*/}
      <Modal
        width={328}
        opened={modalShown === 'release'}
        handleClose={closeModal}
        title={intl.formatMessage({
          id: 'dashboard.user_details.tab_finance.release_reserve',
        })}
        saveTitle={intl.formatMessage({id: 'actions.release'})}
      >
        <Field
          name="release_reserve"
          component={InputField}
          outlined
          externalLabel
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_finance.amount',
          })}
          addon="£"
          addonPosition="start"
        />
      </Modal>
      {/*Withdraw reserve*/}
      <Modal
        width={328}
        opened={modalShown === 'withdraw'}
        handleClose={closeModal}
        title={intl.formatMessage({
          id: 'dashboard.user_details.tab_finance.withdraw_reserve',
        })}
        saveTitle={intl.formatMessage({
          id: 'dashboard.user_details.tab_finance.withdraw',
        })}
      >
        <Field
          name="release_reserve"
          component={InputField}
          outlined
          externalLabel
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_finance.amount',
          })}
          addon="£"
          addonPosition="start"
        />
      </Modal>
      {/*End modals*/}
      <div className="finance-fee">
        <form onSubmit={props.handleSubmit(submit)}>
          <div className="row g-0">
            <div className="col-lg-8">
              {/*Balance and total volume*/}
              <div className="card details-card mx-2 mt-0 mb-3">
                <div className="card-body">
                  <Typography variant="h4" className="mb-3">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_finance.balance_total_volume',
                    })}
                  </Typography>
                  <ActivityList
                    columns={[
                      {
                        name: 'total_balance',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.total_balance',
                        }),
                      },
                      {
                        name: 'in_transit_to_bank',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.transit_bank',
                        }),
                      },
                      {
                        name: 'available_soon',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.available_soon',
                        }),
                      },
                      {
                        name: 'lifetime_total_volume',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.lifetime_total_volume',
                        }),
                      },
                    ]}
                    rows={balance.balance_and_total_volume}
                  />
                </div>
              </div>
              {/*Payout and reserve*/}
              <div className="card details-card mx-2 mt-0 mb-3">
                <div className="card-body">
                  <div className="row align-items-center justify-content-between mb-3">
                    <div className="col">
                      <Typography variant="h4">
                        {intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.payout_reserve',
                        })}
                      </Typography>
                    </div>
                  </div>
                  <ActivityList
                    columns={[
                      {
                        name: 'available_to_payout',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.available_payout',
                        }),
                      },
                      {
                        name: 'total_reserve_accumulated',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.total_reserve_accumulated',
                        }),
                      },
                      {
                        name: 'reserve_amount',
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.reserve_amount',
                        }),
                        renderCell: value => (
                          <div className="bg-white w-lg-90">
                            <TextField
                              value={value}
                              addon="%"
                              addonPosition="end"
                            />
                          </div>
                        ),
                      },
                      {
                        name: 'actions',
                        label: '',
                        renderCell: () => (
                          <div className="d-flex flex-wrap align-items-center">
                            <Button
                              className="d-flex align-items-center unstyled-button my-1 me-lg-3"
                              onClick={() => openModal('release')}
                            >
                              <ReleaseIcon
                                style={{width: 15, height: 12, marginRight: 8}}
                              />
                              <Typography variant="subtitle2">
                                {intl.formatMessage({
                                  id: 'dashboard.user_details.tab_finance.release',
                                })}
                              </Typography>
                            </Button>
                            <Button
                              className="d-flex align-items-center unstyled-button my-1"
                              onClick={() => openModal('withdraw')}
                            >
                              <WithdrawIcon
                                style={{width: 15, height: 14, marginRight: 8}}
                              />
                              <Typography variant="subtitle2">
                                {intl.formatMessage({
                                  id: 'dashboard.user_details.tab_finance.withdraw',
                                })}
                              </Typography>
                            </Button>
                          </div>
                        ),
                      },
                    ]}
                    rows={balance.payout_and_reserve}
                  />
                </div>
              </div>
              {/*Activity*/}
              <ActivityBlock user={user} />
            </div>
            <div className="col-lg-4">
              {/*Transaction fee*/}
              <div className="card details-card mx-2">
                <div className="card-body">
                  <Typography variant="h4" className="mb-3">
                    {intl.formatMessage({
                      id: 'dashboard.user_details.tab_finance.transaction_fee',
                    })}
                  </Typography>
                  <Box pb={1.3} className="d-none d-lg-block">
                    <div className="row">
                      <div className="col-auto col-lg-7">
                        <Typography
                          variant="body2"
                          className="font-weight-bold"
                        >
                          {intl.formatMessage({
                            id: 'dashboard.user_details.tab_finance.transaction_plan',
                          })}
                        </Typography>
                      </div>
                      <div className="col-auto col-lg-5">
                        <Typography
                          variant="body2"
                          className="font-weight-bold"
                        >
                          {intl.formatMessage({
                            id: 'dashboard.user_details.tab_finance.value',
                          })}
                        </Typography>
                      </div>
                    </div>
                  </Box>
                  {/*Commission rate*/}
                  <Box pb={1.5}>
                    <div className="row align-items-center">
                      <div className="col-auto col-lg-7">
                        <div className="d-flex align-items-center pb-1 pb-lg-0">
                          <Typography variant="body2" className="me-1">
                            {intl.formatMessage({
                              id: 'dashboard.user_details.tab_finance.commission_rate',
                            })}
                          </Typography>
                          <HintTooltip
                            id="1"
                            content={intl.formatMessage({
                              id: 'dashboard.user_details.tab_finance.commission_rate_hint',
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-auto col-lg-5">
                        <Field
                          name="commission"
                          component={InputField}
                          outlined
                          addon="%"
                          addonPosition="end"
                          onBlur={props.handleSubmit(submit)}
                          disabled={commissionFieldsDisabled}
                        />
                      </div>
                    </div>
                  </Box>
                  {/*Fixed transaction fee*/}
                  <Box pb={1.5}>
                    <div className="row align-items-center">
                      <div className="col-auto col-lg-7">
                        <div className="d-flex align-items-center pb-1 pb-lg-0">
                          <Typography variant="body2" className="me-1">
                            {intl.formatMessage({
                              id: 'dashboard.user_details.tab_finance.fixed_transaction_fee',
                            })}
                          </Typography>
                          <HintTooltip
                            id="2"
                            content={intl.formatMessage({
                              id: 'dashboard.user_details.tab_finance.fixed_transaction_fee_hint',
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-auto col-lg-5">
                        <Field
                          name="fixedTransactionFee"
                          component={InputField}
                          outlined
                          addon="£"
                          addonPosition="start"
                          onBlur={props.handleSubmit(submit)}
                          disabled={commissionFieldsDisabled}
                        />
                      </div>
                    </div>
                  </Box>
                  {/*Use default value*/}
                  <div className="form-group static ps-1">
                    <Field
                      name="defaultCommission"
                      component={CheckboxField}
                      label={intl.formatMessage({
                        id: 'dashboard.user_details.tab_finance.use_default_value',
                      })}
                      onChange={handleDefaultCommissionChange}
                    />
                  </div>
                  {/*Payout*/}
                  <Field
                    name="payout"
                    component={RadioGroupField}
                    legend={intl.formatMessage({
                      id: 'dashboard.user_details.tab_finance.payout',
                    })}
                    items={[
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.payouts.standard',
                        }),
                        value: 'normal-payout',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.payouts.bulk',
                        }),
                        value: 'bulk-payout',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.payouts.fast_bulk',
                        }),
                        value: 'fast-bulk-payout',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'dashboard.user_details.tab_finance.payouts.none',
                        }),
                        value: 'nope-payout',
                      },
                    ]}
                    radioProps={{
                      className: 'form-field-row',
                      value: payoutMode,
                    }}
                    onChange={handlePayoutModeChange}
                    currentValue={payoutMode}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </TabPane>
  );
};

FinanceDetailsFee = reduxForm({
  form: 'FinanceDetailsFeeForm',
  enableReinitialize: true,
  validate,
})(FinanceDetailsFee);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    commission: ownProps.user?.commission,
    fixedTransactionFee: ownProps.user?.fixedTransactionFee,
    defaultCommission: ownProps.user?.defaultCommission,
    payout: ownProps.user?.payoutMode,
  },
});

FinanceDetailsFee.propTypes = {};

// connect to redux store and inject intl
export default connect(mapStateToProps)(injectIntl(FinanceDetailsFee));
