import moment from 'moment';
import {useCallback, useMemo} from 'react';

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

/**
 * Date hook, used to format parse dates
 * This hook will eventually use user's locale to format date accordingly
 */
export default function useDateHelper() {
  // eventually guess format depending on user's locale
  const DATE_FORMAT = useMemo(() => {
    return DEFAULT_DATE_FORMAT;
  }, []);

  /**
   * Format date with dateFormat
   *
   * @param {string|Date|Moment} date
   * @param {[string]} dateFormat
   * @return {string}
   */
  const format = useCallback(
    (date, dateFormat = DATE_FORMAT, defaultValue = '') => {
      let momentDate;
      if (typeof date === 'string') {
        if (date.length > 19) {
          // Remove the timezone part to prevent local time conversion
          date = date.substring(0, 19);
        }
        // eventually add date format here
        momentDate = moment(date);
      } else {
        momentDate = moment(date);
      }

      return momentDate.isValid()
        ? momentDate.format(dateFormat)
        : defaultValue;
    },
    [DATE_FORMAT]
  );

  /**
   * Format date using DATE_FORMAT
   * @param {string|date|Moment} date
   * @return {string}
   */
  const formatDate = useCallback(
    date => {
      return format(date, DATE_FORMAT);
    },
    [DATE_FORMAT, format]
  );

  const res = useMemo(
    () => ({
      DATE_FORMAT,
      format,
      formatDate,
    }),
    [DATE_FORMAT, format, formatDate]
  );

  return res;
}
