import {useDispatch, useSelector} from 'react-redux';
import {API_USER_ACTIVITY_REQUESTED} from '../../constants/actionTypes';
import {selectToken} from '../../store/slices/authSlice';

/**
 *  Get user's activity
 *
 * @param {string} id user id
 */
export default function useUserActivity(id) {
  const token = useSelector(selectToken);
  const {activity, loading} = useSelector(state => state.userActivityReducer);

  const dispatch = useDispatch();

  // Get User Activity
  const getUserActivity = () => {
    dispatch({
      type: API_USER_ACTIVITY_REQUESTED,
      payload: {token, id},
    });
  };

  return {
    getUserActivity,
    activity,
    loading,
  };
}
