import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Field} from 'redux-form';

import InputField from '../../../Form/Fields/InputField';

const TextField = props => {
  const {
    fullClassName,
    wrapperClassName,
    className = '',
    title,
    fieldName,
  } = props;

  return (
    <div
      className={
        fullClassName
          ? fullClassName
          : clsx(
              'd-flex align-items-center justify-content-between',
              className ? className : 'mt-1'
            )
      }
    >
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
      <div className={wrapperClassName ? wrapperClassName : 'edit-wrapper'}>
        <Field name={fieldName} component={InputField} outlined />
      </div>
    </div>
  );
};

TextField.propTypes = {
  fullClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fieldName: PropTypes.string,
};

export default TextField;
