import * as types from '../constants/actionTypes';

const defaultState = {
  items: [],
  loaded: false,
  totalItems: 0,
  loading: false,
  q: '', // keyword search
  details: {}, // key/value
  notFound: [],
  sort: {},
};

export default function transactionPaymentReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case types.API_TRANSACTION_PAYMENT_LIST_REQUESTED:
      return {
        ...state,
        items: [],
        loading: true,
      };
    case types.API_TRANSACTION_PAYMENT_LIST_SUCCEEDED:
      return {
        ...state,
        items: action.payload['hydra:member'],
        totalItems: action.payload['hydra:totalItems'],
        limit: action.payload.rowsPerPage,
        sort: action.payload.sort,
        loaded: true,
        loading: false,
      };
    case types.API_TRANSACTION_PAYMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.API_TRANSACTION_PAYMENT_LIST_SET_SEARCH_SUCCEEDED:
      return {
        ...state,
        q: action.payload.search || '',
      };
    default:
      return state;
  }
}
