import React, {useState} from 'react';
import cx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ExpandIcon = () => (
  <SvgIcon style={{fontSize: 11}} viewBox="0 0 8 6">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="FAN_Admin_Sales_Details-Page_Custom-delivery_Shipment"
        transform="translate(-1185.000000, -607.000000)"
        fill="#282B3E"
        stroke="#282B3E"
      >
        <path
          d="M1190.67441,609.83537 L1187.45754,607.064808 C1187.35105,606.976226 1187.18135,606.978769 1187.07849,607.07049 C1186.97817,607.159965 1186.97817,607.301807 1187.07849,607.391269 L1190.10585,609.998601 L1187.07849,612.605932 C1186.97384,612.696084 1186.97384,612.842255 1187.07849,612.932407 C1187.18318,613.022531 1187.35289,613.022531 1187.45754,612.932407 L1190.67441,610.161831 C1190.77907,610.071666 1190.77907,609.925522 1190.67441,609.83537"
          id="Fill-1-Copy-2"
          transform="translate(1188.876452, 610.000000) rotate(-270.000000) translate(-1188.876452, -610.000000) "
        ></path>
      </g>
    </g>
  </SvgIcon>
);

const CollapseIcon = () => (
  <SvgIcon style={{fontSize: 11}} viewBox="0 0 8 6">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="FAN_Admin_Sales_Details-Page_Custom-delivery_Shipment"
        transform="translate(-1124.000000, -380.000000)"
        fill="#282B3E"
        stroke="#282B3E"
      >
        <path
          d="M1129.67441,382.83537 L1126.45754,380.064808 C1126.35105,379.976226 1126.18135,379.978769 1126.07849,380.07049 C1125.97817,380.159965 1125.97817,380.301807 1126.07849,380.391269 L1129.10585,382.998601 L1126.07849,385.605932 C1125.97384,385.696084 1125.97384,385.842255 1126.07849,385.932407 C1126.18318,386.022531 1126.35289,386.022531 1126.45754,385.932407 L1129.67441,383.161831 C1129.77907,383.071666 1129.77907,382.925522 1129.67441,382.83537"
          id="Fill-1"
          transform="translate(1127.876452, 383.000000) scale(1, -1) rotate(-270.000000) translate(-1127.876452, -383.000000) "
        ></path>
      </g>
    </g>
  </SvgIcon>
);

const CollapsableBlock = ({
  header,
  restContent,
  headerWrapperClass,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <div className={cx('row', headerWrapperClass ?? 'pt-2 pb-1')}>
        <div className="col">
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => setExpanded(!expanded)}
          >
            {header}
            <span className="d-inline-flex ms-2">
              {!expanded ? <ExpandIcon /> : <CollapseIcon />}
            </span>
          </div>
        </div>
        {restContent && <div className="col-auto">{restContent}</div>}
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
};

export default CollapsableBlock;
