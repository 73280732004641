import React, {ReactNode, useEffect, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import clsx from 'clsx';
import withWidth from '@material-ui/core/withWidth';

interface CustomDropdownProps {
  title?: string;
  menuIcon?: ReactNode;
  items: {label: string; onClick: () => void; disabled?: boolean}[];
  className?: string;
  menuClassName?: string;
  hoverToggle?: boolean;
  open?: boolean;
  onToggle?: (open: boolean) => void;
}

const CustomDropdown = ({
  title,
  menuIcon,
  items = [],
  className = '',
  menuClassName = '',
  hoverToggle = false,
  open = false,
  onToggle,
}: CustomDropdownProps) => {
  //dropdown state
  const [dropdownOpen, setDropdownOpen] = useState(open);

  const toggle = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
    if (typeof onToggle === 'function') {
      onToggle(!dropdownOpen);
    }
  };

  useEffect(() => {
    setDropdownOpen(open);
  }, [open]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={clsx('custom-dropdown', className)}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        onMouseEnter={e => (hoverToggle ? toggle(e) : undefined)}
        className={clsx('dropdown-toggle', menuIcon && 'custom-menu-icon')}
      >
        {title ?? title}
        {menuIcon}
      </DropdownToggle>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DropdownMenu
        right
        className={menuClassName}
        modifiers={{
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
      >
        {items.map((item, key) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <DropdownItem
            key={key}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            <div className="d-flex">
              <span>{item.label}</span>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default withWidth()(CustomDropdown);
