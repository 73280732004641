import React, {useMemo} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import Select from '../../Common/Select';
import Pagination from '../../Common/Pagination';

/**
 * Table Pagination row
 * contains Rows per page selector and pagination with current page input field
 * @param props
 * @constructor
 */
const TablePagination = props => {
  const {
    className,
    rowsPerPageOptions,
    bulkOpacity,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
  const needToShow = count > rowsPerPageOptions[0];
  //Translations
  const intl = useIntl();

  const rowsPerPageItems = useMemo(() => {
    return rowsPerPageOptions.map(number => ({
      label: number.toString(),
      value: number,
    }));
  }, [rowsPerPageOptions]);

  return (
    //Show pagination when the rows number more then minimum page size
    needToShow ? (
      <div
        className={clsx(
          'row align-items-center justify-content-between',
          className
        )}
      >
        {/* Rows per page */}
        <div className="col-lg-6 d-none d-lg-block">
          <div className="d-flex align-items-center">
            <span>
              <FormattedMessage id={'actions.show'} />
            </span>
            <Select
              selectClassName="font-weight-bold"
              disabled={bulkOpacity}
              variant="cropped"
              value={rowsPerPage}
              items={rowsPerPageItems}
              onChange={onChangeRowsPerPage}
            />
            <span>
              <FormattedMessage id={'actions.rows_per_page'} />
            </span>
          </div>
        </div>
        {/* Pagination */}
        <div className="col-lg-auto col-sm-12">
          <Pagination
            disabled={bulkOpacity}
            pages={Math.ceil(count / rowsPerPage)}
            current={+page}
            textPrev={intl.formatMessage({id: 'actions.prev'})}
            textNext={intl.formatMessage({id: 'actions.next'})}
            onChangePage={onChangePage}
          />
        </div>
      </div>
    ) : null
  );
};

TablePagination.propTypes = {
  /**
   * Additional class name
   */
  className: PropTypes.string,
  /**
   * Show number of rows options
   */
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  /**
   * Number of rows
   */
  count: PropTypes.number.isRequired,
  /**
   * Current selected rows per page
   */
  rowsPerPage: PropTypes.number,
  /**
   * Current page
   */
  page: PropTypes.number,
  /**
   * Change current page handler
   */
  onChangePage: PropTypes.func,
  /**
   * Rows per page change handler
   */
  onChangeRowsPerPage: PropTypes.func,
};

export default TablePagination;
