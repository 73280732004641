import {useCallback, useEffect, useMemo, useState} from 'react';
import {getUrlSearchParams} from '../../utils/filters';
import useSearchParams from '../utils/useSearchParams';

export const getStates = ({searchParams, defaultValues, bindQueryParams}) => {
  if (!bindQueryParams) {
    return defaultValues;
  }
  const res = {...defaultValues};

  const page = searchParams.get('page');
  if (page && !Number.isNaN(+page)) {
    res.page = Math.max(1, +page);
  }

  const itemsPerPage = searchParams.get('rowsPerPage');
  if (itemsPerPage && !Number.isNaN(+itemsPerPage)) {
    res.itemsPerPage = Math.max(1, +itemsPerPage);
  }

  const order = searchParams.get('order');
  if (order) {
    const desc = order.startsWith('-');
    res.orderDirection = desc ? 'desc' : 'asc';
    if (desc) {
      res.orderBy = order.substring(1);
    } else {
      res.orderBy = order;
    }
  }

  return res;
};

const defaultStateValues = {
  page: 1,
  itemsPerPage: 25,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export default function useDataTableState({
  orderMap,
  bindQueryParams,
  defaultValues: initialValues,
  onChange,
} = {}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValues = useMemo(
    () => ({
      ...defaultStateValues,
      ...initialValues,
    }),
    []
  );
  const initialState = getStates({
    searchParams,
    defaultValues,
    bindQueryParams,
  });

  const [page, setPage] = useState(initialState.page);
  const [pageSize, setPageSize] = useState(initialState.itemsPerPage);
  const [orderBy, setOrderBy] = useState(initialState.orderBy);
  const [orderDirection, setOrderDirection] = useState(
    initialState.orderDirection
  );

  useEffect(() => {
    const id = setTimeout(() => {
      if (bindQueryParams) {
        const nextStates = getStates({
          searchParams: getUrlSearchParams(searchParams),
          defaultValues,
          bindQueryParams,
        });
        if (nextStates.page && nextStates.page !== page) {
          setPage(nextStates.page);
        }
        if (nextStates.itemsPerPage && nextStates.itemsPerPage !== pageSize) {
          setPageSize(nextStates.itemsPerPage);
        }
        if (nextStates.orderBy && nextStates.orderBy !== orderBy) {
          setOrderBy(nextStates.orderBy);
        }
        if (
          nextStates.orderDirection &&
          nextStates.orderDirection !== orderDirection
        ) {
          setOrderDirection(nextStates.orderDirection);
        }
      }
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const updateSearchParamas = useCallback(
    (values = {}) => {
      try {
        const currentParams = getUrlSearchParams(searchParams);
        const params = getUrlSearchParams(searchParams);
        if (values.page) {
          params.set('page', String(values.page));
        }
        if (values.itemsPerPage) {
          params.set('rowsPerPage', String(values.itemsPerPage));
        }
        if (values.orderBy && values.orderDirection) {
          const val = `${values.orderDirection.toUpperCase() === 'DESC' ? '-' : ''}${
            values.orderBy
          }`;
          params.set('order', val);
        }
        setSearchParams(params, currentParams);
        if (currentParams.toString() !== params.toString()) {
          onChange && onChange(params);
        }
      } catch (e) {
        console.warn({e});
      }
    },
    [searchParams, setSearchParams]
  );

  const handleDataTableParamChange = useCallback(
    (type, newValue) => {
      if (type === 'order') {
        setOrderBy(newValue.orderBy ?? defaultValues.orderBy);
        setOrderDirection(newValue.order ?? defaultValues.orderDirection);
        setPage(1);
        updateSearchParamas({
          orderBy: newValue.orderBy ?? defaultValues.orderBy,
          orderDirection: newValue.order ?? defaultValues.orderDirection,
          page: 1,
          itemsPerPage: pageSize,
        });
      } else if (type === 'page') {
        setPage(newValue.page ?? 1);
        updateSearchParamas({page: newValue.page ?? defaultValues.page});
      } else if (type === 'pageSize') {
        setPageSize(newValue.pageSize ?? defaultValues.itemsPerPage);
        setPage(1);
        updateSearchParamas({
          itemsPerPage: newValue.pageSize ?? defaultValues.itemsPerPage,
          page: 1,
        });
      }
    },
    [updateSearchParamas]
  );

  const order = useMemo(() => {
    const prefix =
      orderDirection && orderDirection.toUpperCase() === 'DESC' ? '-' : '';

    if (orderBy && orderMap) {
      const fields = orderMap[orderBy];
      if (fields && fields.length > 0) {
        return fields.map(field => `${prefix}${field}`);
      }
    }

    return `${prefix}${orderBy}`;
  }, [orderBy, orderDirection, orderMap]);

  return {
    page,
    pageSize,
    order,
    orderBy,
    orderDirection,
    handleDataTableParamChange,
  };
}
