import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm, Field, FieldArray} from 'redux-form';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import InputField from '../Form/Fields/InputField';
import SelectField from '../Form/Fields/SelectField';
import Button from '../Common/Button';
import Switch from '../Common/Switch';
import IconButton from '../Common/IconButton';
import {ColumnDelete, PlusIconDanger} from '../../constants/images';
import clsx from 'clsx';
import ImageField from '../Form/Fields/ImageField';
import RadioGroupField from '../Form/Fields/RadioGroupField';
import * as urls from '../../constants/urls';

//Ticket generator field
const ticketGenerator = ({intl, label, fields, meta: {touched, error}}) => (
  <div>
    {label && (
      <Typography variant="h4" className="mb-2">
        {label}
      </Typography>
    )}
    {/*Headers*/}
    {fields.length > 0 && (
      <div className="form-group row gx-fan-10 mb-0 d-none d-lg-flex">
        <div className="col-lg-4">
          <span className="small-label">{`${intl.formatMessage({id: 'dashboard.events.section'})}*`}</span>
        </div>
        <div className="col-6 col-lg-2">
          <span className="small-label">{`${intl.formatMessage({id: 'dashboard.events.price'})} (£)*`}</span>
        </div>
        <div className="col-6 col-lg-2">
          <span className="small-label">{`#${intl.formatMessage({id: 'dashboard.events.listing'})}*`}</span>
        </div>
        <div className="col-6 col-lg-2">
          <span className="small-label">{`#${intl.formatMessage({id: 'dashboard.events.ticket'})}*`}</span>
        </div>
        <div className="col col-lg-2">
          <span className="small-label text-nowrap">
            {intl.formatMessage({id: 'dashboard.events.sold_view'})}
          </span>
        </div>
      </div>
    )}
    {/*Rows*/}
    {fields.map((item, index) => (
      <div key={index} className="form-group mb-2">
        <div className="row align-items-center g-fan-10">
          <div className="col-lg-4">
            <span className="small-label d-lg-none">{`${intl.formatMessage({id: 'dashboard.events.section'})}*`}</span>
            <Field
              name={`${item}.section`}
              component={SelectField}
              outlined
              items={[
                {label: 'VIP & Executive Box', value: 0},
                {label: 'Away Section', value: 1},
              ]}
            />
          </div>
          {/*Price*/}
          <div className="col-6 col-lg-2">
            <span className="small-label d-lg-none">{`${intl.formatMessage({id: 'dashboard.events.price'})} (£)*`}</span>
            <Field
              name={`${item}.price`}
              component={InputField}
              outlined
              addon="£"
              addonPosition="start"
            />
          </div>
          {/*Listing*/}
          <div className="col-6 col-lg-2">
            <span className="small-label d-lg-none">{`#${intl.formatMessage({id: 'dashboard.events.listing'})}*`}</span>
            <Field name={`${item}.listing`} component={InputField} outlined />
          </div>
          {/*Ticket*/}
          <div className="col-6 col-lg-2">
            <span className="small-label d-lg-none">{`#${intl.formatMessage({id: 'dashboard.events.ticket'})}*`}</span>
            <Field name={`${item}.ticket`} component={InputField} outlined />
          </div>
          <div className="col col-lg-2">
            <span className="small-label text-nowrap d-lg-none">
              {intl.formatMessage({id: 'dashboard.events.sold_view'})}
            </span>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{minHeight: 36}}
            >
              {/*Sold view*/}
              <Field name={`${item}.sold`} component={Switch} />
              {/*Delete*/}
              <IconButton
                size="xs"
                variant="filled"
                onClick={() => fields.remove(index)}
              >
                <ColumnDelete style={{fontSize: 20}} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    ))}
    {touched && error && <span className="error">{error}</span>}
    <Button
      link
      small
      danger
      title={intl.formatMessage({id: 'dashboard.events.new_ticket_generator'})}
      icon={<PlusIconDanger />}
      onClick={() => fields.push()}
    />
  </div>
);

/* See/Add event page */
let EventDetails = props => {
  const {intl} = props;
  //Router
  const history = useHistory();

  const submit = values => {};

  //Event summary block
  const printEventSummary = () => {
    return (
      <div className="card details-card mb-3 mb-lg-0">
        <div className="card-body">
          {/*Event image*/}
          <Field
            name="image"
            component={ImageField}
            label={`${intl.formatMessage({id: 'dashboard.events.event_image'})}*`}
            wrapperClassName="form-group mb-4 pb-2"
          />
          {/*Event live*/}
          <div className="form-group d-flex justify-content-between mb-3">
            <Typography variant="body2" component="span">
              {intl.formatMessage({id: 'dashboard.events.event_live'})}
            </Typography>
            <Field name="live" component={Switch} />
          </div>
          {/*Postpone event*/}
          <div className="form-group d-flex justify-content-between mb-3">
            <Typography variant="body2" component="span">
              {intl.formatMessage({id: 'dashboard.events.postpone_event'})}
            </Typography>
            <Field name="live" component={Switch} />
          </div>
          {/*Premium event*/}
          <div className="form-group d-flex justify-content-between mb-2">
            <Typography variant="body2" component="span">
              {intl.formatMessage({id: 'dashboard.events.premium_event'})}
            </Typography>
            <Field name="live" component={Switch} />
          </div>
          {/*Apply options*/}
          <Field
            name="apply"
            component={RadioGroupField}
            items={[
              {
                label: intl.formatMessage({
                  id: 'dashboard.events.apply_immediately',
                }),
                value: 0,
              },
              {
                label: intl.formatMessage({id: 'dashboard.events.custom_date'}),
                value: 1,
              },
            ]}
            radioProps={{
              className: 'form-field-row',
            }}
          />
          {/*days before the event date*/}
          <div className="d-flex align-items-center ms-3 pt-2">
            <Field
              name="days"
              component={InputField}
              outlined
              fullWidth={false}
              style={{width: 60}}
            />
            <Typography variant="body2" component="span" className="ms-2">
              {intl.formatMessage({id: 'dashboard.events.days_before_date'})}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-wrapper">
      <div className="container mx-lg-0">
        <div className="row align-items-center header-row pb-4 mb-1">
          <div className="col text-center text-lg-start">
            {/* Header */}
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.events.add_event" />
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*Cancel/Add buttons*/}
            <div className="row gx-3 align-items-center">
              <div className="col text-center">
                <Button
                  onClick={() => history.push(urls.EVENTS_LIST_PATH)}
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                />
              </div>
              <div className="col text-center">
                <Button
                  primary
                  className="w-100"
                  title={intl.formatMessage({id: 'actions.add'})}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content">
          <form onSubmit={props.handleSubmit(submit)}>
            <div className="row flex-column flex-column-reverse flex-lg-row gx-3">
              <div className="col-lg-8">
                {/*Event information*/}
                <div className="card details-card">
                  <div className="card-body">
                    <div className="row g-fan-25 g-lg-fg row-cols-1 row-cols-lg-2">
                      <div className="col">
                        <Field
                          name="title"
                          component={InputField}
                          outlined
                          label={`${intl.formatMessage({id: 'dashboard.events.event_title'})}*`}
                          externalLabel
                        />
                      </div>
                      <div className="col">
                        <Field
                          name="venue"
                          component={InputField}
                          outlined
                          label={`${intl.formatMessage({id: 'dashboard.events.venue'})}*`}
                          externalLabel
                        />
                      </div>
                      <div className="col">
                        <Field
                          name="type"
                          component={SelectField}
                          outlined
                          items={[
                            {label: 'Football', value: 'football'},
                            {label: 'Basketball', value: 'basketball'},
                          ]}
                          label={`${intl.formatMessage({id: 'dashboard.events.sport_type'})}*`}
                          externalLabel
                        />
                      </div>
                      <div className="col">
                        <Field
                          name="competition"
                          component={SelectField}
                          outlined
                          items={[{label: 'Premier League', value: 0}]}
                          label={intl.formatMessage({
                            id: 'dashboard.events.competition',
                          })}
                          externalLabel
                        />
                      </div>
                      <div className="col">
                        <Field
                          name="team_1"
                          component={SelectField}
                          outlined
                          items={[{label: 'Arsenal', value: 0}]}
                          label={`${intl.formatMessage({id: 'dashboard.events.team_1'})}*`}
                          externalLabel
                        />
                      </div>
                      <div className="col">
                        <Field
                          name="team_2"
                          component={SelectField}
                          outlined
                          items={[{label: 'Walford', value: 0}]}
                          label={`${intl.formatMessage({id: 'dashboard.events.team_2'})}*`}
                          externalLabel
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/*Ticket generator*/}
                <div className="card details-card mt-3">
                  <div className="card-body">
                    <FieldArray
                      name="ticket_generator"
                      component={ticketGenerator}
                      intl={intl}
                      label={intl.formatMessage({
                        id: 'dashboard.events.ticket_generator',
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">{printEventSummary()}</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const validate = (values, props) => {
  const errors = {};
  return errors;
};

// connect component to redux-form
EventDetails = reduxForm({
  form: 'EventDetailsForm',
  validate,
  initialValues: {
    ticket_generator: [{}, {}, {}],
  },
})(EventDetails);

export default injectIntl(EventDetails);
