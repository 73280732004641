// import { LOAD_COUNTRIES, LOAD_LANGUAGES } from "../constants/actionTypes";

const defaultState = {
  /**
   * @type {Country[]}
   */
  countries: null,
  /**
   * @type {Object}
   */
  languages: null,
  /**
   * Locale used everywhere
   * @type {string}
   */
  locale: 'en_GB',
};

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    // case LOAD_COUNTRIES:
    //     return {
    //         ...state,
    //         countries: action.payload.countries,
    //     };
    // case LOAD_LANGUAGES:
    //     return {
    //         ...state,
    //         languages: action.payload.languages,
    //     };
    default:
      return state;
  }
}
