import * as apiUrl from '../constants/urls';
import {get} from './api';

/**
 * Make api call to get user balance
 *
 * @param {payload}
 *
 * @return {Promise<Object>}
 */
export async function getUserBalance(payload) {
  const url = apiUrl.API_V2_USER_BALANCE_URL.replace(':id', payload.id);
  const data = await get({
    url,
    config: {
      ldjson: true,
    },
    token: payload.token,
  });
  return data;
}
