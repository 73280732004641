const defaultState = {};

export default function formActionReducer(state = defaultState, action) {
  switch (action.type) {
    case '@@redux-form/BLUR':
      return mergeAction(state, action, 'blurred');
    case '@@redux-form/CHANGE':
      return mergeAction(state, action, 'changed');
    case '@@redux-form/FOCUS':
      return mergeAction(state, action, 'focused');
    default:
      return state;
  }
}

const mergeAction = (state, action, key) => {
  const form = action.meta.form;
  return {
    ...state,
    [form]: {
      ...state[form],
      [key]: {
        ...action.meta,
        payload: action.payload,
      },
      payload: action.payload,
      lastAction: key,
      field: action.meta.field,
    },
  };
};
