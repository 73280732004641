import {TeamPermission} from './teamPermission';
import {
  MARKETPLACE_FEE_SETTINGS,
  PERMISSION_SETTINGS,
  SALES_DETAILS_PATH,
  SALES_LIST_PATH,
  SETTINGS_PATH,
  TEAM_MEMBERS_LIST,
  TRANSACTIONS_PATH,
  USERS_LIST_TAB_PATH,
} from './urls';

export const SALES_LIST_PERMISSIONS = ['admin_api_sales_list'];
export const PERMISSION_PERMISSIONS = [
  'admin_roles_index',
  TeamPermission.Index,
];
export const TEAM_MEMBERS_PERMISSIONS: TeamPermission[] = [
  TeamPermission.Index,
];
export const MARKETPLACE_FEE_PERMISSIONS = [];
export const TRANSACTIONS_LIST_PERMISSIONS = ['admin_transactions_list'];

export const SETTINGS_PERMISSIONS = [
  ...PERMISSION_PERMISSIONS,
  ...TEAM_MEMBERS_PERMISSIONS,
  ...MARKETPLACE_FEE_PERMISSIONS,
];
export const SALES_DETAILS_PERMISSIONS = ['admin_api_sales_details'];
export const BREAKDOWN_FEES_PERMISSIONS = ['admin_breakdown_fees'];
export const BUYER_IP_PERMISSIONS = ['admin_buyer_ip'];
export const ETICKET_MTICKET_FILES_PERMISSIONS = [
  'admin_eticket_mticket_files',
];
export const SALES_SUMMARY_PERMISSIONS = ['admin_sales_summary'];
export const LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS = [
  'admin_listing_user_details_hyperlinks',
];
export const USERS_LIST_PERMISSIONS = ['admin_filter_list_users'];
export const ADMIN_TRANSACTION_DETAILS_HYPERLINKS = [
  'admin_transaction_details_hyperlinks',
];
export const ADMIN_TRANSACTION_RESUME_PAUSE_PAYOUT = [
  'admin_resume_pause_payout',
];

export const ADMIN_CANCEL_SALES = ['admin_cancel_sales'];
export const ADMIN_SET_AS_CANCELLED_BOTH = ['admin_set_as_cancelled_both'];
export const ADMIN_REFUND_SALES = ['admin_refund_sales'];
export const ADMIN_SET_AS_DELIVERED = ['admin_set_as_delivered'];
export const ADMIN_PAUSE_RESUME_PAYOUT = ['admin_pause_resume_payout'];
export const ADMIN_ADD_PENALTY = ['admin_add_penalty'];
export const ADMIN_CREATE_PAYMENT = ['admin_create_payment'];
export const ADMIN_USER_ADDRESS_LIST = [
  'admin_user_address_list',
  'admin_add_edit_address',
];
export const ADMIN_EDIT_USER = ['admin_edit_user'];

// Here we define permissions associated with any url used
export const urlPermissions = [
  {
    path: SALES_LIST_PATH,
    permissions: SALES_LIST_PERMISSIONS,
  },
  {
    path: SALES_DETAILS_PATH,
    permissions: SALES_DETAILS_PERMISSIONS,
  },
  {
    path: SETTINGS_PATH,
    permissions: SETTINGS_PERMISSIONS,
  },
  {
    path: PERMISSION_SETTINGS,
    permissions: PERMISSION_PERMISSIONS,
  },
  {
    path: TEAM_MEMBERS_LIST,
    permissions: TEAM_MEMBERS_PERMISSIONS,
  },
  {
    path: MARKETPLACE_FEE_SETTINGS,
    permissions: MARKETPLACE_FEE_PERMISSIONS,
  },
  {
    path: TRANSACTIONS_PATH,
    permissions: TRANSACTIONS_LIST_PERMISSIONS,
  },
  {
    path: USERS_LIST_TAB_PATH,
    permissions: ADMIN_USER_ADDRESS_LIST,
  },
];
