import React from 'react';
import * as PropTypes from 'prop-types';

import ConfirmationModal from '../../../Common/ConfirmationModal';

const ConfirmationPopup = props => {
  const {open, setOpen, onAccept, description, onClose = () => {}} = props;

  return (
    <ConfirmationModal
      onAccept={onAccept}
      onClose={() => {
        onClose();
        setOpen(false);
      }}
      opened={open}
      description={description}
    />
  );
};

ConfirmationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default ConfirmationPopup;
