import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
  logout,
  selectToken,
  updateToken,
  updateUser,
} from '../store/slices/authSlice';
import {useLazyGetMeQuery} from '../services/authApi';
import {enqueueSnackbarError} from '../actions/snackbarActions';

/**
 * Fetch user when the app starts
 */
const FetchUser = () => {
  const token = useSelector(selectToken);
  const [getMe, {isError, isSuccess, data: me, error}] = useLazyGetMeQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      getMe({token});
    }
    // IMPORTANT: Do not add dependencies here
    // This effect should only be executed when this component is initialized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me && isSuccess) {
      // make sure me is an admin
      if (me.is_admin) {
        dispatch(updateUser({user: me}));
      } else {
        dispatch(
          enqueueSnackbarError({
            message: 'Please login with an admin account',
          })
        );
        dispatch(logout());
      }
    }
  }, [me, isSuccess, dispatch]);

  useEffect(() => {
    if (isError) {
      // delete the token from Store as the user was not fetched
      dispatch(updateToken({token: null}));
    }
  }, [dispatch, error, isError]);

  return null;
};

export default FetchUser;
