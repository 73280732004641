export const CURRENCY_SYMBOL = {
  GBP: '£',
  EURO: '€',
  USD: '$',
  EUR: '€',
};

/* APP_COUNTRIES HAS TO MATCH WITH fanpass/src/Cobbleweb/StripeBundle/Model/BankAccount.php */
export const APP_BANK_ACCOUNT_COUNTRIES = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CH: 'Switzerland',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
  US: 'United States',
};

/* APP_COUNTRIES HAS TO MATCH WITH fanpass/src/Cobbleweb/StripeBundle/Model/BankAccount.php */
export const APP_BANK_ACCOUNT_CURRENCIES = {
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  EUR: 'Euro',
  GBP: 'British Pound',
  HKD: 'Hong Kong Dollar',
  HUF: 'Hungarian Forint',
  JPY: 'Japanese Yen',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  USD: 'US Dollar',
  ZAR: 'South African Rand',
};

export const APP_CURRENCIES = [
  {label: '£ (British pound)', value: 'GBP'},
  {label: '€ (Euro)', value: 'EUR'},
  {label: '$ (US dollar)', value: 'USD'},
];
