import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BaseInput from './BaseInput';
import SvgIcon from '@material-ui/core/SvgIcon';

/* Styles */
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  select: {
    lineHeight: 1.6,
    width: '100%',

    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      boxShadow: theme.shadows[0],
      borderRadius: 4,
      fontWeight: 'normal',
      fontSize: 14,
      paddingTop: 7.5,
      paddingBottom: 7.5,
      '&.MuiSelect-outlined': {
        backgroundColor: 'inherit',
      },
    },
    '& .MuiSelect-icon': {
      fontSize: 10,
      top: 13,
      right: 10,
    },

    '&.cropped': {
      width: 'auto',
      display: 'inline-flex',
      padding: theme.spacing(1),
      textAlign: 'center',
      '& .MuiSelect-root': {
        fontSize: 12,
        boxShadow: theme.shadows[4],
        borderRadius: 10,
        minHeight: theme.spacing(2.5),
      },
      '& .MuiSelect-icon': {
        fontSize: 9,
        right: 19,
        top: 26,
      },
    },
    '&.outlined': {
      border: `1px solid ${theme.palette.text.border}`,
      borderRadius: 4,
    },
  },
  label: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    marginBottom: 0,
    zIndex: 1,
    left: 10,
    top: -12,
    pointerEvents: 'none',
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
    '&.Mui-error': {
      color: theme.palette.secondary.light,
    },
  },
  menu: {
    marginTop: 8,
    marginLeft: 0,
  },
  paper: {
    boxShadow: theme.shadows[1],
    maxHeight: 321,
    '& > ul': {
      padding: 0,
    },
    '&.cropped': {
      borderRadius: '0 0 8px 8px',
      maxHeight: 'calc(50vh - 50px)',
    },
  },
  menuItem: {
    fontSize: 14,
    padding: '12px 20px',
    borderTop: '1px solid #EDEDED',
    '&:first-child': {
      borderTop: 'none',
    },
  },
}));

//Dropdown icon
const DropdownIcon = props => (
  <SvgIcon
    viewBox="0 0 9 6"
    className={clsx('MuiSelect-icon', props.disabled && 'text-muted')}
  >
    <g
      id="Filter/Location"
      transform="translate(-191.000000, -246.000000)"
      fill="currentColor"
      stroke="currentColor"
    >
      <path
        d="M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407"
        id="Fill-1"
        transform="translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) "
      ></path>
    </g>
  </SvgIcon>
);

/* Select component */
const CustomSelect = ({
  value = '',
  filled = true,
  onChange,
  items,
  placeholder,
  variant = 'default',
  outlined = false,
  extraChild,
  formControlProps,
  selectClassName = '',
  ...others
}) => {
  const classes = useStyles();
  //selected value
  const [currentValue, setCurrentValue] = useState(value);

  //on select callback
  const onSelectChange = e => {
    setCurrentValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <FormControl className={classes.root} {...formControlProps}>
      {placeholder && !value && (
        <InputLabel
          htmlFor="grouped-select"
          shrink={false}
          className={classes.label}
        >
          {placeholder}
        </InputLabel>
      )}
      <Select
        variant={filled ? 'filled' : 'outlined'}
        className={clsx(
          clsx(classes.select, selectClassName),
          variant === 'cropped' && 'cropped',
          outlined && 'outlined'
        )}
        value={currentValue}
        input={<BaseInput value={currentValue} />}
        IconComponent={() => (
          <DropdownIcon disabled={others.disabled || false} />
        )}
        MenuProps={{
          elevation: 0,
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          className: clsx(classes.menu, variant === 'cropped' && 'cropped'),
          classes: {
            paper: classes.paper,
          },
        }}
        onChange={onSelectChange}
        {...others}
      >
        {items.map((item, key) => (
          <MenuItem key={key} value={item.value} className={classes.menuItem}>
            <div dangerouslySetInnerHTML={{__html: item.label}} />
          </MenuItem>
        ))}
      </Select>
      {extraChild}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  /**
   * Rendering view type
   */
  variant: PropTypes.oneOf(['cropped', 'default']),
  /**
   * Select is filled with white color
   */
  filled: PropTypes.bool,
  /**
   * Input has a border
   */
  outlined: PropTypes.bool,
  /**
   * Menu options
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  /**
   * Selected option value
   */
  value: PropTypes.any,
  /**
   * Selector empty select placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Callback function fired when a menu item is selected
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Extra content to render below the Select Tag
   */
  extraChild: PropTypes.any,
  /**
   * Props to be provided to FormControl tag
   */
  formControlProps: PropTypes.object,
};

export default CustomSelect;
