import {put, select} from '@redux-saga/core/effects';
import {deleteItem, get, post, put as apiPut} from '../connectivity/api';
import {
  API_ROLE_CREATE_FAILED,
  API_ROLE_CREATE_SUCCEEDED,
  API_ROLE_DELETE_FAILED,
  API_ROLE_DELETE_SUCCEEDED,
  API_ROLE_EDIT_FAILED,
  API_ROLE_EDIT_SUCCEEDED,
  API_ROLE_LIST_FAILED,
  API_ROLE_LIST_REQUESTED,
  API_ROLE_LIST_SUCCEEDED,
} from '../constants/actionTypes';
import {API_ROLES_URL} from '../constants/urls';
import {addAuthToken, handleException} from './utils';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';
import {selectToken} from '../store/slices/authSlice';

export function* listRole(action) {
  try {
    const token = yield select(selectToken);
    const data = yield get({
      url: API_ROLES_URL,
      token,
    });
    yield put({
      type: API_ROLE_LIST_SUCCEEDED,
      payload: {...data},
    });
  } catch (error) {
    yield handleException({error, type: API_ROLE_LIST_FAILED});
  }
}

export function* createRole(action) {
  try {
    const {token, ...body} = yield addAuthToken(action.payload);
    const data = yield post({
      url: API_ROLES_URL,
      body,
      token,
    });
    yield put({
      type: API_ROLE_CREATE_SUCCEEDED,
      payload: data,
    });
    yield put({
      type: API_ROLE_LIST_REQUESTED,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.settings.permissions.role_created',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({error, type: API_ROLE_CREATE_FAILED});
  }
}

export function* editRole(action) {
  try {
    const {values: body, token} = yield addAuthToken(action.payload);
    const data = yield apiPut({
      url: `${API_ROLES_URL}/${action.payload.id}`,
      body,
      token,
    });
    yield put({
      type: API_ROLE_EDIT_SUCCEEDED,
      payload: data,
    });
  } catch (error) {
    yield handleException({error, type: API_ROLE_EDIT_FAILED});
  }
}

export function* deleteRole(action) {
  try {
    const token = yield select(selectToken);
    yield deleteItem({
      url: `${API_ROLES_URL}/${action.payload.id}`,
      token,
    });
    yield put({
      type: API_ROLE_DELETE_SUCCEEDED,
      payload: action.payload,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.settings.permissions.role_deleted',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({error, type: API_ROLE_DELETE_FAILED});
  }
}
