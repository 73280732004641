import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import {IntlProvider} from 'react-intl';
import 'babel-polyfill';

import enMessages from './translations/messages.en';
import {flattenMessages} from './utils/functions';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import {loadState} from './store/localStorage';
import './assets/scss/style.scss';
import NotifierComponent from './components/Widgets/NotifierComponent';
import {SnackbarProvider} from 'notistack';
import {IntlGlobalProvider} from './translations/IntlGlobalProvider';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorPage from './error/ErrorPage';

//Locale
const appLocale = 'en';

//Translations
const messages = {
  en: enMessages,
};

//This is the state we persisted in the local storage.
//Load the persisted State
const persistedState = loadState();

//Start and configure the store
const store = configureStore(persistedState);

ReactDOM.render(
  <CookiesProvider>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Provider store={store}>
        <IntlProvider
          locale={appLocale}
          defaultLocale="en"
          messages={flattenMessages(messages[appLocale])}
        >
          <IntlGlobalProvider>
            <SnackbarProvider
              maxSnack={3}
              hideIconVariant
              preventDuplicate
              content={(key, message) => (
                <NotifierComponent id={key} message={message} />
              )}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                containerAnchorOriginTopRight: 'fan-notification-top-right',
                root: 'fan-notification-root',
              }}
            >
              <Router>
                <App />
              </Router>
            </SnackbarProvider>
          </IntlGlobalProvider>
        </IntlProvider>
      </Provider>
    </ErrorBoundary>
  </CookiesProvider>,
  document.getElementById('react-admin-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
