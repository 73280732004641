import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CopyIcon, EyeIcon} from '../../constants/images';
import IconButton from '../Common/IconButton';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#EDEDED !important',
      top: 0,
    },
    '& .MuiInputBase-input': {
      padding: 10,
      fontSize: 14,
      '&::placeholder': {
        color: theme.palette.secondary.light,
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 5,
    },
    '&.notes': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D8D8D8 !important',
        borderRadius: 8,
      },
      '& .MuiInputBase-input': {
        height: 23,
        padding: theme.spacing(2),
      },
    },
    '& .MuiOutlinedInput-root + .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
    },
    '& .MuiInputAdornment-positionEnd': {
      '& .toolbar': {
        '& .MuiButtonBase-root': {
          '& .MuiSvgIcon-root': {
            width: 'auto',
          },
        },
      },
    },
  },
}));

const HiddenEnhancedInput = (props: any) => {
  const {meta, canCopy = true, showVisibilityIcon, placeholderValues} = props;
  const {touched, error, dirty} = meta;
  const classes = useStyles();

  const [values, setValues] = useState({
    placeholder: '',
    text: '',
    showContent: false,
  });

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (placeholderValues && !touched) {
      setValues(prevValues => ({
        ...prevValues,
        placeholder: Math.random().toString(36).substr(2, 8),
      }));
    }
  }, [placeholderValues, touched]);

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
    props.onChange(event.target.value);
  };

  const handleClickShow = () => {
    setValues({...values, showContent: !values.showContent});
  };

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (!isFocused && values.text === '') {
      setValues({...values, text: ''});
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (values.text === '') {
      setValues({...values, text: '', placeholder: values.placeholder});
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!canCopy && (event.ctrlKey || event.metaKey) && event.key === 'c') {
        event.preventDefault();
      }
    };

    const handleContextMenu = (event: MouseEvent) => {
      if (!canCopy) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [canCopy]);

  const handleClickCopy = () => {
    const textToCopy = values.text;

    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      classes={classes}
      type={values.showContent ? 'text' : 'password'}
      value={isFocused || values.text ? values.text : values.placeholder}
      onChange={handleChange('text')}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoComplete="new-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="toolbar d-flex align-items-center">
              {canCopy && (
                <IconButton
                  size="sm"
                  variant="action"
                  className="my-0"
                  onClick={handleClickCopy}
                >
                  <CopyIcon style={{fontSize: 15}} />
                </IconButton>
              )}
              {showVisibilityIcon || (touched && dirty) ? (
                <IconButton
                  variant="action"
                  size="sm"
                  className="my-0"
                  onClick={handleClickShow}
                  onMouseDown={handleMouseDown}
                >
                  <EyeIcon
                    style={{fontSize: 12}}
                    disabled={!values.showContent}
                  />
                </IconButton>
              ) : null}
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

HiddenEnhancedInput.propTypes = {
  onChange: PropTypes.func,
  canCopy: PropTypes.bool,
  error: PropTypes.any,
};

export default HiddenEnhancedInput;
