import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';
import DatePicker from '../Inputs/DatePicker';
import {FormHelperText} from '@material-ui/core';

/**
 * Render two date pickers for redux-form Field
 * `props.input.onChange` is called with an Object value with the following structure
 * `{from: string, to: string}` where string is a moment date formatted with `props.dateFormat`
 *
 * @param {Object} props
 */
const DateBetweenField = props => {
  const {
    displayFormat,
    dateFormat,
    outlined,
    input: {value, onChange},
    meta,
    InputProps,
    InputPropsFrom,
    InputPropsTo,
  } = props;
  const intl = useIntl();
  const {touched, error} = meta;

  const [valueFrom, setValueFrom] = useState();
  const [valueTo, setValueTo] = useState();

  // Format date from iFormat to fFormat
  const formatDate = (date, iFormat, fFormat, defaultValue = null) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  const handleDateChange = (val, inputKey) => {
    if (inputKey === 'from') {
      setValueFrom(val);
    } else if (inputKey === 'to') {
      setValueTo(val);
    }
    onChange({
      ...value,
      [inputKey]: formatDate(val, displayFormat, dateFormat),
    });
  };

  // update state on value change
  useEffect(() => {
    const {from, to} = value;
    setValueFrom(formatDate(from, dateFormat, displayFormat));
    setValueTo(formatDate(to, dateFormat, displayFormat));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="date-selects-wrapper">
      {/* In between variant' inputs */}
      <div className="in-between-inputs">
        {/* Date From */}
        <DatePicker
          value={valueFrom}
          format={displayFormat}
          {...{outlined}}
          placeholder={intl.formatMessage({id: 'actions.from'})}
          onChange={value => handleDateChange(value, 'from')}
          {...(valueTo ? {maxDate: valueTo} : {})}
          {...InputProps}
          {...InputPropsFrom}
        />
        {/* Date To */}
        <DatePicker
          value={valueTo}
          format={displayFormat}
          {...{outlined}}
          placeholder={intl.formatMessage({id: 'actions.to'})}
          onChange={value => handleDateChange(value, 'to')}
          {...(valueFrom ? {minDate: valueFrom} : {})}
          {...InputProps}
          {...InputPropsTo}
        />
      </div>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
};

DateBetweenField.defaultProps = {
  dateFormat: 'YYYY-MM-DD',
};

export default DateBetweenField;
