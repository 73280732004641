import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {FormattedMessage} from 'react-intl';
import {
  Box,
  ButtonBase,
  Input,
  InputAdornment,
  Popper,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  SearchIcon,
} from '../../constants/images';
import {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  button: {
    fontSize: 14,
    minHeight: theme.spacing(3.5),
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.4),
    '& span': {
      width: '100%',
      '&.empty': {
        color: theme.palette.secondary.light,
      },
    },
  },
  popper: {
    width: '100%',
    borderRadius: theme.spacing(0.4, 0.4, 0, 0),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0.8),
    zIndex: theme.zIndex.tooltip,
    boxShadow: theme.shadows[5],
  },
  inputBase: {
    padding: theme.spacing(0.9, 1.5),
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    '& input': {
      padding: theme.spacing(0.5),
      fontSize: 14,
    },
  },
  paper: {
    margin: 0,
    borderRadius: theme.spacing(0, 0, 0.4, 0.4),
    padding: 0,
    boxShadow: theme.shadows[5],
  },
  listbox: {
    padding: theme.spacing(1, 0),
    maxHeight: theme.spacing(17),
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.background.default,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

interface AutocompletePopupProps<T> {
  options: {name: string; subtitle?: string}[];
  placeholder: string;
  onChange: (value: any) => void;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  inputValue?: string;
}

function AutocompletePopup<T>(props: AutocompletePopupProps<T>) {
  const {options, placeholder, onChange, onInputChange, inputValue} = props;
  const classes = useStyles();

  const [value, setValue] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [pendingValue, setPendingValue] = useState<any>(null);
  const prevInputValueRef = useRef(inputValue);

  useEffect(() => {
    if (!inputValue && inputValue !== prevInputValueRef.current) {
      setValue(null);
    }
    prevInputValueRef.current = inputValue;
  }, [inputValue]);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: any,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'select-option') {
      setValue(newValue);
      onChange(newValue);
      setAnchorEl(null);
    } else {
      setPendingValue(newValue);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div className={classes.root}>
        <ButtonBase className={classes.button} onClick={handleClick}>
          <span className={clsx({empty: !value})}>
            {value ? value.name : placeholder}
          </span>
          {!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </ButtonBase>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        className={classes.popper}
        style={{width: anchorEl?.clientWidth}}
      >
        <Autocomplete
          open
          onClose={handleClose}
          onInputChange={onInputChange}
          classes={{
            paper: classes.paper,
            listbox: classes.listbox,
            option: classes.option,
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          options={options}
          renderInput={params => (
            <Input
              ref={params.InputProps.ref}
              disableUnderline
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          )}
          renderOption={option => (
            <Box>
              <Typography variant="body2">{option.name}</Typography>
              {option?.subtitle && (
                <Box mt={0.1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {option.subtitle}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          getOptionLabel={option => option.name}
          onChange={handleChange}
          noOptionsText={
            <Typography variant="subtitle2" color="textSecondary">
              <FormattedMessage id="dashboard.no_results" />
            </Typography>
          }
        />
      </Popper>
    </>
  );
}

export default AutocompletePopup;
