import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Dropdown from '../../Common/Dropdown';
import IconButton from '../../Common/IconButton';
import {PointsMenuIcon2} from '../../../constants/images';

const RoleHeader = ({role, onUpdateRoleName, onDeleteRole, ...others}) => {
  const intl = useIntl();
  const nameRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newName, setNewName] = useState(role.name);

  //Set editable field focused
  useEffect(() => {
    if (showEdit && nameRef) {
      nameRef.current.focus();
    }
  }, [showEdit]);

  //Hide menu when dropdown is collapsed
  useEffect(() => {
    if (!dropdownOpen) {
      setShowMenu(false);
    }
  }, [dropdownOpen]);

  //Make menu is visible
  const handleClick = () => {
    setShowMenu(true);
    setDropdownOpen(true);
  };

  //Role name changed
  const handleChange = e => {
    const value = e.currentTarget.textContent;
    setNewName(value);
    setShowEdit(false);
    value !== role.name && onUpdateRoleName(role, value);
  };

  const handleEditClick = () => {
    setDropdownOpen(false);
    setShowEdit(true);
  };

  const handleDeleteClick = () => {
    onDeleteRole(role);
  };

  return (
    <div
      className={clsx('role-header cursor-pointer', {active: showEdit})}
      onClick={handleClick}
      {...others}
    >
      <Typography
        ref={nameRef}
        component="div"
        contentEditable={showEdit}
        suppressContentEditableWarning={true}
        spellCheck={false}
        variant="body2"
        className={clsx('role-name', {editable: showEdit})}
        // onInput={handleChange}
        onBlur={handleChange}
      >
        {newName}
      </Typography>
      <Dropdown
        open={dropdownOpen}
        className={clsx('actions-menu', {'d-none': !showMenu || showEdit})}
        menuIcon={
          <IconButton
            size="xs"
            variant="action"
            className="m-0"
            onClick={() => {}}
          >
            <PointsMenuIcon2
              style={{fontSize: 13, width: 'auto', color: '#21B59B'}}
            />
          </IconButton>
        }
        items={[
          {
            label: intl.formatMessage({id: 'actions.edit'}),
            onClick: handleEditClick,
          },
          {
            label: intl.formatMessage({id: 'actions.delete'}),
            onClick: handleDeleteClick,
          },
        ]}
        onToggle={v => setDropdownOpen(v)}
      />
    </div>
  );
};

export default RoleHeader;

RoleHeader.propTypes = {
  onDeleteRole: PropTypes.func.isRequired,
  onUpdateRoleName: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};
