import {PROGRESS_HIDE, PROGRESS_SHOW} from '../constants/actionTypes';

/** action creator to show spinner */
export const showSpinner = () => ({
  type: PROGRESS_SHOW,
});

/** action creator to show spinner */
export const hideSpinner = () => ({
  type: PROGRESS_HIDE,
});
