import React from 'react';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '../Common/Button';
import {CloseModalIcon} from '../../constants/images';
import IconButton from '../Common/IconButton';
import {Theme} from '@material-ui/core';
import {Property} from 'csstype';
import {CSSProperties} from '@material-ui/core/styles/withStyles';

const buttonStyles = (theme: Theme) => ({
  width: 'auto',
  minWidth: 97,
  justifyContent: 'center !important',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    minWidth: 'unset',
  },
  '&.textDanger': {
    color: (theme.palette as unknown as {danger: {main: string}}).danger.main,
  },
  '&.MuiButton-text': {
    [theme.breakpoints.up('lg')]: {
      minWidth: 97,
    },
  },
  '& .MuiButton-label': {
    whiteSpace: 'nowrap' as CSSProperties['whiteSpace'],
  },
});

//Modal styles
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  backdrop: {
    backgroundColor: 'rgba(40,43,62,0.5) !important',
  },
  paper: ({width}: {width: Property.Width | undefined}) => ({
    position: 'absolute',
    width: width,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    margin: theme.spacing(2),
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      margin: '0 auto',
    },
    '& .dialog-header': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      '& .modal-close-btn': {
        margin: '4px 0 0 0',
        paddingBottom: 12,
        '& .MuiSvgIcon-root': {
          width: 'auto',
        },
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
    '& .dialog-content': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      maxHeight: '65vh',
      overflow: 'hidden auto',
      '&.h-auto': {
        maxHeight: 'unset',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    '& .dialog-footer': {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      '& .dialog-button': buttonStyles(theme),
    },
  }),
  button: buttonStyles(theme),
}));

interface CustomModalProps {
  opened: boolean;
  handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  width?: Property.Width;
  children: React.ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  btnOkClassName?: string;
  onOk?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  noSaveBtn?: boolean;
  noMaxHeight?: boolean;
  showCloseTop?: boolean;
  cancelTitle?: string;
  saveTitle?: string;
  emptyModal?: boolean;
}

const CustomModal = (props: CustomModalProps) => {
  const {
    title,
    opened,
    onOk,
    handleClose,
    children,
    className = '',
    headerClassName = '',
    contentClassName = '',
    footerClassName = '',
    btnOkClassName = '',
    noSaveBtn = false,
    noMaxHeight = false,
    showCloseTop = false,
    cancelTitle,
    saveTitle,
    emptyModal = false,
  } = props;
  //Translations
  const intl = useIntl();

  const classes = useStyles({...props, width: props.width ?? 'inherit'});

  if (emptyModal) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Modal
        disableAutoFocus
        open={opened}
        onClose={handleClose}
        BackdropProps={{
          className: classes.backdrop,
        }}
      >
        <div className={clsx(classes.root, classes.paper, className)}>
          {children}
        </div>
      </Modal>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      disableAutoFocus
      open={opened}
      onClose={handleClose}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      {
        <div className={clsx(classes.root, classes.paper, className)}>
          {/* Title */}
          <div className={clsx('dialog-header', headerClassName)}>
            <Typography variant="h4">{title}</Typography>
            {showCloseTop && (
              <IconButton
                size="small"
                variant="filled"
                onClick={handleClose}
                className="modal-close-btn"
              >
                <CloseModalIcon style={{fontSize: 15}} />
              </IconButton>
            )}
          </div>
          {/* Modal content */}
          <div
            className={clsx(
              'dialog-content',
              contentClassName,
              noMaxHeight && 'h-auto'
            )}
          >
            {children}
          </div>
          {/* Actions buttons */}
          <div className={clsx('dialog-footer text-lg-end', footerClassName)}>
            <div className="d-flex d-lg-inline-flex justify-content-center">
              {/*Cancel*/}
              <Button
                link
                className={clsx(classes.button, 'me-2 w-100')}
                title={
                  cancelTitle ?? intl.formatMessage({id: 'actions.cancel'})
                }
                onClick={handleClose}
              />
              {/*Save*/}
              {!noSaveBtn && (
                <Button
                  primary
                  className={clsx(classes.button, btnOkClassName, 'w-100')}
                  title={saveTitle ?? intl.formatMessage({id: 'actions.save'})}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    onOk ? onOk(e) : handleClose(e)
                  }
                  data-testid="modalSaveBtn"
                />
              )}
            </div>
          </div>
        </div>
      }
    </Modal>
  );
};

export default CustomModal;
