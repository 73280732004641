import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useIntl} from 'react-intl';

const RefundPopupTotalRow = props => {
  const {currency, maxRefundAmount, totalRefundAmount} = props;
  const intl = useIntl();
  return (
    <div className="row align-items-center bg-primary-light rounded py-2 mb-1">
      <div className="col py-1">
        <Typography color="primary" className="mb-1 font-weight-bold">
          {intl.formatMessage({id: 'dashboard.sales.refund.refund_total'})}
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
        >{`${intl.formatMessage({id: 'dashboard.sales.refund.max_refund_available'})}: ${intl.formatNumber(maxRefundAmount, {style: 'currency', currency: currency})}`}</Typography>
      </div>
      <div className="col-auto">
        <Typography variant="h4" color="primary">
          {intl.formatNumber(totalRefundAmount, {
            style: 'currency',
            currency: currency,
          })}
        </Typography>
      </div>
    </div>
  );
};

export default RefundPopupTotalRow;
