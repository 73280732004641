import {useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export default function useSearchParams() {
  const {search, pathname} = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = useCallback(
    (newSearchParams, currentParams) => {
      let update = true;
      if (currentParams && currentParams instanceof URLSearchParams) {
        update = currentParams.toString() !== newSearchParams.toString();
      }
      if (update) {
        history.push({
          pathname: pathname,
          search: newSearchParams.toString(),
        });
      }
    },
    [history, pathname]
  );

  return [searchParams, setSearchParams];
}
