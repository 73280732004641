import React from 'react';
import {injectIntl} from 'react-intl';
import {reduxForm, Field} from 'redux-form';
import InputField from '../Form/Fields/InputField';
import SelectField from '../Form/Fields/SelectField';
import Modal from '../Widgets/Modal';
import IconButton from '../Common/IconButton';
import {ArrowForwardIcon} from '../../constants/images';

/* Add artist popup */
let ArtistDetailsPopup = props => {
  const {intl, opened, handleClose, handleSubmit} = props;

  const submit = () => {};

  return (
    <Modal
      width={328}
      opened={opened}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.details.title',
      })}
      saveTitle={intl.formatMessage({id: 'actions.add'})}
    >
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <div className="row row-cols-1 g-fan-25 mb-2">
          <div className="col">
            {/*Artist name*/}
            <Field
              name="name"
              component={InputField}
              outlined
              label={`${intl.formatMessage({id: 'dashboard.events.list.artist_tab.artist_name'})}*`}
              externalLabel
            />
          </div>
          <div className="col">
            {/*WP URL*/}
            <Field
              name="url"
              component={InputField}
              outlined
              label={intl.formatMessage({
                id: 'dashboard.events.list.artist_tab.wp_url',
              })}
              externalLabel
              addon={
                <IconButton size="xs" variant="action">
                  <ArrowForwardIcon style={{fontSize: 8}} />
                </IconButton>
              }
              addonPosition="end"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

const validate = values => {
  const errors = {};
  return errors;
};

// connect component to redux-form
ArtistDetailsPopup = reduxForm({
  form: 'ArtistDetailsForm',
  validate,
})(ArtistDetailsPopup);

export default injectIntl(ArtistDetailsPopup);
