/**
 * These are the supported document extensions for UserDocument
 * @type {string[]}
 */
export const SUPPORTED_DOCUMENT_EXTENSIONS = [
  'pdf',
  'docx',
  'doc',
  'odt',
  'jpg',
  'png',
  'jpeg',
  'gif',
  'jfif',
  'exif',
  'eiff',
  'bmp',
  'svg',
  'webP',
];

/**
 * Document upload max size
 * @type {number}
 */
export const DOCUMENT_MAX_SIZE = 50 * 1024 * 1024;

/**
 * Custom attribute image max size
 * @type {number}
 */
export const CUSTOM_ATTRIBUTE_IMAGE_MAX_SIZE = 15 * 1024 * 1024;

/**
 * Breakpoint widths
 * Note: MAX_something is the max width plus 1
 * @type {[key: string]: number}
 */
export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
  MAX_SM: 768,
  MAX_MD: 992,
  MAX_LG: 120,
  MAX_XL: 1400,
};

export const TABLE_DEFAULT_BREAKPOINT = BREAKPOINTS.MAX_MD;

/* APP_COUNTRIES HAS TO MATCH WITH fanpass/src/Fanpass/UserBundle/Entity/Address.php */
export const APP_COUNTRIES = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AG: 'Angola',
  AI: 'Anguilla',
  AB: 'Antigua & Barbuda',
  AR: 'Argentina',
  AA: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BL: 'Bonaire',
  BA: 'Bosnia & Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  BC: 'British Indian Ocean Ter',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  IC: 'Canary Islands',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CD: 'Channel Islands',
  CL: 'Chile',
  CN: 'China',
  CI: 'Christmas Island',
  CS: 'Cocos Island',
  CO: 'Colombia',
  CC: 'Comoros',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CT: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CB: 'Curacao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  TM: 'East Timor',
  EC: 'Ecuador',
  EG: 'Egypt',
  ED: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FA: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  FS: 'French Southern Ter',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GB: 'Great Britain',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GY: 'Guyana',
  HT: 'Haiti',
  HW: 'Hawaii',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IA: 'Iran',
  IQ: 'Iraq',
  IR: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  NK: 'Korea North',
  KS: 'Korea South',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MY: 'Malaysia',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  ME: 'Mayotte',
  MX: 'Mexico',
  MI: 'Midway Islands',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Nambia',
  NU: 'Nauru',
  NP: 'Nepal',
  AN: 'Netherland Antilles',
  NL: 'Netherlands (Holland, Europe)',
  NV: 'Nevis',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NW: 'Niue',
  NF: 'Norfolk Island',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau Island',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PO: 'Pitcairn Island',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RM: 'Republic of Montenegro',
  RS: 'Republic of Serbia',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  NT: 'St Barthelemy',
  EU: 'St Eustatius',
  HE: 'St Helena',
  KN: 'St Kitts-Nevis',
  LC: 'St Lucia',
  MB: 'St Maarten',
  PM: 'St Pierre & Miquelon',
  VC: 'St Vincent & Grenadines',
  SP: 'Saipan',
  SO: 'Samoa',
  SX: 'Samoa American',
  SM: 'San Marino',
  ST: 'Sao Tome & Principe',
  SA: 'Saudi Arabia',
  SF: 'Scotland',
  SN: 'Senegal',
  SB: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SV: 'Slovenia',
  SI: 'Solomon Islands',
  OI: 'Somalia',
  ZA: 'South Africa',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TA: 'Tahiti',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad & Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TU: 'Turkmenistan',
  TC: 'Turks & Caicos Is',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VS: 'Vatican City State',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VB: 'Virgin Islands (Brit)',
  VA: 'Virgin Islands (USA)',
  WK: 'Wake Island',
  WL: 'Wales',
  WF: 'Wallis & Futana Is',
  YE: 'Yemen',
  ZR: 'Zaire',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  UK: 'United Kingdom',
};
