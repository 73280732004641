import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Avatar, {AvatarProps} from '@material-ui/core/Avatar';
import {ITheme} from '../../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    fontFamily: 'Averta Light',
    alignItems: 'flex-start',
  },
  dark: {
    color: theme.palette.dark.contrastText,
    backgroundColor: theme.palette.dark.main,
  },
  pink: {
    color: theme.palette.pink.contrastText,
    backgroundColor: theme.palette.pink.main,
  },
  blue: {
    color: theme.palette.blue.contrastText,
    backgroundColor: theme.palette.blue.main,
  },
  grey: {
    color: theme.palette.blue.contrastText,
    backgroundColor: '#B8B8B8',
  },
  orange: {
    color: theme.palette.orange.contrastText,
    backgroundColor: theme.palette.orange.main,
  },
  large: {
    width: 70,
    height: 70,
    fontSize: 30,
  },
  medium: {
    width: 49,
    height: 49,
    fontSize: 21,
  },
  small: {
    width: 35,
    height: 35,
    fontSize: 12,
  },
  xs: {
    width: 30,
    height: 30,
    fontSize: 13,
  },
  xs10: {
    width: 30,
    height: 30,
    fontSize: 10,
  },
  xxs: {
    fontFamily: 'Averta Semibold',
    width: 15,
    height: 15,
    fontSize: 11,
  },
  altText: {
    marginBottom: 0,
    '&.lg': {
      paddingTop: 22,
    },
    '&.md': {
      paddingTop: 15,
    },
    '&.sm': {
      paddingTop: 12,
    },
    '&.xs': {
      paddingTop: 9,
    },
    '&.xs10': {
      paddingTop: 10,
    },
    '&.xxs': {
      paddingTop: 2,
      paddingLeft: 2,
    },
  },
}));

interface CustomAvatarProps extends AvatarProps {
  alt?: string;
  src?: string;
  color?: 'pink' | 'blue' | 'grey' | 'orange' | 'dark';
  size?: 'lg' | 'md' | 'sm' | 'xs' | 'xs10' | 'xxs';
}

const CustomAvatar = ({
  src = '',
  alt,
  color = 'grey',
  size = 'lg',
  ...other
}: CustomAvatarProps) => {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        classes.root,
        color === 'pink' && classes.pink,
        color === 'blue' && classes.blue,
        color === 'grey' && classes.grey,
        color === 'orange' && classes.orange,
        color === 'dark' && classes.dark,
        size === 'lg' && classes.large,
        size === 'md' && classes.medium,
        size === 'sm' && classes.small,
        size === 'xs' && classes.xs,
        size === 'xs10' && classes.xs10,
        size === 'xxs' && classes.xxs
      )}
      src={src}
      {...other}
    >
      <p className={clsx(classes.altText, size)}>{alt}</p>
    </Avatar>
  );
};

export default CustomAvatar;
