import Avatar from '../../Common/Avatar';
import React from 'react';
import {Nameable} from '../types';

type TeamMemberAvatarProps = {
  member: Nameable;
};
const TeamMemberAvatar = ({member}: TeamMemberAvatarProps) => (
  <Avatar
    alt={
      member.firstname && member.lastname
        ? `${member.firstname.charAt(0)}${member.lastname.charAt(0)}`
        : ''
    }
    size="sm"
    color="dark"
  />
);

export default TeamMemberAvatar;
