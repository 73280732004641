import {call, cancelled, delay, put} from '@redux-saga/core/effects';
import {getList} from '../connectivity/functions';
import * as types from '../constants/actionTypes';
import {handleException} from './utils';
import {API_V2_TRANSACTIONS_URL} from '../constants/urls';
import axios from 'axios';

export function* doGetTransactionList(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    yield delay(500);
    const data = yield call(getList, {
      ...action.payload,
      cancelToken: cancelSource.token,
      url: API_V2_TRANSACTIONS_URL,
    });

    yield put({
      type: types.API_TRANSACTION_PAYMENT_LIST_SUCCEEDED,
      payload: {...action.payload, ...data},
    });
  } catch (error) {
    yield handleException({
      error,
      type: types.API_TRANSACTION_PAYMENT_LIST_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}
