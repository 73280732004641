import {useSelector} from 'react-redux';
import {selectUser} from '../store/slices/authSlice';
import {useCallback, useMemo} from 'react';
import {MenuType} from '../constants/menus';

export default function usePermission() {
  const user = useSelector(selectUser);

  const hasPermission = useCallback(
    permission => {
      return user?.permissions.includes(permission);
    },
    [user?.permissions]
  );

  /**
   * Check if current user has any of permissions specified
   * @param {array} permissions
   * @returns {boolean}
   */
  const hasAnyPermission = useCallback(
    permissions => {
      for (const permission of permissions) {
        if (hasPermission(permission)) {
          return true;
        }
      }
      return false;
    },
    [hasPermission]
  );

  /**
   * Check if current user has all permissions specified
   * @param {array} permissions
   * @returns {boolean}
   */
  const hasPermissions = useCallback(
    permissions => {
      for (const permission of permissions) {
        if (!hasPermission(permission)) {
          return false;
        }
      }
      return true;
    },
    [hasPermission]
  );

  /**
   * Check if user has any permission to show the menu item
   * @param menus
   * @returns {*}
   */
  const filterMenusByPermissions = useCallback(
    (menus: MenuType[]) => {
      return menus.filter(item => {
        return item.permissions ? hasAnyPermission(item.permissions) : true;
      });
    },
    [hasAnyPermission]
  );

  const res = useMemo(
    () => ({
      hasPermission,
      hasAnyPermission,
      hasPermissions,
      filterMenusByPermissions,
    }),
    [filterMenusByPermissions, hasAnyPermission, hasPermission, hasPermissions]
  );

  return res;
}
