import axios from 'axios';
import {
  API_V2_USER_DETAILS_ADDRESS_URL,
  API_V2_USER_EDIT_DELETE_ADDRESS_URL,
  API_V2_USER_ADD_ADDRESS_URL,
  API_V2_USER_ADDRESS_MAKE_AS_DEFAULT_URL,
} from '../constants/urls';
import {get, post, put} from './api';

/**
 * Make api call to get user addresss
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userAdress
 */
export async function getUserAddress(payload) {
  const url = API_V2_USER_DETAILS_ADDRESS_URL.replace(':id', payload.id);
  const data = await get({
    url,
    config: {
      ldjson: true,
    },
    token: payload.token,
  });
  return data;
}

/**
 * Make api call to delete user addresss
 *
 * @param {payload}
 *
 * @return {Promise<Boolean>} userAdress
 */
export async function deleteUserAddress(payload) {
  const url = API_V2_USER_EDIT_DELETE_ADDRESS_URL.replace(':id', payload.id);
  await axios.delete(url);
  return true;
}

/**
 * Make api call to edit address
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userAdress
 */
export async function editUserAddress({token, ...payload}) {
  // Consume endpoint here.
  const body = {...payload};
  const data = await put({
    url: API_V2_USER_EDIT_DELETE_ADDRESS_URL.replace(':id', payload.id),
    body,
    ldjson: true,
    token,
  });
  return data;
}

/**
 * Make api call to set user address as default
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userAdress
 */
export async function makeAddressAsDefault({token, ...payload}) {
  // Consume endpoint here.
  const body = {...payload};
  const data = await put({
    url: API_V2_USER_ADDRESS_MAKE_AS_DEFAULT_URL.replace(':id', payload.id),
    body,
    ldjson: true,
    token,
  });
  return data;
}

/**
 * Make api call to create new address
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userAdress
 */
export async function createNewAddress({token, ...payload}) {
  // Consume endpoint here.
  const body = {...payload};
  const data = await post({
    url: API_V2_USER_ADD_ADDRESS_URL,
    body,
    ldjson: true,
    token,
  });
  return data;
}
