import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash.get';
import {FormattedMessage, useIntl} from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {PlusIcon, MinusIcon} from '../../constants/images';
import Typography from '@material-ui/core/Typography';
import {Close as CloseIcon} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {Collapse} from 'reactstrap';
import Button from '../Common/Button';
import {FILTER_ICON} from '../../constants/images';
import {reduxForm} from 'redux-form';
import DynamicField from '../Form/Fields/DynamicField';
import {inputTypes} from '../../constants/enums';
import {isEqual} from 'lodash';
import {Paper} from '@material-ui/core';
import useSearchParams from '../../hooks/utils/useSearchParams';
import {
  countActiveFilters,
  getParamsFromUrl,
  getUrlSearchParams,
  parseFilterSearchParams,
} from '../../utils/filters';

//Filter styles
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    overflow: 'hidden',

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '21.4%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 51,
      paddingBottom: 0,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
  closeWrapper: {
    padding: '0px 40px',
  },
  [theme.breakpoints.up('lg')]: {
    form: {
      overflow: 'auto',
    },
  },
}));

/**
 * Collapsible filter component
 * @constructor
 */
const CollapsibleFilterBlock = ({
  title,
  className,
  active,
  children,
  isOpen,
  onToggle,
  formName,
  filterName,
}) => {
  const values = useSelector(state =>
    get(state, `form.${formName}.values`, {})
  );

  const isActive = useMemo(() => {
    if (active) {
      return true;
    }
    const val = get(values, filterName);
    return Array.isArray(val) ? val.length > 0 : val;
  }, [active, values]);

  //Expand/collapse block
  const toggle = useCallback(() => {
    onToggle && onToggle(!isOpen);
  }, [onToggle, isOpen]);

  return (
    <div
      className={clsx(
        'card collapsable-block',
        isOpen && 'expanded',
        className
      )}
    >
      <div className="card-header" onClick={toggle}>
        <h6
          className={clsx('card-title', {
            'font-weight-bold': isOpen,
            'text-app-primary': isActive,
          })}
        >
          {title}
        </h6>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="card-body">{children}</div>
      </Collapse>
    </div>
  );
};

export const FILTERS_SEARCH_KEY = 'filters';

export const FILTERS_APPLIED_SEARCH_KEY = 'apply';

/**
 * Filter component
 * @param props
 * @constructor
 */
let Filter = props => {
  const {
    width,
    opened,
    items,
    onClose,
    onFilter,
    onClear,
    onSearchParamsChange,
    dirty,
    anyTouched,
    clearAdditionalParams,
    filterAdditionalParams,
    dispatch,
    resetSection,
    change,
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  // get form values from redux-form reducer
  const values = useSelector(state =>
    get(state, `form.${props.form}.values`, {})
  );
  const registeredFields = useSelector(state =>
    get(state, `form.${props.form}.registeredFields`, {})
  );
  // active filters number state
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [openedFilter, setOpenedFilter] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [applied, setApplied] = useState(() => {
    // Check if the filters are already applied
    const appliedFlag = parseFilterSearchParams(
      getUrlSearchParams(searchParams),
      FILTERS_APPLIED_SEARCH_KEY
    );

    return appliedFlag === true;
  });

  // Update the applied flag in the URL when the applied state changes
  useEffect(() => {
    const currentParams = getUrlSearchParams(searchParams);
    const p = getUrlSearchParams(searchParams);

    if (applied) {
      // Set the applied flag in the URL
      p.set(FILTERS_APPLIED_SEARCH_KEY, true);
    } else {
      p.delete(FILTERS_APPLIED_SEARCH_KEY);
    }

    setSearchParams(p, currentParams);
  }, [applied]);

  // get initial value from url
  useEffect(() => {
    const filters = parseFilterSearchParams(getUrlSearchParams(searchParams));

    if (!isEqual(filters, values)) {
      const filterFields = Object.keys(filters ?? {});
      const sections = (items ?? [])
        .map(item => item.name)
        .filter(field => !filterFields.includes(field));
      if (Object.keys(filters).length > 0) {
        Object.keys(filters).forEach(field =>
          dispatch(change(field, filters[field]))
        );
      }
      if (sections.length > 0) {
        dispatch(resetSection(...sections));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // update url on change
  useEffect(() => {
    const id = setTimeout(() => {
      const filters = parseFilterSearchParams(getUrlSearchParams(searchParams));
      if (!isEqual(filters, values) && (dirty || anyTouched)) {
        // Un-apply currently applied filters when the filters are changed
        setApplied(false);

        const currentParams = getUrlSearchParams(searchParams);
        const p = getUrlSearchParams(searchParams);
        p.set(FILTERS_SEARCH_KEY, JSON.stringify(values));
        setSearchParams(p, currentParams);
      }
    }, 300);

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // Update account filters count on filter form values changes
  useEffect(() => {
    setActiveFiltersCount(
      countActiveFilters(Object.keys(registeredFields), values)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const activeFilters = getParamsFromUrl(searchParams).filterValues || {};
    const activeFiltersForDisplay: {id: string; title: any; values: any}[] = [];

    // Iterate over the active filters found in searchParams
    Object.entries(activeFilters).forEach(([filterName, filterValues]) => {
      const filterDefinition = items.find(
        item => item.name === filterName || item.outputName === filterName
      );

      if (!filterDefinition) return; // Skip if the filter is not defined in items

      const filterTitle = filterDefinition.title;
      let valuesLabels = [];

      // Handle different types of filters
      switch (filterDefinition.inputType) {
        case inputTypes.CHECKBOXES:
        case inputTypes.RADIO:
          // Map filter values to their labels
          valuesLabels = filterDefinition.items
            .filter(item => filterValues.includes(item.value))
            .map(item => item.label);
          break;
        case inputTypes.DATE_SELECT:
          if (
            filterValues.type === 'between' &&
            filterValues.from &&
            filterValues.to
          ) {
            valuesLabels = [`From ${filterValues.from} to ${filterValues.to}`];
          } else if (
            filterValues.type === 'last' &&
            filterValues.periodNumber
          ) {
            const periodType = filterValues.period || 'days'; // Default to 'days'
            valuesLabels = [`Last ${filterValues.periodNumber} ${periodType}`];
          }
          break;
        case inputTypes.ADVANCED_NUMBER:
          if (filterValues.option === 'is_greater') {
            valuesLabels = [`Greater than ${filterValues.gt}`];
          } else if (filterValues.option === 'is_less') {
            valuesLabels = [`Less than ${filterValues.lt}`];
          } else if (filterValues.option === 'is_between') {
            valuesLabels = [
              `Between ${filterValues.min} and ${filterValues.max}`,
            ];
          } else if (filterValues.option === 'is_equal') {
            valuesLabels = [`Equal to ${filterValues.eq}`];
          }
          break;
        default:
          // Direct use of filter values for other types (e.g., text, autocomplete)
          valuesLabels = Array.isArray(filterValues)
            ? filterValues
            : [filterValues];
      }

      if (valuesLabels.length > 0) {
        activeFiltersForDisplay.push({
          id: filterName,
          title: filterTitle,
          values: valuesLabels,
        });
      }
    });

    const cleared = !activeFiltersForDisplay.length;

    // Notify the parent component about the active filters
    onSearchParamsChange &&
      onSearchParamsChange(activeFiltersForDisplay, applied, cleared);
  }, [searchParams, onSearchParamsChange]);

  const clearFilters = () => {
    // Un-apply currently applied filters when the filters are cleared
    setApplied(false);

    const p = getUrlSearchParams(searchParams);
    p.delete(FILTERS_SEARCH_KEY);
    if (clearAdditionalParams) {
      Object.keys(clearAdditionalParams).forEach(k =>
        p.set(k, clearAdditionalParams[k])
      );
    }
    setSearchParams(p, null);
    onClear && onClear({}, p);
  };

  const applyFilters = () => {
    const currentParams = getUrlSearchParams(searchParams);
    const p = getUrlSearchParams(searchParams);
    p.set(FILTERS_SEARCH_KEY, JSON.stringify(values));
    if (filterAdditionalParams) {
      Object.keys(filterAdditionalParams).forEach(k =>
        p.set(k, filterAdditionalParams[k])
      );
    }

    // Set the URL search params
    setSearchParams(p, currentParams);

    // Set the applied flag, so the added filters are considered applied & appear in the active filters list
    setApplied(true);

    onFilter && onFilter(values, p);
  };

  //Render filter content inside collapsible block
  const renderFilterSubItems = useCallback(filterItem => {
    const {title, inputType, subItems, ...others} = filterItem;
    return (
      <DynamicField
        label={title}
        items={subItems}
        inputType={inputType}
        {...others}
        maxDepth={1}
      />
    );
  }, []);

  const isActive = useCallback(filterName => {
    const val = get(values, filterName);
    return Array.isArray(val) ? val.length > 0 : val;
  }, []);

  const getBlockClassName = useCallback(item => {
    if (item.inputType === inputTypes.ADVANCED_NUMBER) {
      return 'quantity_select';
    } else {
      return '';
    }
  }, []);

  const renderItems = useMemo(() => {
    return items.map((item, key) => (
      <CollapsibleFilterBlock
        key={key}
        filterName={item.name}
        formName={props.form}
        active={isActive(item.name)}
        {...item}
        className={clsx(
          item.blockClassName,
          getBlockClassName(item),
          'filter-item-' + item.inputType
        )}
        isOpen={openedFilter === item.name}
        onToggle={isOpen => setOpenedFilter(isOpen ? item.name : null)}
      >
        {renderFilterSubItems(item)}
      </CollapsibleFilterBlock>
    ));
  }, [items, openedFilter, setOpenedFilter]);

  return (
    <Drawer
      anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={onClose}
    >
      <Paper className={classes.closeWrapper} elevation={0}>
        {/* Close action button */}
        <Button
          link
          light
          title={intl.formatMessage({id: 'actions.close'})}
          onClick={onClose}
          icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
        />
      </Paper>
      <form className={'position-relative h-100 ' + classes.form}>
        {/* Filter block content */}
        <div className="filter-content">
          {/* Filter header */}
          <div className="filter-header border-bottom">
            {/* Filter title */}
            <div className="filter-header-title d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {FILTER_ICON}
                <Typography variant="h5">
                  <FormattedMessage id="actions.filter" />
                </Typography>
              </div>
              {activeFiltersCount > 0 && (
                <div className="filter-counter-indicator text-center">
                  {activeFiltersCount}
                </div>
              )}
              {/*  Use 'invisible' class if you need to hide this indicator */}
            </div>
          </div>
          {/* Filter items collapsible */}
          <div className="filter-items-container filter-items-container-lg flex-fill">
            {renderItems}
          </div>
          {/* Filter actions */}
          <div className="filter-bottom-actions filter-bottom-actions-lg d-flex align-items-center justify-content-center">
            {/*Clear*/}
            <Button
              link
              title={intl.formatMessage({id: 'actions.clear'})}
              type="button"
              onClick={clearFilters}
            />
            {/*Apply*/}
            <Button
              primary
              title={intl.formatMessage({id: 'actions.apply'})}
              type="button"
              onClick={applyFilters}
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  /**
   * TRUE if filter block is shown
   */
  opened: PropTypes.bool,
  /**
   * Filtration items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Redux-form field name
       */
      name: PropTypes.string.isRequired,
      /**
       * The displayed filter title
       */
      title: PropTypes.string,
      /**
       * Used for select (drop-list), checkbox group, radio group or something similar
       */
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
        })
      ),
      /**
       * The filter item input types
       */
      inputType: PropTypes.oneOf([
        /** Render a single checkbox */
        inputTypes.CHECKBOX,
        /** Render a group of checkbox filter, subItems is required here */
        inputTypes.CHECKBOXES,
        /** Render a select filter, subItems is required here */
        inputTypes.SELECT,
        /** Render two datepickers, value structure will be an object with two keys: `from` and `to` */
        inputTypes.DATE_BETWEEN,
        /** Render a SearchTextField */
        inputTypes.SEARCH,
        /** Render a two datepickers or one period select and one input */
        inputTypes.DATE_SELECT,
        /** Render radio buttons for each subitem in subItems */
        inputTypes.RADIO,
        /** Render an AdvancedNumberField */
        inputTypes.ADVANCED_NUMBER,
        /** Autocomplete field */
        inputTypes.AUTOCOMPLETE,
        inputTypes.AUTOCOMPLETE2,
      ]),
    })
  ),
  /**
   * onFilter callback, fired when `clear` or `apply` buttons are clicked
   * the callback parameters will be the filters value
   */
  onFilter: PropTypes.func,
  /**
   * onSearchParamsChange callback, fired when any changes to searchParams are made so the parent component is aware of it,
   * the callback parameters will be the active filters array value
   */
  onSearchParamsChange: PropTypes.func,
  /**
   * Flag indicating whether the filter is ready or not
   * This is used to perform actions when ready
   * like reading location search and triggering filter change
   */
  ready: PropTypes.bool,
  /**
   * Flag indicating whether the filter should get/set location search
   * If true, it will update the location search on apply/clear
   * and location search changes will update the filter values too
   * meaning that user will be able to go back/forward and the filters will change
   * accordingly
   * Default: false
   */
  bindLocation: PropTypes.bool,
  currentValues: PropTypes.object,
  onSilentFilterChange: PropTypes.func,
};

Filter.defaultProps = {
  ready: false,
  bindLocation: false,
};

// the form name can be overridden by providing a "form" prop to Filter
// useful to avoid having the multiple filters with the same name
Filter = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(Filter);

export default withWidth()(Filter);
