import {put, select} from 'redux-saga/effects';
import {get} from 'lodash';
import {doEnqueueSnackBar} from './snackbar.saga';
import {selectToken} from '../store/slices/authSlice';
import {cloneDeep} from 'lodash';
import {isTokenError} from '../store/listenerMiddleware';

const formatMessage = error => {
  const errors = error && error.data && error.data.error;
  if (errors) {
    let message = '';
    Object.keys(errors.fields).map(field => {
      message += errors.fields[field].join('<br/>');
      return true;
    });

    return message;
  }

  return error.message;
};

export function* handleException({
  error,
  type,
  customMessage,
  staticMessageStatusCodes = [500],
}) {
  // do not display error message if it's a token error
  if (!isTokenError({payload: {error}})) {
    let message = customMessage ?? formatMessage(error);
    const statusCode = +get(
      error,
      'statusCode',
      get(error, 'status', get(error, 'response.status'))
    );

    // Use static message for status code included in staticMessageStatusCodes
    if (staticMessageStatusCodes.indexOf(statusCode) > -1) {
      message =
        'There have been an error with your request. Please try again or contact the support.';
    }

    yield doEnqueueSnackBar(message, 'error');

    yield put({
      type,
      payload: {error},
    });
  }
}

export function* addAuthToken(originalPayload, name = 'token') {
  try {
    const payload = cloneDeep(originalPayload);
    const token = yield select(selectToken);

    if (typeof payload !== 'object') {
      throw Error(`Payload must be an object, ${typeof payload} given`);
    }

    payload[name] = token;

    return payload;
  } catch (e) {
    console.warn('Failed to add token', e);
    return originalPayload;
  }
}
