import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import TopPaper from '../Common/TopPaper';
import Typography from '@material-ui/core/Typography';
import Button from '../Common/Button';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '../Common/Tabs';
import ListEventTab from './ListEventTab';
import get from 'lodash.get';
import {useHistory, useRouteMatch} from 'react-router-dom';
import * as urls from '../../constants/urls';
import ListVenueTab from './ListVenueTab';
import ListCompetitionTab from './ListCompetitionTab';
import ListTeamTab from './ListTeamTab';
import ListArtistTab from './ListArtistTab';

/* Events list */
const EventsList = ({intl}) => {
  //Router
  const history = useHistory();
  const match = useRouteMatch();

  const tabs = [
    {
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.event'}),
      value: 'event',
    },
    {
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.venue'}),
      value: 'venue',
    },
    {
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.competition'}),
      value: 'competition',
    },
    {
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.team'}),
      value: 'team',
    },
    {
      label: intl.formatMessage({id: 'dashboard.events.list.tabs.artist'}),
      value: 'artist',
    },
  ];

  // get activeTab from routematch
  const getTab = (m, defaultTab) =>
    get(m, 'params[0]', defaultTab ?? (tabs.length > 1 ? 'event' : null));

  //active tab
  const [activeTab, setActiveTab] = useState(getTab(match));

  //switch active tab
  const toggle = tab => {
    if (activeTab !== tab) {
      history.push(urls.EVENTS_LIST_TAB_PATH.replace(':tab', tab));
    }
  };

  //Details popup state
  const [addPopup, setAddPopup] = useState(false);

  const toggleAddPopup = tab => setAddPopup(tab);

  useEffect(() => {
    const active = getTab(match);
    if (active !== activeTab) {
      setActiveTab(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  //Add click action
  const onAddClick = () => {
    let route = '';
    switch (activeTab) {
      case 'event':
        route = urls.EVENT_DETAILS_PATH;
        break;
      case 'venue':
        route = urls.VENUE_DETAILS_PATH;
        break;
      case 'competition':
        toggleAddPopup('competition');
        break;
      case 'team':
        toggleAddPopup('team');
        break;
      case 'artist':
        toggleAddPopup('artist');
        break;
      default:
        break;
    }

    if (route.length > 0) {
      history.push(route.replace(':id', 'new'));
    }
  };

  return (
    <div className="dashboard-wrapper events-list p-0">
      <div className="container-fluid">
        <TopPaper>
          <div className="row header-row">
            <div className="col">
              <Typography
                variant="h3"
                className="font-weight-bolder text-center text-lg-start mb-lg-1"
              >
                {intl.formatMessage({id: 'dashboard.events.list.title'})}
              </Typography>
            </div>
            <div className="col-auto d-none d-lg-block">
              <Button
                primary
                icon={<AddIcon />}
                title={intl.formatMessage({
                  id: `dashboard.events.add_${activeTab}`,
                })}
                onClick={onAddClick}
              />
            </div>
          </div>
          {tabs.length > 1 && (
            <div className="row">
              <div className="col">
                {/* Tabs */}
                <Tabs
                  className="mb-0"
                  indicators={false}
                  value={activeTab}
                  tabs={tabs}
                  onChange={toggle}
                />
              </div>
            </div>
          )}
        </TopPaper>
        {/*Tabs content*/}
        {activeTab === 'event' && <ListEventTab onAddClick={onAddClick} />}
        {activeTab === 'venue' && <ListVenueTab onAddClick={onAddClick} />}
        {activeTab === 'competition' && (
          <ListCompetitionTab
            addPopup={addPopup === 'competition'}
            onAddClick={onAddClick}
            closeAddPopup={() => setAddPopup(false)}
          />
        )}
        {activeTab === 'team' && (
          <ListTeamTab
            addPopup={addPopup === 'team'}
            onAddClick={onAddClick}
            closeAddPopup={() => setAddPopup(false)}
          />
        )}
        {activeTab === 'artist' && (
          <ListArtistTab
            addPopup={addPopup === 'artist'}
            onAddClick={onAddClick}
            closeAddPopup={() => setAddPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(EventsList);
