import axios from 'axios';
import * as apiUrl from '../constants/urls';
import {get, post, put} from './api';

/**
 * Make api call to get user banks
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userAdress
 */
export async function getUserBanks(payload) {
  const url = apiUrl.API_V2_USER_BANK_LIST.replace(':id', payload.id);
  const data = await get({
    url,
    config: {
      ldjson: true,
    },
    token: payload.token,
  });
  return data;
}

/**
 * Make api call to delete user bank
 *
 * @param {payload}
 *
 * @return {Promise<Boolean>} userBank
 */
export async function deleteUserBank(payload) {
  const url = apiUrl.API_V2_USER_DELETE_BANK_ACCOUNT.replace(
    ':userId',
    payload.userId
  ).replace(':bankAccountId', payload.id);
  await axios.delete(url);
  return true;
}

/**
 * Make api call to set user bank as default
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userBank
 */
export async function setAsDefault(payload) {
  // // Consume endpoint here.
  const body = {stripeBankAccount: payload.id};
  const data = await put({
    url: apiUrl.API_V2_USER_SET_BANK_ACCOUNT_AS_DEFAULT.replace(
      ':userId',
      payload.userId
    ),
    body,
    ldjson: true,
    token: payload.token,
  });
  return data;
}

/**
 * Make api call to create new bank
 *
 * @param {payload}
 *
 * @return {Promise<Object>} userBank
 */
export async function createNewBank({token, ...payload}) {
  // Consume endpoint here.
  const body = {...payload};
  delete body.id;
  const url = apiUrl.API_V2_USER_BANK_LIST.replace(':id', payload.id);
  const data = await post({
    url,
    body,
    ldjson: true,
    token,
  });
  return data;
}
