import {FANPASS_LOGO_IMAGE} from '../constants/images';
import {FormattedMessage, useIntl} from 'react-intl';
import {HTMLProps} from 'react';
import clsx from 'clsx';

export type TwoFaQrCodeProps = {
  onNextClick: () => void;
  qrCodeProps?: Partial<HTMLProps<HTMLImageElement>>;
};

const TwoFaQrCode = ({onNextClick, qrCodeProps}: TwoFaQrCodeProps) => {
  const intl = useIntl();

  return (
    <section className="inner_page fa2 vh-100">
      <div className="container text-center">
        <div className="fa2-wrapper">
          <img src={FANPASS_LOGO_IMAGE} width="180" height="40" alt="" />
          <p className="fa2-title">
            <FormattedMessage id="auth.2fa.title" />
          </p>
          <div className="fa2-step">
            <p className="fa2-subtitle">
              <FormattedMessage id="auth.2fa.initial.step1_title" />
            </p>
            <p className="fa2-step-header">
              <FormattedMessage id="auth.2fa.initial.step1_subtitle" />
            </p>
            <p className="fa2-step-text">
              <FormattedMessage id="auth.2fa.initial.step1_text" />
            </p>
          </div>
          <div className="fa2-step">
            <div className="row">
              <div className="col-sm-8">
                <p className="fa2-subtitle">
                  <FormattedMessage id="auth.2fa.initial.step2_title" />
                </p>
                <p className="fa2-step-header">
                  <FormattedMessage id="auth.2fa.initial.step2_subtitle" />
                </p>
                <p className="fa2-step-text">
                  <FormattedMessage id="auth.2fa.initial.step2_text" />
                </p>
              </div>
              <div className="col-sm-4 text-center">
                <img
                  width="175"
                  height="175"
                  alt={intl.formatMessage({id: 'auth.2fa.initial.step2_title'})}
                  {...qrCodeProps}
                  className={clsx('fa2-step-img', qrCodeProps?.className)}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn fa2-btn-action"
          onClick={onNextClick}
        >
          <FormattedMessage id="auth.2fa.initial.button.text" />
        </button>
      </div>
    </section>
  );
};

export default TwoFaQrCode;
