import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from '../Inputs/DatePicker';

export const DEFAULT_DATE_PICKER_FORMAT = 'YYYY-MM-DD';

/**
 * Render a date picker for redux-form Field
 * `props.input.onChange` is called with a date strint formatted with `props.dateFormat`
 *
 * @param {Object} props
 */
const DatePickerField = props => {
  const {
    label,
    excludeLabel,
    enableBlur,
    displayFormat,
    maxDate,
    dateFormat,
    outlined,
    input: {name, value, onChange, onBlur},
    meta,
    InputProps,
    ...others
  } = props;
  const {touched, error} = meta;
  const extra = {label};

  if (excludeLabel) {
    delete extra.label;
  }

  if (outlined) {
    extra.inputVariant = 'outlined';
    extra.size = 'small';
  }

  // if `enableBlur` prop is enabled (true), call onBlur on picker close (onClose callback)
  if (enableBlur) {
    extra.onClose = () => {
      onBlur(value);
    };
  }

  // Format date from iFormat to fFormat
  const formatDate = (date, iFormat, fFormat, defaultValue = null) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  return (
    <DatePicker
      value={formatDate(value, dateFormat, displayFormat)}
      format={displayFormat}
      onChange={val => onChange(formatDate(val, displayFormat, dateFormat))}
      {...(maxDate ? {maxDate: maxDate} : {})}
      {...others}
      InputProps={{
        ...InputProps,
        name,
      }}
      outlined={outlined ? 'outlined' : ''}
      {...extra}
      {...(touched && error ? {helperText: error, error: true} : {})}
    />
  );
};

DatePickerField.propTypes = {
  displayFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  excludeLabel: PropTypes.bool,
};

DatePickerField.defaultProps = {
  dateFormat: DEFAULT_DATE_PICKER_FORMAT,
  excludeLabel: false,
};

export default DatePickerField;
