import {put} from '@redux-saga/core/effects';
import {get} from '../connectivity/api';
import {API_PERMISSIONS_URL} from '../constants/urls';
import {addAuthToken, handleException} from './utils';
import {
  API_PERMISSION_LIST_FAILED,
  API_PERMISSION_LIST_SUCCEEDED,
} from '../constants/actionTypes';

export function* listPermission(action) {
  try {
    const {q, token} = yield addAuthToken(action.payload);
    const url = q ? `${API_PERMISSIONS_URL}?q=${q}` : API_PERMISSIONS_URL;
    const data = yield get({
      url,
      token,
    });
    yield put({
      type: API_PERMISSION_LIST_SUCCEEDED,
      payload: data,
    });
  } catch (error) {
    yield handleException({error, type: API_PERMISSION_LIST_FAILED});
  }
}
