import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Radio from './Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {FormControlLabelProps} from '@material-ui/core/FormControlLabel/FormControlLabel';

const useStyles = makeStyles(theme => ({
  root: {
    '& .form-field-row': {
      flex: '1 0 100%',
      marginRight: 0,
      marginLeft: -7,
      '& .MuiRadio-root': {
        padding: 7,
        marginRight: 5,
      },
    },
  },
  legend: {
    color: theme.palette.secondary.light,
    fontSize: 12,
    marginBottom: 2,
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
    '&.Mui-error': {
      color: theme.palette.secondary.light,
    },
  },
  control: {
    marginRight: 26,
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },
  },
  label: {
    fontSize: 14,
    minWidth: 50,
  },
}));

interface CustomGenderRadioGroupProps {
  legend?: string;
  label?: string;
  items: {label: string; value: string}[];
  infoText?: string;
  formControlProps?: any;
  value: string;
  name: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const CustomGenderRadioGroup = (props: CustomGenderRadioGroupProps) => {
  const {
    legend,
    label,
    items,
    infoText,
    formControlProps,
    value,
    name,
    onChange,
    disabled,
  } = props;
  const classes = useStyles();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      className={classes.root}
      component="fieldset"
      fullWidth
      {...formControlProps}
      disabled={disabled}
    >
      <FormLabel className={classes.legend} component="legend">
        {legend ?? label}
      </FormLabel>
      <RadioGroup row value={value || ''} onChange={changeHandler}>
        {items.map(({label, value}, key) => (
          <FormControlLabel
            key={key}
            value={value.toString()}
            control={(<Radio />) as FormControlLabelProps['control']}
            label={label}
            name={name}
          />
        ))}
      </RadioGroup>
      {infoText}
    </FormControl>
  );
};

export default CustomGenderRadioGroup;
