import React from 'react';
import PermissionContainer from '../Common/PermissionContainer';
import ProfileDetailsGeneral from './Profile/ProfileDetailsGeneral';

/* User Details - Profile tab */
const ProfileDetailsTab = props => {
  const {user} = props;
  return (
    <div className="container ms-lg-0">
      <div className="content-block">
        {/* Add permission for authorised admins */}
        <PermissionContainer permissions={['admin_listing_user_details']}>
          <ProfileDetailsGeneral user={user} />
        </PermissionContainer>
      </div>
    </div>
  );
};

export default ProfileDetailsTab;
