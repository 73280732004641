import {createApi} from '@reduxjs/toolkit/query/react';
import {buildBaseQuery} from '../../services/functions';
import {FeedbackParams} from '../../services/types';
import {NoteEntity} from '../../types/common/NoteEntity';

export type UpdateNoteArgs = {
  id: number;
  note: string;
} & FeedbackParams;

export type CreateSaleNoteArgs = {
  saleId: number;
  note: string;
} & FeedbackParams;

export const noteApi = createApi({
  reducerPath: 'noteApi',
  baseQuery: buildBaseQuery(),
  endpoints: builder => ({
    updateNote: builder.mutation<NoteEntity, UpdateNoteArgs>({
      query: ({note, id}) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body: {note},
      }),
    }),
    createSaleNote: builder.mutation<NoteEntity, CreateSaleNoteArgs>({
      query: ({note, saleId}) => ({
        url: '/sale_notes',
        method: 'POST',
        body: {note, saleId},
      }),
    }),
  }),
});

export const {useUpdateNoteMutation, useCreateSaleNoteMutation} = noteApi;
