import {call, cancelled, delay, put} from '@redux-saga/core/effects';
import {
  API_COMPETITION_AUTOCOMPLETE_FAILED,
  API_COMPETITION_AUTOCOMPLETE_SUCCEEDED,
} from '../constants/actionTypes';
import axios from 'axios';
import {API_COMPETITION_AUTOCOMPLETE_URL} from '../constants/urls';

export function* doGetCompetitionAutocomplete(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    yield delay(200);
    const url =
      API_COMPETITION_AUTOCOMPLETE_URL + '?term=' + (action.payload.term ?? '');
    const response = yield call(axios.get, url, {
      cancelToken: cancelSource.token,
    });
    const data = response.data.map(item => item.label);

    yield put({
      type: API_COMPETITION_AUTOCOMPLETE_SUCCEEDED,
      payload: {competitionNames: data},
    });
  } catch (error) {
    console.warn('competition autocomplete request failed', error);
    yield put({type: API_COMPETITION_AUTOCOMPLETE_FAILED, payload: {error}});
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}
