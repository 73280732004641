import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
  token: 0,
  user: null,
  targetPath: '/',
};

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload.token;
    },
    updateTargetPath: (state, action) => {
      state.targetPath = action.payload.targetPath;
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
    },
    logout: state => {
      const {token, user, targetPath} = initialState;
      state.token = token;
      state.user = user;
      state.targetPath = targetPath;
    },
  },
});

export const {updateToken, updateUser, updateTargetPath, logout} =
  authSlice.actions;

export const selectToken = state => state[authSlice.name].token;

export const selectUser = state => state[authSlice.name].user;

export const selectTargetPath = state => state[authSlice.name].targetPath;

export default authSlice.reducer;
