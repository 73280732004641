import React, {Fragment, useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import get from 'lodash.get';
import Box from '@material-ui/core/Box';
// import {USER_LIST_SET_SEARCH_SUCCEEDED} from "../../constants/actionTypes";
import {FILTER_ICON} from '../../constants/images';
import TopPaper from '../Common/TopPaper';
import Typography from '@material-ui/core/Typography';
import Tabs from '../Common/Tabs';
import * as urls from '../../constants/urls';
import SearchForm from '../Form/SearchForm';
import IconButton from '../Common/IconButton';
import ArrowLink from '../Common/ArrowLink';
import SellerMarks from '../Sales/Details/SellerMark';
import StateIndicator from '../Common/StateIndicator';
import {SELLER_MARKS} from '../../constants/enums';
import DataTable from '../Widgets/DataTable';
import UserListBuyer from './UserListBuyer';
import Filter from '../Widgets/Filter';

/* User List */
const UserList = ({intl}) => {
  //Router
  const history = useHistory();
  const match = useRouteMatch();

  const tabs = [
    {
      label: intl.formatMessage({id: 'dashboard.users.seller'}),
      value: 'seller',
    },
    {label: intl.formatMessage({id: 'dashboard.users.buyer'}), value: 'buyer'},
  ];

  // get activeTab from routematch
  const getTab = (m, defaultTab) =>
    get(m, 'params[0]', defaultTab ?? (tabs.length > 1 ? 'seller' : null));

  //active tab
  const [activeTab, setActiveTab] = useState(getTab(match));

  //switch active tab
  const toggle = tab => {
    if (activeTab !== tab) {
      history.push(urls.USERS_LIST_TAB_PATH.replace(':tab', tab));
    }
  };

  // Header cells items
  const HeaderCells = [
    {
      name: 'user',
      disablePadding: true,
      label: intl.formatMessage({id: 'dashboard.transactions.user_name'}),
      renderValue: ({seller, options}) => (
        <div className="ps-1">
          <Box mb={0.3}>
            <ArrowLink title={seller.name} url={seller.link} />
          </Box>
          {options && <SellerMarks items={options} />}
        </div>
      ),
      collapse: false,
    },
    {
      name: 'tickets',
      label: intl.formatMessage({id: 'dashboard.users.tickets'}),
      renderValue: ({sold, added, active}) => (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center pb-2 pb-lg-0">
          {/*Sold*/}
          <div className="d-flex align-items-center me-3">
            <Typography variant="body2" className="text-placeholder me-2">
              {intl.formatMessage({id: 'dashboard.users.sold'})}
            </Typography>
            <Typography variant="body2">{sold}</Typography>
          </div>
          {/*Added*/}
          <div className="d-flex align-items-center me-3">
            <Typography variant="body2" className="text-placeholder me-2">
              {intl.formatMessage({id: 'dashboard.users.added'})}
            </Typography>
            <Typography variant="body2">{added}</Typography>
          </div>
          {/*Active*/}
          <div className="d-flex align-items-center me-3">
            <Typography variant="body2" className="text-placeholder me-2">
              {intl.formatMessage({id: 'dashboard.users.active'})}
            </Typography>
            <Typography variant="body2">{active}</Typography>
          </div>
        </div>
      ),
      width: '30%',
      collapse: true,
    },
    {
      name: 'acceptance',
      label: intl.formatMessage({id: 'dashboard.users.acceptance'}),
      collapse: true,
    },
    {
      name: 'turnover',
      label: intl.formatMessage({id: 'dashboard.users.turnover'}),
      collapse: true,
    },
    {
      name: 'date',
      label: intl.formatMessage({id: 'dashboard.users.creation_date'}),
      collapse: true,
    },
    {
      name: 'status',
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      renderValue: status => <StateIndicator {...status} />,
      collapse: false,
    },
  ];

  // change the id to use for the mock api
  const rows = [
    {
      id: 1,
      user: {
        seller: {id: '378P', name: 'Missie Clotilda', link: 'http://link'},
        options: [
          SELLER_MARKS.MARK_TRUSTED,
          SELLER_MARKS.MARK_DELIVERY,
          SELLER_MARKS.MARK_PREMIUM,
          SELLER_MARKS.MARK_PRO,
          SELLER_MARKS.MARK_INSTANT,
        ],
      },
      tickets: {
        sold: 10,
        added: 100,
        active: 80,
      },
      acceptance: '100%',
      turnover: '£450',
      date: '23 Nov 2019',
      status: {label: 'active', color: 'green'},
    },
    {
      id: 2,
      user: {
        seller: {id: '378P', name: 'Topaz Lynne', link: 'http://link'},
        options: [
          SELLER_MARKS.MARK_TRUSTED,
          SELLER_MARKS.MARK_DELIVERY,
          SELLER_MARKS.MARK_PREMIUM,
        ],
      },
      tickets: {
        sold: 29,
        added: 250,
        active: 145,
      },
      acceptance: '100%',
      turnover: '£650',
      date: '23 Nov 2019',
      status: {label: 'Suspended', color: 'error'},
    },
    {
      id: 3,
      user: {
        seller: {id: '378P', name: 'Sondra Jaylynn', link: 'http://link'},
        options: [SELLER_MARKS.MARK_DELIVERY],
      },
      tickets: {
        sold: 29,
        added: 250,
        active: 145,
      },
      acceptance: '100%',
      turnover: '£650',
      date: '23 Nov 2019',
      status: {label: 'Active', color: 'green'},
    },
    {
      id: 4,
      user: {
        seller: {id: '378P', name: 'Sara Samuel', link: 'http://link'},
      },
      tickets: {
        sold: 29,
        added: 250,
        active: 145,
      },
      acceptance: '100%',
      turnover: '£650',
      date: '23 Nov 2019',
      status: {label: 'Suspended', color: 'error'},
    },
  ];

  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);

  const filterItems = [
    {
      name: 'user',
      title: intl.formatMessage({id: 'dashboard.users.user_name'}),
      inputType: 'autocomplete',
      subitems: ['Example name1', 'tExt2', 'some_value'],
    },
    {
      name: 'phone',
      title: intl.formatMessage({id: 'dashboard.users.phone_number'}),
      inputType: 'autocomplete',
      subitems: ['0123456', '78912345', '0000'],
    },
    {
      name: 'email',
      title: intl.formatMessage({id: 'dashboard.users.email_address'}),
      inputType: 'autocomplete',
      subitems: ['test@email.com', 'aaa@bbb.ccc'],
    },
    {
      name: 'option_type',
      title: intl.formatMessage({id: 'dashboard.users.option_type'}),
      inputType: 'checkboxes',
      subitems: [
        {
          label: intl.formatMessage({
            id: 'dashboard.users.seller_options.trustable_seller',
          }),
          value: 'trustable_seller',
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.users.seller_options.custom_delivery',
          }),
          value: 'custom_delivery',
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.users.seller_options.premium_events',
          }),
          value: 'premium_events',
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.users.seller_options.pro_features',
          }),
          value: 'pro_features',
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.users.seller_options.instant_confirmation',
          }),
          value: 'instant_confirmation',
        },
      ],
    },
    {
      name: 'creation_date',
      title: intl.formatMessage({id: 'dashboard.users.creation_date'}),
      inputType: 'date_between',
    },
    {
      name: 'status',
      title: intl.formatMessage({id: 'dashboard.users.status'}),
      inputType: 'checkboxes',
      subitems: [
        {
          label: intl.formatMessage({id: 'dashboard.status.active'}),
          value: 'active',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.pending'}),
          value: 'pending',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.failed'}),
          value: 'failed',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.expired'}),
          value: 'expired',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.cancelled'}),
          value: 'cancelled',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.suspended'}),
          value: 'suspended',
        },
      ],
    },
  ];

  useEffect(() => {
    const active = getTab(match);
    if (active !== activeTab) {
      setActiveTab(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const handleRowClick = itemId => {
    history.push(urls.USER_DETAILS_PATH.replace(':id', itemId));
  };

  return (
    <div className="dashboard-wrapper user-list p-0">
      <div className="container-fluid">
        <TopPaper>
          <div className="row header-row">
            <div className="col">
              <Typography
                variant="h3"
                className="font-weight-bolder text-center text-lg-start mb-lg-1"
              >
                {intl.formatMessage({id: 'dashboard.users.user_list'})}
              </Typography>
            </div>
          </div>
          {tabs.length > 1 && (
            <div className="row">
              <div className="col">
                {/* Tabs */}
                <Tabs
                  className="mb-0"
                  indicators={false}
                  value={activeTab}
                  tabs={tabs}
                  onChange={toggle}
                />
              </div>
            </div>
          )}
        </TopPaper>
        <div className="dashboard-content">
          <div className="row align-items-center justify-content-between search-row">
            <div className="col-xl-5 col-lg-8 d-flex">
              {/* Search */}
              <SearchForm
                dispatchAction={true}
                // successType={USER_LIST_SET_SEARCH_SUCCEEDED}
                delay={180}
                SearchProps={{
                  buttonProps: {
                    onClick: () => {},
                  },
                }}
              />
              {/* Filter Button on mobile view only */}
              <Box ml={1} className="d-block d-lg-none">
                <IconButton
                  className="m-0"
                  onClick={() => setFilterOpened(true)}
                >
                  {FILTER_ICON}
                </IconButton>
              </Box>
            </div>
            {/* Button toolbar */}
            <div className="col-auto d-none d-lg-block">
              <div className="d-flex">
                {/* Filter button */}
                <IconButton
                  //{...(activeFilters ? {numberLabel: activeFilters} : {})}
                  className="m-0"
                  onClick={() => {
                    setFilterOpened(true);
                  }}
                >
                  {FILTER_ICON}
                </IconButton>
              </div>
            </div>
          </div>

          {/*Data Table*/}
          <div className="row">
            <div className="col">
              {activeTab === 'seller' ? (
                <Fragment>
                  {/* Filter on the right side */}
                  <Filter
                    opened={filterOpened}
                    items={filterItems}
                    onClose={() => setFilterOpened(false)}
                  />
                  <DataTable
                    className="seller-user-list"
                    canSelect={false}
                    empty={false}
                    loading={false}
                    onRowClick={itemId => handleRowClick(itemId)}
                    headCells={HeaderCells.map(item => ({
                      ...item,
                      numeric: false,
                      disablePadding: false,
                    }))}
                    rows={rows}
                    onChange={() => {}}
                    total={100}
                    currentPage={1}
                  />
                </Fragment>
              ) : (
                <UserListBuyer
                  filterOpened={filterOpened}
                  onFilterClose={() => setFilterOpened(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(UserList);
