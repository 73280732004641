import React, {useEffect, useRef} from 'react';
import {TableCell} from '@material-ui/core';
import clsx from 'clsx';

const WrappedRowCell = ({classes, renderSubRows, row, onHeightChange}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      onHeightChange && onHeightChange(ref.current.clientHeight);
    }
  }, [onHeightChange]);

  useEffect(() => {
    let virtualRow = null;
    let previousRow = null;
    const rowClass = 'virtual-wrapped-row';
    const previousRowClass = 'virtual-wrapped-previous-row';

    if (ref.current && ref.current.parentElement) {
      virtualRow = ref.current.parentElement.parentElement;
      virtualRow.classList.add(rowClass);
      previousRow = virtualRow.previousSibling;
    }

    if (previousRow) {
      previousRow.classList.add(previousRowClass);
    }

    return () => {
      if (virtualRow) {
        virtualRow.classList.remove(rowClass);
      }
      if (previousRow) {
        previousRow.classList.remove(previousRowClass);
      }
    };
  }, []);

  return (
    <div ref={ref} className="sub-table">
      {row.subrows ? renderSubRows(row.subrows) : JSON.stringify(row)}
    </div>
  );
};

export default WrappedRowCell;
