import React from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import {Switch} from 'react-router-dom';
import Route from '../Routes/Route';

import PayoutList from './PayoutList';
import TransactionHeader from './TransactionHeader';
import {
  TRANSACTIONS_PAYMENT_PATH,
  TRANSACTIONS_PAYOUT_PATH,
} from '../../constants/urls';
import PaymentList from './PaymentList';

/* Transaction container */
const TransactionsContainer = () => {
  const {path} = useRouteMatch();

  return (
    <div className="dashboard-wrapper transaction-list p-0">
      <div className="container-fluid">
        <TransactionHeader />
        <div className="dashboard-content">
          <Switch>
            <Route
              exact
              path={TRANSACTIONS_PAYOUT_PATH}
              component={PayoutList}
            />
            <Route
              exact
              path={TRANSACTIONS_PAYMENT_PATH}
              component={PaymentList}
            />
            {/* redirect all other routes to payment path */}
            <Redirect from={`${path}`} to={TRANSACTIONS_PAYMENT_PATH} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default TransactionsContainer;
