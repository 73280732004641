import {useEffect, useState} from 'react';
import {setRequestConfig} from '../../connectivity/functions';
import {API_USER_PICTURE_URL} from '../../constants/urls';

/**
 * Get profile image from `filename`
 * `filename` can be found on user.profileImage
 *
 * @param {string} filename profile image filename
 * @return {string}     The resolved profile image url
 */
export default function useProfilePictureUrl(filename) {
  const [image, setImage] = useState();

  useEffect(() => {
    if (filename) {
      getImage();
    } else {
      setImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename]);

  const getImage = async () => {
    const requestConfig = setRequestConfig({method: 'GET'});
    const response = await fetch(
      API_USER_PICTURE_URL.replace(':filename', filename),
      requestConfig
    );

    if (response.url) {
      setImage(response.url);
    } else {
      console.warn('Unable to get image', filename);
    }
  };

  return image;
}
