import {call, put} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as userBalanceApi from '../connectivity/userBalance.api';
import {handleException} from './utils';

// Get user balance
export function* doGetUserBalance(action) {
  try {
    const data = yield call(userBalanceApi.getUserBalance, action.payload);
    yield put({
      type: types.API_USER_BALANCE_SUCCEEDED,
      payload: {data},
    });
  } catch (error) {
    yield handleException({
      type: types.API_USER_BALANCE_FAILED,
      error,
    });
  }
}
