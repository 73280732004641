import {CollectionResponse, RawCollectionResponse} from './models';
import {fetchBaseQuery} from '@reduxjs/toolkit/query';
import {authSlice} from '../store/slices/authSlice';
import {API_BASE_URL} from '../constants/urls';

export const buildBaseQuery = (base = `${API_BASE_URL}/api/v2/`) =>
  fetchBaseQuery({
    baseUrl: `${base}`,
    prepareHeaders: (headers, {getState}) => {
      const token = getState()[authSlice.name].token;

      // Add authorization header if there is a token in the state
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

export const buildListSearchParams = ({
  q,
  filters = {},
  sort,
  page = 1,
  rowsPerPage = 25,
} = {}) => {
  const searchParams = new URLSearchParams();

  // In case of search as query, append it to the params
  if (q) {
    if (Array.isArray(q)) {
      q.forEach(item => searchParams.append('q[]', item));
    } else {
      searchParams.append('q', q);
    }
  }

  // Apply sort if it's required, append it to the params
  if (sort) {
    const sorts = Array.isArray(sort) ? sort : [sort];
    sorts.forEach(s => {
      searchParams.append(
        `order[${s.by}]`,
        s.direction.toLowerCase() === 'asc' ? 'asc' : 'desc'
      );
    });
  }

  // Apply pagination if it's required, append it to the params
  if (page && rowsPerPage) {
    searchParams.append('page', page);
    searchParams.append('limit', rowsPerPage);
  }

  // Apply filter if it's required, append it to the params
  if (filters && typeof filters === 'object') {
    for (const filterName in filters) {
      if (filters.hasOwnProperty(filterName)) {
        const value = filters[filterName];
        if (Array.isArray(value)) {
          for (const item of value) {
            searchParams.append(`${filterName}[]`, item);
          }
        } else {
          searchParams.append(filterName, value);
        }
      }
    }
  }

  return searchParams;
};

export const rawToCollectionResponse = (
  rawCollectionResponse: RawCollectionResponse<T>
): CollectionResponse<T> => ({
  items: rawCollectionResponse['hydra:member'],
  totalItems: rawCollectionResponse['hydra:totalItems'],
});
