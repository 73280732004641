import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {inputTypes} from '../../constants/enums';
import useEventAutocomplete from '../event/useEventAutocomplete';
import useCompetitionAutocomplete from '../competition/useCompetitionAutocomplete';
import useUserAutocomplete from '../user/useUserAutocomplete';
import {SALE_FILTER_STATUS_CHOICES} from '../../constants/sales';

export default function useSaleFilter(version) {
  const intl = useIntl();
  const {eventNamesSelector, getEventNames} = useEventAutocomplete();
  const {getCompetitionNames, competitionNamesSelector} =
    useCompetitionAutocomplete();
  const {getUserNames, userNamesSelector} = useUserAutocomplete();
  const isV2 = useMemo(() => version === 'v2', [version]);

  const filterItems = useMemo(() => {
    const optionTypeChoices = [
      'isTrustableSeller',
      'isCustomDelivery',
      'isPremiumSeller',
      'isFanpassPro',
      'isInstantConfirm',
    ];

    const saleStatusChoices = SALE_FILTER_STATUS_CHOICES;

    const paymentStatusChoices = [
      'pending',
      'paid',
      'refunded',
      'partially-refunded',
    ];

    const ticketTypeChoices = ['e-ticket', 'm-ticket', 'paper', 'membership'];

    const choiceMapper = prefix => item => ({
      label: intl.formatMessage({id: prefix + item}),
      value: item,
    });

    const items = [
      {
        name: 'fullname',
        title: intl.formatMessage({id: 'dashboard.sales.filter.name'}),
        inputType: inputTypes.AUTOCOMPLETE,
        freeSolo: true,
        textFieldProps: {
          onChange: e => getUserNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: userNamesSelector,
        label: false,
      },
      {
        name: 'email',
        title: intl.formatMessage({id: 'dashboard.sales.filter.email_address'}),
        inputType: inputTypes.SEARCH,
      },
      {
        name: 'optionType',
        title: intl.formatMessage({
          id: 'dashboard.sales.filter.seller_features',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: optionTypeChoices.map(choiceMapper('option_types.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'saleHex',
        ...(isV2 ? {outputName: 'saleMetadata.referenceHexa'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.sales_id'}),
        inputType: inputTypes.SEARCH,
      },
      {
        name: 'status',
        ...(isV2 ? {outputName: 'saleMetadata.saleStatus'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.sales_status'}),
        inputType: inputTypes.CHECKBOXES,
        items: saleStatusChoices.map(choiceMapper('status.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'createdAt',
        ...(isV2 ? {outputName: 'createdAt'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.sales_date'}),
        inputType: inputTypes.DATE_SELECT,
      },
      {
        name: 'paymentStatus',
        ...(isV2 ? {outputName: 'saleMetadata.transactionStatus'} : {}),
        title: intl.formatMessage({
          id: 'dashboard.sales.filter.payment_status',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: paymentStatusChoices.map(choiceMapper('status.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'eventName',
        ...(isV2 ? {outputName: 'saleMetadata.eventName'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.event_title'}),
        inputType: inputTypes.AUTOCOMPLETE2,
        freeSolo: true,
        onInputChange: e => getEventNames(e.target.value),
        textFieldProps: {
          onChange: e => getEventNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: eventNamesSelector,
        label: false,
      },
      {
        name: 'eventDate',
        ...(isV2 ? {outputName: 'saleMetadata.eventDate'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.event_date'}),
        inputType: inputTypes.DATE_SELECT,
      },
      {
        name: 'competitionName',
        ...(isV2 ? {outputName: 'saleMetadata.competitionName'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.competition'}),
        inputType: inputTypes.AUTOCOMPLETE,
        freeSolo: true,
        textFieldProps: {
          onChange: e => getCompetitionNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: competitionNamesSelector,
        label: false,
      },
      {
        name: 'ticketType',
        ...(isV2 ? {outputName: 'saleMetadata.ticketType'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.type_ticket'}),
        inputType: inputTypes.CHECKBOXES,
        items: ticketTypeChoices.map(choiceMapper('ticket.type.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'price',
        ...(isV2 ? {outputName: 'totalWithCharges'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.price'}),
        inputType: inputTypes.ADVANCED_NUMBER,
      },
      {
        name: 'hasNote',
        ...(isV2 ? {outputName: 'note'} : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.note'}),
        inputType: inputTypes.RADIO,
        radioProps: {classes: {root: 'w-100'}},
        classes: {checkboxRow: 'checkbox-filter-row'},
        label: '',
        items: [
          {
            value: 1,
            label: intl.formatMessage({id: 'dashboard.sales.filter.has_note'}),
          },
          {
            value: 0,
            label: intl.formatMessage({
              id: 'dashboard.sales.filter.has_not_note',
            }),
          },
        ],
      },
    ];

    return items;
  }, [
    competitionNamesSelector,
    eventNamesSelector,
    getCompetitionNames,
    getEventNames,
    getUserNames,
    intl,
    isV2,
    userNamesSelector,
  ]);

  return {
    filterItems,
  };
}
