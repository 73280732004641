import React, {createRef, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {injectIntl} from 'react-intl';
import {useDropzone} from 'react-dropzone';
import clsx from 'clsx';

/* Image uploading field */
const ImageUpload = props => {
  const {intl, className = '', onChange} = props;
  const dropzoneRef = createRef();

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onChange(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <div {...getRootProps({className: clsx('dropzone', className)})}>
      {/*eslint-disable-next-line*/}
         <div>
        <input {...getInputProps()} />
        <a className="add-file-link" href="#" onClick={openDialog}>
          +&nbsp;{intl.formatMessage({id: 'actions.add_file'})}
        </a>
      </div>
      <Typography className="text-muted font-italic" variant="subtitle2">
        {intl.formatMessage({id: 'actions.drop_upload'})}
      </Typography>
    </div>
  );
};

export default injectIntl(ImageUpload);
