import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useIntl} from 'react-intl';

import Modal from '../Widgets/Modal';

interface ConfirmationModalProps {
  onAccept: () => void;
  onClose: () => void;
  opened: boolean;
  yesLabel?: string;
  noLabel?: string;
  title?: string;
  description?: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {opened, onAccept, onClose, description, yesLabel, noLabel, title} =
    props;
  const intl = useIntl();

  const content: string =
    description ??
    intl.formatMessage({id: 'actions.confirmation_popup.description'});
  const yesText = yesLabel ?? intl.formatMessage({id: 'actions.yes'});
  const noText = noLabel ?? intl.formatMessage({id: 'actions.no'});
  const titleText =
    title ?? intl.formatMessage({id: 'actions.confirmation_popup.areYouSure'});

  return (
    <Modal
      opened={opened}
      width="328px"
      saveTitle={yesText}
      cancelTitle={noText}
      title={titleText}
      onOk={onAccept}
      handleClose={onClose}
    >
      <Typography variant="body2">{content}</Typography>
    </Modal>
  );
};

export default ConfirmationModal;
