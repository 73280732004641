import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';

import auth from './authReducer';
import appReducer from './appReducer';
import snackbarReducer from './snackbarReducer';
import modalReducer from './modalReducer';
import formActionReducer from './formActionReducer';
import saleReducer from './saleReducer';
import permissionReducer from './permissionReducer';
import roleReducer from './roleReducer';
import userPreferencesReducer from './userPreferencesReducer';
import userReducer from './userReducer';
import eventReducer from './eventReducer';
import competitionReducer from './competitionReducer';
import transactionPaymentReducer from './transactionPaymentReducer';
import transactionPayoutReducer from './transactionPayoutReducer';
import userAddressReducer from './userAddress';
import userBankReducer from './userBankReducer';
import userActivityReducer from './userActivityReducer';
import userBalanceReducer from './userBalanceReducer';
import {saleApi} from '../services/saleApi';
import {authApi} from '../services/authApi';
import authReducer, {authSlice} from '../store/slices/authSlice';
import {teamMemberApi} from '../services/teamMemberApi';
import {roleApi} from '../services/roleApi';
import {noteApi} from '../features/Note/noteApi';

/**
 * List of reducers
 */
export default combineReducers({
  form,
  auth,
  appReducer,
  snackbarReducer,
  modalReducer,
  formActionReducer,
  userPreferencesReducer,
  saleReducer,
  permissionReducer,
  roleReducer,
  userReducer,
  eventReducer,
  competitionReducer,
  transactionPaymentReducer,
  transactionPayoutReducer,
  userAddressReducer,
  userBankReducer,
  userActivityReducer,
  userBalanceReducer,
  [saleApi.reducerPath]: saleApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [authSlice.name]: authReducer,
  [teamMemberApi.reducerPath]: teamMemberApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [noteApi.reducerPath]: noteApi.reducer,
});
