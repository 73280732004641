//Ticket preview row
import Typography from '@material-ui/core/Typography';
import MuiIconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import IconButton from '../../Common/IconButton';
import {DownloadIcon, EyeIcon} from '../../../constants/images';

export type AttachmentPreviewRowProps = {
  label: string;
  fileName?: string;
  onDownloadClick?: IconButtonProps['onClick'];
  onPreviewClick?: IconButtonProps['onClick'];
};

const AttachmentPreviewRow = ({
  label,
  fileName,
  onPreviewClick,
  onDownloadClick,
}: AttachmentPreviewRowProps) => (
  <div className="document-preview-row">
    <div className="row gx-2 flex-lg-nowrap">
      <div className="col-lg-3">
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </div>
      <div className="col-lg-9">
        <Typography variant="body2">{fileName}</Typography>
      </div>
      <div className="col-auto text-end ms-auto">
        <IconButton size="sm" variant="action" onClick={onPreviewClick}>
          <EyeIcon style={{fontSize: 12}} />
        </IconButton>
        <MuiIconButton
          size="small"
          style={{marginLeft: 2, color: '#21B59B'}}
          onClick={onDownloadClick}
        >
          <DownloadIcon style={{fontSize: 12}} />
        </MuiIconButton>
      </div>
    </div>
  </div>
);

export default AttachmentPreviewRow;
