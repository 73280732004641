import {handleError, setRequestConfig} from './functions';

export async function get({token, url, ldjson, config}) {
  const options = setRequestConfig({token, ldjson, ...config});
  const response = await fetch(url, options);
  const data = await response.json();

  if (response.status === 200) {
    return data;
  }

  handleError(data, response);
}

export async function put({token, url, body, ldjson}) {
  const options = setRequestConfig({
    token,
    body,
    ldjson,
    method: 'PUT',
  });

  const response = await fetch(url, options);
  const data = await response.json();

  if (response.status === 200) {
    return data;
  }

  handleError(data, response);
}

export async function post({token, url, body, ldjson}) {
  const options = setRequestConfig({
    token,
    body,
    ldjson,
    method: 'POST',
  });

  const response = await fetch(url, options);
  const data = await response.json();

  if (response.status === 201 || response.status === 200) {
    return data['hydra:member'] || data;
  }

  handleError(data, response);
}

export async function deleteItem({token, url, body}) {
  const options = setRequestConfig({method: 'DELETE', token, body});
  const response = await fetch(url, options);

  if (response.status !== 204) {
    handleError(await response.json(), response);
  }
}

const api = {
  get,
  post,
  put,
  deleteItem,
};

export default api;
