import {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatDate} from '../../utils/functions';
import Button from '../../components/Common/Button';
import {EditIconGreen} from '../../constants/images';
import {useTheme} from '@material-ui/styles';
import {NoteEntity} from '../../types/common/NoteEntity';
import {useUpdateNoteMutation} from './noteApi';
import NoteForm, {NoteFormData} from './NoteForm';

export type NoteItemProps = {
  note: NoteEntity;
  onNoteUpdated?: (updatedNote: NoteEntity) => void;
};

const NoteItem = ({note: note1, onNoteUpdated}: NoteItemProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const [updateNote, {data, isSuccess, isError}] = useUpdateNoteMutation();
  const [note, setNote] = useState<NoteEntity>(note1);
  const [noteText, setNoteText] = useState(note?.note);

  const onEditClick = () => {
    setEditing(true);
  };

  const onSubmit = (formValues: NoteFormData) => {
    // update the note text
    setNoteText(formValues.note);
    // back to show mode
    setEditing(false);
    // save changes
    updateNote({
      id: note.id,
      note: formValues.note,
      showProgressDialog: true,
      showErrorMessage: true,
      successMessage: intl.formatMessage({id: 'notes.message.updated'}),
    });
  };

  useEffect(() => {
    if (note1) {
      setNote(note1);
      setNoteText(note1.note);
    }
  }, [note1]);

  useEffect(() => {
    if (isError) {
      // back to original value
      setNoteText(note.note);
    } else if (isSuccess && data) {
      // save note value
      setNoteText(data.note);
      setNote(data);
      if (onNoteUpdated) {
        onNoteUpdated(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, isSuccess]);

  return (
    <Box
      borderBottom={1}
      style={{borderColor: theme?.palette?.text?.border}}
      paddingBottom={1}
    >
      {editing ? (
        <div>
          <NoteForm
            form={`NoteFormEdit-${note.id}`}
            onCancel={() => setEditing(false)}
            initialValues={{note: noteText}}
            onSubmit={onSubmit}
          />
        </div>
      ) : (
        <>
          <Typography
            variant="body2"
            className="mb-1"
            style={{whiteSpace: 'break-spaces'}}
          >
            {noteText}
          </Typography>
          {(note.createdBy || note.updatedBy) && (
            <div className="mb-2">
              {note.updatedAt && note.updatedAt !== note.createdAt ? (
                <>
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id="notes.last_edited" />{' '}
                    {note.updatedBy?.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(
                      note.updatedAt,
                      'DD/MM/YYYY hh:mm a'
                    ).toUpperCase()}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id="notes.created_by" />{' '}
                    {note.createdBy?.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(
                      note.createdAt,
                      'DD/MM/YYYY hh:mm a'
                    ).toUpperCase()}
                  </Typography>
                </>
              )}
            </div>
          )}
          <Button
            small
            link
            primary
            icon={<EditIconGreen />}
            title={intl.formatMessage({id: 'actions.edit'})}
            onClick={onEditClick}
          />
        </>
      )}
    </Box>
  );
};

export default NoteItem;
