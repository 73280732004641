import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import Tabs from '../Common/Tabs';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const TabWidget = props => {
  const {tabs, value, wrapperClass, containerClass, onChange} = props;
  const {history} = props;

  const handleChange = val => {
    history.push(val);
    if (typeof onChange === 'function') {
      onChange(val);
    }
  };

  return (
    <>
      <div className={containerClass}>
        <div className={wrapperClass}>
          <Tabs
            indicators={false}
            value={value || history.location.pathname}
            tabs={tabs}
            onChange={handleChange}
          />
        </div>
      </div>
      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={value || history.location.pathname}
            index={tab.value}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </>
  );
};

export default withRouter(TabWidget);
