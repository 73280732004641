import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash.get';
import getSymbolFromCurrency from 'currency-symbol-map';
import {reduxForm, reset} from 'redux-form';
import cx from 'clsx';
import BigNumber from 'bignumber.js';

import Modal from '../../../Widgets/Modal';
import TotalRefund from './RefundPopupTotalRow';
import {isEmpty} from '../../../../utils/functions';
import {TRANSACTION_REFUND_REASONS} from '../../../../constants/transactions';
import StaticField from '../Fields/StaticField';
import AmountField from '../Fields/AmountField';
import DropdownField from '../Fields/DropdownField';
import TextField from '../Fields/TextField';
import {validateBeforeSubmit} from '../../../../validation/validators';
import * as actionTypes from '../../../../constants/actionTypes';
import {getSellingPrice, roundAmount} from '../../../../utils/saleHelper';
import {useMemo} from 'react';

const validate = (values, props) => {
  const {sale} = props;
  const {
    sellingPriceRefund = 0,
    feesRefund = 0,
    reason,
    reasonText,
  } = values || {};
  const errors = {};

  const emptySellingPrice = !sellingPriceRefund || +sellingPriceRefund === 0;
  const emptyFees = !feesRefund || +feesRefund === 0;
  if (emptyFees && emptySellingPrice) {
    errors.sellingPriceRefund = 'Please provide a refund amount.';
    errors.feesRefund = 'Please provide a refund amount.';
  }

  //Reason is required
  if (isEmpty(reason)) {
    errors.reason = 'This is required';
  }
  //Reason text is required when reason is other
  if (reason === 'other' && isEmpty(reasonText)) {
    errors.reasonText = 'This is required';
  }

  const {
    platformRefundsMaxSellingPrice = 0,
    platformRefundsMaxHandlingFees = 0,
    platformRefundsMaxAmount = 0,
  } = sale ?? {};

  //Check total refunds against max refund available
  const totalRefund = roundAmount(
    BigNumber.sum(sellingPriceRefund, feesRefund).toNumber(),
    2
  );
  if (totalRefund > platformRefundsMaxAmount) {
    errors.totalRefund =
      'The total refund amount can not be greater than the max refund available.';
  }

  //Check selling price refunds against max selling refund available
  if (roundAmount(sellingPriceRefund, 2) > platformRefundsMaxSellingPrice) {
    errors.sellingPriceRefund = `Amount too big! Max is ${platformRefundsMaxSellingPrice}`;
  }

  //Check fees refunds against max fees refund available
  if (roundAmount(feesRefund, 2) > platformRefundsMaxHandlingFees) {
    errors.feesRefund = `Amount too big! Max is ${platformRefundsMaxHandlingFees}`;
  }

  return errors;
};

/* Refund Sale */
let RefundPopup = props => {
  const currency = 'GBP';
  const intl = useIntl();
  const dispatch = useDispatch();

  const {opened, handleClose, handleSubmit, sale} = props;
  const formValues = useSelector(state =>
    get(state, 'form.RefundPopupForm.values', {})
  );

  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [currentCurrency, setCurrentCurrency] = useState('GBP');
  const [currentCurrencySymbol, setCurrentCurrencySymbol] = useState('£');
  const [showReasonText, setShowReasonText] = useState(false);
  const [totalRefundError, setTotalRefundError] = useState(null);

  // This useEffect is use to set information about currency and max price amount
  useEffect(() => {
    // Set the currency code related to this sale
    sale?.ticket?.currency && setCurrentCurrency(sale.ticket.currency);
    // Set the currency symbol related to this sale
    sale?.ticket?.currency &&
      setCurrentCurrencySymbol(getSymbolFromCurrency(sale.ticket.currency));
  }, [sale]);

  // This UseEffect is used to get information from the form
  useEffect(() => {
    const {sellingPriceRefund = 0, feesRefund = 0, reason} = formValues;
    const total = +sellingPriceRefund + +feesRefund;
    if (!Number.isNaN(total)) {
      setTotalRefundAmount(+sellingPriceRefund + +feesRefund);
    }

    setShowReasonText(reason === 'other');
  }, [formValues]);

  /**
   * Function to submit the information to API
   **/
  const submit = async (values, dispatch, props) => {
    const errors = validate(values, props);
    setTotalRefundError(errors.totalRefund);
    validateBeforeSubmit(errors);

    //Create refunds
    const {
      sellingPriceRefund = 0,
      feesRefund = 0,
      reason,
      reasonText,
    } = values || {};
    dispatch({
      type: actionTypes.API_ADMIN_SALE_ADD_REFUND_REQUESTED,
      payload: {
        sale,
        values: {
          sellingAmount: +sellingPriceRefund,
          handlingAmount: +feesRefund,
          reason,
          reasonDescription: reasonText,
        },
      },
    });
  };

  // Clear form and close modal
  const closeModal = () => {
    dispatch(reset('RefundPopupForm'));
    setTotalRefundError(null);
    handleClose();
  };

  //amounts
  const saleSellingPrice = useMemo(
    () => (sale ? getSellingPrice(sale) : 0),
    [sale]
  );
  const numberOptions = useMemo(() => ({style: 'currency', currency}), []);

  if (!sale) {
    return null;
  }

  return (
    <Modal
      width={435}
      opened={opened}
      title={intl.formatMessage({id: 'dashboard.sales.refund.refund'})}
      saveTitle={intl.formatMessage({
        id: 'dashboard.sales.refund.create_refund',
      })}
      handleClose={closeModal}
      onOk={handleSubmit(submit)}
    >
      {opened ? (
        <form className="refund-popup-form">
          {/*Total paid by customer*/}
          <StaticField
            className={'mt-2'}
            title={intl.formatMessage({
              id: 'dashboard.sales.refund.total_paid_customer',
            })}
            value={intl.formatNumber(sale.totalWithCharges, numberOptions)}
          />
          <StaticField
            className={''}
            title={intl.formatMessage({
              id: 'dashboard.sales.refund.selling_price',
            })}
            value={intl.formatNumber(saleSellingPrice, numberOptions)}
          />
          {/*Refund amount*/}
          <AmountField
            className={''}
            title={intl.formatMessage(
              {
                id: 'dashboard.sales.refund.refund_amount',
              },
              {
                amount: intl.formatNumber(
                  sale.platformRefundsMaxSellingPrice,
                  numberOptions
                ),
              }
            )}
            fieldName={'sellingPriceRefund'}
            currency={currentCurrencySymbol}
          />
          {/*Handling fee*/}
          <StaticField
            className=""
            title={intl.formatMessage({
              id: 'dashboard.sales.refund.handling_fee',
            })}
            value={intl.formatNumber(sale.fullHandlingFees, numberOptions)}
          />
          {/*Refund amount*/}
          <AmountField
            className={''}
            title={intl.formatMessage(
              {
                id: 'dashboard.sales.refund.refund_amount',
              },
              {
                amount: intl.formatNumber(
                  sale.platformRefundsMaxHandlingFees,
                  numberOptions
                ),
              }
            )}
            fieldName={'feesRefund'}
            currency={currentCurrencySymbol}
          />
          {/*Reason for refund*/}
          <DropdownField
            containerClass={cx({'mb-3': !showReasonText})}
            title={intl.formatMessage({
              id: 'dashboard.sales.refund.reason_refund',
            })}
            fieldName={'reason'}
            items={TRANSACTION_REFUND_REASONS}
          />
          {/*Text of reason*/}
          <TextField
            fullClassName={cx(
              'row align-items-center justify-content-between mt-2 mb-3',
              {'d-none': !showReasonText}
            )}
            wrapperClassName={'col-lg-7 offset-lg-5'}
            title={''}
            fieldName={'reasonText'}
          />
          {totalRefundError && (
            <div className="submit-error mb-4">{totalRefundError}</div>
          )}
          {/*Refund total*/}
          <TotalRefund
            currency={currentCurrency}
            maxRefundAmount={sale.platformRefundsMaxAmount}
            totalRefundAmount={totalRefundAmount}
          />
        </form>
      ) : null}
    </Modal>
  );
};

// connect component to redux-form
RefundPopup = reduxForm({
  form: 'RefundPopupForm',
  validate,
})(RefundPopup);

export default RefundPopup;
