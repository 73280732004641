import * as PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  BuyerInformationIcon,
  SellerInformationIcon,
} from '../../../constants/images';
import ArrowLink from '../../Common/ArrowLink';
import Avatar from '../../Common/Avatar';
import SellerMarks from './SellerMark';
import useUserInfo from '../../../hooks/useUserInfo';
import useCountryHelper from '../../../hooks/common/useCountryHelper';
import useTicketHelper from '../../../hooks/ticket/useTicketHelper';
import {LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS} from '../../../constants/permissions';
import usePermission from '../../../Auth/usePermission';

const UserInformation = props => {
  const intl = useIntl();
  const {type, address, showAddress} = props;
  const user = Object.keys(props.user ?? {}).length > 0 ? props.user : null;
  const {name, profilePath} = useUserInfo(user);
  const {lineOne, lineTwo, zipcode, city, country} = address ?? {};
  const countryHelper = useCountryHelper();
  const ticketHelper = useTicketHelper();
  const options = ticketHelper.getSellerMarks(user);
  const {hasPermissions} = usePermission();

  return (
    <div className="card details-card sales-details mx-0 h-100">
      <div className="card-header bg-light-orange">
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            <span className="icon-wrapper orange">
              {type === 'seller' && (
                <SellerInformationIcon style={{fontSize: 15}} />
              )}
              {type === 'buyer' && (
                <BuyerInformationIcon style={{fontSize: 17}} />
              )}
            </span>
          </div>
          <div className="col">
            <Typography variant="body2">
              <b>
                {type === 'seller' && (
                  <FormattedMessage id="dashboard.sales.details.seller_information" />
                )}
                {type === 'buyer' && (
                  <FormattedMessage id="dashboard.sales.details.buyer_information" />
                )}
              </b>
            </Typography>
          </div>
        </div>
      </div>
      <div className="card-body ps-lg-4">
        <div className="row">
          <div className="col-auto">
            {user && (
              <Avatar
                color={type === 'seller' ? 'orange' : 'blue'}
                alt={type === 'seller' ? 'S' : 'B'}
                size="xs"
              />
            )}
          </div>
          <div className="col ps-0">
            <div className="d-flex align-items-center mb-1 mt-1">
              {user && (
                <span>
                  <ArrowLink
                    url={
                      hasPermissions(
                        LISTING_USER_DETAILS_PERMISSIONS_HYPERLINKS
                      ) && profilePath
                    }
                    title={name}
                  />
                </span>
              )}
              {/*Marks*/}
              {type === 'seller' && (
                <SellerMarks className="ms-2" items={options} iconSize={15} />
              )}
            </div>
            {/*Phone*/}
            <Typography variant="body2">{user && user.phoneNumber}</Typography>
            {/*Email*/}
            <Typography className="text-break" variant="body2">
              {user && user.email}
            </Typography>
            {/*Address*/}
            {showAddress && (
              <>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-3 mb-2"
                >
                  {type === 'seller' &&
                    intl.formatMessage({
                      id: 'dashboard.sales.details.collection_address',
                    })}
                  {type === 'buyer' &&
                    intl.formatMessage({
                      id: 'dashboard.sales.details.shipping_address',
                    })}
                </Typography>
                <Typography variant="body2">
                  {address && (
                    <>
                      <span className="d-block">{lineOne}</span>
                      <span className="d-block">{lineTwo}</span>
                      <span className="d-block">
                        {zipcode} {city}
                      </span>
                      <span className="d-block">
                        {countryHelper.getCountryName(country)}
                      </span>
                    </>
                  )}
                  {!address && (
                    <FormattedMessage id="dashboard.sales.details.not_provided" />
                  )}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UserInformation.propTypes = {
  user: PropTypes.object,
  type: PropTypes.oneOf(['buyer', 'seller']),
  address: PropTypes.object,
};

export default UserInformation;
