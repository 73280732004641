import React, {Fragment} from 'react';
import {injectIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import DataTable from '../Widgets/DataTable';
import * as urls from '../../constants/urls';
import StateIndicator from '../Common/StateIndicator';
import Filter from '../Widgets/Filter';

/* User List - Buyer/User */
const UserListBuyer = ({intl, filterOpened, onFilterClose}) => {
  //Router
  const history = useHistory();

  // Header cells items
  const HeaderCells = [
    {
      name: 'user',
      label: intl.formatMessage({id: 'dashboard.users.name'}),
      renderValue: v => <div className="ps-1">{v}</div>,
      collapse: false,
    },
    {
      name: 'country',
      label: intl.formatMessage({id: 'dashboard.users.country'}),
      collapse: true,
    },
    {
      name: 'date',
      label: intl.formatMessage({id: 'dashboard.users.creation_date'}),
      collapse: true,
    },
    {
      name: 'status',
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      renderValue: status => <StateIndicator {...status} />,
      collapse: false,
    },
  ];

  const rows = [
    {
      id: 1,
      user: 'Lachlan Donald',
      country: 'United Kingdom',
      date: '23 Nov 2019',
      status: {label: 'Active', color: 'green'},
    },
    {
      id: 2,
      user: 'Kieren Ira Zimmerman',
      country: 'United Kingdom',
      date: '11 Oct 2019',
      status: {label: 'Expired', color: 'danger'},
    },
    {
      id: 3,
      user: 'Abdul Reid',
      country: 'United Kingdom',
      date: '28 Dec 2019',
      status: {label: 'Suspended', color: 'secondary'},
    },
    {
      id: 4,
      user: 'Wilma Rogers',
      country: 'United Kingdom',
      date: '28 Dec 2019',
      status: {label: 'Pending', color: 'warning'},
    },
  ];

  const filterItems = [
    {
      name: 'user',
      title: intl.formatMessage({id: 'dashboard.users.user_name'}),
      inputType: 'autocomplete',
      subitems: ['Example name1', 'tExt2', 'some_value'],
    },
    {
      name: 'phone',
      title: intl.formatMessage({id: 'dashboard.users.phone_number'}),
      inputType: 'autocomplete',
      subitems: ['0123456', '78912345', '0000'],
    },
    {
      name: 'email',
      title: intl.formatMessage({id: 'dashboard.users.email_address'}),
      inputType: 'autocomplete',
      subitems: ['test@email.com', 'aaa@bbb.ccc'],
    },
    {
      name: 'creation_date',
      title: intl.formatMessage({id: 'dashboard.users.creation_date'}),
      inputType: 'date_between',
    },
    {
      name: 'status',
      title: intl.formatMessage({id: 'dashboard.users.status'}),
      inputType: 'checkboxes',
      subitems: [
        {
          label: intl.formatMessage({id: 'dashboard.status.active'}),
          value: 'active',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.pending'}),
          value: 'pending',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.failed'}),
          value: 'failed',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.expired'}),
          value: 'expired',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.cancelled'}),
          value: 'cancelled',
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.suspended'}),
          value: 'suspended',
        },
      ],
    },
  ];

  const handleRowClick = itemId => {
    history.push(urls.USER_DETAILS_PATH.replace(':itemId', '0'));
  };

  return (
    <Fragment>
      <Filter
        opened={filterOpened}
        items={filterItems}
        onClose={onFilterClose}
      />
      <DataTable
        className="user-list"
        canSelect={false}
        empty={false}
        loading={false}
        onRowClick={itemId => handleRowClick(itemId)}
        headCells={HeaderCells.map(item => ({
          ...item,
          numeric: false,
          disablePadding: false,
        }))}
        rows={rows}
        onChange={() => {}}
        total={100}
        currentPage={1}
      />
    </Fragment>
  );
};

export default injectIntl(UserListBuyer);
