import {useMemo} from 'react';
import {useCallback} from 'react';
import {SELLER_MARKS} from '../constants/enums';
import {getResourceId} from '../utils/functions';

/**
 * React hook to get user related information
 *
 * @param {*} user  User Object
 * @return Object   Object containing the following keys: name, initials
 */
export default function useUserHelper() {
  const getName = useCallback(user => {
    let name = '';
    if (user) {
      name = [user.firstname, user.lastname].filter(v => v).join(' ');
    }
    return name;
  }, []);

  const getUserLink = useCallback(user => {
    if (user) {
      return `/admin-dashboard/users/${getResourceId(user)}`;
    }
    return '#';
  }, []);

  const getSellerMarks = useCallback(seller => {
    const marks = [];
    const map = {
      isTrustableSeller: SELLER_MARKS.MARK_TRUSTED,
      isCustomDelivery: SELLER_MARKS.MARK_DELIVERY,
      isPremiumSeller: SELLER_MARKS.MARK_PREMIUM,
      isFanpassPro: SELLER_MARKS.MARK_PRO,
      isInstantConfirm: SELLER_MARKS.MARK_INSTANT,
      // For old list endpoint support
      // TODO: remove when list endpoint use camelCase for object properties name
      is_trustable_seller: SELLER_MARKS.MARK_TRUSTED,
      is_custom_delivery: SELLER_MARKS.MARK_DELIVERY,
      is_premium_seller: SELLER_MARKS.MARK_PREMIUM,
      is_fanpass_pro: SELLER_MARKS.MARK_PRO,
      is_instant_confirm: SELLER_MARKS.MARK_INSTANT,
    };

    for (const prop in map) {
      if (seller && seller[prop]) {
        marks.push(map[prop]);
      }
    }

    return marks;
  }, []);

  const res = useMemo(
    () => ({
      getName,
      getUserLink,
      getSellerMarks,
    }),
    [getName, getUserLink, getSellerMarks]
  );

  return res;
}
