import React from 'react';
import {injectIntl} from 'react-intl';
import {reduxForm, Field} from 'redux-form';
import InputField from '../Form/Fields/InputField';
import SelectField from '../Form/Fields/SelectField';
import Modal from '../Widgets/Modal';
import IconButton from '../Common/IconButton';
import {ArrowForwardIcon} from '../../constants/images';

/* Add competition popup */
let CompetitionDetailsPopup = props => {
  const {intl, opened, handleClose, handleSubmit} = props;

  const submit = () => {};

  return (
    <Modal
      width={328}
      opened={opened}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'dashboard.events.list.competition_tab.details.title_add_competition',
      })}
      saveTitle={intl.formatMessage({id: 'actions.add'})}
    >
      <form onSubmit={handleSubmit(submit)}>
        <div className="row row-cols-1 g-fan-25 mb-2">
          <div className="col">
            {/*Competition name*/}
            <Field
              name="name"
              component={InputField}
              outlined
              label={`${intl.formatMessage({id: 'dashboard.events.list.competition_tab.competition_name'})}*`}
              externalLabel
            />
          </div>
          <div className="col">
            {/*Sport type*/}
            <Field
              name="type"
              component={SelectField}
              outlined
              label={`${intl.formatMessage({id: 'dashboard.events.list.competition_tab.details.sport_type'})}*`}
              externalLabel
              items={[
                {label: 'Footbal', value: '0'},
                {label: 'Rugby', value: '1'},
                {label: 'Tennis', value: '2'},
              ]}
            />
          </div>
          <div className="col">
            {/*Landing page URL*/}
            <Field
              name="url"
              component={InputField}
              outlined
              label={intl.formatMessage({
                id: 'dashboard.events.list.competition_tab.details.landing_page_url',
              })}
              externalLabel
              addon={
                <IconButton size="xs" variant="action">
                  <ArrowForwardIcon style={{fontSize: 8}} />
                </IconButton>
              }
              addonPosition="end"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

const validate = values => {
  const errors = {};
  return errors;
};

// connect component to redux-form
CompetitionDetailsPopup = reduxForm({
  form: 'CompetitionDetailsForm',
  validate,
})(CompetitionDetailsPopup);

export default injectIntl(CompetitionDetailsPopup);
