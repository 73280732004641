import React, {ChangeEvent, useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, WithWidth} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import Tabs from '@material-ui/core/Tabs';
import Tab, {TabProps} from '@material-ui/core/Tab';
import IndicatorValue from './IndicatorValue';
import {TabsProps} from '@material-ui/core/Tabs/Tabs';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: 23,
      marginLeft: -20,
      marginRight: 0,
      '& .MuiTabs-scroller': {
        overflowX: 'auto !important',
      },
    },
  },
  indicator: {
    height: 3,
  },
  tab: {
    textAlign: 'left',
    color: theme.palette.secondary.light,
    padding: '11px 0 13px 0',
    minWidth: 'auto',
    marginRight: 40,
    '&.Mui-selected': {
      outline: 'none',
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
    '&.default': {
      minWidth: 'auto',
      padding: '7px 0px 0 0px',
      marginRight: 20,
      [theme.breakpoints.down('lg')]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    '&.Mui-selected .MuiTab-wrapper': {
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold !important',
      alignItems: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0 16px',
      padding: '15px 0 9px 0',
      '&.Mui-selected .MuiTab-wrapper': {
        fontWeight: 'normal',
      },
      '&.default': {
        minWidth: 92,
      },
    },
  },
  wrapper: {
    fontWeight: 'normal',
  },
  labelIcon: {
    minHeight: 'unset',
    '& .MuiTab-wrapper': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .MuiChip-root': {
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
      marginBottom: '0 !important',
    },
  },
}));

type CustomTabsProps = Omit<TabsProps, 'className' | 'children'> &
  WithWidth & {
    className?: string;
    indicators?: boolean;
    value: string;
    tabs: {
      label: string;
      value: any;
      number: any;
      disabled?: boolean;
      dataTestId?: string;
    }[];
    onChange: (value: any) => void;
  };

const CustomTabs = (props: CustomTabsProps) => {
  const {
    className = '',
    tabs,
    value,
    indicators = true,
    onChange,
    ...others
  } = props;
  //current selected tab
  const [currentValue, setCurrentValue] = useState(value);

  const classes = useStyles();

  //Tab change handler
  const handleChange = (e: ChangeEvent<{}>, newValue: string) => {
    setCurrentValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    //change priority of firing this event
    setTimeout(() => window.dispatchEvent(new CustomEvent('resize')));
  }, []);

  return (
    <Tabs
      className={className}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      variant={isWidthUp('lg', props.width) ? 'standard' : 'fullWidth'}
      value={currentValue}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}
      {...others}
    >
      {/* Render tab element */}
      {tabs.map((item, key) => (
        <Tab
          classes={{
            root: clsx(
              classes.tab,
              !indicators && 'default',
              item.disabled && 'disabled'
            ),
            wrapper: classes.wrapper,
            labelIcon: classes.labelIcon,
          }}
          key={`${key}-${value}`}
          label={item.label}
          {...(indicators && {
            icon: (
              <IndicatorValue
                value={item.number.toString()}
                variant={item.value === value ? 'primary' : 'secondary'}
              />
            ) as TabProps['icon'],
          })}
          value={item.value}
          {...(item.dataTestId && {'data-testid': item.dataTestId})}
        />
      ))}
    </Tabs>
  );
};

// @ts-ignore
export default withWidth()(CustomTabs);
