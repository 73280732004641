import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  userNames: [],
  user: null,
  existingUser: null,
  loaded: false,
};

export default function userReducer(state = defaultState, action) {
  const {type, payload} = action;
  switch (type) {
    case actionTypes.API_USER_AUTOCOMPLETE_REQUESTED:
      return {
        ...state,
        userNames: [],
      };
    case actionTypes.API_USER_AUTOCOMPLETE_SUCCEEDED:
      return {
        ...state,
        userNames: payload.userNames,
      };
    case actionTypes.API_ADMIN_USER_DETAIL_REQUESTED:
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_REQUESTED:
    case actionTypes.API_ADMIN_SUSPEND_USER_REQUESTED:
    case actionTypes.API_ADMIN_UNSUSPEND_USER_REQUESTED:
    case actionTypes.API_ADMIN_SEND_GUEST_EMAIL_REQUESTED:
    case actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED:
    case actionTypes.API_ADMIN_DELETE_USER_REQUESTED:
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_REQUESTED:
      return {
        ...state,
        loaded: true,
      };
    case actionTypes.API_ADMIN_USER_DETAIL_FAILED:
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_FAILED:
    case actionTypes.API_ADMIN_SUSPEND_USER_FAILED:
    case actionTypes.API_ADMIN_UNSUSPEND_USER_FAILED:
    case actionTypes.API_ADMIN_SEND_GUEST_EMAIL_FAILED:
    case actionTypes.API_ADMIN_SEND_GUEST_EMAIL_SUCCEEDED:
    case actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_FAILED:
    case actionTypes.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_SUCCEEDED:
    case actionTypes.API_ADMIN_DELETE_USER_FAILED:
    case actionTypes.API_ADMIN_DELETE_USER_SUCCEEDED:
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_FAILED:
      return {
        ...state,
        loaded: false,
      };
    case actionTypes.API_ADMIN_USER_DETAIL_SUCCEEDED:
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_SUCCEEDED:
    case actionTypes.API_ADMIN_SUSPEND_USER_SUCCEEDED:
    case actionTypes.API_ADMIN_UNSUSPEND_USER_SUCCEEDED:
      return {
        ...state,
        user: payload.user,
        loaded: false,
      };
    case actionTypes.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_SUCCEEDED:
      return {
        ...state,
        existingUser: payload.existingUser,
        loaded: false,
      };
    case actionTypes.API_ADMIN_USER_LIST_SUCCEEDED:
      return {
        ...state,
        adminUsers: payload.data['hydra:member'],
      };
    default:
      return state;
  }
}
