import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import {useIntl} from 'react-intl';

//Indicator style
const useStyles = makeStyles(theme => ({
  sizeSmall: {
    height: 20,
  },
  labelSmall: {
    fontSize: 12,
    lineHeight: '20px',
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.palette.secondary.light,
  },
  colorPrimary: {
    backgroundColor: theme.palette.green.main,
    '& .MuiChip-labelSmall': {
      color: theme.palette.background.paper,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}));

/**
 * Indicator default value, clickable when it's not default
 * @param isDefault
 * @param onClick
 * @returns {*}
 * @constructor
 */
const IndicatorDefault = ({isDefault, onClick = () => {}, ...others}) => {
  //Translations
  const intl = useIntl();

  //styles
  const classes = useStyles();

  return (
    //Show default indicator
    isDefault ? (
      <Chip
        classes={classes}
        color="primary"
        size="small"
        label={intl.formatMessage({
          id: 'dashboard.user_details.tab_address.default',
        })}
        {...others}
      />
    ) : (
      //Show 'set as default' button
      <Chip
        classes={classes}
        variant="outlined"
        clickable
        size="small"
        label={intl.formatMessage({
          id: 'dashboard.user_details.tab_address.set_as_default',
        })}
        onClick={onClick}
        {...others}
      />
    )
  );
};

IndicatorDefault.propTypes = {
  /**
   * TRUE if indicator shows Default value
   */
  isDefault: PropTypes.bool,
  /**
   * Set as default handler
   */
  onClick: PropTypes.func,
};

export default IndicatorDefault;
