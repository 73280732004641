import React, {Fragment, useState} from 'react';
import {injectIntl} from 'react-intl';
import SearchForm from '../Form/SearchForm';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '../Common/IconButton';
import {FILTER_ICON, PlusRoundedDangerIcon} from '../../constants/images';
import DataTable from '../Widgets/DataTable';
import Filter from '../Widgets/Filter';
import ArrowLink from '../Common/ArrowLink';
import ArtistDetailsPopup from './ArtistDetailsPopup';

const rows = [
  {
    id: '0',
    name: 'Artist name',
    url: 'https://www.websiteurl.com',
    date: '23 Nov 2019',
  },
  {
    id: '1',
    name: 'Artist name',
    url: 'https://www.websiteurl.com',
    date: '23 Nov 2019',
  },
  {
    id: '2',
    name: 'Artist name',
    url: null,
    date: '28 Dec 2019',
  },
  {
    id: '3',
    name: 'Artist name',
    url: 'https://www.websiteurl.com',
    date: '20 Sep 2019',
  },
];

/* Events list - tab Artist */
const ListArtistTab = ({intl, addPopup, onAddClick, closeAddPopup}) => {
  // Header cells items
  const HeaderCells = [
    {
      name: 'name',
      disablePadding: true,
      label: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.artist_name',
      }),
      collapse: false,
      width: '25%',
      renderValue: title => (
        <Typography variant="body2" className="ps-1">
          {title}
        </Typography>
      ),
    },
    {
      name: 'url',
      label: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.wp_url',
      }),
      collapse: true,
      width: '25%',
      renderValue: url => (url ? <ArrowLink title={url} url={url} /> : '--'),
    },
    {
      name: 'date',
      label: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.created_date',
      }),
      collapse: false,
      width: '50%',
    },
  ];

  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);

  const filterItems = [
    {
      name: 'name',
      title: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.artist_name',
      }),
      inputType: 'autocomplete',
      subitems: ['FirstName LastName', 'Title'],
    },
    {
      name: 'url',
      title: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.wp_url',
      }),
      inputType: 'autocomplete',
      subitems: ['Url1', 'Url2'],
    },
    {
      name: 'date',
      title: intl.formatMessage({
        id: 'dashboard.events.list.artist_tab.created_date',
      }),
      inputType: 'date_between',
    },
  ];

  return (
    <Fragment>
      {/*Add Artist Popup*/}
      <ArtistDetailsPopup opened={addPopup} handleClose={closeAddPopup} />
      <div className="dashboard-content">
        <div className="row align-items-center justify-content-between search-row pb-1">
          <div className="col-xl-4 col-lg-8 d-flex">
            {/* Search */}
            <SearchForm
              dispatchAction={true}
              delay={180}
              SearchProps={{
                buttonProps: {
                  onClick: () => {},
                },
              }}
            />
            {/* Filter Button on mobile view only */}
            <Box ml={1} className="d-block d-lg-none">
              <IconButton className="m-0" onClick={() => setFilterOpened(true)}>
                {FILTER_ICON}
              </IconButton>
            </Box>
          </div>
          {/* Button toolbar */}
          <div className="col-auto d-none d-lg-block">
            <div className="d-flex">
              {/* Filter button */}
              <IconButton className="m-0" onClick={() => setFilterOpened(true)}>
                {FILTER_ICON}
              </IconButton>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* Filter on the right side */}
            <Filter
              opened={filterOpened}
              items={filterItems}
              onClose={() => setFilterOpened(false)}
            />
            {/*Table*/}
            <DataTable
              className="teams-table"
              canSelect={false}
              empty={false}
              loading={false}
              headCells={HeaderCells.map(item => ({
                ...item,
                numeric: false,
                disablePadding: false,
              }))}
              actionButton={{
                variant: 'filled',
                size: 'sm',
                children: <PlusRoundedDangerIcon />,
                onClick: onAddClick,
              }}
              rows={rows}
              onChange={() => {}}
              total={100}
              currentPage={1}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(ListArtistTab);
