import React from 'react';
import {injectIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import InputField from '../Form/Fields/InputField';
import HintTooltip from '../Common/HintTooltip';

const validate = (values, {intl}) => {
  const errors = {};
  return errors;
};

/* Marketplace fee */
let MarketplaceFeeSettings = props => {
  const {intl} = props;

  return (
    <div className="dashboard-wrapper settings marketplace-fee">
      <div className="container ms-lg-0">
        <div className="header-row pb-lg-4 text-center text-lg-start">
          <Typography variant="h3" className="font-weight-bolder">
            {intl.formatMessage({id: 'dashboard.settings.marketplace_fee'})}
          </Typography>
        </div>
        <div className="row pt-2">
          <div className="col-lg-7">
            <div className="card details-card mx-0">
              <div className="card-body">
                {/*Headers*/}
                <Box pb={1.3}>
                  <div className="row gx-2">
                    <div className="col-7 col-lg-8">
                      <Typography variant="body2">
                        <b>
                          {intl.formatMessage({
                            id: 'dashboard.marketplace_fee.transaction_plan',
                          })}
                        </b>
                      </Typography>
                    </div>
                    <div className="col-5 col-lg-4">
                      <Typography variant="body2">
                        <b>
                          {intl.formatMessage({
                            id: 'dashboard.marketplace_fee.value',
                          })}
                        </b>
                      </Typography>
                    </div>
                  </div>
                </Box>
                {/*Commission rate*/}
                <div className="row gx-2 align-items-center mb-1">
                  <div className="col-7 col-lg-8">
                    <div className="d-flex align-items-center">
                      <Typography variant="body2" component="span">
                        {intl.formatMessage({
                          id: 'dashboard.marketplace_fee.commission_rate',
                        })}
                      </Typography>
                      <span className="ms-1 pt-lg-1">
                        <HintTooltip
                          id="commission_rate"
                          content="Commission rate"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-5 col-lg-4">
                    <Field
                      name="rate"
                      component={InputField}
                      addon="%"
                      addonPosition="end"
                      outlined
                    />
                  </div>
                </div>
                {/*Fixed commission fee*/}
                <div className="row gx-2 align-items-center pt-2 mb-1">
                  <div className="col-7 col-lg-8">
                    <div className="d-flex align-items-center">
                      <Typography variant="body2" component="span">
                        {intl.formatMessage({
                          id: 'dashboard.marketplace_fee.fixed_commission_fee',
                        })}
                      </Typography>
                      <span className="ms-1 pt-lg-1">
                        <HintTooltip
                          id="commission_rate"
                          content="Fixed commission fee"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-5 col-lg-4">
                    <Field
                      name="rate"
                      component={InputField}
                      outlined
                      addon="£"
                      addonPosition="start"
                    />
                  </div>
                </div>
                {/*Shipping fee markup*/}
                <div className="row gx-2 align-items-center pt-2">
                  <div className="col-7 col-lg-8">
                    <div className="d-flex align-items-center">
                      <Typography variant="body2" component="span">
                        {intl.formatMessage({
                          id: 'dashboard.marketplace_fee.shipping_fee_markup',
                        })}
                      </Typography>
                      <span className="ms-1 pt-lg-1">
                        <HintTooltip
                          id="commission_rate"
                          content="Shipping fee markup"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-5 col-lg-4">
                    <Field
                      name="rate"
                      component={InputField}
                      outlined
                      addon="%"
                      addonPosition="end"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MarketplaceFeeSettings.propTypes = {};

MarketplaceFeeSettings = reduxForm({
  form: 'MarketplaceFeeSettingsForm',
  enableReinitialize: true,
  validate,
})(MarketplaceFeeSettings);

const mapStateToProps = state => {
  return {
    initialValues: {},
  };
};

export default connect(mapStateToProps)(injectIntl(MarketplaceFeeSettings));
