import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {Navbar, Nav, NavItem, NavbarBrand} from 'reactstrap';
import {NavLink, useLocation} from 'react-router-dom';
import AvatarMenu from './AvatarMenu';
import Button from '../Common/Button';
import IconButton from '../Common/IconButton';
import clsx from 'clsx';
import menus from '../../constants/menus';
import IMG_LOGO from '../../assets/images/Admin_Logo.svg';
import {OLD_ADMIN_PATH} from '../../constants/urls';
import usePermission from '../../Auth/usePermission';

/**
 * Left menu (shows logo and admin part urls)
 * @param props
 * @returns {*}
 * @constructor
 */
const MenuLeft = props => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {filterMenusByPermissions} = usePermission();
  const menuItems = filterMenusByPermissions(menus);
  //view data toggler state
  const [viewDataOn, setViewDataOn] = useState(true);

  //view data toggler callback
  const toggleViewData = () => setViewDataOn(!viewDataOn);

  //Check is the menu is active
  const isActive = item => {
    return pathname.includes(item.path);
  };

  return (
    <Navbar className="d-none d-lg-flex flex-nowrap">
      <Nav navbar vertical>
        <div className="d-flex justify-content-between align-items-center pb-4 mb-1">
          <NavbarBrand href="/" onClick={e => e.stopPropagation()}>
            <img src={IMG_LOGO} alt="" />
          </NavbarBrand>
          <AvatarMenu viewDataOn={viewDataOn} viewDataChange={toggleViewData} />
        </div>
        {menuItems.map((item, index) => (
          <NavItem active={isActive(item)} key={index}>
            <NavLink
              className={clsx(
                'nav-link text-decoration-none',
                isActive(item) && 'active'
              )}
              activeClassName="active"
              to={item.path}
            >
              <div className="d-flex align-items-center">
                <span className="icon-wrapper">
                  <i className={clsx('menu-icon', item.name)} />
                </span>
                <span>
                  <FormattedMessage id={`dashboard.menu.${item.name}`} />
                </span>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {/*Back to old admin button*/}
      <div className="pt-2">
        <a className="hidden-link" href={OLD_ADMIN_PATH}>
          <Button
            link
            icon={<KeyboardBackspaceIcon />}
            title={intl.formatMessage({id: 'actions.backOldAdmin'})}
            className="d-none d-xl-inline-flex text-white"
          />
          <IconButton
            size="xs"
            variant="filled"
            className="d-xl-none text-white"
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </a>
      </div>
    </Navbar>
  );
};

export default MenuLeft;
