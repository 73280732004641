/**
 * Enum for custom attribute types
 *
 * @global
 * @readonly
 * @enum {string}
 */
export const customAttributeType = {
  BOOLEAN: 'boolean',
  NUMERIC: 'numeric',
  TEXT_FIELD: 'text-field',
  EMAIL: 'email',
  IMAGE: 'image',
  DATE: 'date',
  TIME: 'time',
  DATE_TIME: 'date-time',
  OBJECT: 'object',
  DROP_LIST: 'drop-list',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  COLLECTION: 'collection',
  COMBINATION: 'combination',
  TEXTAREA: 'text-area',
};

/**
 * Enum for `DynamicField` inputType
 *
 * @global
 * @readonly
 * @enum {string}
 */
export const inputTypes = {
  TEXT: 'text',
  SEARCH: 'search',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  CHECKBOXES: 'checkboxes',
  RADIO: 'radio',
  IMAGE: 'image',
  TIME: 'time',
  DATEPICKER: 'datepicker',
  DATE_TIME: 'date_time',
  DATE_BETWEEN: 'date_between',
  DATE_SELECT: 'date_select',
  CUSTOM_OBJECT: 'custom_object',
  COLLECTION: 'collection',
  TEXTAREA: 'textarea',
  ADVANCED_NUMBER: 'advanced_number',
  AUTOCOMPLETE: 'autocomplete',
  AUTOCOMPLETE2: 'autocomplete2',
};

/**
 * Available custom attribute permissions
 * @remark : The values here are used throughout the application
 *  please be careful when changing the values here
 * @readonly
 * @enum {string}
 */
export const customAttributePermissions = {
  VISIBILITY: 'visibility',
  UPDATABILITY: 'updatability',
};

//Types of marks of seller
export const SELLER_MARKS = {
  MARK_TRUSTED: 'MARK_TRUSTED',
  MARK_DELIVERY: 'MARK_DELIVERY',
  MARK_PREMIUM: 'MARK_PREMIUM',
  MARK_PRO: 'MARK_PRO',
  MARK_INSTANT: 'MARK_INSTANT',
};

//Charts colors
export const CHART_COLORS = {
  red: '#E00058',
  orange: '#FF8145',
  orange2: '#FF7575',
  green: '#21B59B',
  blue: '#3385CC',
  grey: '#9AA5B1',
};

export const PAYOUT_STATUSES = {
  CREATED: {
    value: 'CREATED',
    color: '#FF8145',
  },
  pending: {
    value: 'pending',
    color: '#FF8145',
  },
  'on-going': {
    value: 'on-going',
    color: '#FF8145',
  },
  'balance-due': {
    value: 'balance-due',
    color: '#FF8145',
  },
  paused: {
    value: 'paused',
    color: '#9AA5B1',
  },
  'in-transit': {
    value: 'in-transit',
    color: '#FF8145',
  },
  failed: {
    value: 'failed',
    color: '#EE2737',
  },
  'paid-out': {
    value: 'paid-out',
    color: '#21B59B',
  },
  refunded: {
    value: 'refunded',
    color: '#BA688C',
  },
  completed: {
    value: 'completed',
    color: '#21B59B',
  },
  manual: {
    value: 'manual',
    color: '#FF0000',
  },
  unpaid: {
    value: 'unpaid',
    color: '#9AA5B1',
  },
};
