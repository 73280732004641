import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useIntl} from 'react-intl';
import Select from '../../Common/Select';
import DatePicker from '../Inputs/DatePicker';
import BaseInput from '../../Common/BaseInput';
import {FormHelperText} from '@material-ui/core';

/**
 * Redux-form DateSelectField
 * Render a select field for type and other fields depending on field type
 * - "is in between" render two date pickers
 * - "is in the last" render one input (for number) and a select for period (days, weeks, months)
 * Redux-form `onChange` is fired with a javascript object with the following keys depending on the selected type
 * - `{ from: string, to: string }` : date string for "is in between"
 * - `{ period: string, periodNumber: string }` : for "is in the last"
 *
 * @remarks: in case the onChange value keys (from, to, period, periodNumber) have to be changed,
 *             make sure to check everywhere where the component values if used
 *
 * @param {Object} props
 */
const DateSelectField = props => {
  const {
    displayFormat,
    dateFormat,
    input: {value, onChange},
    meta,
  } = props;
  const intl = useIntl();
  const {touched, error} = meta;

  /**
   * the select type, Possible value : 'between', 'last'
   */
  const [type, setType] = useState(null);
  const [period, setPeriod] = useState('days');
  const [periodNumber, setPeriodNumber] = useState(null);
  const [valueFrom, setValueFrom] = useState(null);
  const [valueTo, setValueTo] = useState(null);

  // Format date from iFormat to fFormat
  const formatDate = (date, iFormat, fFormat, defaultValue = null) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  const handleDatePickerChange = (val, inputKey) => {
    if (inputKey === 'from') {
      setValueFrom(val);
    } else if (inputKey === 'to') {
      setValueTo(val);
    }
    onChange({
      ...value,
      [inputKey]: formatDate(val, displayFormat, dateFormat),
      type,
    });
  };

  const getLastTypeValue = () => {
    if (period && periodNumber) {
      return {period, periodNumber, type};
    }
    return null;
  };

  const getBetweenTypeValue = (from, to) => {
    if (from && to) {
      return {
        from,
        to,
        type,
      };
    }
    return null;
  };

  useEffect(() => {
    const {from, to, period, periodNumber, type: initialType} = value ?? {};

    // If value is not provided, form is empty, then reset all states to null
    if (!value) {
      setType(null);
      setValueFrom(null);
      setValueTo(null);
      setPeriodNumber(null);
    }

    if (!type && initialType) {
      setType(initialType);
    }

    switch (initialType) {
      case 'between':
        setValueFrom(formatDate(from, dateFormat, displayFormat));
        setValueTo(formatDate(to, dateFormat, displayFormat));
        break;
      case 'last':
        setPeriodNumber(periodNumber);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (type === 'between') {
      onChange(getBetweenTypeValue(valueFrom, valueTo) ?? {});
    } else if (type === 'last') {
      onChange(getLastTypeValue() ?? {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handlePeriodChange = (value, key) => {
    if (key === 'period') {
      setPeriod(value);
    } else {
      setPeriodNumber(value);
    }
    onChange({
      period,
      periodNumber,
      [key]: value,
      type,
    });
  };

  return (
    <div className="date-selects-wrapper d-flex flex-column">
      <Select
        placeholder={intl.formatMessage({id: 'dashboard.filters.date_range'})}
        items={[
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_between'}),
            value: 'between',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_last'}),
            value: 'last',
          },
        ]}
        value={type}
        onChange={newValue => setType(newValue)}
      />
      {type === 'between' && (
        <>
          {/* In between variant' inputs */}
          <div className="in-between-inputs">
            {/* Date From */}
            <DatePicker
              value={valueFrom}
              format={displayFormat}
              placeholder={intl.formatMessage({id: 'actions.from'})}
              onChange={value => handleDatePickerChange(value, 'from')}
              {...(valueTo ? {maxDate: valueTo} : {})}
            />
            {/* Date To */}
            <DatePicker
              value={valueTo}
              format={displayFormat}
              placeholder={intl.formatMessage({id: 'actions.to'})}
              onChange={value => handleDatePickerChange(value, 'to')}
              {...(valueFrom ? {minDate: valueFrom} : {})}
            />
          </div>
        </>
      )}
      {type === 'last' && (
        <>
          {/* in the last variant' inputs */}
          <div className="last-days-inputs d-flex align-items-center">
            <BaseInput
              className="days-input"
              value={periodNumber}
              type="number"
              onChange={event =>
                handlePeriodChange(event.target.value, 'periodNumber')
              }
            />
            <div className="flex-grow-1">
              <Select
                placeholder={intl.formatMessage({id: 'dashboard.filters.days'})}
                items={[
                  {
                    label: intl.formatMessage({id: 'dashboard.filters.days'}),
                    value: 'days',
                  },
                  {
                    label: intl.formatMessage({id: 'dashboard.filters.weeks'}),
                    value: 'weeks',
                  },
                  {
                    label: intl.formatMessage({id: 'dashboard.filters.months'}),
                    value: 'months',
                  },
                ]}
                value={period}
                onChange={newPeriod => handlePeriodChange(newPeriod, 'period')}
              />
            </div>
          </div>
        </>
      )}
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
};

DateSelectField.defaultProps = {
  dateFormat: 'YYYY-MM-DD',
};

export default DateSelectField;
