import {useDispatch} from 'react-redux';
import {ENQUEUE_SNACKBAR} from '../../constants/actionTypes';

export default function useSnackBar() {
  const dispatch = useDispatch();

  const showMessage = (message, options) => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        message: message,
        key: new Date().getTime() + Math.random(),
        options,
      },
    });
  };

  return {
    showMessage,
  };
}
