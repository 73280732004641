import React from 'react';
import {injectIntl} from 'react-intl';
import {Field, reduxForm} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import Modal from '../Widgets/Modal';
import clsx from 'clsx';
import InputField from '../Form/Fields/InputField';
import HintTooltip from '../Common/HintTooltip';

const validate = () => {
  const errors = {};
  return errors;
};

/* Payout popup */
let PayoutPopup = ({intl, opened, handleClose, handleSubmit}) => {
  const submit = values => {};

  const printRow = (className = '', title, value, edit, fieldName) => (
    <div
      className={clsx(
        'row align-items-center justify-content-between',
        className
      )}
    >
      <div className={clsx(edit ? 'col-12 mb-2' : 'col')}>
        <Typography
          variant={edit ? 'subtitle2' : 'body2'}
          color={edit ? 'textSecondary' : 'textPrimary'}
        >
          {title}
        </Typography>
      </div>
      <div className={clsx(edit ? 'col-12' : 'col-auto')}>
        {edit ? (
          <div className="edit-wrapper">
            <Field
              name={fieldName}
              component={InputField}
              outlined
              addon="£"
              addonPosition="start"
            />
          </div>
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      width={328}
      opened={opened}
      title={intl.formatMessage({
        id: 'dashboard.sales.pay_out_bank.pay_out_bank',
      })}
      saveTitle={intl.formatMessage({
        id: 'dashboard.sales.pay_out_bank.payout',
      })}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(submit)}>
        <div className="px-2 px-lg-0">
          {/*From seller's balance*/}
          <div className="row bg-graylight2 py-1 rounded mt-2 mb-1">
            <div className="col">
              <Typography variant="caption">
                {intl.formatMessage({
                  id: 'dashboard.sales.pay_out_bank.from_seller_balance',
                })}
              </Typography>
            </div>
            <div className="col-auto">
              <Typography variant="caption" className="font-weight-bold">
                {intl.formatMessage(
                  {id: 'dashboard.sales.pay_out_bank.num_available'},
                  {num: '£54,345'}
                )}
              </Typography>
            </div>
          </div>

          {/*Sent to*/}
          <div className="row bg-graylight2 py-1 rounded mb-3 pb-1">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between">
                <Typography variant="caption" className="me-2">
                  {intl.formatMessage({
                    id: 'dashboard.sales.pay_out_bank.sent_to',
                  })}
                  :
                </Typography>
                <p className="mb-0">
                  <Typography
                    variant="caption"
                    className="font-weight-bold me-1"
                  >
                    LLOYDS BANK PLC •••• 4568{' '}
                  </Typography>
                  <Typography variant="caption" className="font-weight-bold">
                    {intl.formatMessage(
                      {id: 'dashboard.sales.pay_out_bank.default_for_currency'},
                      {currency: 'GBP'}
                    )}
                  </Typography>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*Amount*/}
        {printRow(
          'mb-3 pb-1',
          intl.formatMessage({id: 'dashboard.sales.new_payment_create.amount'}),
          '100',
          true,
          'amount'
        )}

        {/*Statement descriptor*/}
        <div className="row mb-1">
          <div className="col-12">
            <div className="d-flex align-items-center mb-2">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="span"
                className="me-1"
              >
                {intl.formatMessage({
                  id: 'dashboard.sales.new_payment_create.statement_desc',
                })}
              </Typography>
              <HintTooltip
                id="statement"
                gray={false}
                content={
                  <Typography variant="subtitle2">
                    {intl.formatMessage(
                      {id: 'dashboard.sales.pay_out_bank.statement_tooltip'},
                      {characters: '<, >, ", \''}
                    )}
                  </Typography>
                }
              />
            </div>
          </div>
          <div className="col-12">
            <Field name="descriptor" component={InputField} outlined />
          </div>
        </div>
      </form>
    </Modal>
  );
};

// connect component to redux-form
PayoutPopup = reduxForm({
  form: 'PayoutPopupForm',
  validate,
})(PayoutPopup);

export default injectIntl(PayoutPopup);
