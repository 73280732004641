import React from 'react';
import HiddenEnhancedInput from '../../Widgets/HiddenEnhancedInput';
import {touch} from 'redux-form';
import {useDispatch} from 'react-redux';

/* Hidden Field with actions (copy & show/hide) */
const HiddenEnhancedField = (props: any) => {
  const {
    inputClass,
    input: {onChange, ...restInput},
    meta,
    errorHelperText = true,
    label,
    showVisibilityIcon = true,
    placeholderValues = false,
    ...custom
  } = props;
  const {touched, error} = meta;
  const dispatch = useDispatch();

  const onInputChange = (event: any) => {
    if (!touched) {
      dispatch(touch(meta.form, props.input.name));
    }
    onChange(event);
  };

  return (
    <div>
      {props.externalLabel && label && (
        <span className="small-label pb-2">{label}</span>
      )}
      <HiddenEnhancedInput
        onChange={onInputChange}
        error={touched && !!error}
        showVisibilityIcon={showVisibilityIcon}
        placeholderValues={placeholderValues}
        meta={meta}
        {...restInput}
        {...custom}
      />
      {/* Show error here if errorHelperText is false */}
      {errorHelperText && touched && error && (
        <span className="error">{error}</span>
      )}
    </div>
  );
};

export default HiddenEnhancedField;
