import {Box, CircularProgress} from '@material-ui/core';
import React, {Fragment, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {TabPane} from 'reactstrap';
import useUserBank from '../../../hooks/user/useUserBank';
import PermissionContainer from '../../Common/PermissionContainer';
import Modal from '../../Widgets/Modal';
import BankModalForm from '../Form/BankForm';
import AddNewBank from '../Items/AddNewBank';
import BankListItem from '../Items/BankListItem';
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Profile Details Bank details tab
 * @param props
 * @returns {*}
 * @constructor
 */
const FinanceDetailsBanks = props => {
  const {user} = props;
  //Translations
  const intl = useIntl();
  const dispatch = useDispatch();
  const {banks, getUserBanks} = useUserBank(user.id);

  // fetch user bank lists
  useEffect(() => {
    getUserBanks();
  }, []);

  //Modal open state
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [isNew, setIsNew] = useState(false);

  //open/close modal handlers
  const handleModalClose = () => setEditModalOpened(false);

  // handle set bank account as default
  const handleSetBankAsDefault = item => {
    dispatch({
      type: actionTypes.API_USER_BANK_DEFAULT_REQUESTED,
      payload: {...item, userId: user.id},
    });
  };

  // handle delete bank account
  const handleDeleteBank = item => {
    dispatch({
      type: actionTypes.API_USER_BANK_DELETE_REQUESTED,
      payload: {...item, userId: user.id},
    });
  };

  // open modal in new mode
  const openNewModal = () => {
    setIsNew(true);
    setEditModalOpened(true);
  };

  return (
    <Fragment>
      <TabPane tabId="banks">
        <div className="cards-list">
          <div className="row g-0 row-cols-1 row-cols-sm-2 row-cols-xl-3">
            <PermissionContainer permissions={['admin_bank_detail_list']}>
              {banks.map((item, key) => (
                <div key={key} className="col">
                  <BankListItem
                    item={item}
                    isNew={false}
                    onSetAsDefault={handleSetBankAsDefault}
                    onDelete={handleDeleteBank}
                  />
                </div>
              ))}
            </PermissionContainer>
            {/*Add Bank*/}
            <div className="col">
              <AddNewBank isNew={true} openNewModal={openNewModal} />
            </div>
          </div>
        </div>
      </TabPane>
      {/*User Details Finance - Edit Bank*/}
      <BankModalForm
        opened={editModalOpened}
        handleClose={handleModalClose}
        isNew={isNew}
        user={user}
      />
    </Fragment>
  );
};

export default FinanceDetailsBanks;
