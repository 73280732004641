import React, {Fragment, useState, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SearchTextField from '../../Common/SearchTextField';
import Button from '../../Common/Button';
import NewRolePopup from './NewRolePopup';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth/withWidth';
import {useDispatch, useSelector} from 'react-redux';
import {
  API_PERMISSION_LIST_REQUESTED,
  API_ROLE_CREATE_REQUESTED,
  API_ROLE_DELETE_REQUESTED,
  API_ROLE_EDIT_REQUESTED,
  API_ROLE_LIST_REQUESTED,
} from '../../../constants/actionTypes';
import RoleHeader from './RoleHeader';
import PermissionGroupList from './PermissionGroupList';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import {getFiltersFromUrl, setFiltersOnUrl} from '../../../utils/filters';
import IconButton from '../../Common/IconButton';
import {PlusRoundedDangerIcon} from '../../../constants/images';

/* Permission Management */
const PermissionSettings = props => {
  const {intl, width} = props;
  const dispatch = useDispatch();
  const roleReducer = useSelector(store => store.roleReducer);
  const roles = roleReducer?.items;
  const permissions = useSelector(store => store.permissionReducer.items);
  //new role popup state
  const [popupOpen, setPopupOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const columnWidth = 100 / roles.length;
  const [searchTypingTimeout, setSearchTypingTimeout] = useState(0);
  const [filters, setFilters] = useState({q: ''});

  useEffect(() => {
    dispatch({
      type: API_ROLE_LIST_REQUESTED,
    });
    const permissionFilters = getFiltersFromUrl(['q']);
    setFilters(permissionFilters);
    listPermissions(permissionFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // handle create role success
    if (roleReducer.success) {
      setPopupOpen(false);
    }
  }, [roleReducer]);

  //Toggle new role popup state
  const togglePopup = () => setPopupOpen(!popupOpen);

  const updateFilters = (value, dispatch = true) => {
    const newFilters = {...filters, ...value};
    setFilters(newFilters);
    if (dispatch) {
      setFiltersOnUrl(newFilters);
      listPermissions(newFilters);
    }
  };

  const onSearchChange = event => {
    const value = event.target.value;
    updateFilters({q: value}, false);
    //prevent this request when user still typing
    if (searchTypingTimeout) {
      clearTimeout(searchTypingTimeout);
    }
    setSearchTypingTimeout(
      setTimeout(() => {
        updateFilters({q: value});
      }, 500)
    );
  };

  const listPermissions = payload => {
    dispatch({
      type: API_PERMISSION_LIST_REQUESTED,
      payload: payload,
    });
  };

  const handleSaveRole = values => {
    dispatch({
      type: API_ROLE_CREATE_REQUESTED,
      payload: values,
    });
  };

  const handleEditRole = (role, values) => {
    dispatch({
      type: API_ROLE_EDIT_REQUESTED,
      payload: {values, id: role.id},
    });
  };

  const handleDeleteRole = () => {
    dispatch({
      type: API_ROLE_DELETE_REQUESTED,
      payload: {id: deleteConfirmation.id},
    });
    setDeleteConfirmation(null);
  };

  //Role list header
  const printRoleListHeader = () => {
    return (
      <div className="role-list">
        {roles.map(role => (
          <RoleHeader
            key={role.id}
            style={{width: `${columnWidth}%`}}
            role={role}
            onUpdateRoleName={(role, value) =>
              handleEditRole(role, {name: value})
            }
            onDeleteRole={role => setDeleteConfirmation(role)}
          />
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      {/*Confirmation popup*/}
      <DeleteConfirmationModal
        opened={!!deleteConfirmation}
        onAccept={handleDeleteRole}
        onClose={() => setDeleteConfirmation(null)}
      />
      {/*New Role popup*/}
      <NewRolePopup
        opened={popupOpen}
        onClose={togglePopup}
        saveRole={handleSaveRole}
      />
      {/*See permission management*/}
      <div className="details-page pb-3 pb-lg-0">
        <div className="container-fluid permission-management px-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="top-column-left">
                <Typography
                  variant="h3"
                  className="py-3 font-weight-bolder text-center text-lg-start"
                >
                  {intl.formatMessage({
                    id: 'dashboard.settings.permissions.permission_management',
                  })}
                </Typography>
                {/*Keyword search*/}
                <div className="pt-lg-3">
                  <div className="d-flex align-items-center gap-1">
                    <SearchTextField
                      clearable
                      buttonProps={{
                        onClear: () => updateFilters({q: ''}),
                      }}
                      value={filters.q || ''}
                      onSearchChanged={onSearchChange}
                      placeholder={intl.formatMessage({id: 'actions.search'})}
                      grayBackground={isWidthUp('lg', width)}
                    />
                    <IconButton
                      size="sm"
                      variant="filled"
                      className="d-lg-none"
                      onClick={togglePopup}
                    >
                      <PlusRoundedDangerIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 text-end">
              <div className="top-column-right d-none d-lg-block">
                {/*New Role button*/}
                <Button
                  primary
                  title={intl.formatMessage({
                    id: 'dashboard.settings.permissions.new_role',
                  })}
                  icon={<AddIcon />}
                  onClick={togglePopup}
                />
              </div>
            </div>
          </div>
          {/*Role list*/}
          <div className="row gx-0">
            <div className="col-lg-4 bg-white border-bottom"></div>
            <div className="col-lg-8">{printRoleListHeader()}</div>
          </div>
          {Object.keys(permissions).map((groupTitle, index) => (
            <PermissionGroupList
              key={index}
              title={groupTitle}
              permissions={permissions[groupTitle]}
              roles={roles}
              onPermissionAssigned={(role, permissions) =>
                handleEditRole(role, {permissions})
              }
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default withWidth()(injectIntl(PermissionSettings));
