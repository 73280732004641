import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@material-ui/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth/withWidth';
import Drawer from '@material-ui/core/Drawer';
import theme from '../../constants/theme';
import Button from './Button';
import {Close as CloseIcon} from '@material-ui/icons';
import {injectIntl} from 'react-intl';

/**
 * Drawer base styled component
 * If the component contents form and action buttons then form will take whole space and the action buttons were be on the bottom
 */
const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    overflowX: 'hidden',
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up('lg')]: {
      minWidth: '379px !important',
    },

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      borderRadius: '40px 0 0 40px',
      paddingTop: 49,
      paddingBottom: 40,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '90%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 33,
    },

    '& .header': {
      padding: '0 45px 40px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 30px 20px',
      },
    },

    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0',
      padding: '0 50px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 30px',
      },

      '& form': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0',
      },
      '& .fields': {
        paddingBottom: 25,
        flex: '1 0',
      },
      '& .buttons': {
        flex: '0 1',
        '& .action-buttons': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          '& .MuiButton-contained': {
            minWidth: 97,
            marginLeft: 7,
          },
        },
        [theme.breakpoints.down('md')]: {
          '& .action-buttons': {
            justifyContent: 'center',
            '& .MuiButton-root': {
              width: '50%',
              margin: '0 10px',
            },
          },
        },
      },
    },
  },
});

/* Drawer custom component with default props */
const CustomStyledDrawer = ({
  intl,
  width,
  children,
  onClose,
  className = '',
  ...otherProps
}) => (
  <StyledDrawer
    anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
    variant="persistent"
    elevation={0}
    {...otherProps}
  >
    <div className={className}>
      <div className="header">
        {/* Close action button */}
        <Button
          link
          light
          title={intl.formatMessage({id: 'actions.close'})}
          onClick={onClose}
          icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
        />
      </div>
      <div className="content">{children}</div>
    </div>
  </StyledDrawer>
);

CustomStyledDrawer.propTypes = {
  /**
   * Root className
   */
  className: PropTypes.string,
  /**
   * Opened state
   */
  open: PropTypes.bool.isRequired,
  /**
   * Close callback
   */
  onClose: PropTypes.func.isRequired,
};

export default withWidth()(injectIntl(CustomStyledDrawer));
