import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import * as PropTypes from 'prop-types';
import Avatar from '../../Common/Avatar';
import IconButton from '../../Common/IconButton';
import {ENQUEUE_SNACKBAR} from '../../../constants/actionTypes';
import {EditIcon, TrashIcon} from '../../../constants/images';
import useUserInfo from '../../../hooks/useUserInfo';
import useProfilePictureUrl from '../../../hooks/user/useProfilePictureUrl';

const UploadableProfilePicture = props => {
  const {user, canNotEdit} = props;
  const {initials} = useUserInfo(user);
  const inputRef = useRef();
  const {extensions, invalidSize, invalidType, maxSize} = props;
  const dispatch = useDispatch();
  const avatarUrl = useProfilePictureUrl(
    user &&
      user.profileImage &&
      user.profileImage.sizes &&
      user.profileImage.sizes.thumbnail
  );

  const deletePicture = () => {
    // dispatch({
    //     type: API_USER_DELETE_PICTURE_REQUESTED,
    //     payload: { token, user }
    // });
  };

  //Read and add file to list when uploading
  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = function (e) {
        // Add the file name to the data URL
        let dataURL = e.target.result;
        dataURL = dataURL.replace(';base64', ';name=' + file.name + ';base64');
        resolve({file: file, dataURL: dataURL});
      };

      reader.readAsDataURL(file);
    });
  };

  const uploadPhoto = fileData => {
    // dispatch({
    //     type: API_USER_CHANGE_PICTURE_REQUESTED,
    //     payload: {
    //         token,
    //         user,
    //         fileData,
    //     }
    // });
  };

  //Handle file upload
  const onFileUploadedChange = event => {
    const images = Array.from(event.target.files);

    readFile(images[0]).then(newFileData => {
      let errorMessage = null;
      const typeRegExp = new RegExp(
        `^image/${extensions.map(ext => ext.replace('.', '')).join('|')}`
      );
      if (!typeRegExp.test(newFileData.file.type)) {
        errorMessage = invalidType;
      } else if (newFileData.file.size > maxSize) {
        errorMessage = invalidSize.replace(':maxSize', maxSize / (1024 * 1024));
      }
      if (!errorMessage) {
        uploadPhoto(newFileData);
      } else {
        dispatch({
          type: ENQUEUE_SNACKBAR,
          notification: {
            message: errorMessage,
            key: new Date().getTime() + Math.random(),
            options: {
              variant: 'error',
            },
          },
        });
      }
    });
  };

  return (
    <>
      <input
        type="file"
        style={{display: 'none'}}
        ref={inputRef}
        value=""
        accept={extensions.join(',')}
        onChange={onFileUploadedChange}
      />
      <Avatar
        alt={initials}
        src={user.image}
        color={
          user.gender === 'mr'
            ? 'blue'
            : user.gender === 'miss'
              ? 'pink'
              : 'grey'
        }
        size="md"
      />
      {!canNotEdit && (
        <IconButton
          size="xs"
          variant="action"
          className="edit-icon-button"
          onClick={() => inputRef.current && inputRef.current.click()}
        >
          <EditIcon style={{color: '#989898'}} />
        </IconButton>
      )}
      {avatarUrl && (
        <IconButton
          size="xs"
          variant="action"
          className="delete-icon-button"
          onClick={deletePicture}
        >
          <TrashIcon style={{color: '#989898'}} />
        </IconButton>
      )}
    </>
  );
};

UploadableProfilePicture.propTypes = {
  maxSize: PropTypes.number,
  invalidType: PropTypes.string,
  invalidSize: PropTypes.string,
  extensions: PropTypes.arrayOf(PropTypes.string),
};

UploadableProfilePicture.defaultProps = {
  maxSize: 20 * 1024 * 1024, // 20M
  invalidType: 'Only valid image formats are allowed (.jpg, .jpeg. .png .bmp)',
  invalidSize: 'Maximum image size :maxSizeM',
  extensions: ['.jpg', '.jpeg', '.png', '.bmp'],
};

export default UploadableProfilePicture;
