import {call, cancelled, delay, put} from '@redux-saga/core/effects';
import {
  API_EVENT_AUTOCOMPLETE_FAILED,
  API_EVENT_AUTOCOMPLETE_SUCCEEDED,
} from '../constants/actionTypes';
import axios from 'axios';
import {API_EVENT_AUTOCOMPLETE_URL} from '../constants/urls';

export function* doGetEventAutocomplete(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    yield delay(200);
    const url =
      API_EVENT_AUTOCOMPLETE_URL + '?term=' + (action.payload.term ?? '');
    const response = yield call(axios.get, url, {
      cancelToken: cancelSource.token,
    });
    const data = response.data;

    yield put({
      type: API_EVENT_AUTOCOMPLETE_SUCCEEDED,
      payload: {eventNames: Array.isArray(data) ? data : Object.values(data)},
    });
  } catch (error) {
    console.warn('event autocomplete request failed', error);
    yield put({type: API_EVENT_AUTOCOMPLETE_FAILED, payload: {error}});
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}
