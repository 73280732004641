import React from 'react';
import AutocompletePopup from '../../Common/AutocompletePopup';

const AutocompletePopupField = props => {
  const {
    externalLabel,
    label,
    options,
    input: {onChange, value},
    meta,
    onInputChange,
    textFieldProps,
    ...others
  } = props;
  const {touched, error} = meta;
  const extra = {};
  if (!externalLabel && label) {
    extra.label = label;
  }

  return (
    <div>
      {externalLabel && label && <span className="small-label">{label}</span>}
      <AutocompletePopup
        {...others}
        {...extra}
        inputValue={value}
        placeholder="Event title"
        options={options}
        onChange={event => onChange(event.name)}
        onInputChange={onInputChange}
      />
    </div>
  );
};

export default AutocompletePopupField;
