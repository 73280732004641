import React, {createRef, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {injectIntl} from 'react-intl';
import {useDropzone} from 'react-dropzone';
import {PlusIconDanger} from '../../constants/images';

/* Image uploading field */
const ImageSmallUpload = props => {
  const {intl, onChange} = props;
  const dropzoneRef = createRef();

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onChange(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{
          bgcolor: 'error.light',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
        }}
        p={1}
        role="button"
        onClick={openDialog}
      >
        <PlusIconDanger />
        <Typography
          variant="subtitle2"
          color="primary"
          className="ms-2 font-weight-bold"
        >
          {intl.formatMessage({
            id: 'dashboard.events.venue_details.section_details.upload_photo',
          })}
        </Typography>
      </Box>
    </div>
  );
};

export default injectIntl(ImageSmallUpload);
