import {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Col, Row} from 'reactstrap';
import Button from '../../Common/Button';
import Dropdown from '../../Common/Dropdown';
import {EllipsisIcon, RefreshIcon} from '../../../constants/images';
import TeamMemberAvatar from './TeamMemberAvatar';
import {Role, TeamMember} from '../types';
import TeamMemberStatus from './TeamMemberStatus';
import AutocompleteMultiple, {
  AmOptionItem,
} from '../../Form/Inputs/AutocompleteMultiple';
import {AutocompleteProps} from '@material-ui/lab';
import usePermission from '../../../Auth/usePermission';
import {TeamPermission} from '../../../constants/teamPermission';
import CustomSwitch from '../../Common/Switch';
import {withStyles} from '@material-ui/styles';
import {useTeamSettingsMutations} from '../../../hooks/settings/team/useTeamSettings';
import Chip from '../../Common/Chip';

const styles = {
  resetButton: {
    '&.loading': {
      color: 'green',
    },
    '&.success': {
      color: 'green',
    },
    '&.error': {
      color: 'red',
    },
  },
  rotating: {
    animation: '$rotation 0.7s infinite linear',
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
};

type Classes = {
  resetButton: string;
  rotating: string;
};

type TeamItemProps = {
  classes: Classes;
  member: TeamMember;
  onEditMenu: Function;
  onDeleteMenu: Function;
  onSuspend?: (member: TeamMember) => void;
  onUnsuspend?: (member: TeamMember) => void;
  roles?: Role[];
  onRolesChange?: (newRoles: Role[], member: TeamMember) => void;
  isMyself: boolean;
};

type ActionItem = {
  label: string;
  onClick: Function;
};

type ResetStatus = 'idle' | 'loading' | 'success' | 'error';

/* Team Item card */
const TeamItem = (props: TeamItemProps) => {
  const {
    classes,
    member,
    onEditMenu,
    onDeleteMenu,
    onSuspend,
    onUnsuspend,
    roles,
    onRolesChange,
    isMyself,
  } = props;

  const {id, email, firstname, lastname, enabled, enable2Fa} = member;

  const {hasPermission} = usePermission();

  const intl = useIntl();

  const actions = useMemo(() => {
    const res: ActionItem[] = [];

    if (hasPermission(TeamPermission.Suspend) && !isMyself) {
      if (member.enabled) {
        res.push({
          label: intl.formatMessage({
            id: 'dashboard.settings.team.actions.suspend',
          }),
          onClick: () => {
            onSuspend && onSuspend(member);
          },
        });
      } else {
        res.push({
          label: intl.formatMessage({
            id: 'dashboard.settings.team.actions.unsuspend',
          }),
          onClick: () => {
            onUnsuspend && onUnsuspend(member);
          },
        });
      }
    }

    if (hasPermission(TeamPermission.Edit)) {
      res.push({
        label: intl.formatMessage({
          id: 'dashboard.settings.team.actions.edit',
        }),
        onClick: onEditMenu,
      });
    }

    if (hasPermission(TeamPermission.Delete) && !isMyself) {
      res.push({
        label: intl.formatMessage({
          id: 'dashboard.settings.team.actions.delete',
        }),
        onClick: onDeleteMenu,
      });
    }

    return res;
  }, [intl, member, onDeleteMenu, onEditMenu, onSuspend, onUnsuspend]);

  const [memberRoles, setMemberRoles] = useState<Role[]>([]);

  useEffect(() => {
    setMemberRoles(
      (roles ?? []).filter(role => member.roles?.includes(role.slug))
    );
  }, [member, roles]);

  const roleToOption = useCallback(
    ({name, slug}: Role): AmOptionItem => ({
      title: name,
      value: slug,
    }),
    []
  );

  const onRoleChange = useCallback(
    (newValue: AmOptionItem[]) => {
      const newRoles = (roles ?? []).filter(role =>
        newValue.map(v => v.value).includes(role.slug)
      );
      setMemberRoles(newRoles);
      onRolesChange && onRolesChange(newRoles, member);
    },
    [member, onRolesChange, roles]
  );

  const rolesValues = useMemo(
    () => (memberRoles ?? []).map(roleToOption),
    [memberRoles, roleToOption]
  );

  const autocompleteProps = useMemo(
    () =>
      ({
        getOptionSelected: (option: any, value: any) =>
          option?.value === value?.value,
      }) as Partial<AutocompleteProps<AmOptionItem, true, true, false>>,
    []
  );

  const {isToggle2FASuccess, isReset2FASuccess, onReset2FA, onToggle2FA} =
    useTeamSettingsMutations();

  const [enabled2Fa, setEnabled2Fa] = useState<boolean>(false);

  useEffect(() => {
    // Set initial state of 2FA on initial page load
    setEnabled2Fa(prevState => enable2Fa);
  }, []); // empty dependency array to run only once when the component first mounts

  const handleToggle2FA = () => {
    const updatedMember = {...member, enable2Fa: !enabled2Fa};

    // Trigger the API request to update the 2FA status
    onToggle2FA && onToggle2FA(updatedMember);
  };

  useEffect(() => {
    if (isToggle2FASuccess) {
      setEnabled2Fa(prevState => !prevState);
    }
  }, [isToggle2FASuccess]);

  const [resetStatus, setResetStatus] = useState<ResetStatus>('idle');

  const handleReset2FA = useCallback(() => {
    setResetStatus(prevState => {
      if (prevState === 'loading') {
        return prevState;
      }
      onReset2FA && onReset2FA(member);
      return 'loading';
    });
  }, [onReset2FA, member]);

  useEffect(() => {
    if (isReset2FASuccess) {
      setResetStatus('success');
      setTimeout(() => {
        setResetStatus('idle');
      }, 2000);
    } else {
      setResetStatus('error');
      setTimeout(() => setResetStatus('idle'), 2000);
    }
  }, [isReset2FASuccess]);

  return (
    <div className="card details-card team-item">
      <div className="card-header p-3">
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            <TeamMemberAvatar member={member} />
          </div>
          <div className="col-8">
            <div className="d-flex flex-nowrap align-items-center">
              <Typography className="font-weight-bold text-truncate">{`${firstname} ${lastname}`}</Typography>
              {isMyself ? (
                <Chip
                  label={intl.formatMessage({
                    id: 'dashboard.settings.team.you',
                  })}
                  className="ms-2"
                />
              ) : (
                <TeamMemberStatus member={member} />
              )}
            </div>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className="text-truncate"
            >
              {email}
            </Typography>
          </div>
          {actions.length > 0 && (
            <div className="col-auto ms-auto">
              <Dropdown
                menuIcon={<EllipsisIcon style={{width: 15, height: 10}} />}
                items={actions}
              />
            </div>
          )}
        </div>
      </div>
      <div className="card-body px-3 pt-0 pb-3">
        <Row className="gx-2 pb-3 align-items-center">
          {/* 2FA label */}
          <Col xs="auto">
            <Typography variant="body2" className="font-weight-bold">
              <FormattedMessage id="dashboard.settings.team.2fa" />
            </Typography>
          </Col>
          {/* 2FA switch */}
          <Col xs="auto">
            <Box py={0.4}>
              <CustomSwitch
                isChecked={enabled2Fa}
                handleOptionCheck={handleToggle2FA}
                disable={!hasPermission(TeamPermission.EnableDisable2Fa)}
              />
            </Box>
          </Col>
          {/* Reset 2FA button */}
          {enabled2Fa && hasPermission(TeamPermission.Reset2Fa) && (
            <Col xs="auto">
              <Button
                link
                icon={
                  <RefreshIcon
                    style={{width: 15, height: 15, marginRight: 5}}
                    className={`${classes.resetButton} ${resetStatus} ${resetStatus === 'loading' ? classes.rotating : ''}`}
                  />
                }
                onClick={handleReset2FA}
                title={intl.formatMessage({
                  id: 'dashboard.settings.team.reset',
                })}
              />
            </Col>
          )}
        </Row>
        {/* Roles select  */}
        <AutocompleteMultiple
          canSelect={hasPermission(TeamPermission.Edit)}
          options={(roles ?? []).map(roleToOption)}
          values={rolesValues}
          className="mb-0"
          placeholder={intl.formatMessage({
            id: 'dashboard.settings.team.select_roles_add',
          })}
          onChange={onRoleChange}
          autocompleteProps={autocompleteProps}
          chipIdProp="value"
          blurOnChange
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(TeamItem);
