/**
 * Snackbar reducer
 */
import * as types from '../constants/actionTypes';

const initialState = {
  notifications: [],
};

export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.notification.key,
            ...action.notification,
          },
        ],
      };
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? {...notification, dismissed: true}
            : {...notification}
        ),
      };
    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        ),
      };
    default:
      return state;
  }
}
