import React, {useMemo} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Typography} from '@material-ui/core';
import ArrowLink from '../Common/ArrowLink';
import {get} from 'lodash';
import useDateHelper from '../../hooks/utils/useDateHelper';

const Adjustments = ({items = [], widths = [], version}) => {
  const intl = useIntl();
  const dateHelper = useDateHelper();
  const isV2 = useMemo(() => version === 'v2', [version]);
  const widthStyle = i =>
    !Array.isArray(widths)
      ? {}
      : {style: {width: `${widths[i]}%`, paddingLeft: 5, paddingRight: 5}};

  return (
    <div className="adjustment">
      <div className="collapsable-header pt-fan-15">
        <span className="font-weight-bold">
          <FormattedMessage id="dashboard.transactions.adjustments.title" />
        </span>
      </div>
      <div className="pt-fan-15">
        {/*Header*/}
        <div className="subheader">
          <div className="row">
            <div className="col-lg-6" {...widthStyle(0)}>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'dashboard.transactions.payout.sales_event',
                })}
              </Typography>
            </div>
            <div className="col-lg-3" {...widthStyle(1)}>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'dashboard.transactions.payout.penalty',
                })}
              </Typography>
            </div>
            <div className="col-lg-3" {...widthStyle(2)}>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'dashboard.transactions.payout.event_date',
                })}
              </Typography>
            </div>
          </div>
        </div>
        <div className="content disable-scroll-auto">
          {items.map(item => {
            const referenceHexa = get(
              item,
              isV2
                ? 'salePenalty.sale.saleMetadata.referenceHexa'
                : 'salePenalty.sale.referenceHexa'
            );

            const eventName = get(
              item,
              isV2
                ? 'salePenalty.sale.saleMetadata.eventName'
                : 'salePenalty.sale.ticket.event.name'
            );

            const eventPostponed = get(
              item,
              isV2
                ? 'salePenalty.sale.saleMetadata.eventPostponed'
                : 'salePenalty.sale.ticket.event.postponed'
            );

            const eventDate = get(
              item,
              isV2
                ? 'salePenalty.sale.saleMetadata.eventDate'
                : 'salePenalty.sale.ticket.event.date'
            );

            return (
              <div className="row" key={item.id}>
                <div className="col-lg-6" {...widthStyle(0)}>
                  <ArrowLink
                    className="w-auto flex-shrink-0"
                    title={`#${referenceHexa} ${eventName}`}
                    url={`/admin-dashboard/sales/${get(item, 'salePenalty.sale.id')}`}
                  />
                </div>
                <div className="col-lg-3" {...widthStyle(1)}>
                  <Typography variant="body2">{item.amount}</Typography>
                </div>
                <div className="col-lg-3" {...widthStyle(2)}>
                  <Typography variant="body2">
                    {eventPostponed
                      ? intl.formatMessage({
                          id: 'dashboard.events.date_to_be_confirmed',
                        })
                      : dateHelper.formatDate(eventDate)}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Adjustments;
