import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import SearchForm from '../Form/SearchForm';
import Box from '@material-ui/core/Box';
import IconButton from '../Common/IconButton';
import {FILTER_ICON} from '../../constants/images';
import ArrowLink from '../Common/ArrowLink';
import SellerMarks from '../Sales/Details/SellerMark';
import StateIndicator from '../Common/StateIndicator';
import {SELLER_MARKS} from '../../constants/enums';
import DataTable from '../Widgets/DataTable';
import Filter from '../Widgets/Filter';
import * as urls from '../../constants/urls';

/* Listing list */
const ListingsList = props => {
  const {intl} = props;
  //Router
  const history = useHistory();
  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);

  useEffect(() => {
    if (filterOpened) {
      document.body.classList.add('right-modal-open');
    } else {
      document.body.classList.remove('right-modal-open');
    }
  }, [filterOpened]);

  const filterItems = [
    {
      name: 'event',
      title: intl.formatMessage({id: 'dashboard.listings.event_title'}),
      inputType: 'search',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'event_date',
      title: intl.formatMessage({id: 'dashboard.listings.event_date'}),
      inputType: 'date_select',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'creation_date',
      title: intl.formatMessage({id: 'dashboard.listings.creation_date'}),
      inputType: 'date_select',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'seller',
      title: intl.formatMessage({id: 'dashboard.listings.seller_name'}),
      inputType: 'search',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'type',
      title: intl.formatMessage({id: 'dashboard.listings.ticket_type'}),
      inputType: 'checkboxes',
      subitems: [
        {label: 'E-ticket', value: 'e-ticket'},
        {label: 'M-ticket', value: 'm-ticket'},
      ],
    },
    {
      name: 'section',
      title: intl.formatMessage({id: 'dashboard.listings.section'}),
      inputType: 'search',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'price',
      title: intl.formatMessage({id: 'dashboard.listings.price'}),
      inputType: 'search',
      classes: {fieldWrapper: 'search-filter-row'},
    },
    {
      name: 'status',
      title: intl.formatMessage({id: 'dashboard.listings.status'}),
      inputType: 'checkboxes',
      subitems: [
        {label: 'Paused', value: 'paused'},
        {label: 'On Sale', value: 'on-sale'},
        {label: 'Completed', value: 'completed'},
      ],
    },
  ];

  // Header cells items
  const HeaderCells = [
    {
      name: 'event',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.listings.event_title'}),
      collapse: false,
      renderValue: v => <div className="ps-1">{v}</div>,
      width: '18%',
    },
    {
      name: 'event_date',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.listings.event_date'}),
      collapse: true,
    },
    {
      name: 'creation_date',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.listings.creation_date'}),
      collapse: true,
    },
    {
      name: 'seller',
      label: intl.formatMessage({id: 'dashboard.listings.seller_name'}),
      collapse: true,
      renderValue: seller => printSellerCell(seller),
    },
    {
      name: 'type',
      label: intl.formatMessage({id: 'dashboard.listings.ticket_type'}),
      collapse: true,
    },
    {
      name: 'section',
      label: intl.formatMessage({id: 'dashboard.listings.section'}),
      collapse: true,
    },
    {
      name: 'price',
      label: intl.formatMessage({id: 'dashboard.listings.price'}),
      collapse: true,
      width: '80px',
    },
    {
      name: 'status',
      label: intl.formatMessage({id: 'dashboard.listings.status'}),
      collapse: false,
      renderValue: obj => <StateIndicator {...obj} />,
      width: '130px',
    },
  ];

  const rows = [
    {
      event: 'Arsenal - Tottenham',
      event_date: '23 Nov 2021',
      creation_date: '23 Nov 2021',
      seller: {
        name: 'Missie Clotilda',
        link: 'http://link',
        items: [
          SELLER_MARKS.MARK_TRUSTED,
          SELLER_MARKS.MARK_DELIVERY,
          SELLER_MARKS.MARK_PREMIUM,
          SELLER_MARKS.MARK_PRO,
          SELLER_MARKS.MARK_INSTANT,
        ],
      },
      type: 'E-ticket (4)',
      section: 'Longside lower',
      price: '£50',
      status: {label: 'Paused', color: 'gray'},
    },
    {
      event: 'Chelsea – Leicester City',
      event_date: '11 Oct 2021',
      creation_date: '11 Oct 2021',
      seller: {
        name: 'Missie Clotilda',
        link: 'http://link',
        items: [
          SELLER_MARKS.MARK_TRUSTED,
          SELLER_MARKS.MARK_DELIVERY,
          SELLER_MARKS.MARK_PREMIUM,
        ],
      },
      type: 'E-ticket (4)',
      section: 'Longside lower',
      price: '£50',
      status: {label: 'On sale', color: 'warning'},
    },
    {
      event: 'Ariana Grande',
      event_date: '11 Oct 2021',
      creation_date: '11 Oct 2021',
      seller: {name: 'Sara Samuel', link: 'http://link', items: []},
      type: 'E-ticket (4)',
      section: 'Longside lower',
      price: '£50',
      status: {label: 'Completed', color: 'green'},
    },
  ];

  //Seller name column rendering procedure
  const printSellerCell = ({id, name, link, items}) => {
    return (
      <div>
        <Box pb={0.3}>
          <ArrowLink title={name} url={link} />
        </Box>
        <SellerMarks items={items} id={id} />
      </div>
    );
  };

  const handleRowClick = itemId => {
    history.push(urls.LISTING_DETAILS_PATH.replace(':id', 0));
  };

  return (
    <Fragment>
      {/* Filter on the right side */}
      <Filter
        opened={filterOpened}
        items={filterItems}
        onClose={() => setFilterOpened(false)}
      />

      <div className="dashboard-wrapper">
        <div className="container-fluid">
          <div className="row align-items-center header-row pb-4 mb-1">
            <div className="col text-center text-lg-start">
              {/* Header */}
              <Typography variant="h3" className="font-weight-bolder">
                <FormattedMessage id="dashboard.listings.title" />
              </Typography>
            </div>
          </div>
          <div className="dashboard-content">
            <div className="row align-items-center justify-content-between search-row">
              <div className="col-xl-4 col-lg-8 d-flex">
                {/* Search */}
                <SearchForm
                  dispatchAction={true}
                  // successType={USER_LIST_SET_SEARCH_SUCCEEDED}
                  delay={180}
                  SearchProps={{
                    buttonProps: {
                      onClick: () => {},
                    },
                  }}
                />
                {/* Filter Button on mobile view only */}
                <Box ml={1} className="d-block d-lg-none">
                  <IconButton
                    className="m-0"
                    onClick={() => setFilterOpened(true)}
                  >
                    {FILTER_ICON}
                  </IconButton>
                </Box>
              </div>
              {/* Button toolbar */}
              <div className="col-auto d-none d-lg-block">
                <div className="d-flex">
                  {/* Filter button */}
                  <IconButton
                    className="me-0"
                    onClick={() => {
                      setFilterOpened(true);
                    }}
                  >
                    {FILTER_ICON}
                  </IconButton>
                </div>
              </div>
            </div>
            {/* Data Table */}
            <DataTable
              className="sales-list"
              canSelect={false}
              empty={false}
              loading={false}
              onRowClick={itemId => handleRowClick(itemId)}
              headCells={HeaderCells}
              rows={rows}
              onChange={() => {}}
              total={100}
              currentPage={1}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(ListingsList);
