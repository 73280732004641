import React, {useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import {reduxForm} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import {useDispatch} from 'react-redux';

import Modal from '../../../Widgets/Modal';
import getSymbolFromCurrency from 'currency-symbol-map';
import PrintRow from './PaymentPopupRow';
import {cardLabel} from '../../../../utils/functions';
import {
  positive,
  required,
  tooLong,
  validateBeforeSubmit,
} from '../../../../validation/validators';
import * as actionTypes from '../../../../constants/actionTypes';

const validate = values => {
  const errors = {};

  errors.amount = required(values.amount) || positive(values.amount);
  errors.paymentMethod = required(values.paymentMethod);
  errors.description =
    required(values.description) ||
    tooLong(22)(values.description, 'Maximum characters exceeded');

  return errors;
};

/* Payment popup */
let PaymentPopup = props => {
  const dispatch = useDispatch();
  const {intl, opened, handleClose, handleSubmit, sale, sellerPaymentMethods} =
    props;
  const [currentCurrencySymbol, setCurrentCurrencySymbol] = useState('£');

  // This useEffect is use to set information about currency and max price amount
  React.useEffect(() => {
    // Set the currency symbol related to this sale
    sale?.ticket?.currency &&
      setCurrentCurrencySymbol(getSymbolFromCurrency(sale.ticket.currency));
  }, [sale]);

  const paymentMethodItems = useMemo(() => {
    return sellerPaymentMethods.map(item => {
      const {brand, last4, expMonth, expYear} = item;
      const valid = `${expMonth.toString().padStart(2, '0')}/${expYear}`;
      return {
        label: cardLabel({paymentMethod: brand, lastNumbers: last4, valid}),
        value: item['@id'],
      };
    });
  }, [sellerPaymentMethods]);

  const submit = values => {
    const errors = validate(values);
    validateBeforeSubmit(errors);

    //Create payment
    dispatch({
      type: actionTypes.API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED,
      payload: {
        values,
        sale,
      },
    });
  };

  return (
    <Modal
      width={328}
      opened={opened}
      title={intl.formatMessage({
        id: 'dashboard.sales.new_payment_create.create_new_payment',
      })}
      saveTitle={intl.formatMessage({id: 'actions.create'})}
      handleClose={handleClose}
      onOk={handleSubmit(submit)}
    >
      <form onSubmit={handleSubmit(submit)}>
        {/*Amount*/}
        <PrintRow
          className={'mb-3 pb-1'}
          title={intl.formatMessage({
            id: 'dashboard.sales.new_payment_create.amount',
          })}
          value={100}
          edit={true}
          fieldName={'amount'}
          type={'Input'}
          currency={currentCurrencySymbol}
        />

        {/*Payment method*/}
        <PrintRow
          title={intl.formatMessage({
            id: 'dashboard.sales.new_payment_create.payment_method',
          })}
          value={100}
          edit={true}
          fieldName={'paymentMethod'}
          type={'Select'}
          items={paymentMethodItems}
        />

        {/*Statement descriptor*/}
        <PrintRow
          fullClassName={'mb-3 pb-1'}
          titleWrapperClassName={'d-flex align-items-center mb-2'}
          title={intl.formatMessage({
            id: 'dashboard.sales.new_payment_create.statement_desc',
          })}
          value={100}
          edit={true}
          fieldName={'description'}
          type={'Input'}
          tooltip={true}
          tooltipId={'statement'}
          tooltipContent={
            <div>
              <div className="mb-1">
                <Typography variant="subtitle2">
                  {intl.formatMessage({
                    id: 'dashboard.sales.new_payment_create.statement_tooltip',
                  })}
                </Typography>
              </div>
              <div className="d-flex align-items-center justify-content-between bg-graylight2 px-2 py-1 mt-2 rounded">
                <Typography
                  variant="caption"
                  component="span"
                  className="text-uppercase font-weight-bold"
                >
                  {intl.formatMessage({id: 'app_name'})}
                </Typography>
                <Typography
                  variant="caption"
                  component="span"
                  className="font-weight-bold"
                >
                  £0.00
                </Typography>
              </div>
            </div>
          }
        />
      </form>
    </Modal>
  );
};

// connect component to redux-form
PaymentPopup = reduxForm({
  form: 'PaymentPopupForm',
  validate,
})(PaymentPopup);

export default injectIntl(PaymentPopup);
