import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Field, reduxForm} from 'redux-form';
import clsx from 'clsx';
import Button from '../../Common/Button';
import InputField from '../../Form/Fields/InputField';
import CheckboxField from '../../Form/Fields/CheckboxField';
import {scrollToFirstError} from '../../../validation/validators';
import {connect, useDispatch} from 'react-redux';
import {
  validateBeforeSubmit,
  required,
  phoneNumber,
} from '../../../validation/validators';
import * as actionTypes from '../../../constants/actionTypes';
import {
  APP_COUNTRY_STATES,
  COUNTRY_WITH_NO_STATES,
} from '../../../constants/countryStateList';
import CustomAutocomplete from '../../Common/CustomAutocomplete';

const validate = values => {
  const errors = {};
  // make sure firstname is not empty
  errors.firstname = required(values.firstname);
  // make sure lastname is not empty
  errors.lastname = required(values.lastname);
  // make sure postal code is not empty
  errors.zipcode = required(values.zipcode);
  // make sure city is not empty
  errors.city = required(values.city);
  // make sure address line one is not empty
  errors.lineOne = required(values.lineOne);
  // make sure inputed phone number is valid
  errors.phoneNumber = phoneNumber(
    values.phoneNumber,
    'The phone number format is invalid'
  );
  return errors;
};

/**
 * Address Add/Edit form
 */
let AddressForm = props => {
  //Translations
  const {intl, closeModal, address, open, user} = props;
  const dispatch = useDispatch();
  // holds the country state
  const [country, setCountry] = useState('');
  // holds the state/ region of selected country
  const [state, setState] = useState('');
  // selected country regions
  const [regions, setRegions] = useState([]);
  // handle custom input error message
  const [errorMessage, setErrorMessage] = useState({
    country: null,
    state: null,
  });

  // show/hide state field
  const [showState, setShowState] = useState(false);

  // set default values in edit mode
  useEffect(() => {
    // if the modal is open and if address is not null
    // then set default values
    if (open && address) {
      const countryAddess = address.country === 'UK' ? 'GB' : address.country;
      const defaultCountry = APP_COUNTRY_STATES.find(
        where => where.countryShortCode === countryAddess
      );
      if (defaultCountry) {
        setCountry(defaultCountry ? defaultCountry.countryName : '');
        if (!COUNTRY_WITH_NO_STATES.includes(defaultCountry.countryName)) {
          setShowState(true);
          // set the regions and state of selected country
          setRegions(defaultCountry ? defaultCountry.regions : []);
          const defaultState = defaultCountry.regions.find(
            where => where.name === address.state
          );
          setState(defaultState ? defaultState.name : '');
          setState(defaultState ? defaultState.name : '');
          setState(defaultState ? defaultState.name : '');
        } else {
          setShowState(false);
        }
      }
    }
  }, [open, address]);

  // handle country changes
  const handleCountryChange = name => {
    // find the country in the list of country lists
    const countryStates = APP_COUNTRY_STATES.find(
      where => where.countryName === name
    );
    setCountry(countryStates.countryName);
    if (!COUNTRY_WITH_NO_STATES.includes(countryStates.countryName)) {
      setShowState(true);
      // set the regions of selected country
      setRegions(countryStates.regions);
    } else {
      setShowState(false);
      setState('');
    }
    setErrorMessage({
      ...errorMessage,
      country: null,
    });
  };

  // set selected state
  const handleStateChange = name => {
    setState(name);
    setErrorMessage({
      ...errorMessage,
      state: null,
    });
  };

  // extract Id from @id "/v2/addresses/:id"
  const extractId = stringValue => {
    const splitValue = stringValue.split('/');
    const id = splitValue[4];
    return id;
  };

  // submit the address form and validate values
  const submit = values => {
    const message = {...errorMessage};
    // make sure country is not empty
    message.country = required(country);
    if (showState) {
      message.state = required(state);
    } else {
      message.state = null;
    }
    // set custom error message
    setErrorMessage(message);

    // validate and make sure empty fields are not submitted
    const errors = validate(values);
    validateBeforeSubmit(errors);
    if (message.country || message.state) {
      return;
    }

    // country code is being saved on the backend
    // find the country and take the
    const countryStates = APP_COUNTRY_STATES.find(
      where => where.countryName === country
    );
    const countryValue = countryStates.countryShortCode;

    // add country and state field to values object
    values.country = countryValue;
    // state can be empty for some countries
    if (state !== '') {
      values.state = state;
      values.state = state;
      values.state = state;
    }

    // check if it is edit mode
    // edit mode if address prop is not null otherwise create mode
    if (address) {
      // extract the id of the address to update
      const stringValue = address['@id'];
      const id = extractId(stringValue);
      dispatch({
        type: actionTypes.API_USER_ADDRESS_EDIT_REQUESTED,
        payload: {
          ...values,
          id,
        },
      });
    } else {
      dispatch({
        type: actionTypes.API_USER_ADDRESS_CREATE_REQUESTED,
        payload: {
          ...values,
          user: user['@id'],
        },
      });
    }
    closeModal();
  };

  // fill custom country list with options
  const countryOptions = APP_COUNTRY_STATES.map(country => country.countryName);

  // fill custom states list with options
  const stateOptions =
    regions.length > 0 ? regions.map(region => region.name) : [];

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <div className="dialog-header" style={{paddingBottom: '20px'}}>
        <Typography variant="h4">
          {address ? (
            <FormattedMessage
              id={'dashboard.user_details.tab_address.edit_address'}
            />
          ) : (
            <FormattedMessage
              id={'dashboard.user_details.tab_address.create_address'}
            />
          )}
        </Typography>
      </div>
      <div className="dialog-content fix-labels">
        {/*First name / Last name*/}
        <div className="row gx-2">
          <div className="col-lg-6">
            <Field
              name="firstname"
              component={InputField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.first_name',
              })}
              outlined
              externalLabel
              outlineGroupClass="mb-fg"
              placeholder="First name"
            />
          </div>
          <div className="col-lg-6">
            <Field
              name="lastname"
              component={InputField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.last_name',
              })}
              outlined
              externalLabel
              outlineGroupClass="mb-fg"
              placeholder="Last name"
            />
          </div>
        </div>
        {/*Phone number*/}
        <Field
          name="phoneNumber"
          component={InputField}
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_address.phone_number',
          })}
          outlined
          externalLabel
          outlineGroupClass="mb-fg"
          placeholder="Phone number"
        />
        {/* Address 1 */}
        <Field
          name="lineOne"
          component={InputField}
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_address.address1',
          })}
          outlined
          externalLabel
          outlineGroupClass="mb-fg"
          placeholder="Address 1"
        />
        {/* Address 2 */}
        <Field
          name="lineTwo"
          component={InputField}
          label={intl.formatMessage({
            id: 'dashboard.user_details.tab_address.address2',
          })}
          outlined
          externalLabel
          outlineGroupClass="mb-fg"
          placeholder="Address 2"
        />
        {/* City / Postcode */}
        <div className="row gx-2">
          <div className="col-lg-6">
            <Field
              name="city"
              component={InputField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.city',
              })}
              outlined
              externalLabel
              outlineGroupClass="mb-fg"
              placeholder="City"
            />
          </div>
          <div className="col-lg-6">
            <Field
              name="zipcode"
              component={InputField}
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.post_code',
              })}
              outlined
              externalLabel
              outlineGroupClass="mb-fg"
              placeholder="Postcode"
            />
          </div>
        </div>
        {/* Country */}
        <div className="form-group autocomplete-sm mb-fg">
          <span className="small-label">
            {intl.formatMessage({
              id: 'dashboard.user_details.tab_address.country',
            })}
          </span>
          <CustomAutocomplete
            options={countryOptions}
            name="country"
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.country',
            })}
            changeHandler={handleCountryChange}
            value={country}
            error={errorMessage.country}
          />
        </div>
        {/* State - Displayed only for countries with states */}
        {showState && (
          <div className="form-group autocomplete-sm mb-fg">
            <span className="small-label">
              {intl.formatMessage({
                id: 'dashboard.user_details.tab_address.state',
              })}
            </span>

            <CustomAutocomplete
              options={stateOptions}
              name="state"
              label={intl.formatMessage({
                id: 'dashboard.user_details.tab_address.state',
              })}
              changeHandler={handleStateChange}
              value={state}
              error={errorMessage.state}
            />
          </div>
        )}

        {/*Set as default address*/}
        <div className="form-group edit-address-checkbox-row">
          <Field
            name="isDefault"
            component={CheckboxField}
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.set_as_default_address',
            })}
          />
        </div>
      </div>
      <div className="dialog-footer">
        <div className={clsx('d-flex', 'justify-content-center')}>
          {/*Cancel*/}
          <Button
            link
            className={clsx('dialog-button')}
            title={intl.formatMessage({id: 'actions.cancel'})}
            onClick={closeModal}
          />
          {/*Save*/}
          <Button
            type="submit"
            primary
            className={clsx('dialog-button')}
            title={
              address
                ? intl.formatMessage({id: 'actions.save'})
                : intl.formatMessage({id: 'actions.add'})
            }
          />
        </div>
      </div>
    </form>
  );
};

AddressForm = reduxForm({
  form: 'UserAddressForm',
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError(),
  validate,
})(AddressForm);

const mapStateToProps = (state, {address = null}) => {
  const initialValues = {};
  if (address) {
    initialValues.firstname = address.firstname;
    initialValues.lastname = address.lastname;
    initialValues.lineOne = address.lineOne;
    initialValues.lineTwo = address.lineTwo;
    initialValues.isDefault = address.isDefault;
    initialValues.city = address.city;
    initialValues.zipcode = address.zipcode;
    initialValues.phoneNumber = address.phoneNumber;
  }

  return {
    initialValues,
  };
};

AddressForm.propTypes = {
  /**
   * If not null, the address to be edited
   */
  address: PropTypes.object,
  user: PropTypes.object.isRequired,
};

// connect to redux store and inject intl
export default connect(mapStateToProps)(injectIntl(AddressForm));
