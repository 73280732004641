import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  items: [],
  loading: false,
  totalItems: -1,
  q: '',
};

export default function transactionPayoutReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.API_TRANSACTION_PAYOUT_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.API_TRANSACTION_PAYOUT_LIST_SUCCEEDED:
      return {
        ...state,
        loading: false,
        items: action.payload['hydra:member'],
        totalItems: action.payload['hydra:totalItems'],
      };
    case actionTypes.API_TRANSACTION_PAYOUT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.API_TRANSACTION_PAYOUT_LIST_SET_SEARCH_SUCCEEDED:
      return {
        ...state,
        q: action.payload.search,
      };

    case actionTypes.API_ADMIN_RESUME_PAYOUT_SUCCEEDED:
    case actionTypes.API_ADMIN_RETRY_PAYOUT_SUCCEEDED:
    case actionTypes.API_ADMIN_PAUSE_PAYOUT_SUCCEEDED:
      return {
        ...state,
        items: state.items.map(item => {
          if (item['@id'] === action.payload.payout['@id']) {
            return action.payload.payout;
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
