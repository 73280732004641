import {useDispatch, useSelector} from 'react-redux';
import {API_USER_BANK_DETAILS_REQUESTED} from '../../constants/actionTypes';
import {selectToken} from '../../store/slices/authSlice';

/**
 *  Get, of user's bank
 *
 * @param {string} id user id
 */
export default function useUserBank(id) {
  const token = useSelector(selectToken);
  const {banks, loading} = useSelector(state => state.userBankReducer);

  const dispatch = useDispatch();

  //   Get user bank
  const getUserBanks = () => {
    dispatch({
      type: API_USER_BANK_DETAILS_REQUESTED,
      payload: {token, id},
    });
  };

  return {
    getUserBanks,
    banks,
    loading,
  };
}
