//Base URLS
export const APP_SITE_URL = process.env.WEBSITE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const IMG_BASE_URL = '/images/';

export const OLD_ADMIN_PATH = process.env.REACT_APP_OLD_ADMIN_URL;
export const DASHBOARD_ROOT_PATH = '/admin-dashboard';

//USERS DASHBOARD
//User list
export const USERS_LIST_PATH = DASHBOARD_ROOT_PATH + '/users';
export const USERS_LIST_TAB_PATH = DASHBOARD_ROOT_PATH + '/users/:tab';
//User details
export const USER_DETAILS_PATH = DASHBOARD_ROOT_PATH + '/users/:id';
export const USER_ADDRESS_PATH = DASHBOARD_ROOT_PATH + '/users/:id/address';

//Sales list
export const SALES_LIST_PATH = DASHBOARD_ROOT_PATH + '/sales';
export const SALES_DETAILS_PATH = DASHBOARD_ROOT_PATH + '/sales/:saleId';
//Listings
export const LISTINGS_PATH = DASHBOARD_ROOT_PATH + '/listings';
export const LISTING_DETAILS_PATH = DASHBOARD_ROOT_PATH + '/listings/:id';

//Events
export const EVENTS_LIST_PATH = DASHBOARD_ROOT_PATH + '/events';
export const EVENTS_LIST_TAB_PATH = DASHBOARD_ROOT_PATH + '/events/:tab';
export const EVENT_DETAILS_PATH = DASHBOARD_ROOT_PATH + '/events/event/:id';
export const VENUE_DETAILS_PATH = DASHBOARD_ROOT_PATH + '/events/venue/:id';

//Transactions
export const TRANSACTIONS_PATH = DASHBOARD_ROOT_PATH + '/transactions';
export const TRANSACTIONS_TAB_PATH = DASHBOARD_ROOT_PATH + '/transactions/:tab';
export const TRANSACTIONS_PAYMENT_PATH =
  DASHBOARD_ROOT_PATH + '/transactions/payments';
export const TRANSACTIONS_PAYOUT_PATH =
  DASHBOARD_ROOT_PATH + '/transactions/payouts';

export const API_V2_TRANSACTIONS_URL = API_BASE_URL + '/api/v2/transactions';
export const API_V2_CREATE_SELLER_CHARGE_URL =
  API_BASE_URL + '/api/v2/seller_charges';

//Settings
export const SETTINGS_PATH = DASHBOARD_ROOT_PATH + '/settings';
export const PERMISSION_SETTINGS =
  DASHBOARD_ROOT_PATH + '/settings/permissions';
export const MARKETPLACE_FEE_SETTINGS =
  DASHBOARD_ROOT_PATH + '/settings/marketplace_fee';
export const TEAM_MEMBERS_LIST = DASHBOARD_ROOT_PATH + '/settings/team';
//Account settings
export const ACCOUNT_SETTINGS = '/account';

export const API_USER_ME_URL = API_BASE_URL + '/api/users/me.json';
export const API_USER_LOGOUT_URL = API_BASE_URL + '/admin/users/logout';
export const API_SALES_URL = API_BASE_URL + '/api/admin/sales';
export const API_SALE_DETAILS_URL = API_BASE_URL + '/api/admin/sales/:id';
export const API_V2_SALE_DETAILS_URL = API_BASE_URL + '/api/v2/sales/:id';
export const API_V2_SALE_ADD_PENALTY_URL =
  API_BASE_URL + '/api/v2/sale_penalties';
export const API_V2_SALE_CANCEL_URL = API_BASE_URL + '/api/v2/sales/:id/cancel';
export const API_V2_SALE_SET_AS_CANCELLED_URL =
  API_BASE_URL + '/api/v2/sales/:id/set_as_cancel';
export const API_V2_SALE_SET_AS_DELIVERED_URL =
  API_BASE_URL + '/api/v2/sales/:id/set_as_delivered';
export const API_V2_SALE_ADD_REFUND_URL =
  API_BASE_URL + '/api/v2/platform_refunds';
export const SALE_ETICKET_DISPLAY_URL =
  API_BASE_URL + '/api/tickets/etickets/:id/display';
export const SALE_MTICKET_DISPLAY_URL =
  API_BASE_URL + '/api/tickets/proof-mticket/:id/display';
export const API_V2_SALE_PAUSE_PAYOUT_URL =
  API_BASE_URL + '/api/v2/sales/:id/pause_payout';
export const API_V2_SALE_RESUME_PAYOUT_URL =
  API_BASE_URL + '/api/v2/sales/:id/resume_payout';

//Payment details
export const API_V2_GET_PAYMENT_METHODS_URL =
  API_BASE_URL + '/api/v2/stripe_payment_methods';

export const LOGGED_OUT_REDIRECT_URL = '/';

export const API_PERMISSIONS_URL = API_BASE_URL + '/api/admin/permissions';
export const API_ROLES_URL = API_BASE_URL + '/api/admin/roles';

export const API_USER_PICTURE_URL = API_BASE_URL + '';

export const API_USER_AUTOCOMPLETE_URL =
  API_BASE_URL + '/admin/users/autocomplete';
export const API_EVENT_AUTOCOMPLETE_URL =
  API_BASE_URL + '/admin/events/autocomplete';
export const API_COMPETITION_AUTOCOMPLETE_URL =
  API_BASE_URL + '/admin/competitions/autocomplete';

//PAYOUT URLS
export const API_V2_PAYOUTS_URL = API_BASE_URL + '/api/v2/payouts';
export const API_V2_PAYOUT_PAUSE_URL =
  API_BASE_URL + '/api/v2/payouts/:id/pause';
export const API_V2_PAYOUT_RESUME_URL =
  API_BASE_URL + '/api/v2/payouts/:id/resume';
export const API_V2_PAYOUT_RETRY_URL =
  API_BASE_URL + '/api/v2/payouts/:id/retry';

// User Details URL
export const API_V2_GET_USER_DETAILS = API_BASE_URL + '/api/v2/users/:id';
export const API_V2_SUSPEND_USER = API_BASE_URL + '/api/v2/users/:id/suspend';
export const API_V2_UNSUSPEND_USER =
  API_BASE_URL + '/api/v2/users/:id/unsuspend';
export const API_V2_SEND_GUEST_EMAIL =
  API_BASE_URL + '/api/v2/users/:id/send-guest-email';
export const API_V2_SEND_RESET_PASSWORD =
  API_BASE_URL + '/api/v2/users/:id/send-reset-password';
export const API_V2_GET_CHECK_EXISTING_EMAIL =
  API_BASE_URL + '/api/v2/users/checkemail/:id/:email';

// USER Address URLS
export const API_V2_USER_DETAILS_ADDRESS_URL =
  API_BASE_URL + '/api/v2/users/:id/addresses';
export const API_V2_USER_ADD_ADDRESS_URL = API_BASE_URL + '/api/v2/addresses';
export const API_V2_USER_EDIT_DELETE_ADDRESS_URL =
  API_BASE_URL + '/api/v2/addresses/:id';
export const API_V2_USER_ADDRESS_MAKE_AS_DEFAULT_URL =
  API_BASE_URL + '/api/v2/addresses/:id/make_as_default';

// User Bank Details URLS
export const API_V2_USER_BANK_LIST =
  API_BASE_URL + '/api/v2/users/:id/bank_accounts';
export const API_V2_USER_ADD_BANK_ACCOUNT =
  API_BASE_URL + '/api/v2/bank_accounts';
export const API_V2_USER_SET_BANK_ACCOUNT_AS_DEFAULT =
  API_BASE_URL + '/api/v2/users/:userId/default_bank_accounts';
export const API_V2_USER_DELETE_BANK_ACCOUNT =
  API_BASE_URL + '/api/v2/users/:userId/bank_accounts/:bankAccountId';

// User Balance
export const API_V2_USER_BALANCE_URL =
  API_BASE_URL + '/api/v2/users/:id/balance';

// User Activity
export const API_V2_USER_ACTIVITY_URL =
  API_BASE_URL + '/api/v2/users/:id/activity';

// auth
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const ME_URL = process.env.REACT_APP_ME_URL;

export const API_V2_TEAM_MEMBERS_URL = API_BASE_URL + '/api/v2/users/team-members';
