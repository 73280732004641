import * as types from '../constants/actionTypes';

const defaultState = {
  loading: false,
  signIn: false,
  token: null,
  refreshToken: null,
  userId: null,
  user: null,
  loginFailed: false,
  loginSuccess: false,
  loaded: false,
};

export default function authReducer(state = defaultState, action) {
  switch (action.type) {
    case types.API_USER_ME_SUCCEEDED:
      return {
        ...state,
        user: action.payload.user,
        loaded: true,
      };
    case types.USER_LOGOUT_SUCCEEDED:
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
}
