import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {reduxForm, Field, FieldArray} from 'redux-form';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InputField from '../Form/Fields/InputField';
import CheckboxField from '../Form/Fields/CheckboxField';
import ImageSmallField from '../Form/Fields/ImageSmallField';
import Button from '../Common/Button';
import IconButton from '../Common/IconButton';
import {ColumnDelete, PlusIconDanger} from '../../constants/images';
import ImageField from '../Form/Fields/ImageField';
import * as urls from '../../constants/urls';

//Block fields
const renderBlocks = ({fields, intl, onDeleteSection, meta: {error}}) => (
  <ul className="list-unstyled mb-0">
    {/*Blocks headers*/}
    {fields.length > 0 && (
      <div className="row form-group mb-0 align-items-end gx-fan-10 d-none d-lg-flex">
        <div className="col-lg-7">
          <span className="small-label">
            {intl.formatMessage({
              id: 'dashboard.events.venue_details.section_details.block_name',
            })}
          </span>
        </div>
        <div className="col-lg-5">
          <span className="small-label">
            {intl.formatMessage({
              id: 'dashboard.events.venue_details.section_details.seat_view',
            })}
          </span>
        </div>
      </div>
    )}
    {fields.map((item, index) => (
      <li key={index}>
        <div className="row form-group mb-0 align-items-end gx-fan-10 pb-2">
          <div className="col-lg-7">
            {/*Block name*/}
            <span className="small-label d-lg-none">
              {intl.formatMessage({
                id: 'dashboard.events.venue_details.section_details.block_name',
              })}
            </span>
            <Field
              name={`${item}.name`}
              component={InputField}
              outlined
              controlClass="bg-white"
            />
          </div>
          <div className="col-lg-5 pt-2 pt-lg-0">
            <span className="small-label d-lg-none">
              {intl.formatMessage({
                id: 'dashboard.events.venue_details.section_details.seat_view',
              })}
            </span>
            <div className="d-flex align-items-center">
              {/*Seat view*/}
              <Field
                name={`${item}.view`}
                component={ImageSmallField}
                wrapperClassName="form-group flex-column"
              />
              {/*Delete row*/}
              <IconButton
                className="ms-2"
                size="xs"
                variant="filled"
                onClick={() => fields.remove(index)}
              >
                <ColumnDelete style={{fontSize: 20}} />
              </IconButton>
            </div>
          </div>
        </div>
      </li>
    ))}
    {/*Action buttons*/}
    <li>
      <div className="row gx-fan-20 align-items-center mt-1">
        <div className="col-auto">
          {/*New block*/}
          <Button
            link
            small
            danger
            title={intl.formatMessage({
              id: 'dashboard.events.venue_details.section_details.new_block',
            })}
            icon={<PlusIconDanger />}
            onClick={() => fields.push({})}
          />
        </div>
        <div className="col-auto">
          {/*Delete section*/}
          <Button
            link
            small
            secondary
            title={intl.formatMessage({
              id: 'dashboard.events.venue_details.section_details.delete_section',
            })}
            icon={<CloseIcon style={{width: 12}} />}
            onClick={onDeleteSection}
          />
        </div>
      </div>
    </li>
  </ul>
);

//Sections field
const renderSections = ({fields, intl, meta: {error, submitFailed}}) => (
  <ul className="list-unstyled mb-0">
    {fields.map((section, index) => (
      <li key={index}>
        <Paper elevation={0} className="bg-fan-gray-light rounded mt-3">
          <Box pt={1} py={1.5} px={1}>
            <div className="row form-group align-items-center mb-1">
              <div className="col">
                <Typography variant="subtitle2" className="text-muted">
                  <FormattedMessage id="dashboard.events.venue_details.section_details.section_name" />
                </Typography>
              </div>
              {/*Is general admission*/}
              <div className="col-auto">
                <Field
                  name={`${section}.general`}
                  component={CheckboxField}
                  label={intl.formatMessage({
                    id: 'dashboard.events.venue_details.section_details.general_admission',
                  })}
                  size="default"
                  classes={{
                    formControlLabel: 'checkbox me-0',
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              {/*Section name*/}
              <div className="col">
                <Field
                  name={`${section}.name`}
                  component={InputField}
                  outlined
                  controlClass="bg-white"
                />
              </div>
            </div>
            <FieldArray
              name={`${section}.blocks`}
              component={renderBlocks}
              intl={intl}
              onDeleteSection={() => fields.remove(index)}
            />
          </Box>
        </Paper>
      </li>
    ))}
    <li>
      {/*New section*/}
      <Box mt={1.5}>
        <Button
          link
          small
          danger
          title={intl.formatMessage({
            id: 'dashboard.events.venue_details.new_section',
          })}
          icon={<PlusIconDanger />}
          onClick={() => fields.push({})}
        />
      </Box>
    </li>
  </ul>
);

/* Edit/Add Venue */
let VenueDetails = props => {
  const {intl} = props;
  //Router
  const history = useHistory();

  const submit = values => {};

  return (
    <div className="dashboard-wrapper">
      <div className="container mx-lg-0">
        {/* Header */}
        <div className="row align-items-center header-row pb-4 mb-1">
          <div className="col text-center text-lg-start">
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.events.add_venue" />
            </Typography>
          </div>
          <div className="col-auto d-none d-lg-block">
            {/*Cancel/Add buttons*/}
            <div className="row gx-3 align-items-center">
              <div className="col text-center">
                <Button
                  onClick={() =>
                    history.push(
                      urls.EVENTS_LIST_TAB_PATH.replace(':tab', 'venue')
                    )
                  }
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                />
              </div>
              <div className="col text-center">
                <Button
                  primary
                  className="w-100"
                  title={intl.formatMessage({id: 'actions.add'})}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-content">
          <form onSubmit={props.handleSubmit(submit)} autoComplete="off">
            <div className="row flex-column flex-column-reverse flex-lg-row gx-3">
              <div className="col-lg-8">
                {/*Venue details*/}
                <div className="card details-card mb-3">
                  <div className="card-body">
                    <div className="form-group static">
                      <Field
                        name="name"
                        component={InputField}
                        outlined
                        label={`${intl.formatMessage({id: 'dashboard.events.venue_details.venue_name'})}*`}
                        externalLabel
                      />
                    </div>
                    <div className="form-group mb-0">
                      <Field
                        name="address"
                        component={InputField}
                        outlined
                        label={`${intl.formatMessage({id: 'dashboard.events.venue_details.venue_address'})}*`}
                        externalLabel
                      />
                    </div>
                  </div>
                </div>
                {/*Sections*/}
                <div className="card details-card mb-3">
                  <div className="card-body">
                    <Typography variant="h4" className="mb-3">
                      {intl.formatMessage({
                        id: 'dashboard.events.venue_details.sections',
                      })}
                    </Typography>
                    <FieldArray
                      name="sections"
                      component={renderSections}
                      intl={intl}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card details-card mb-3 mb-lg-0">
                  <div className="card-body">
                    {/*Event image*/}
                    <Field
                      name="image"
                      component={ImageField}
                      label={`${intl.formatMessage({id: 'dashboard.events.venue_details.configuration_image'})}*`}
                      wrapperClassName="form-group"
                      fieldClassName="venue-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const validate = (values, props) => {
  const errors = {};
  return errors;
};

// connect component to redux-form
VenueDetails = reduxForm({
  form: 'VenueDetailsForm',
  validate,
  initialValues: {
    sections: [{blocks: [{}]}, {general: true}],
  },
})(VenueDetails);

export default injectIntl(VenueDetails);
