import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {API_TRANSACTION_PAYOUT_LIST_REQUESTED} from '../../constants/actionTypes';
import ArrowLink from '../Common/ArrowLink';
import SellerMarks from '../Sales/Details/SellerMark';
import StateIndicator from '../Common/StateIndicator';
import {inputTypes, PAYOUT_STATUSES} from '../../constants/enums';
import {InfoRedIcon, QuestionGreenIcon} from '../../constants/images';
import {TABLE_DEFAULT_BREAKPOINT} from '../../constants/appConstants';
import {Box, Typography} from '@material-ui/core';
import HintTooltip from '../Common/HintTooltip';
import {get} from 'lodash';
import useDateHelper from '../../hooks/utils/useDateHelper';
import useUserHelper from '../../hooks/useUserHelper';
import {DEFAULT_LIST_OPTIONS} from '../../hooks/common/useDataTable';
import useEventAutocomplete from '../../hooks/event/useEventAutocomplete';
import useCompetitionAutocomplete from '../../hooks/competition/useCompetitionAutocomplete';
import useUserAutocomplete from '../../hooks/user/useUserAutocomplete';
import {getResourceId, isEmpty} from '../../utils/functions';
import useFilters from '../../hooks/common/useFilters';
import {useMediaQuery} from 'react-responsive';
import {SALE_FILTER_STATUS_CHOICES} from '../../constants/sales';
import Adjustments from './Adjustments';
import useSearchParams from '../../hooks/utils/useSearchParams';
import {countActiveFilters, getParamsFromUrl} from '../../utils/filters';
import useDataTableState from '../../hooks/table/useDatatableState';

const DEFAULT_TABLE_STATE = {
  orderDirection: 'DESC',
  orderBy: 'createdAt',
  page: 1,
  itemsPerPage: 25,
};

const defaultParamsValue = {
  ...DEFAULT_TABLE_STATE,
  orderDirection: DEFAULT_TABLE_STATE.orderDirection.toLocaleLowerCase(),
};

export default function usePayoutList(containerRef = null, {version} = {}) {
  const dispatch = useDispatch();
  const items = useSelector(state => state.transactionPayoutReducer.items);
  const totalItems = useSelector(
    state => state.transactionPayoutReducer.totalItems
  );
  const loading = useSelector(state => state.transactionPayoutReducer.loading);
  const intl = useIntl();
  const dateHelper = useDateHelper();
  const userHelper = useUserHelper();
  const defaultListParams = useMemo(
    () => ({
      ...DEFAULT_LIST_OPTIONS,
    }),
    []
  );
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      new ResizeObserver(() => {
        try {
          setContainerWidth(containerRef.current.clientWidth);
        } catch (e) {
          console.warn('Unable to get clientWidth', e);
        }
      }).observe(containerRef.current);
    }
  }, [containerRef]);
  const [activeFilters, setActiveFilters] = useState(0);
  const [filterOpened, setFilterOpened] = useState(false);
  const {eventNamesSelector, getEventNames} = useEventAutocomplete();
  const {getCompetitionNames, competitionNamesSelector} =
    useCompetitionAutocomplete();
  const {getUserNames, userNamesSelector} = useUserAutocomplete();
  const isV2 = useMemo(() => version === 'v2', [version]);
  const {formatFilters} = useFilters(
    'v2',
    isV2 ? {dateVersion: 'v2'} : undefined
  );
  const [searchParams] = useSearchParams();
  const [ready, setReady] = useState(false);

  const filterItems = useMemo(() => {
    const optionSellerTypeChoices = [
      'isTrustableSeller',
      'isCustomDelivery',
      'isPremiumSeller',
      'isFanpassPro',
      'isInstantConfirm',
    ];

    const payoutStatusOptions = [
      ...Object.keys(PAYOUT_STATUSES).filter(
        s => ['CREATED', 'pending', 'completed'].indexOf(s) === -1
      ),
    ];

    const payoutTypeOptions = [
      'normal-payout',
      'bulk-payout',
      'fast-bulk-payout',
      'nope-payout',
    ];

    const saleStatusOptions = SALE_FILTER_STATUS_CHOICES;

    const choiceMapper = prefix => item => ({
      label: intl.formatMessage({id: prefix + item}),
      value: item,
    });

    const items = [
      {
        name: 'salesSeller',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.seller_name',
        }),
        inputType: inputTypes.AUTOCOMPLETE,
        freeSolo: true,
        textFieldProps: {
          onChange: e => getUserNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: userNamesSelector,
        label: false,
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.sellerName',
            }
          : {}),
      },
      {
        name: 'email',
        title: intl.formatMessage({id: 'dashboard.sales.filter.email_address'}),
        inputType: inputTypes.SEARCH,
        ...(isV2
          ? {
              outputName: 'sales.seller.email',
            }
          : {}),
      },
      {
        name: 'seller',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.option_type',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: optionSellerTypeChoices.map(choiceMapper('option_types.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'id',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.payout_id',
        }),
        inputType: inputTypes.SEARCH,
      },
      {
        name: 'status',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.payout_status',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: payoutStatusOptions.map(
          choiceMapper('dashboard.transactions.payout_status.')
        ),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'type',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.payout_type',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: payoutTypeOptions.map(
          choiceMapper('dashboard.transactions.payout_type.')
        ),
        classes: {checkboxRow: 'checkbox-filter-row'},
      },
      {
        name: 'paymentExecutionDate',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.payout_date',
        }),
        inputType: inputTypes.DATE_SELECT,
      },
      {
        name: 'salesHex',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.sales_id',
        }),
        inputType: inputTypes.SEARCH,
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.referenceHexa',
            }
          : {}),
      },
      {
        name: 'salesStatus',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.sales_status',
        }),
        inputType: inputTypes.CHECKBOXES,
        items: saleStatusOptions.map(choiceMapper('status.')),
        classes: {checkboxRow: 'checkbox-filter-row'},
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.saleStatus',
            }
          : {}),
      },
      {
        name: 'salesEventDate',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.event_date',
        }),
        inputType: inputTypes.DATE_SELECT,
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.eventDate',
            }
          : {}),
      },
      {
        name: 'salesEventName',
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.eventName',
            }
          : {}),
        title: intl.formatMessage({id: 'dashboard.sales.filter.event_title'}),
        inputType: inputTypes.AUTOCOMPLETE2,
        freeSolo: true,
        onInputChange: e => getEventNames(e.target.value),
        textFieldProps: {
          onChange: e => getEventNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: eventNamesSelector,
        label: false,
      },
      {
        name: 'competitionName',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.competition',
        }),
        inputType: inputTypes.AUTOCOMPLETE,
        freeSolo: true,
        textFieldProps: {
          onChange: e => getCompetitionNames(e.target.value),
        },
        useOptionsSelector: true,
        getOptionsSelector: competitionNamesSelector,
        label: false,
        ...(isV2
          ? {
              outputName: 'sales.saleMetadata.competitionName',
            }
          : {}),
      },
      {
        name: 'amount',
        title: intl.formatMessage({id: 'dashboard.transactions.filter.amount'}),
        inputType: inputTypes.ADVANCED_NUMBER,
      },
      {
        name: 'penaltyTotalAmount',
        title: intl.formatMessage({
          id: 'dashboard.transactions.filter.payout_penalty',
        }),
        inputType: inputTypes.ADVANCED_NUMBER,
      },
    ];

    return items;
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      const {filterValues} = getParamsFromUrl(searchParams);
      setActiveFilters(
        countActiveFilters(
          filterItems.map(item => item.name),
          filterValues
        )
      );
      setReady(true);
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getList = useCallback(
    (
      {sort, page, rowsPerPage, filters = {}, q} = defaultListParams,
      origin
    ) => {
      const sortMap = {
        bank: 'stripeBankName',
      };
      for (const key in sortMap) {
        if (sort && sort.by === key) {
          sort.by = sortMap[key];
        }
      }
      if (sort && sort.by === 'seller') {
        sort = [
          {by: 'sales.seller.firstname', direction: sort.direction},
          {by: 'sales.seller.lastname', direction: sort.direction},
        ];
      }
      dispatch({
        type: API_TRANSACTION_PAYOUT_LIST_REQUESTED,
        payload: {
          sort,
          page,
          rowsPerPage,
          filters,
          q,
        },
      });
    },
    [defaultListParams, dispatch]
  );

  const gbp = useMemo(() => 'GBP', []);

  const filterFormat = useCallback(
    filters => {
      if (!isEmpty(filters)) {
        let formatted = {...filters};
        // Format seller
        if (Array.isArray(filters.seller)) {
          if (!isV2) {
            for (const item of filters.seller) {
              formatted[`salesSeller.${item}`] = true;
            }
          } else {
            for (const sellerOption of formatted.seller) {
              formatted[`sales.seller.${sellerOption}`] = true;
            }
          }
          delete formatted.seller;
        }

        // Apply formatters for date and number
        formatted = formatFilters(formatted, filterItems);

        if (!isV2) {
          const filterNameMap = {
            competitionName: 'salesEvent.competition.name',
            salesEventName: 'salesEvent.name',
            salesEventDate: 'salesEvent.date',
          };

          for (const filterName in filterNameMap) {
            if (formatted[filterName] !== undefined) {
              formatted[filterNameMap[filterName]] = formatted[filterName];
              delete formatted[filterName];
            } else {
              const dateSuffixes = [
                '[last][number]',
                '[last][period]',
                '[range][start]',
                '[range][end]',
              ];
              for (const suffix of dateSuffixes) {
                const key = `${filterName}${suffix}`;
                if (formatted[key]) {
                  formatted[`${filterNameMap[filterName]}${suffix}`] =
                    formatted[key];
                  delete formatted[key];
                }
              }
            }
          }
        }

        return formatted;
      }
      return {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [filterItems]
  );

  const getResults = useCallback(
    p => {
      const {q, filterValues, ...sortAndPagination} = getParamsFromUrl(
        p ?? searchParams,
        defaultParamsValue
      );
      getList({...sortAndPagination, q, filters: filterFormat(filterValues)});
    },
    [filterFormat, getList, searchParams]
  );

  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'ASC',
      orderBy: 'name',
    },
    bindQueryParams: true,
    onChange: p => getResults(p),
  });

  const mapPayoutSale = useCallback(
    item => sale => {
      const currency = item.currency ?? 'GBP';
      const toBePaid = Math.max(0, get(sale, 'toBePaidAmount', 0));
      const sellingPrice = get(sale, 'transaction.sellerCurrencyAmount', 0);
      const payoutCurrency = isV2
        ? currency
        : get(sale, 'convertedPayoutCurrency', currency);
      const referenceHexa = get(
        sale,
        isV2 ? 'saleMetadata.referenceHexa' : 'referenceHexa'
      );
      const eventName = get(
        sale,
        isV2 ? 'saleMetadata.eventName' : 'ticket.event.name'
      );
      const eventDate = get(
        sale,
        isV2 ? 'saleMetadata.eventDate' : 'ticket.event.date'
      );
      const eventPostponed = isV2
        ? get(sale, 'saleMetadata.eventPostponed')
        : item?.event?.postponed;

      return {
        id: sale.id,
        reference_hexa: referenceHexa,
        sale_link: `/admin-dashboard/sales/${sale.id}`,
        event_name: eventName,
        sales_event: `${referenceHexa} ${eventName}`,
        sellingPrice:
          sellingPrice === null
            ? ''
            : intl.formatNumber(sellingPrice, {
                style: 'currency',
                currency: payoutCurrency ?? currency,
              }),
        amount:
          toBePaid === null
            ? ''
            : intl.formatNumber(toBePaid, {
                style: 'currency',
                currency: payoutCurrency ?? currency,
              }),
        penalty: {
          value: intl.formatNumber(sale.currentPenaltyAmount, {
            style: 'currency',
            currency: gbp,
          }),
          tooltip: sale.penaltiesDescriptions
            ? sale.penaltiesDescriptions.join(', ')
            : null,
        },
        reserve: intl.formatNumber(0, {style: 'currency', currency: gbp}),
        event_date: eventPostponed
          ? intl.formatMessage({id: 'dashboard.events.date_to_be_confirmed'})
          : dateHelper.formatDate(eventDate),
      };
    },
    [dateHelper, gbp, intl, isV2]
  );

  const canPayoutBePaused = row => {
    const penaltyFullyApplied: boolean = row.penaltyTotalAmount > 0 && row.penaltyTotalAmount >= row.totalSellingPrice; // Is penalty fully applied to current sale?
    const statusPreventingPause: string[] = [PAYOUT_STATUSES['in-transit'].value, PAYOUT_STATUSES['paid-out'].value, PAYOUT_STATUSES['refunded'].value, PAYOUT_STATUSES['paused'].value];

    return !penaltyFullyApplied && !statusPreventingPause.includes(row.status);
  };

  // Get messages explaining why the payout cannot be paused
  const getPayoutCannotPauseReasons = row => {
    const reasons: string[] = [
      intl.formatMessage({
        id: 'dashboard.transactions.payout.cannotPaused_tooltip.description',
      })
    ];

    let wrongPayoutStatusMessage: string|undefined = undefined;
    switch (row.status) {
      case PAYOUT_STATUSES['in-transit'].value:
        wrongPayoutStatusMessage = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_in_transit',
        });
        break;
      case PAYOUT_STATUSES['paid-out'].value:
        wrongPayoutStatusMessage = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_paid_out',
        });
        break;
      case PAYOUT_STATUSES['refunded'].value:
        wrongPayoutStatusMessage = intl.formatMessage({
          id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_refunded',
        });
        break;
    }
    if (wrongPayoutStatusMessage) {
      reasons.push(wrongPayoutStatusMessage);
    }

    const penaltyFullyApplied: boolean = row.penaltyTotalAmount >= row.totalSellingPrice; // Is penalty fully applied to current sale?
    if (penaltyFullyApplied) {
      reasons.push(intl.formatMessage({
        id: 'dashboard.transactions.payout.cannotPaused_tooltip.reason_fully_applied',
      }));
    }

    return {
      title: intl.formatMessage({
        id: 'dashboard.transactions.payout.cannotPaused_tooltip.title',
      }),
      items: reasons,
    };
  };

  const rows = useMemo(() => {
    const statusMap = Object.keys(PAYOUT_STATUSES)
      .map(status => ({
        ...PAYOUT_STATUSES[status],
        label: intl.formatMessage({
          id: `dashboard.transactions.payout_status.${PAYOUT_STATUSES[status].value}`,
        }),
      }))
      .reduce((res, item) => ({...res, [item.value]: item}), {});

    return items.map(item => {
      const currency = item.currency ?? 'GBP';
      const bankAccount =
        item.account &&
        item.account.substring(item.account.length, item.account.length - 8);
      const sppCount = (item.salePenaltyPayments ?? []).length;

      return {
        id: item.id,
        seller: !item.seller
          ? null
          : {
              user: {
                ...item.seller,
                name: userHelper.getName(item.seller),
                link: userHelper.getUserLink(item.seller),
              },
              options: userHelper.getSellerMarks(item.seller),
              id: getResourceId(item.seller),
            },
        // payout_type: {id: '#378P', type: 'Bulk'},
        type: {
          id: item.id,
          type: intl.formatMessage({
            id: `dashboard.transactions.payout_type.${item.type || 'standard'}`,
          }),
        },
        totalSellingPrice: intl.formatNumber(item.totalSellingPrice, {
          style: 'currency',
          currency,
        }),
        amount: intl.formatNumber(Math.max(item.amount, 0), {
          style: 'currency',
          currency,
        }),
        penaltyTotalAmount: intl.formatNumber(item.penaltyTotalAmount, {
          style: 'currency',
          currency: gbp,
        }),
        reserve: intl.formatNumber(get(item, 'reserve', 0), {
          style: 'currency',
          currency: gbp,
        }),
        bank: item.account
          ? `${item.stripeBankName ?? ''} ${bankAccount}` // show 8 last chars
          : '',
        paymentExecutionDate: dateHelper.formatDate(item.paymentExecutionDate),
        stripeArrivalDate: dateHelper.formatDate(item.stripeArrivalDate),
        status: !item.status
          ? null
          : {
              ...statusMap[item.status],
              // add tooltip to payout status if status is failed/unpaid
              ...(['failed', 'unpaid'].includes(item.status) &&
              item.stripeLastErrors
                ? {tooltip: item.stripeLastErrors}
                : {}),
            },
        subrows: {
          sales: (item.sales ?? []).map(mapPayoutSale(item)),
          length:
            (item.sales ?? []).length + (sppCount > 0 ? sppCount + 3.5 : 0),
          salePenaltyPayments: item.salePenaltyPayments.map(spp => ({
            ...spp,
            amount: intl.formatNumber(spp.amount, {
              style: 'currency',
              currency: gbp,
            }),
          })),
        },
        // for datatable
        rowIsExpandable: !item.reserve, // TODO: check payout type
        isPaused: item.status === PAYOUT_STATUSES.paused.value,
        canBePaused: canPayoutBePaused(item),
        pauseForbiddenReasons: getPayoutCannotPauseReasons(item),
      };
    });
  }, [items, intl, userHelper, gbp, dateHelper, mapPayoutSale]);

  const headerCells = useMemo(
    () => [
      {
        name: 'seller',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.seller_name',
        }),
        disablePadding: true,
        renderValue: val =>
          !val ? (
            ''
          ) : (
            <div className="ps-1">
              <Box mb={0.3}>
                <ArrowLink title={val.user.name} url={val.user.link} />
              </Box>
              {val.options && <SellerMarks id={val.id} items={val.options} />}
            </div>
          ),
        collapse: false,
        width: '15%',
        virtualWidth: 14.5,
        headerClassName: 'pl-1',
        cellClassName: 'pl-1',
      },
      {
        name: 'type',
        label: (
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'dashboard.transactions.payout.payout_id_type',
              }),
            }}
          />
        ),
        disablePadding: false,
        renderValue: ({id, type}) => (
          <div>
            <p className="mb-2 mb-lg-0">{id}</p>
            <p className="mb-0">{type}</p>
          </div>
        ),
        collapse: true,
        virtualWidth: 10,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'paymentExecutionDate',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.exec_date',
        }),
        disablePadding: false,
        collapse: true,
        virtualWidth: 11.5,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'stripeArrivalDate',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.est_arrival_date',
        }),
        disablePadding: false,
        collapse: true,
        width: '13%',
        virtualWidth: 10,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'totalSellingPrice',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.sell_price',
        }),
        disablePadding: false,
        collapse: true,
        virtualWidth: 8.5,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
        sortable: false,
      },
      {
        name: 'penaltyTotalAmount',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.penalty',
        }),
        disablePadding: false,
        collapse: true,
        virtualWidth: 7.5,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'reserve',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.reserve',
        }),
        disablePadding: false,
        collapse: true,
        virtualWidth: 7.5,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'amount',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.to_be_paid',
        }),
        disablePadding: false,
        collapse: true,
        virtualWidth: 9,
        wrap: false,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
      {
        name: 'bank',
        label: intl.formatMessage({
          id: 'dashboard.transactions.payout.bank_account',
        }),
        disablePadding: false,
        collapse: true,
        width: '162px',
        virtualWidth: 13,
        headerClassName: 'px-1',
        cellClassName: 'px-1 text-wrap',
        wrap: false,
      },
      {
        name: 'status',
        label: intl.formatMessage({id: 'dashboard.transactions.payout.status'}),
        renderValue: val =>
          !val ? (
            ''
          ) : (
            <div className="d-flex align-items-center overflow-hidden">
              <StateIndicator className="me-1" {...val} />
              {get(val, 'tooltip') && (
                <HintTooltip
                  icon={<InfoRedIcon style={{fontSize: 13}} />}
                  gray={false}
                  content={get(val, 'tooltip')}
                />
              )}
            </div>
          ),
        width: '140px',
        virtualWidth: 9,
        headerClassName: 'px-1',
        cellClassName: 'px-1',
      },
    ],
    [intl]
  );

  const isMobile = useMediaQuery({maxWidth: TABLE_DEFAULT_BREAKPOINT});

  const subWidthds = useMemo(() => {
    const customWidths =
      (headerCells ?? []).filter(item => !item.virtualWidth).length === 0;
    if (
      isMobile ||
      !customWidths ||
      headerCells.length < 5 ||
      containerWidth < 100
    ) {
      return null;
    }
    const subContainerWidth = containerWidth - (30 + 15 - 15) * 2;

    const toPercentage = (w, i) => {
      return (
        (((w * (containerWidth - 85)) / 100 - (i === 0 ? 30 : 0)) * 100) /
        subContainerWidth
      );
    };

    const computeWidths = (headerCells, defs) => {
      const res = [];

      // [[0, 1, 2, 3], [4], [5], [6], [7]]
      defs.forEach((cols, i) => {
        let w = 0;
        (cols ?? []).forEach(colIndex => {
          w += headerCells[colIndex].virtualWidth ?? 0;
        });
        res[i] = w;
      });

      return res;
    };

    const saleWidths = computeWidths(headerCells, [
      [0, 1, 2, 3],
      [4],
      [5],
      [6],
      [7],
      [8],
    ]).map(toPercentage);

    const salePenaltyPaymentWidths = computeWidths(headerCells, [
      [0, 1, 2, 3, 4],
      [5, 6, 7],
      [8],
    ]).map(toPercentage);

    return {saleWidths, salePenaltyPaymentWidths};
  }, [headerCells, containerWidth, isMobile]);

  //Subtable rendering procedure
  const renderSubtable = useCallback(
    ({sales: items, salePenaltyPayments}) => {
      const {saleWidths = [], salePenaltyPaymentWidths = []} = subWidthds;
      const widthStyle = i =>
        !Array.isArray(saleWidths)
          ? {}
          : {
              style: {
                width: `${saleWidths[i]}%`,
                paddingLeft: 5,
                paddingRight: 5,
              },
            };

      return (
        <div>
          {/*Header*/}
          <div className="subheader py-cwb-15">
            <div className="row">
              <div className="col-lg-3" {...widthStyle(0)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.sales_event',
                  })}
                </Typography>
              </div>
              <div className="col-lg-1" {...widthStyle(1)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.sell_price',
                  })}
                </Typography>
              </div>
              <div className="col-lg-1" {...widthStyle(2)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.penalty',
                  })}
                </Typography>
              </div>
              <div className="col-lg-1" {...widthStyle(3)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.reserve',
                  })}
                </Typography>
              </div>
              <div className="col-lg-1" {...widthStyle(4)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.to_be_paid',
                  })}
                </Typography>
              </div>
              <div className="col-lg-2" {...widthStyle(5)}>
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.transactions.payout.event_date',
                  })}
                </Typography>
              </div>
            </div>
          </div>
          {/*Content*/}
          <div className="content disable-scroll-auto">
            {items.map((item, key) => (
              <div key={key} className="row">
                <div className="col-lg-3" {...widthStyle(0)}>
                  <Typography variant="body2" className="text-truncate">
                    <div className="d-flex">
                      <ArrowLink
                        className="w-auto flex-shrink-0"
                        title={`#${item.reference_hexa} ${item.event_name}`}
                        url={item.sale_link}
                      />
                    </div>
                  </Typography>
                </div>
                <div className="col-lg-1" {...widthStyle(1)}>
                  <Typography variant="body2" className="text-truncate">
                    {item.sellingPrice}
                  </Typography>
                </div>
                <div className="col-lg-1" {...widthStyle(2)}>
                  <div className="d-flex align-items-center">
                    <span className="me-1">{item.penalty.value}</span>
                    {item.penalty.tooltip && (
                      <Box mt={0.1} className="ms-1 d-inline-flex">
                        <HintTooltip
                          id={item.id}
                          gray={false}
                          icon={<QuestionGreenIcon style={{fontSize: 12}} />}
                          content={
                            <Typography variant="subtitle2">
                              {item.penalty.tooltip}
                            </Typography>
                          }
                        />
                      </Box>
                    )}
                  </div>
                </div>
                <div className="col-lg-1" {...widthStyle(3)}>
                  <Typography variant="body2" className="text-truncate">
                    {item.reserve}
                  </Typography>
                </div>
                <div className="col-lg-1" {...widthStyle(4)}>
                  <Typography variant="body2" className="text-truncate">
                    {item.amount}
                  </Typography>
                </div>
                <div className="col-lg-2" {...widthStyle(5)}>
                  <Typography variant="body2" className="text-truncate">
                    {item.event_date}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
          {(salePenaltyPayments ?? []).length > 0 && (
            <Adjustments
              items={salePenaltyPayments}
              widths={salePenaltyPaymentWidths}
              version={version}
            />
          )}
        </div>
      );
    },
    [intl, subWidthds, version]
  );

  useEffect(() => {
    if (ready) {
      getResults();
    }
  }, [ready]);

  return {
    getList,
    rows,
    items,
    loading,
    headerCells,
    renderSubtable,
    totalItems,
    activeFiltersCount: activeFilters,
    filterOpened,
    setFilterOpened,
    filterItems,
    containerWidth,
    tableState,
    filterFormat,
    getResults,
  };
}
