import * as types from '../constants/actionTypes';

const defaultState = {
  loading: false,
  error: null,
  balance: {
    balance_and_total_volume: [],
    payout_and_reserve: [],
  },
};

export default function userBalanceReducer(state = defaultState, action) {
  const {type, payload} = action;
  switch (type) {
    case types.API_USER_BALANCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.API_USER_BALANCE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        balance: payload.data,
      };
    case types.API_USER_BALANCE_FAILED:
      return {
        ...state,
        loading: false,
        balance: {
          balance_and_total_volume: [],
          payout_and_reserve: [],
        },
      };
    default:
      return state;
  }
}
