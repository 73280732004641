import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {API_EVENT_AUTOCOMPLETE_REQUESTED} from '../../constants/actionTypes';
import {isEmpty} from '../../utils/functions';

export default function useEventAutocomplete() {
  const eventNamesSelector = useCallback(
    state => state.eventReducer.eventNames,
    []
  );
  const dispatch = useDispatch();

  const getEventNames = useCallback(
    term => {
      //Trigger search upon 2 characters or more
      if (!isEmpty(term) && term.length > 1) {
        dispatch({
          type: API_EVENT_AUTOCOMPLETE_REQUESTED,
          payload: {term},
        });
      }
    },
    [dispatch]
  );

  return {eventNamesSelector, getEventNames};
}
