import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {SELLER_MARKS} from '../../constants/enums';

export default function useTicketHelper() {
  const intl = useIntl();

  const getEvent = useCallback(ticket => {
    return ticket && (ticket.event || ticket.concert || ticket.sport_event);
  }, []);

  const getSaleEvent = useCallback(sale => getEvent(sale && sale.ticket), []);

  const getSellerMarks = useCallback(seller => {
    const marks = [];
    const map = {
      isTrustableSeller: SELLER_MARKS.MARK_TRUSTED,
      isCustomDelivery: SELLER_MARKS.MARK_DELIVERY,
      isPremiumSeller: SELLER_MARKS.MARK_PREMIUM,
      isFanpassPro: SELLER_MARKS.MARK_PRO,
      isInstantConfirm: SELLER_MARKS.MARK_INSTANT,
      // For old list endpoint support
      // TODO: remove when list endpoint use camelCase for object properties name
      is_trustable_seller: SELLER_MARKS.MARK_TRUSTED,
      is_custom_delivery: SELLER_MARKS.MARK_DELIVERY,
      is_premium_seller: SELLER_MARKS.MARK_PREMIUM,
      is_fanpass_pro: SELLER_MARKS.MARK_PRO,
      is_instant_confirm: SELLER_MARKS.MARK_INSTANT,
    };

    for (const prop in map) {
      if (seller && seller[prop]) {
        marks.push(map[prop]);
      }
    }

    return marks;
  }, []);

  const formatTicketType = useCallback(ticket => {
    let type = ticket && ticket.type;
    if (type === 'paper' && (ticket.hasMembership || ticket.has_membership)) {
      type = 'membership';
    }

    return type ? intl.formatMessage({id: 'ticket.type.' + type}) : '';
  }, []);

  const res = useMemo(
    () => ({
      getEvent,
      getSaleEvent,
      getSellerMarks,
      formatTicketType,
    }),
    [formatTicketType, getEvent, getSaleEvent, getSellerMarks]
  );

  return res;
}
