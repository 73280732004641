import {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import {reduxForm} from 'redux-form';
import {connect, useSelector} from 'react-redux';
import Button from '../Common/Button';
import Modal from '../Widgets/Modal';
import TeamItem from './Team/TeamItem';
import EditUserForm from './Team/EditUserForm';
import ConfirmDialog from '../Widgets/ConfirmDialog';
import {TeamMember} from './types';
import {useGetMembersQuery} from '../../services/teamMemberApi';
import {useGetRolesQuery} from '../../services/roleApi';
import usePermission from '../../Auth/usePermission';
import {TeamPermission} from '../../constants/teamPermission';
import {useTeamSettingsMutations} from '../../hooks/settings/team/useTeamSettings';
import {selectUser} from '../../store/slices/authSlice';

/* Team Settings */
let TeamSettings = () => {
  const user = useSelector(selectUser);

  const intl = useIntl();
  const {hasPermission} = usePermission();

  const {data, refetch: refetchMembers} = useGetMembersQuery(
    {showProgressDialog: true},
    {refetchOnMountOrArgChange: true}
  );
  const {data: roles} = useGetRolesQuery();

  const toggleFormShown = (isNew = false) => {
    if (isNew) {
      // Reset selected member for editing when adding new member
      setSelectedMember(null);
    }

    setEditFormShown(!editFormShown);
    setIsNew(isNew);
  };

  const [deleteConfirmShown, setDeleteConfirmShown] = useState<boolean>(false);
  const [editFormShown, setEditFormShown] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

  const {
    isAddSuccess,
    isEditSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    isSuspendMemberSuccess,
    isUnsuspendMemberSuccess,
    onAddMember,
    onEditMember,
    onDeleteMember,
    onMemberRolesChange,
    onMemberSuspend,
    onMemberUnsuspend,
  } = useTeamSettingsMutations();

  useEffect(() => {
    if (isAddSuccess) {
      setEditFormShown(false);
      refetchMembers();
    }
  }, [isAddSuccess, refetchMembers]);

  useEffect(() => {
    if (isEditSuccess) {
      setEditFormShown(false);
      refetchMembers();
    }
  }, [isEditSuccess, refetchMembers]);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetchMembers();
    }
  }, [isUpdateSuccess, refetchMembers]);

  useEffect(() => {
    if (isDeleteSuccess) {
      refetchMembers();
    }
  }, [isDeleteSuccess, refetchMembers]);

  useEffect(() => {
    if (isSuspendMemberSuccess) {
      refetchMembers();
    }
  }, [isSuspendMemberSuccess, refetchMembers]);

  useEffect(() => {
    if (isUnsuspendMemberSuccess) {
      refetchMembers();
    }
  }, [isUnsuspendMemberSuccess, refetchMembers]);

  return (
    <>
      {/* Edit/Add edit modal */}
      {editFormShown && (
        <Modal
          width={328}
          emptyModal
          title={intl.formatMessage({id: 'dashboard.settings.team.add_user'})}
          opened={editFormShown}
          handleClose={toggleFormShown}
        >
          <EditUserForm
            isNew={isNew}
            member={selectedMember}
            onAdd={onAddMember}
            onEdit={onEditMember}
            toggleModal={toggleFormShown}
          />
        </Modal>
      )}
      {/* Delete confirm dialog */}
      {deleteConfirmShown && (
        <ConfirmDialog
          open={deleteConfirmShown}
          title={intl.formatMessage({
            id: 'actions.confirmation_popup.areYouSure',
          })}
          onOk={() => {
            if (selectedMember) {
              onDeleteMember(selectedMember);
            }
            setDeleteConfirmShown(false);
          }}
          onClose={() => {
            setSelectedMember(null);
            setDeleteConfirmShown(false);
          }}
          onCancel={() => {
            setSelectedMember(null);
            setDeleteConfirmShown(false);
          }}
        >
          <Typography variant="body2">
            <FormattedMessage id="actions.confirmation_popup.description" />
          </Typography>
        </ConfirmDialog>
      )}
      <div className="dashboard-wrapper settings team">
        <div className="container">
          <div className="row header-row align-items-center pb-lg-4 mb-lg-1">
            <div className="col-lg text-center text-lg-start">
              <Typography variant="h3" className="font-weight-bolder">
                {intl.formatMessage({id: 'dashboard.settings.team.title'})}
              </Typography>
            </div>
            {hasPermission(TeamPermission.Add) && (
              <div className="col-lg-auto d-none d-lg-block">
                <Button
                  icon={<AddIcon />}
                  primary
                  className="dashboard-btn"
                  title={intl.formatMessage({
                    id: 'dashboard.settings.team.add_user',
                  })}
                  onClick={() => toggleFormShown(true)}
                />
              </div>
            )}
          </div>
          <div className="row gy-2 gy-lg-3 gx-3">
            {data?.items.map((item: TeamMember, index) => (
              <div key={index} className="col col-lg-4">
                <TeamItem
                  member={item}
                  onEditMenu={() => {
                    setSelectedMember(item);
                    toggleFormShown(false);
                  }}
                  onDeleteMenu={() => {
                    setSelectedMember(item);
                    setDeleteConfirmShown(true);
                  }}
                  roles={roles}
                  onRolesChange={onMemberRolesChange}
                  onSuspend={onMemberSuspend}
                  onUnsuspend={onMemberUnsuspend}
                  isMyself={item.email === user?.email}
                />
              </div>
            ))}
          </div>
          <div className="d-lg-none mt-3">
            {hasPermission(TeamPermission.Add) && (
              <Button
                icon={<AddIcon />}
                primary
                className="dashboard-btn"
                title={intl.formatMessage({
                  id: 'dashboard.settings.team.add_user',
                })}
                onClick={() => toggleFormShown(true)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const validate = () => {
  return {};
};

TeamSettings = reduxForm({
  form: 'TeamSettingsForm',
  enableReinitialize: true,
  validate,
})(TeamSettings);

const mapStateToProps = state => {
  return {
    initialValues: {},
  };
};

export default connect(mapStateToProps)(TeamSettings);
