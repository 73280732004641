import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {API_COMPETITION_AUTOCOMPLETE_REQUESTED} from '../../constants/actionTypes';
import {isEmpty} from '../../utils/functions';

export default function useCompetitionAutocomplete() {
  const competitionNamesSelector = useCallback(
    state => state.competitionReducer.competitionNames,
    []
  );
  const dispatch = useDispatch();

  const getCompetitionNames = useCallback(
    term => {
      //Trigger search upon 2 characters or more
      if (!isEmpty(term) && term.length > 1) {
        dispatch({
          type: API_COMPETITION_AUTOCOMPLETE_REQUESTED,
          payload: {term},
        });
      }
    },
    [dispatch]
  );

  return {competitionNamesSelector, getCompetitionNames};
}
