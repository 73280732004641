import {
  API_PERMISSION_LIST_FAILED,
  API_PERMISSION_LIST_SUCCEEDED,
} from '../constants/actionTypes';

const defaultState = {
  items: [],
  loaded: false,
};

export default function permissionReducer(state = defaultState, action) {
  switch (action.type) {
    case API_PERMISSION_LIST_SUCCEEDED:
      return {
        ...state,
        items: action.payload.result,
        loaded: true,
      };
    case API_PERMISSION_LIST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}
