import React from 'react';

import {FormControl, FormHelperText} from '@material-ui/core';
import MultiSelect from '../../Common/MultiSelect';

const MultiSelectField = props => {
  const {
    input,
    meta: {touched, error},
    ...others
  } = props;

  return (
    <FormControl fullWidth {...{error: touched && error}}>
      <MultiSelect
        {...others}
        name={input.name}
        values={Array.isArray(input.value) ? input.value : []}
        onChange={input.onChange}
      />
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

export default MultiSelectField;
