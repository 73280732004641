import {FormHelperText} from '@material-ui/core';
import React, {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {touch} from 'redux-form';
import CustomSelect from '../../Common/Select';

/**
 * This component renders a select field for redux-form
 *
 * @param props
 * @returns {*}
 */

const SelectField = props => {
  const {
    label,
    excludeLabel,
    input: {onChange, value, name},
    labelField = 'label',
    valueField = 'value',
    items,
    customOnChange,
    meta,
    ...custom
  } = props;
  const {touched, error} = meta;
  const dispatch = useDispatch();
  const extra = {label};
  if (excludeLabel) {
    delete extra.label;
  }

  const onSelectChange = val => {
    if (!touched) {
      dispatch(touch(meta.form, props.input.name));
    }
    onChange(val);
    customOnChange && customOnChange(val);
  };

  return (
    <div className="form-group m-0">
      {props.externalLabel && label && (
        <span className="small-label pb-2">{label}</span>
      )}
      <CustomSelect
        {...custom}
        name={name}
        value={value}
        onChange={onSelectChange}
        formControlProps={{error: touched && !!error}}
        items={items.map(item => ({
          ...item,
          label: item[labelField],
          value: item[valueField],
        }))}
        extraChild={
          <>
            {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
          </>
        }
        {...extra}
      />
    </div>
  );
};

export default SelectField;
