import {get} from 'lodash';
import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  items: null,
  loaded: false,
  totalItems: 0,
  totalPages: 0,
  page: 1,
  limit: 100,
  loading: false,
  q: '', // keyword search
  details: {}, // key/value,
  sellerPaymentMethods: [],
  notFound: [],
  refundAdded: false,
  penaltyAdded: false,
  sellerChargeCreated: false,
};

export default function saleReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.API_ADMIN_SALE_DETAILS_REQUESTED:
      return {
        ...state,
        sellerPaymentMethods: [],
      };
    case actionTypes.API_ADMIN_SALE_DETAILS_SUCCEEDED:
    case actionTypes.API_ADMIN_SALE_CANCEL_SUCCEEDED:
    case actionTypes.API_ADMIN_SALE_SET_AS_CANCELLED_SUCCEEDED:
    case actionTypes.API_ADMIN_SALE_SET_AS_DELIVERED_SUCCEEDED:
    case actionTypes.API_ADMIN_SALE_PAUSE_PAYOUT_SUCCEEDED:
    case actionTypes.API_ADMIN_SALE_RESUME_PAYOUT_SUCCEEDED:
      return {
        ...state,
        details: action.payload.sale,
      };
    case actionTypes.API_ADMIN_SALE_DETAILS_FAILED:
      const newState = {...state, sellerPaymentMethods: []};
      if (
        get(action, 'payload.error.statusCode') === 404 &&
        !newState.notFound.includes(action.payload.error.id)
      ) {
        newState.notFound = [...newState.notFound, action.payload.error.id];
      }
      return newState;
    case actionTypes.API_ADMIN_USER_PAYMENT_METHOD_LIST_SUCCEEDED:
      if (action.payload.seller) {
        return {
          ...state,
          sellerPaymentMethods: action.payload.items || [],
        };
      }
      return state;
    case actionTypes.API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED:
      return {
        ...state,
        sellerChargeCreated: false,
      };
    case actionTypes.API_ADMIN_CREATE_SELLER_CHARGE_SUCCEEDED:
      return {
        ...state,
        sellerChargeCreated: true,
      };
    case actionTypes.API_ADMIN_SALE_NOTE_SUCCEEDED:
      return {
        ...state,
        details: {...state.details, note: action.payload.note},
      };
    case actionTypes.API_ADMIN_SALE_ADD_PENALTY_REQUESTED:
      return {
        ...state,
        penaltyAdded: false,
      };
    case actionTypes.API_ADMIN_SALE_ADD_PENALTY_SUCCEEDED:
      return {
        ...state,
        penaltyAdded: true,
      };
    case actionTypes.API_ADMIN_SALE_NOTE_FAILED:
      return {
        ...state,
      };
    case actionTypes.API_ADMIN_SALE_ADD_REFUND_REQUESTED:
      return {
        ...state,
        refundAdded: false,
      };
    case actionTypes.API_ADMIN_SALE_ADD_REFUND_SUCCEEDED:
      return {
        ...state,
        refundAdded: true,
      };
    default:
      return state;
  }
}
