import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '../../Common/IconButton';
import {FILTER_ICON, RefreshSearchResultsIcon} from '../../../constants/images';
import DataTable from '../../Widgets/DataTable';
import * as urls from '../../../constants/urls';
import useSaleFilter from '../../../hooks/sale/useSaleFilter';
import useFilters from '../../../hooks/common/useFilters';
import {SALES_DETAILS_PERMISSIONS} from '../../../constants/permissions';
import Filters from '../../Widgets/Filters';
import KeywordSearchForm, {KEYWORD_FIELD} from '../../Form/KeywordSearchForm';
import {
  countActiveFilters,
  getParamsFromUrl,
  getUrlSearchParams,
} from '../../../utils/filters';
import useSearchParams from '../../../hooks/utils/useSearchParams';
import useDataTableState from '../../../hooks/table/useDatatableState';
import {useGetSalesQuery} from '../../../services/saleApi';
import useSaleListHelper from './useSaleListHelper';
import {useDispatch} from 'react-redux';
import {enqueueSnackbar} from '../../../actions/snackbarActions';
import usePermission from '../../../Auth/usePermission';
import ActiveFilters from '../../Widgets/ActiveFilters';

const DEFAULT_TABLE_STATE = {
  orderDirection: 'DESC',
  orderBy: 'createdAt',
  page: 1,
  itemsPerPage: 25,
};

const defaultParamsValue = {
  ...DEFAULT_TABLE_STATE,
  orderDirection: DEFAULT_TABLE_STATE.orderDirection.toLocaleLowerCase(),
};

/* Sale List using API V2 via RTK Query */
const SaleList = props => {
  const {intl} = props;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {columnDefs, mapTableSale, parseParams, parseFilterValues} =
    useSaleListHelper();
  const {formatFilters} = useFilters('v2', {dateVersion: 'v2'});
  const {filterItems} = useSaleFilter('v2');
  const {hasPermissions} = usePermission();
  const [filterOpened, setFilterOpened] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState([]);
  const [ready, setReady] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [params, setParams] = useState(() => {
    const {q, filterValues, page, sort, rowsPerPage} = getParamsFromUrl(
      searchParams,
      defaultParamsValue
    );

    return {
      q,
      filters: formatFilters(parseFilterValues(filterValues), filterItems),
      page,
      sort,
      rowsPerPage,
    };
  });

  const parsedParams = useMemo(
    () => parseParams(params, {timestamp: true}),
    [params, parseParams]
  );

  const {data, isFetching, isLoading, isUninitialized} = useGetSalesQuery(
    {
      ...parsedParams,
      errorMessage: intl.formatMessage({id: 'api_error'}),
    },
    {skip: !ready}
  );

  const tableState = useDataTableState({
    defaultValues: DEFAULT_TABLE_STATE,
    bindQueryParams: true,
    onChange: p => getResults(p),
  });

  const getResults = useCallback(
    p => {
      const {q, filterValues, page, sort, rowsPerPage} = getParamsFromUrl(
        p ?? searchParams,
        defaultParamsValue
      );
      setParams({
        q,
        filters: formatFilters(parseFilterValues(filterValues), filterItems),
        page,
        sort,
        rowsPerPage,
      });
      // getSaleList({ ...sortAndPagination, q, filters: formatFilters(filterValues, filterItems) });
    },
    [filterItems, formatFilters, parseFilterValues, searchParams]
  );
  const totalItems = useMemo(
    () => (data ? data['hydra:totalItems'] ?? 0 : 0),
    [data]
  );

  const sales = useMemo(
    () => (data ? (data['hydra:member'] ?? []).map(mapTableSale) : []),
    [data, mapTableSale]
  );

  const handleRowClick = useCallback((itemId, event) => {
    const detailsUrl = urls.SALES_DETAILS_PATH.replace(':saleId', itemId);
    // to be opened in a new TAB and not in the same tab
    if (event && window.getSelection().toString() == '') {
      window.open(detailsUrl, '_blank');
    }
  }, []);

  const onFilter = useCallback(
    (filters, p) => {
      // Dispatch action to get results
      getResults(p);
      // Close filter modal
      setFilterOpened(false);
    },
    [getResults]
  );

  const onFilterClose = useCallback(() => {
    setFilterOpened(false);
  }, [setFilterOpened]);

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    getResults(getUrlSearchParams(searchParams));

    setTimeout(() => {
      if (!isFetching && !isLoading) {
        setIsRefreshing(false);
      }
    }, 500);
  }, [getResults]);

  const onSearchParamsChange = useCallback(
    (
      activeFiltersForDisplay: {id: string; title: any; values: any}[],
      applied: boolean,
      cleared: boolean
    ) => {
      // Set active filters to pass to ActiveFilters component
      // Do this when any change to searchParams is made,
      // so we're not only listening to the applyFilters action
      if (applied && !cleared) {
        // Set active filters that have been applied to the search query
        setActiveFilters(activeFiltersForDisplay);
      } else if (cleared) {
        // Clear active filters
        setActiveFilters([]);
      }
    },
    []
  );

  useEffect(() => {
    const id = setTimeout(() => {
      const {filterValues} = getParamsFromUrl(searchParams);
      setActiveFiltersCount(
        countActiveFilters(
          filterItems.map(item => item.name),
          filterValues
        )
      );
      setReady(true);
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, filterItems]);

  useEffect(() => {
    if (filterOpened) {
      document.body.classList.add('right-modal-open');
    } else {
      document.body.classList.remove('right-modal-open');
    }
  }, [filterOpened]);

  return (
    <Fragment>
      <Filters
        form="SaleListFilterForm"
        opened={filterOpened}
        translations={{
          filter: intl.formatMessage({id: 'actions.filter'}),
          close: intl.formatMessage({id: 'actions.close'}),
          clear: intl.formatMessage({id: 'actions.clear'}),
          apply: intl.formatMessage({id: 'actions.apply'}),
        }}
        items={filterItems}
        onClose={onFilterClose}
        onFilter={onFilter}
        onSearchParamsChange={onSearchParamsChange}
        onClear={onRefresh}
        filterAdditionalParams={{page: 1}}
        clearAdditionalParams={{[KEYWORD_FIELD]: '', page: 1}}
      />

      <div className="dashboard-wrapper">
        <div className="container-fluid">
          <div className="row align-items-center header-row pb-4 mb-1">
            <div className="col text-center text-lg-start">
              {/* Header */}
              <Typography variant="h3" className="font-weight-bolder">
                <FormattedMessage id="dashboard.sales.sales_list" />
              </Typography>
            </div>
          </div>
          <div className="dashboard-content">
            <div className="row align-items-center justify-content-between search-row">
              <div className="col-xl-4 col-lg-8 d-flex">
                {/* Search */}
                <KeywordSearchForm
                  updateUrlOnChange
                  additionalParams={{page: 1}}
                  onSearch={(v, p) => getResults(p)}
                />
                {/* Refresh button */}
                <Box ml={1} mt={1}>
                  <IconButton
                    variant="filled"
                    size="xs"
                    onClick={onRefresh}
                    style={{
                      transform:
                        (isFetching || isLoading) && isRefreshing
                          ? 'rotate(360deg)'
                          : 'none',
                      transition: 'transform 0.5s',
                    }}
                  >
                    <RefreshSearchResultsIcon style={{width: 18, height: 14}} />
                  </IconButton>
                </Box>
                {/* Filter Button on mobile view only */}
                <Box ml={1} className="d-block d-lg-none">
                  <IconButton
                    className="m-0"
                    onClick={() => setFilterOpened(true)}
                    {...(activeFiltersCount
                      ? {numberLabel: activeFiltersCount}
                      : {})}
                  >
                    {FILTER_ICON}
                  </IconButton>
                </Box>
              </div>
              {/* Button toolbar */}
              <div className="col-auto d-none d-lg-block">
                <div className="d-flex">
                  {/* Filter button */}
                  <IconButton
                    className="me-0"
                    onClick={() => setFilterOpened(true)}
                    {...(activeFiltersCount
                      ? {numberLabel: activeFiltersCount}
                      : {})}
                  >
                    {FILTER_ICON}
                  </IconButton>
                </div>
              </div>
            </div>
            <ActiveFilters
              items={activeFilters}
              onFilterRemoved={onRefresh}
              clearAdditionalParams={{[KEYWORD_FIELD]: '', page: 1}}
            />
            {/* Data Table */}
            <DataTable
              className="sales-list"
              canSelect={false}
              empty={!isUninitialized && sales.length === 0}
              loading={isLoading || isFetching}
              clickableRow={hasPermissions(SALES_DETAILS_PERMISSIONS)}
              // pass onclick handler when user has required permissions
              onRowClick={
                hasPermissions(SALES_DETAILS_PERMISSIONS)
                  ? handleRowClick
                  : undefined
              }
              headCells={columnDefs}
              rows={sales}
              total={totalItems}
              handleParameterChange={tableState.handleDataTableParamChange}
              defaultOrder={tableState.orderDirection.toLowerCase()}
              defaultOrderBy={tableState.orderBy}
              defaultRowPerPage={tableState.pageSize}
              currentPage={tableState.page}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(SaleList);
