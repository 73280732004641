import {useSelector} from 'react-redux';
import {selectToken} from '../store/slices/authSlice';
import {useEffect} from 'react';
import axios from 'axios';

/**
 * Adds auth token to axios
 */
const AxiosAuthorizationToken = () => {
  const token = useSelector(selectToken);

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, [token]);

  return null;
};

export default AxiosAuthorizationToken;
