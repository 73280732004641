import get from 'lodash.get';
import HttpApiCallError from '../errors/HttpApiCallError';
import {get as getApi} from './api';
import axios from 'axios';

/**
 * Common function for FETCH request config
 *
 * @param props
 * @returns {{mode: string, headers: *, cache: string, method: *, credentials: string}}
 */
export function setRequestConfig(props) {
  const {
    method = 'GET',
    body = null,
    token = null,
    formData = false,
    extraHeaders = {},
    ldjson = false,
  } = props;

  let headers = {
    'Content-Type':
      method === 'PATCH'
        ? 'application/merge-patch+json'
        : ldjson
          ? 'application/ld+json'
          : 'application/json',
    accept: ldjson ? 'application/ld+json' : 'application/json',
    ...extraHeaders,
  };

  if (formData) {
    headers = {
      Accept: 'application/json',
    };
  }

  //Add token if provided
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const requestConfig = {
    method: method,
    mode: 'cors',
    cache: 'no-store',
    credentials: 'same-origin',
    headers,
  };

  // Test if config has body
  if (body) {
    if (formData) {
      requestConfig.body = body;
    } else {
      requestConfig.body = JSON.stringify(body);
    }
  }

  return requestConfig;
}

export function handleError(data, response) {
  let errorMessage = get(data, 'hydra:description');
  if (!errorMessage) {
    errorMessage = get(data, 'error.message');
  }

  if (!errorMessage) {
    errorMessage = get(data, 'message', response.statusText);
  }

  if (typeof errorMessage === 'object') {
    errorMessage = Object.values(errorMessage)
      .filter(err => typeof err !== 'object')
      .join('\n');
  }

  throw new HttpApiCallError(errorMessage, response.status, data);
}

/**
 * This function is used to get a list from the api
 * It will apply search as query, filter, sort and pagination if required
 * @param payload
 * @returns {Promise<*>}
 */
export async function getList(payload) {
  // Get params from URL
  const searchParams = new URLSearchParams();

  // Get all the params sent as payload
  const {q, filters, sort, page, rowsPerPage, url, cancelToken} = payload;

  // In case of search as query, append it to the params
  if (q) {
    if (Array.isArray(q)) {
      q.forEach(item => searchParams.append('q[]', item));
    } else {
      searchParams.append('q', q);
    }
  }

  // Apply sort if it's required, append it to the params
  if (sort) {
    const sorts = Array.isArray(sort) ? sort : [sort];
    sorts.forEach(({by, direction}) => {
      if (by && direction) {
        searchParams.append(
          `order[${by}]`,
          direction.toLowerCase() === 'asc' ? 'asc' : 'desc'
        );
      }
    });
  }

  // Apply pagination if it's required, append it to the params
  if (page && rowsPerPage) {
    searchParams.append('page', page);
    searchParams.append('limit', rowsPerPage);
  }

  // Apply filter if it's required, append it to the params
  if (filters && typeof filters === 'object') {
    for (const filterName in filters) {
      if (filters.hasOwnProperty(filterName)) {
        const value = filters[filterName];
        if (Array.isArray(value)) {
          for (const item of value) {
            searchParams.append(`${filterName}[]`, item);
          }
        } else {
          searchParams.append(filterName, value);
        }
      }
    }
  }

  const response = await axios.get(url + '?' + searchParams.toString(), {
    cancelToken,
  });

  return response.data;
}

/**
 * This function is used to get an item from the api
 * @param payload
 * @returns {Promise<*>}
 */
export async function getDetail(payload) {
  const {token, id, url} = payload;

  const data = await getApi({
    url: url.replace(':id', id),
    token,
    config: {
      ldjson: true,
    },
  });

  return data;
}
