/**
 * Modal reducer
 */
import * as types from '../constants/actionTypes';

const initialState = {
  progressModal: {
    show: false,
    count: 0,
  },
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case types.PROGRESS_SHOW:
      // increment progress modal count
      const count = state.progressModal.count + 1;
      return {
        ...state,
        progressModal: {
          show: count > 0,
          count,
        },
      };
    case types.PROGRESS_HIDE:
      // decrement progress modal count, min value is 0
      const _count = Math.max(state.progressModal.count - 1, 0);
      return {
        ...state,
        progressModal: {
          show: _count > 0,
          count: _count,
        },
      };
    case types.PROGRESS_HIDE_ALL:
      return {
        ...state,
        progressModal: {
          show: false,
          count: 0,
        },
      };
    default:
      return state;
  }
}
