import {useCallback, useState} from 'react';

export const DEFAULT_LIST_OPTIONS = {
  page: 1,
  sort: {
    by: 'createdAt',
    direction: 'desc',
  },
  rowsPerPage: 100,
};

export default function useDataTable({
  enabled = false,
  initialState = {},
  onChange,
}) {
  const [sortAndPagination, setSortAndPagination] = useState({
    ...DEFAULT_LIST_OPTIONS,
    ...initialState,
  });

  /**
   * DataTable sort and pagination change handler
   */
  const onSpChange = useCallback(
    res => {
      const {page: _page, rowsPerPage: _rowsPerPage, sort: _sort = {}} = res;
      const {_by, _direction} = _sort;
      const {page, rowsPerPage, sort = {}} = sortAndPagination;
      const {by, direction} = sort;
      if (
        page !== _page ||
        rowsPerPage !== _rowsPerPage ||
        by !== _by ||
        direction !== _direction
      ) {
        setSortAndPagination(res);
        // applies only when initial table data has been loaded
        if (enabled) {
          onChange(res);
        }
      }
    },
    [enabled, onChange, sortAndPagination]
  );

  const setPage = useCallback(
    page => {
      setSortAndPagination({...sortAndPagination, page: Math.max(1, page)});
    },
    [sortAndPagination]
  );

  return {
    sortAndPagination,
    onSpChange,
    setPage,
  };
}
