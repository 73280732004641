export const API_LOGIN_REQUESTED = 'API_LOGIN_REQUESTED';
export const API_LOGIN_SUCCEEDED = 'API_LOGIN_SUCCEEDED';
export const API_LOGIN_COMPLETED = 'API_LOGIN_COMPLETED';
export const API_LOGIN_FAILED = 'API_LOGIN_FAILED';
export const API_LOGOUT_COMPLETED = 'API_LOGOUT_COMPLETED';
export const API_IS_LOGGED = 'API_IS_LOGGED';

export const APP_SET_SEARCH_REQUESTED = 'APP_SET_SEARCH_REQUESTED';

// PROGRESS MODAL
export const PROGRESS_SHOW = 'PROGRESS_SHOW';
export const PROGRESS_HIDE = 'PROGRESS_HIDE';
export const PROGRESS_HIDE_ALL = 'PROGRESS_HIDE_ALL';

// SNACKBAR
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const ENQUEUE_SNACKBAR_FROM_COMPONENT =
  'ENQUEUE_SNACKBAR_FROM_COMPONENT';

// USER ME
export const API_USER_ME_REQUESTED = 'API_USER_ME_REQUESTED';
export const API_USER_ME_SUCCEEDED = 'API_USER_ME_SUCCEEDED';
export const API_USER_ME_FAILED = 'API_USER_ME_FAILED';

// LOGOUT
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const SAVE_USER_PREFERENCES_REQUESTED =
  'SAVE_USER_PREFERENCES_REQUESTED';
export const SAVE_USER_PREFERENCES_SUCCEEDED =
  'SAVE_USER_PREFERENCES_SUCCEEDED';
export const SAVE_USER_PREFERENCES_FAILED = 'SAVE_USER_PREFERENCES_FAILED';

// Permission
export const API_PERMISSION_LIST_REQUESTED = 'API_PERMISSION_LIST_REQUESTED';
export const API_PERMISSION_LIST_SUCCEEDED = 'API_PERMISSION_LIST_SUCCEEDED';
export const API_PERMISSION_LIST_FAILED = 'API_PERMISSION_LIST_FAILED';

// Role
export const API_ROLE_LIST_REQUESTED = 'API_ROLE_LIST_REQUESTED';
export const API_ROLE_LIST_SUCCEEDED = 'API_ROLE_LIST_SUCCEEDED';
export const API_ROLE_LIST_FAILED = 'API_ROLE_LIST_FAILED';

export const API_ROLE_EDIT_REQUESTED = 'API_ROLE_EDIT_REQUESTED';
export const API_ROLE_EDIT_SUCCEEDED = 'API_ROLE_EDIT_SUCCEEDED';
export const API_ROLE_EDIT_FAILED = 'API_ROLE_EDIT_FAILED';

export const API_ROLE_CREATE_REQUESTED = 'API_ROLE_CREATE_REQUESTED';
export const API_ROLE_CREATE_SUCCEEDED = 'API_ROLE_CREATE_SUCCEEDED';
export const API_ROLE_CREATE_FAILED = 'API_ROLE_CREATE_FAILED';

export const API_ROLE_DELETE_REQUESTED = 'API_ROLE_DELETE_REQUESTED';
export const API_ROLE_DELETE_SUCCEEDED = 'API_ROLE_DELETE_SUCCEEDED';
export const API_ROLE_DELETE_FAILED = 'API_ROLE_DELETE_FAILED';

export const API_USER_AUTOCOMPLETE_REQUESTED =
  'API_USER_AUTOCOMPLETE_REQUESTED';
export const API_USER_AUTOCOMPLETE_SUCCEEDED =
  'API_USER_AUTOCOMPLETE_SUCCEEDED';
export const API_USER_AUTOCOMPLETE_FAILED = 'API_USER_AUTOCOMPLETE_FAILED';

export const API_EVENT_AUTOCOMPLETE_REQUESTED =
  'API_EVENT_AUTOCOMPLETE_REQUESTED';
export const API_EVENT_AUTOCOMPLETE_SUCCEEDED =
  'API_EVENT_AUTOCOMPLETE_SUCCEEDED';
export const API_EVENT_AUTOCOMPLETE_FAILED = 'API_EVENT_AUTOCOMPLETE_FAILED';

export const API_COMPETITION_AUTOCOMPLETE_REQUESTED =
  'API_COMPETITION_AUTOCOMPLETE_REQUESTED';
export const API_COMPETITION_AUTOCOMPLETE_SUCCEEDED =
  'API_COMPETITION_AUTOCOMPLETE_SUCCEEDED';
export const API_COMPETITION_AUTOCOMPLETE_FAILED =
  'API_COMPETITION_AUTOCOMPLETE_FAILED';

export const API_ADMIN_SALE_DETAILS_REQUESTED =
  'API_ADMIN_SALE_DETAILS_REQUESTED';
export const API_ADMIN_SALE_DETAILS_SUCCEEDED =
  'API_ADMIN_SALE_DETAILS_SUCCEEDED';
export const API_ADMIN_SALE_DETAILS_FAILED = 'API_ADMIN_SALE_DETAILS_FAILED';

// Sale note
export const API_ADMIN_SALE_NOTE_REQUESTED = 'API_ADMIN_SALE_NOTE_REQUESTED';
export const API_ADMIN_SALE_NOTE_SUCCEEDED = 'API_ADMIN_SALE_NOTE_SUCCEEDED';
export const API_ADMIN_SALE_NOTE_FAILED = 'API_ADMIN_SALE_NOTE_FAILED';

// Sale penalty
export const API_ADMIN_SALE_ADD_PENALTY_REQUESTED =
  'API_ADMIN_SALE_ADD_PENALTY_REQUESTED';
export const API_ADMIN_SALE_ADD_PENALTY_SUCCEEDED =
  'API_ADMIN_SALE_ADD_PENALTY_SUCCEEDED';
export const API_ADMIN_SALE_ADD_PENALTY_FAILED =
  'API_ADMIN_SALE_ADD_PENALTY_FAILED';

// Cancel sales
export const API_ADMIN_SALE_CANCEL_REQUESTED =
  'API_ADMIN_SALE_CANCEL_REQUESTED';
export const API_ADMIN_SALE_CANCEL_SUCCEEDED =
  'API_ADMIN_SALE_CANCEL_SUCCEEDED';
export const API_ADMIN_SALE_CANCEL_FAILED = 'API_ADMIN_SALE_CANCEL_FAILED';

// Set As cancelled sales
export const API_ADMIN_SALE_SET_AS_CANCELLED_REQUESTED =
  'API_ADMIN_SALE_SET_AS_CANCELLED_REQUESTED';
export const API_ADMIN_SALE_SET_AS_CANCELLED_SUCCEEDED =
  'API_ADMIN_SALE_SET_AS_CANCELLED_SUCCEEDED';
export const API_ADMIN_SALE_SET_AS_CANCELLED_FAILED =
  'API_ADMIN_SALE_SET_AS_CANCELLED_FAILED';

// Set As delivered sales
export const API_ADMIN_SALE_SET_AS_DELIVERED_REQUESTED =
  'API_ADMIN_SALE_SET_AS_DELIVERED_REQUESTED';
export const API_ADMIN_SALE_SET_AS_DELIVERED_SUCCEEDED =
  'API_ADMIN_SALE_SET_AS_DELIVERED_SUCCEEDED';
export const API_ADMIN_SALE_SET_AS_DELIVERED_FAILED =
  'API_ADMIN_SALE_SET_AS_DELIVERED_FAILED';

// Create a refund for sales
export const API_ADMIN_SALE_ADD_REFUND_REQUESTED =
  'API_ADMIN_SALE_ADD_REFUND_REQUESTED';
export const API_ADMIN_SALE_ADD_REFUND_SUCCEEDED =
  'API_ADMIN_SALE_ADD_REFUND_SUCCEEDED';
export const API_ADMIN_SALE_ADD_REFUND_FAILED =
  'API_ADMIN_SALE_ADD_REFUND_FAILED';

// Create seller charge
export const API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED =
  'API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED';
export const API_ADMIN_CREATE_SELLER_CHARGE_SUCCEEDED =
  'API_ADMIN_CREATE_SELLER_CHARGE_SUCCEEDED';
export const API_ADMIN_CREATE_SELLER_CHARGE_FAILED =
  'API_ADMIN_CREATE_SELLER_CHARGE_FAILED';

// Transaction
export const API_TRANSACTION_PAYMENT_LIST_REQUESTED =
  'API_TRANSACTION_PAYMENT_LIST_REQUESTED';
export const API_TRANSACTION_PAYMENT_LIST_SUCCEEDED =
  'API_TRANSACTION_PAYMENT_LIST_SUCCEEDED';
export const API_TRANSACTION_PAYMENT_LIST_FAILED =
  'API_TRANSACTION_PAYMENT_LIST_FAILED';
export const API_TRANSACTION_PAYMENT_LIST_SET_SEARCH_SUCCEEDED =
  'API_TRANSACTION_PAYMENT_LIST_SET_SEARCH_SUCCEEDED';
export const API_TRANSACTION_PAYOUT_LIST_SET_SEARCH_SUCCEEDED =
  'API_TRANSACTION_PAYOUT_LIST_SET_SEARCH_SUCCEEDED';

// Payment methods
export const API_ADMIN_USER_PAYMENT_METHOD_LIST_REQUESTED =
  'API_ADMIN_USER_PAYMENT_METHOD_LIST_REQUESTED';
export const API_ADMIN_USER_PAYMENT_METHOD_LIST_SUCCEEDED =
  'API_ADMIN_USER_PAYMENT_METHOD_LIST_SUCCEEDED';
export const API_ADMIN_USER_PAYMENT_METHOD_LIST_FAILED =
  'API_ADMIN_USER_PAYMENT_METHOD_LIST_FAILED';

// Payout
export const API_TRANSACTION_PAYOUT_LIST_REQUESTED =
  'API_TRANSACTION_PAYOUT_LIST_REQUESTED';
export const API_TRANSACTION_PAYOUT_LIST_SUCCEEDED =
  'API_TRANSACTION_PAYOUT_LIST_SUCCEEDED';
export const API_TRANSACTION_PAYOUT_LIST_FAILED =
  'API_TRANSACTION_PAYOUT_LIST_FAILED';

export const API_ADMIN_PAUSE_PAYOUT_REQUESTED =
  'API_ADMIN_PAUSE_PAYOUT_REQUESTED';
export const API_ADMIN_PAUSE_PAYOUT_SUCCEEDED =
  'API_ADMIN_PAUSE_PAYOUT_SUCCEEDED';
export const API_ADMIN_PAUSE_PAYOUT_FAILED = 'API_ADMIN_PAUSE_PAYOUT_FAILED';

export const API_ADMIN_RESUME_PAYOUT_REQUESTED =
  'API_ADMIN_RESUME_PAYOUT_REQUESTED';
export const API_ADMIN_RESUME_PAYOUT_SUCCEEDED =
  'API_ADMIN_RESUME_PAYOUT_SUCCEEDED';
export const API_ADMIN_RESUME_PAYOUT_FAILED = 'API_ADMIN_RESUME_PAYOUT_FAILED';

export const API_ADMIN_RETRY_PAYOUT_REQUESTED =
  'API_ADMIN_RETRY_PAYOUT_REQUESTED';
export const API_ADMIN_RETRY_PAYOUT_SUCCEEDED =
  'API_ADMIN_RETRY_PAYOUT_SUCCEEDED';
export const API_ADMIN_RETRY_PAYOUT_FAILED = 'API_ADMIN_RETRY_PAYOUT_FAILED';

//Sale Pause Payout
export const API_ADMIN_SALE_PAUSE_PAYOUT_REQUESTED =
  'API_ADMIN_SALE_PAUSE_PAYOUT_REQUESTED';
export const API_ADMIN_SALE_PAUSE_PAYOUT_SUCCEEDED =
  'API_ADMIN_SALE_PAUSE_PAYOUT_SUCCEEDED';
export const API_ADMIN_SALE_PAUSE_PAYOUT_FAILED =
  'API_ADMIN_SALE_PAUSE_PAYOUT_FAILED';

//Sale Resume Payout
export const API_ADMIN_SALE_RESUME_PAYOUT_REQUESTED =
  'API_ADMIN_SALE_RESUME_PAYOUT_REQUESTED';
export const API_ADMIN_SALE_RESUME_PAYOUT_SUCCEEDED =
  'API_ADMIN_SALE_RESUME_PAYOUT_SUCCEEDED';
export const API_ADMIN_SALE_RESUME_PAYOUT_FAILED =
  'API_ADMIN_SALE_RESUME_PAYOUT_FAILED';

// User Details
export const API_ADMIN_USER_DETAIL_REQUESTED =
  'API_ADMIN_USER_DETAIL_REQUESTED';
export const API_ADMIN_USER_DETAIL_SUCCEEDED =
  'API_ADMIN_USER_DETAIL_SUCCEEDED';
export const API_ADMIN_USER_DETAIL_FAILED = 'API_ADMIN_USER_DETAIL_FAILED';

// Edit User Details
export const API_ADMIN_USER_EDIT_DETAIL_REQUESTED =
  'API_ADMIN_USER_EDIT_DETAIL_REQUESTED';
export const API_ADMIN_USER_EDIT_DETAIL_SUCCEEDED =
  'API_ADMIN_USER_EDIT_DETAIL_SUCCEEDED';
export const API_ADMIN_USER_EDIT_DETAIL_FAILED =
  'API_ADMIN_USER_EDIT_DETAIL_FAILED';

// Edit user details - Check existing email
export const API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_REQUESTED =
  'API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_REQUESTED';
export const API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_SUCCEEDED =
  'API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_SUCCEEDED';
export const API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_FAILED =
  'API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_FAILED';

// Suspend User
export const API_ADMIN_SUSPEND_USER_REQUESTED =
  'API_ADMIN_SUSPEND_USER_REQUESTED';
export const API_ADMIN_SUSPEND_USER_SUCCEEDED =
  'API_ADMIN_SUSPEND_USER_SUCCEEDED';
export const API_ADMIN_SUSPEND_USER_FAILED = 'API_ADMIN_SUSPEND_USER_FAILED';

// Unsuspend User
export const API_ADMIN_UNSUSPEND_USER_REQUESTED =
  'API_ADMIN_UNSUSPEND_USER_REQUESTED';
export const API_ADMIN_UNSUSPEND_USER_SUCCEEDED =
  'API_ADMIN_UNSUSPEND_USER_SUCCEEDED';
export const API_ADMIN_UNSUSPEND_USER_FAILED =
  'API_ADMIN_UNSUSPEND_USER_FAILED';

// Resend Guest Email
export const API_ADMIN_SEND_GUEST_EMAIL_REQUESTED =
  'API_ADMIN_SEND_GUEST_EMAIL_REQUESTED';
export const API_ADMIN_SEND_GUEST_EMAIL_SUCCEEDED =
  'API_ADMIN_SEND_GUEST_EMAIL_SUCCEEDED';
export const API_ADMIN_SEND_GUEST_EMAIL_FAILED =
  'API_ADMIN_SEND_GUEST_EMAIL_FAILED';

// Send Reset Password Email
export const API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED =
  'API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED';
export const API_ADMIN_SEND_RESET_PASSWORD_EMAIL_SUCCEEDED =
  'API_ADMIN_SEND_RESET_PASSWORD_EMAIL_SUCCEEDED';
export const API_ADMIN_SEND_RESET_PASSWORD_EMAIL_FAILED =
  'API_ADMIN_SEND_RESET_PASSWORD_EMAIL_FAILED';

// Send Reset Password Email
export const API_ADMIN_DELETE_USER_REQUESTED =
  'API_ADMIN_DELETE_USER_REQUESTED';
export const API_ADMIN_DELETE_USER_SUCCEEDED =
  'API_ADMIN_DELETE_USER_SUCCEEDED';
export const API_ADMIN_DELETE_USER_FAILED = 'API_ADMIN_DELETE_USER_FAILED';

// User Address Details
export const API_USER_ADDRESS_DETAILS_REQUESTED =
  'API_USER_ADDRESS_DETAILS_REQUESTED';
export const API_USER_ADDRESS_DETAILS_SUCCEEDED =
  'API_USER_ADDRESS_DETAILS_SUCCEEDED';
export const API_USER_ADDRESS_DETAILS_FAILED =
  'API_USER_ADDRESS_DETAILS_FAILED';

// Delete User Address
export const API_USER_ADDRESS_DELETE_REQUESTED =
  'API_USER_ADDRESS_DELETE_REQUESTED';
export const API_USER_ADDRESS_DELETE_SUCCEEDED =
  'API_USER_ADDRESS_DELETE_SUCCEEDED';
export const API_USER_ADDRESS_DELETE_FAILED = 'API_USER_ADDRESS_DELETE_FAILED';

// Set User address as Default
export const API_USER_ADDRESS_DEFAULT_REQUESTED =
  'API_USER_ADDRESS_DEFAULT_REQUESTED';
export const API_USER_ADDRESS_DEFAULT_SUCCEEDED =
  'API_USER_ADDRESS_DEFAULT_SUCCEEDED';
export const API_USER_ADDRESS_DEFAULT_FAILED =
  'API_USER_ADDRESS_DEFAULT_FAILED';

// Add User Address
export const API_USER_ADDRESS_CREATE_REQUESTED =
  'API_USER_ADDRESS_CREATE_REQUESTED';
export const API_USER_ADDRESS_CREATE_SUCCEEDED =
  'API_USER_ADDRESS_CREATE_SUCCEEDED';
export const API_USER_ADDRESS_CREATE_FAILED = 'API_USER_ADDRESS_CREATE_FAILED';

// Edit User Address
export const API_USER_ADDRESS_EDIT_REQUESTED =
  'API_USER_ADDRESS_EDIT_REQUESTED';
export const API_USER_ADDRESS_EDIT_SUCCEEDED =
  'API_USER_ADDRESS_EDIT_SUCCEEDED';
export const API_USER_ADDRESS_EDIT_FAILED = 'API_USER_ADDRESS_EDIT_FAILED';

// User Bank Details
export const API_USER_BANK_DETAILS_REQUESTED =
  'API_USER_BANK_DETAILS_REQUESTED';
export const API_USER_BANK_DETAILS_SUCCEEDED =
  'API_USER_BANK_DETAILS_SUCCEEDED';
export const API_USER_BANK_DETAILS_FAILED = 'API_USER_BANK_DETAILS_FAILED';

// Set User Bank detail as Default
export const API_USER_BANK_DEFAULT_REQUESTED =
  'API_USER_BANK_DEFAULT_REQUESTED';
export const API_USER_BANK_DEFAULT_SUCCEEDED =
  'API_USER_BANK_DEFAULT_SUCCEEDED';
export const API_USER_BANK_DEFAULT_FAILED = 'API_USER_BANK_DEFAULT_FAILED';

// Delete User Bank
export const API_USER_BANK_DELETE_REQUESTED = 'API_USER_BANK_DELETE_REQUESTED';
export const API_USER_BANK_DELETE_SUCCEEDED = 'API_USER_BANK_DELETE_SUCCEEDED';
export const API_USER_BANK_DELETE_FAILED = 'API_USER_BANK_DELETE_FAILED';

// Add User Address
export const API_USER_BANK_CREATE_REQUESTED = 'API_USER_BANK_CREATE_REQUESTED';
export const API_USER_BANK_CREATE_SUCCEEDED = 'API_USER_BANK_CREATE_SUCCEEDED';
export const API_USER_BANK_CREATE_FAILED = 'API_USER_BANK_CREATE_FAILED';

// User Activity
export const API_USER_ACTIVITY_REQUESTED = 'API_USER_ACTIVITY_REQUESTED';
export const API_USER_ACTIVITY_SUCCEEDED = 'API_USER_ACTIVITY_SUCCEEDED';
export const API_USER_ACTIVITY_FAILED = 'API_USER_ACTIVITY_FAILED';

// User Balance
export const API_USER_BALANCE_REQUESTED = 'API_USER_BALANCE_REQUESTED';
export const API_USER_BALANCE_SUCCEEDED = 'API_USER_BALANCE_SUCCEEDED';
export const API_USER_BALANCE_FAILED = 'API_USER_BALANCE_FAILED';

export const API_ADMIN_USER_LIST_REQUESTED = 'API_USER_LIST_REQUESTED';
export const API_ADMIN_USER_LIST_SUCCEEDED = 'API_USER_LIST_SUCCEEDED';
export const API_ADMIN_USER_LIST_FAILED = 'API_USER_LIST_FAILED';
