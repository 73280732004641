import {put, call} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as authApi from '../connectivity/auth.api';
import {doEnqueueSnackBar} from './snackbar.saga';
import {LOGGED_OUT_REDIRECT_URL} from '../constants/urls';

/**
 * Get current user
 *
 * @param action an object containing user token
 */
export function* doGetMe(action) {
  try {
    const user = yield call(authApi.getMe, action.payload);
    yield put({
      type: types.API_USER_ME_SUCCEEDED,
      payload: {user},
    });
  } catch (e) {
    // logout user on getMe error
    if (e.statusCode === 401) {
      yield put({
        type: types.USER_LOGOUT_SUCCEEDED,
        payload: {},
      });
    } else {
      yield put({
        type: types.API_USER_ME_FAILED,
      });
    }
  }
}

/**
 * Logout user
 *
 * @param {*} action
 */
export function* doLogout(action) {
  try {
    yield call(authApi.logout, action.payload);
    yield put({
      type: types.USER_LOGOUT_SUCCEEDED,
      payload: {},
    });
    window.localStorage.clear();
    window.location.href = LOGGED_OUT_REDIRECT_URL;
  } catch (e) {
    yield doEnqueueSnackBar(e.message, 'error');
  }
}
