import {createTheme} from '@material-ui/core/styles';
import {Palette} from '@material-ui/core/styles/createPalette';
import {Theme} from '@material-ui/core';

interface IPaletteOptions {
  danger: {
    main: string;
    hover?: string;
  };
  gray: {
    main: string;
    light: string;
  };
  pink: {
    main: string;
    contrastText: string;
  };
  blue: {
    main: string;
    light: string;
    contrastText: string;
  };
  purple: {
    main: string;
  };
  green: {
    main: string;
    light: string;
  };
  orange: {
    main: string;
    contrastText: string;
  };
  dark: {
    main: string;
    light: string;
    contrastText: string;
  };
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    disabled?: string;
    disabledBg?: string;
    additional?: string;
  }

  interface TypeText {
    light?: string;
    border?: string;
    disabledBg?: string;
    gray?: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends IPaletteOptions {}
}

export interface ITheme extends Theme {
  palette: Palette & IPaletteOptions;
}

/* Fanpass Admin Theme */
const theme = createTheme({
  palette: {
    background: {
      paper: '#ffffff',
      default: '#fafafa',
      disabled: '#F2F1F1',
      additional: 'rgba(154,165,177,0.1)',
    },
    text: {
      primary: '#282B3E',
      light: '#C2C2C2',
      disabled: '#979797',
      disabledBg: '#9797971A',
      secondary: '#989898',
      border: '#EDEDED',
      gray: '#707070',
    },
    primary: {
      main: '#FF7575',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#282B3E',
      light: '#B8B8B8',
    },
    error: {
      main: '#EE2737',
      light: 'rgba(255,117,117,0.1)',
    },
    warning: {
      main: '#FF8145',
    },
    success: {
      main: '#21B59B',
      light: '#21B59B1A',
    },
    danger: {
      main: '#FF6571',
      hover: '#b34c57',
    },
    gray: {
      main: '#9AA5B1',
      light: '#F5F5F5',
    },
    pink: {
      main: '#F263A8',
      contrastText: '#ffffff',
    },
    blue: {
      main: '#3385CC',
      contrastText: '#ffffff',
      light: '#3385CC1A',
    },
    purple: {
      main: '#6600CC',
    },
    green: {
      main: '#21B59B',
      light: 'rgba(33,181,155,0.1)',
    },
    orange: {
      main: '#FFB945',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#282B3E',
      light: '#808285',
      contrastText: '#ffffff',
    },
    divider: '#D8D8D8',
  },
  spacing: 10,
  typography: {
    fontFamily: 'Averta Regular',
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 15,
    },
    h3: {
      fontSize: 25,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 20,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 15,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.28,
    },
    subtitle1: {
      fontSize: 11,
      lineHeight: 1.8,
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: 1.25,
    },
    caption: {
      fontSize: 10,
    },
  },
  shadows: [
    'none',
    '0 5px 10px 0 rgba(40,43,62,0.2)',
    '0 3px 6px 0 rgba(0,0,0,0.04)',
    '0 0 30px 5px rgba(0,0,0,0.08)',
    '0 0 20px 0 rgba(0,0,0,0.05)',
    '0 5px 10px 0 rgba(14,19,30,0.02)',
    '0 0 10px 0 rgba(0,0,0,0.03)',
    '0 0 50px 10px rgba(0,0,0,0.03)',
    '0 0 30px 10px rgba(0,0,0,0.05)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

//Responsive font sizes
//Headers
theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
    fontWeight: 'bold',
  },
};

//Body text
theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('md')]: {
    fontSize: 13,
  },
};

export const PICKER_ROOT_STYLES = {
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.secondary.light,
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: 0,
    '& + .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#EDEDED !important',
    top: 0,
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    fontSize: 14,
    '&::placeholder': {
      color: theme.palette.secondary.light,
      opacity: 1,
    },
  },
  '& .MuiButtonBase-root': {
    padding: theme.spacing(1.1),
  },
};

export default theme;
