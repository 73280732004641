import React, {Fragment} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {touch} from 'redux-form';
import CustomCheckbox from '../../Common/Checkbox';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

/**
 * This component renders a Checkbox multi select field for redux form
 *
 * options props is required
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CheckboxGroupField = props => {
  const {
    input: {value, onChange, name},
    meta,
    options,
    label,
    formControlLabelClass = 'checkbox',
    checkboxSize = 'small',
    labelField = 'label',
    valueField = 'value',
    groupClassName,
    controlClassName,
    extraChild,
    labelClassName,
    ...custom
  } = props;
  const {touched, error} = meta;
  const dispatch = useDispatch();

  const onValueChange = checkboxValue => {
    // sometimes, the given value is a boolean
    const _value = value && typeof value == 'object' ? value : [];

    if (_value && typeof _value === 'object') {
      let newValue;
      if (_value.includes(checkboxValue)) {
        newValue = _value.filter(item => item !== checkboxValue);
      } else {
        newValue = [..._value, checkboxValue];
      }
      onChange(newValue);
    }
    if (!touched) {
      dispatch(touch(meta.form, name));
    }
  };

  return (
    <Fragment>
      <div className={groupClassName} {...custom}>
        {options &&
          options.map((option, key) => {
            // Test if option should be checked
            const checked =
              value &&
              typeof value === 'object' &&
              value.includes(option[valueField])
                ? true
                : false;
            return (
              <FormControlLabel
                key={key}
                className={clsx(formControlLabelClass, controlClassName)}
                label={
                  <Typography variant="body2">{option[labelField]}</Typography>
                }
                control={
                  <CustomCheckbox
                    value={option[valueField]}
                    size={checkboxSize}
                    checked={checked}
                    onChange={_ => onValueChange(option[valueField])}
                    inputProps={{
                      name: name,
                    }}
                  />
                }
              />
            );
          })}
        {extraChild}
      </div>
      {touched && !!error && <span className="error">{error}</span>}
    </Fragment>
  );
};

export default CheckboxGroupField;
