/**
 * Root saga, import all app sagas
 */
import {takeEvery, takeLatest, all} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as authSaga from './auth.saga';
import * as snackBarSaga from './snackbar.saga';
import * as appSaga from './app.saga';
import * as userPreferencesSaga from './userPreferences.saga';
import * as saleSaga from './sale.saga';
import * as roleSaga from './role.saga';
import * as permissionSaga from './permission.saga';
import * as userSaga from './user.saga';
import * as eventSaga from './event.saga';
import * as competitionSaga from './competition.saga';
import * as transactionSaga from './transaction.saga';
import * as transactionPayoutSaga from './transactionPayout.saga';
import * as userAddressSaga from './userAddress.saga';
import * as userBankSaga from './userBank.saga';
import * as userActivitySaga from './userActivity.saga';
import * as userBalanceSaga from './userBalance.saga';
import {
  AUTH_API_MUTATION_FULFILLED,
  AUTH_API_MUTATION_PENDING,
  AUTH_API_MUTATION_REJECTED,
  AUTH_API_QUERY_FULFILLED,
  AUTH_API_QUERY_PENDING,
  AUTH_API_QUERY_REJECTED,
} from '../services/authApi';

const showProgress = actions =>
  actions.map(action => takeLatest(action, appSaga.doProgressModalShow));

const hideProgress = actions =>
  actions.map(action => takeLatest(action, appSaga.doProgressModalHide));

/**
 * This function is responsible of listen dispatched actions and trigger the
 * corresponding function
 * @returns {IterableIterator<*>}
 */
function* rootSaga() {
  yield all([
    ...showProgress([
      types.API_LOGIN_REQUESTED,
      types.API_ROLE_LIST_REQUESTED,
      types.API_ADMIN_SALE_NOTE_REQUESTED,
      types.API_ADMIN_SALE_ADD_PENALTY_REQUESTED,
      types.API_ADMIN_SALE_CANCEL_REQUESTED,
      types.API_ADMIN_SALE_SET_AS_CANCELLED_REQUESTED,
      types.API_ADMIN_SALE_SET_AS_DELIVERED_REQUESTED,
      types.API_ADMIN_SALE_ADD_REFUND_REQUESTED,
      types.API_ADMIN_SALE_DETAILS_REQUESTED,
      types.API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED,
      types.API_ADMIN_SALE_PAUSE_PAYOUT_REQUESTED,
      types.API_ADMIN_SALE_RESUME_PAYOUT_REQUESTED,
      types.API_ADMIN_PAUSE_PAYOUT_REQUESTED,
      types.API_ADMIN_RESUME_PAYOUT_REQUESTED,
      types.API_ADMIN_RETRY_PAYOUT_REQUESTED,
      types.API_USER_ADDRESS_DETAILS_REQUESTED,
      types.API_USER_ADDRESS_DELETE_REQUESTED,
      types.API_USER_ADDRESS_DEFAULT_REQUESTED,
      types.API_ADMIN_USER_DETAIL_REQUESTED,
      types.API_ADMIN_USER_EDIT_DETAIL_REQUESTED,
      types.API_ADMIN_SUSPEND_USER_REQUESTED,
      types.API_ADMIN_UNSUSPEND_USER_REQUESTED,
      types.API_ADMIN_SEND_GUEST_EMAIL_REQUESTED,
      types.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED,
      types.API_ADMIN_DELETE_USER_REQUESTED,
      types.API_USER_ADDRESS_CREATE_REQUESTED,
      types.API_USER_ADDRESS_EDIT_REQUESTED,
      types.API_USER_BANK_DETAILS_REQUESTED,
      types.API_USER_BANK_DEFAULT_REQUESTED,
      types.API_USER_BANK_DELETE_REQUESTED,
      types.API_USER_BANK_CREATE_REQUESTED,
      types.API_USER_ACTIVITY_REQUESTED,
      types.API_USER_BALANCE_REQUESTED,
      types.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_REQUESTED,
      AUTH_API_MUTATION_PENDING,
      AUTH_API_QUERY_PENDING,
    ]),
    ...hideProgress([
      types.API_LOGIN_SUCCEEDED,
      types.API_LOGIN_FAILED,
      types.API_ROLE_LIST_SUCCEEDED,
      types.API_ROLE_LIST_FAILED,
      types.API_ADMIN_SALE_NOTE_SUCCEEDED,
      types.API_ADMIN_SALE_NOTE_FAILED,
      types.API_ADMIN_SALE_ADD_PENALTY_SUCCEEDED,
      types.API_ADMIN_SALE_ADD_PENALTY_FAILED,
      types.API_ADMIN_SALE_CANCEL_FAILED,
      types.API_ADMIN_SALE_CANCEL_SUCCEEDED,
      types.API_ADMIN_SALE_SET_AS_CANCELLED_SUCCEEDED,
      types.API_ADMIN_SALE_SET_AS_CANCELLED_FAILED,
      types.API_ADMIN_SALE_SET_AS_DELIVERED_SUCCEEDED,
      types.API_ADMIN_SALE_SET_AS_DELIVERED_FAILED,
      types.API_ADMIN_SALE_ADD_REFUND_SUCCEEDED,
      types.API_ADMIN_SALE_ADD_REFUND_FAILED,
      types.API_ADMIN_SALE_DETAILS_SUCCEEDED,
      types.API_ADMIN_SALE_DETAILS_FAILED,
      types.API_ADMIN_CREATE_SELLER_CHARGE_SUCCEEDED,
      types.API_ADMIN_CREATE_SELLER_CHARGE_FAILED,
      types.API_ADMIN_SALE_PAUSE_PAYOUT_SUCCEEDED,
      types.API_ADMIN_SALE_RESUME_PAYOUT_SUCCEEDED,
      types.API_ADMIN_SALE_PAUSE_PAYOUT_FAILED,
      types.API_ADMIN_SALE_RESUME_PAYOUT_FAILED,
      types.API_ADMIN_PAUSE_PAYOUT_FAILED,
      types.API_ADMIN_PAUSE_PAYOUT_SUCCEEDED,
      types.API_ADMIN_RESUME_PAYOUT_FAILED,
      types.API_ADMIN_RESUME_PAYOUT_SUCCEEDED,
      types.API_ADMIN_RETRY_PAYOUT_SUCCEEDED,
      types.API_ADMIN_RETRY_PAYOUT_FAILED,
      types.API_USER_ADDRESS_DETAILS_FAILED,
      types.API_USER_ADDRESS_DETAILS_SUCCEEDED,
      types.API_USER_ADDRESS_DELETE_FAILED,
      types.API_USER_ADDRESS_DELETE_SUCCEEDED,
      types.API_USER_ADDRESS_DEFAULT_FAILED,
      types.API_USER_ADDRESS_DEFAULT_SUCCEEDED,
      types.API_ADMIN_USER_DETAIL_FAILED,
      types.API_ADMIN_USER_DETAIL_SUCCEEDED,
      types.API_ADMIN_USER_EDIT_DETAIL_FAILED,
      types.API_ADMIN_USER_EDIT_DETAIL_SUCCEEDED,
      types.API_ADMIN_SUSPEND_USER_FAILED,
      types.API_ADMIN_SUSPEND_USER_SUCCEEDED,
      types.API_ADMIN_UNSUSPEND_USER_FAILED,
      types.API_ADMIN_UNSUSPEND_USER_SUCCEEDED,
      types.API_ADMIN_SEND_GUEST_EMAIL_FAILED,
      types.API_ADMIN_SEND_GUEST_EMAIL_SUCCEEDED,
      types.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_FAILED,
      types.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_SUCCEEDED,
      types.API_ADMIN_DELETE_USER_FAILED,
      types.API_ADMIN_DELETE_USER_SUCCEEDED,
      types.API_USER_ADDRESS_CREATE_SUCCEEDED,
      types.API_USER_ADDRESS_CREATE_FAILED,
      types.API_USER_ADDRESS_EDIT_FAILED,
      types.API_USER_ADDRESS_EDIT_SUCCEEDED,
      types.API_USER_BANK_DETAILS_FAILED,
      types.API_USER_BANK_DETAILS_SUCCEEDED,
      types.API_USER_BANK_DEFAULT_FAILED,
      types.API_USER_BANK_DEFAULT_SUCCEEDED,
      types.API_USER_BANK_DELETE_FAILED,
      types.API_USER_BANK_DELETE_SUCCEEDED,
      types.API_USER_BANK_CREATE_FAILED,
      types.API_USER_BANK_CREATE_SUCCEEDED,
      types.API_USER_ACTIVITY_FAILED,
      types.API_USER_ACTIVITY_SUCCEEDED,
      types.API_USER_BALANCE_FAILED,
      types.API_USER_BALANCE_SUCCEEDED,
      types.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_FAILED,
      types.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_SUCCEEDED,
      AUTH_API_MUTATION_FULFILLED,
      AUTH_API_MUTATION_REJECTED,
      AUTH_API_QUERY_FULFILLED,
      AUTH_API_QUERY_REJECTED,
    ]),
    takeEvery(
      types.ENQUEUE_SNACKBAR_FROM_COMPONENT,
      snackBarSaga.doEnqueueSnackBarFromComponent
    ),
    takeEvery(types.API_USER_ME_REQUESTED, authSaga.doGetMe),
    takeEvery(types.USER_LOGOUT_REQUESTED, authSaga.doLogout),
    takeLatest(types.APP_SET_SEARCH_REQUESTED, appSaga.doSetSearch),
    takeLatest(
      types.SAVE_USER_PREFERENCES_REQUESTED,
      userPreferencesSaga.saveUserPreference
    ),
    // Permission
    takeLatest(
      types.API_PERMISSION_LIST_REQUESTED,
      permissionSaga.listPermission
    ),
    // Role
    takeLatest(types.API_ROLE_LIST_REQUESTED, roleSaga.listRole),
    takeLatest(types.API_ROLE_CREATE_REQUESTED, roleSaga.createRole),
    takeLatest(types.API_ROLE_EDIT_REQUESTED, roleSaga.editRole),
    takeLatest(types.API_ROLE_DELETE_REQUESTED, roleSaga.deleteRole),

    takeLatest(
      types.API_USER_AUTOCOMPLETE_REQUESTED,
      userSaga.doGetUserAutocomplete
    ),
    takeLatest(
      types.API_EVENT_AUTOCOMPLETE_REQUESTED,
      eventSaga.doGetEventAutocomplete
    ),
    takeLatest(
      types.API_COMPETITION_AUTOCOMPLETE_REQUESTED,
      competitionSaga.doGetCompetitionAutocomplete
    ),
    takeEvery(
      types.API_ADMIN_SALE_DETAILS_REQUESTED,
      saleSaga.doGetAdminSaleDetails
    ),
    takeEvery(types.API_ADMIN_SALE_NOTE_REQUESTED, saleSaga.doSaveSaleNote),

    // Sale/Penalty/Refund/Payment/Pause-Resume Payout
    takeLatest(
      types.API_ADMIN_SALE_ADD_PENALTY_REQUESTED,
      saleSaga.doSavePenalty
    ),
    takeLatest(types.API_ADMIN_SALE_CANCEL_REQUESTED, saleSaga.doCancelSales),
    takeLatest(
      types.API_ADMIN_SALE_SET_AS_CANCELLED_REQUESTED,
      saleSaga.doSetAsCancelledSales
    ),
    takeLatest(
      types.API_ADMIN_SALE_SET_AS_DELIVERED_REQUESTED,
      saleSaga.doSetAsDeliveredSales
    ),
    takeLatest(
      types.API_ADMIN_SALE_ADD_REFUND_REQUESTED,
      saleSaga.doAddRefundForSale
    ),
    takeLatest(
      types.API_ADMIN_CREATE_SELLER_CHARGE_REQUESTED,
      saleSaga.doCreatePaymentForSale
    ),
    takeLatest(
      types.API_ADMIN_SALE_PAUSE_PAYOUT_REQUESTED,
      saleSaga.doPausePayoutSale
    ),
    takeLatest(
      types.API_ADMIN_SALE_RESUME_PAYOUT_REQUESTED,
      saleSaga.doResumePayoutSale
    ),

    // Transactions
    takeLatest(
      types.API_TRANSACTION_PAYMENT_LIST_REQUESTED,
      transactionSaga.doGetTransactionList
    ),
    takeLatest(
      types.API_TRANSACTION_PAYOUT_LIST_REQUESTED,
      transactionPayoutSaga.doGetPayoutList
    ),

    //Payouts
    takeLatest(
      types.API_ADMIN_PAUSE_PAYOUT_REQUESTED,
      transactionPayoutSaga.doPausePayout
    ),
    takeLatest(
      types.API_ADMIN_RESUME_PAYOUT_REQUESTED,
      transactionPayoutSaga.doResumePayout
    ),
    takeLatest(
      types.API_ADMIN_RETRY_PAYOUT_REQUESTED,
      transactionPayoutSaga.doRetryPayout
    ),

    //Payment methods
    takeLatest(
      types.API_ADMIN_USER_PAYMENT_METHOD_LIST_REQUESTED,
      userSaga.doGetUserPaymentMethods
    ),

    // User Address
    takeLatest(
      types.API_USER_ADDRESS_DETAILS_REQUESTED,
      userAddressSaga.doGetUserAddressDetails
    ),
    // Set as default
    takeLatest(
      types.API_USER_ADDRESS_DELETE_REQUESTED,
      userAddressSaga.doDeleteUserAddress
    ),
    // Delete user address
    takeLatest(
      types.API_USER_ADDRESS_DEFAULT_REQUESTED,
      userAddressSaga.doSetAsDefault
    ),

    //User details
    takeLatest(
      types.API_ADMIN_USER_DETAIL_REQUESTED,
      userSaga.doGetAdminUserDetails
    ),
    // Update user details
    takeLatest(
      types.API_ADMIN_USER_EDIT_DETAIL_REQUESTED,
      userSaga.doUpdateUserDetails
    ),
    // Suspend user
    takeLatest(types.API_ADMIN_SUSPEND_USER_REQUESTED, userSaga.doSuspendUser),
    // Unsuspend user
    takeLatest(
      types.API_ADMIN_UNSUSPEND_USER_REQUESTED,
      userSaga.doUnSuspendUser
    ),
    // Resend Guest Email
    takeLatest(
      types.API_ADMIN_SEND_GUEST_EMAIL_REQUESTED,
      userSaga.doResendGuestEmail
    ),
    // Send Reset Password Email
    takeLatest(
      types.API_ADMIN_SEND_RESET_PASSWORD_EMAIL_REQUESTED,
      userSaga.doSendPasswordResetEmail
    ),
    // Delete user from the database
    takeLatest(
      types.API_ADMIN_DELETE_USER_REQUESTED,
      userSaga.doDeleteUserData
    ),
    // Create user address
    takeLatest(
      types.API_USER_ADDRESS_CREATE_REQUESTED,
      userAddressSaga.doCreateUserAddress
    ),
    // Create user address
    takeLatest(
      types.API_USER_ADDRESS_EDIT_REQUESTED,
      userAddressSaga.doEditUserAddress
    ),
    // List User Banks
    takeLatest(
      types.API_USER_BANK_DETAILS_REQUESTED,
      userBankSaga.doGetUserBankDetails
    ),
    // Set Bank Account as default
    takeLatest(
      types.API_USER_BANK_DEFAULT_REQUESTED,
      userBankSaga.doSetAsDefault
    ),
    // Delete Bank Account
    takeLatest(
      types.API_USER_BANK_DELETE_REQUESTED,
      userBankSaga.doDeleteUserBank
    ),
    // Create Bank Account
    takeLatest(
      types.API_USER_BANK_CREATE_REQUESTED,
      userBankSaga.doCreateUserBank
    ),
    // List User Activity
    takeLatest(
      types.API_USER_ACTIVITY_REQUESTED,
      userActivitySaga.doGetUserActivity
    ),
    // List User Balance
    takeLatest(
      types.API_USER_BALANCE_REQUESTED,
      userBalanceSaga.doGetUserBalance
    ),
    // Update user details - check existing email
    takeLatest(
      types.API_ADMIN_USER_EDIT_DETAIL_CHECK_EXISTING_EMAIL_REQUESTED,
      userSaga.doCheckExistingEmail
    ),
    takeLatest(types.API_ADMIN_USER_LIST_REQUESTED, userSaga.doListTeamMembers),
  ]);
}

export default rootSaga;
