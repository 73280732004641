import React from 'react';
import {Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {HoldingTimeIcon, SalesTruckIcon} from '../../../constants/images';
import StatusStateIndicator from '../../Common/Indicator/StatusStateIndicator';
import SalesRow from './SalesRow';
import DeliveryDetails from "./DeliveryDetails";

const SaleUpsShipment = props => {
  const intl = useIntl();
  const {
    sale,
    showDeliveryDetails,
    showDeliverTimeLeft,
    deliverTimeLeft,
    ticket,
  } = props.saleDetails;

  return (
    <div className="card details-card sales-details mx-0 mt-3">
      <div className="card-header bg-light-blue">
        <div className="row gx-2">
          <div className="col-auto">
            <span className="icon-wrapper blue">
              <SalesTruckIcon style={{fontSize: 15}} />
            </span>
          </div>
          <div className="col">
            <Typography variant="body2" component="b">
              {intl.formatMessage({
                id: 'dashboard.sales.details.ups_shipment',
              })}
              {ticket &&
                ticket.hasMembership &&
                ` (${intl.formatMessage({id: 'dashboard.sales.details.membership'})})`}
            </Typography>
            {/* If the sale status is delivered, and the status is set by the admin, display "Delivered by the admin" instead of "Delivered" */}
            <StatusStateIndicator
              status={
                sale.status == 'delivered' &&
                sale.status == sale.statusSetByAdmin
                  ? 'delivered-by-admin'
                  : sale.status
              }
              color="gray"
              className="d-inline-flex ps-2"
            />
            {sale.deliveryDate &&
                <DeliveryDetails status={sale.status} deliveryDate={sale.deliveryDate}
                                 statusSetByAdmin={sale.statusSetByAdmin} adminName={sale.statusSetByAdminUser?.name}/>}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row gx-2 align-items-center pb-1">
          <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-between">
              <Typography variant="body2">
                <b>
                  {intl.formatMessage({
                    id: 'dashboard.sales.details.delivery_details',
                  })}
                </b>
              </Typography>
              {showDeliverTimeLeft && <HoldingTimeIcon />}
            </div>
          </div>
          {showDeliverTimeLeft && (
            <>
              <div className="col-auto col-lg-3">
                <Typography variant="subtitle2" color="textSecondary">
                  {intl.formatMessage({
                    id: 'dashboard.sales.details.delivery_time_left',
                  })}
                </Typography>
              </div>
              <div className="col-auto col-lg-3">
                <Typography variant="subtitle2">
                  {deliverTimeLeft} left
                </Typography>
              </div>
            </>
          )}
        </div>
        {showDeliveryDetails && (
          <>
            <div className="row">
              <div className="col-xl-6">
                <SalesRow
                  label={intl.formatMessage({
                    id: 'dashboard.sales.details.tracking_number',
                  })}
                  value={sale.shipping?.trackingNumber}
                  showTrackingLink
                />
              </div>
            </div>
          </>
        )}
        {!showDeliveryDetails && (
          <Typography variant="body2" className="pt-2">
            {intl.formatMessage({
              id: 'dashboard.sales.details.there_is_no_delivery',
            })}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default SaleUpsShipment;
