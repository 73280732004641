import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import {MouseEventHandler} from 'react';
import TextAreaField from '../../components/Form/Fields/TextAreaField';
import CustomIconButton from '../../components/Common/IconButton';
import {AcceptIcon, CancelIcon} from '../../constants/images';
import {injectIntl, IntlShape} from 'react-intl';

export type NoteFormData = {
  note: string;
};

export type NoteFormProps = {
  onCancel: MouseEventHandler<Element>;
};

const DecoratedNoteForm = ({
  onCancel,
  handleSubmit,
}: NoteFormProps & InjectedFormProps<NoteFormData, NoteFormProps>) => {
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit}>
      <Field name="note" component={TextAreaField} minRows={4} autosize>
        <Box
          border={1}
          borderTop={0}
          style={{borderColor: theme?.palette?.text?.border}}
          borderRadius="0 0 4px 4px"
          display="flex"
          alignItems="center"
          padding={1}
          gridGap={10}
        >
          <CustomIconButton size="xs" variant="filled" type="submit">
            <AcceptIcon />
          </CustomIconButton>
          <CustomIconButton size="xs" variant="filled" onClick={onCancel}>
            <CancelIcon />
          </CustomIconButton>
        </Box>
      </Field>
    </form>
  );
};

const NoteForm = reduxForm<NoteFormData, NoteFormProps & {intl: IntlShape}>({
  form: 'NoteForm',
  validate: (values, {intl}) => {
    const errors: {[key: string]: string} = {};
    if (!values.note) {
      errors.note = intl.formatMessage({id: 'validation.error.required'});
    }

    return errors;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})(DecoratedNoteForm as any);

export default injectIntl(NoteForm);
