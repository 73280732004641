import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import SearchForm from '../Form/SearchForm';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '../Common/IconButton';
import {FILTER_ICON, PlusRoundedDangerIcon} from '../../constants/images';
import {IMG_BASE_URL} from '../../constants/urls';
import DataTable from '../Widgets/DataTable';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import Filter from '../Widgets/Filter';
import * as urls from '../../constants/urls';

const rows = [
  {
    id: '0',
    venue: {
      title: 'Emirates Stadium',
      image: `${IMG_BASE_URL}event.jpeg`,
    },
    location: 'London, UK',
    num_events: '10',
    date: '23 Nov 2019',
  },
  {
    id: '1',
    venue: {
      title: 'Stamford Bridge',
      image: `${IMG_BASE_URL}event.jpeg`,
    },
    location: 'London, UK',
    num_events: '29',
    date: '23 Nov 2019',
  },
  {
    id: '2',
    venue: {
      title: 'Etihad Stadium',
      image: `${IMG_BASE_URL}event.jpeg`,
    },
    location: 'London, UK',
    num_events: '15',
    date: '23 Nov 2019',
  },
];

/* Events list - tab Venue */
const ListVenueTab = ({intl, onAddClick}) => {
  //Router
  const history = useHistory();

  // Header cells items
  const HeaderCells = [
    {
      name: 'venue',
      disablePadding: true,
      label: intl.formatMessage({id: 'dashboard.events.list.venue_tab.venue'}),
      collapse: false,
      renderValue: ({title, image}) => (
        <div className="d-flex overflow-hidden align-items-center ps-1 ps-lg-1">
          <picture className={clsx('event-picture me-2')}>
            <img className="img-fluid rounded-sm" src={image} alt="" />
          </picture>
          <Typography variant="body2" className="text-truncate">
            {title}
          </Typography>
        </div>
      ),
      width: '25%',
    },
    {
      name: 'location',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.venue_location',
      }),
      collapse: true,
      width: '20%',
    },
    {
      name: 'num_events',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.events_number',
      }),
      collapse: true,
      width: '20%',
    },
    {
      name: 'date',
      label: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.created_date',
      }),
      collapse: false,
    },
  ];

  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);

  const filterItems = [
    {
      name: 'venue',
      title: intl.formatMessage({id: 'dashboard.events.list.venue_tab.venue'}),
      inputType: 'autocomplete',
      subitems: ['Emirates Stadium', 'Stamford Bridge', 'Etihad Stadium'],
    },
    {
      name: 'location',
      title: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.venue_location',
      }),
      inputType: 'autocomplete',
      subitems: [],
    },
    {
      name: 'date',
      title: intl.formatMessage({
        id: 'dashboard.events.list.venue_tab.created_date',
      }),
      inputType: 'date_between',
    },
  ];

  const handleRowClick = itemId => {
    history.push(urls.VENUE_DETAILS_PATH.replace(':id', itemId));
  };

  return (
    <div className="dashboard-content">
      <div className="row align-items-center justify-content-between search-row pb-1">
        <div className="col-xl-4 col-lg-8 d-flex">
          {/* Search */}
          <SearchForm
            dispatchAction={true}
            delay={180}
            SearchProps={{
              buttonProps: {
                onClick: () => {},
              },
            }}
          />
          {/* Filter Button on mobile view only */}
          <Box ml={1} className="d-block d-lg-none">
            <IconButton className="m-0" onClick={() => setFilterOpened(true)}>
              {FILTER_ICON}
            </IconButton>
          </Box>
        </div>
        {/* Button toolbar */}
        <div className="col-auto d-none d-lg-block">
          <div className="d-flex">
            {/* Filter button */}
            <IconButton className="m-0" onClick={() => setFilterOpened(true)}>
              {FILTER_ICON}
            </IconButton>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {/* Filter on the right side */}
          <Filter
            opened={filterOpened}
            items={filterItems}
            onClose={() => setFilterOpened(false)}
          />
          {/*Table*/}
          <DataTable
            className="events-table"
            canSelect={false}
            empty={false}
            loading={false}
            onRowClick={itemId => handleRowClick(itemId)}
            actionButton={{
              variant: 'filled',
              size: 'sm',
              children: <PlusRoundedDangerIcon />,
              onClick: onAddClick,
            }}
            headCells={HeaderCells.map(item => ({
              ...item,
              numeric: false,
              disablePadding: false,
            }))}
            rows={rows}
            onChange={() => {}}
            total={100}
            currentPage={1}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ListVenueTab);
