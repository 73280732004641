import {handleError, setRequestConfig} from './functions';
import {API_USER_LOGOUT_URL, API_USER_ME_URL} from '../constants/urls';

/**
 * Make api call to get current user
 *
 * @param {string} token
 * @return {Promise<Object>} user
 */
export async function getMe({token}) {
  const requestConfig = setRequestConfig({method: 'GET', token});
  const response = await fetch(API_USER_ME_URL, requestConfig);
  const data = await response.json();

  if (response.status === 200) {
    return data;
  }

  handleError(data, response);
}

export async function logout({token}) {
  const response = await new Promise((resolve, reject) => {
    const requestConfig = setRequestConfig({method: 'GET', token});
    fetch(API_USER_LOGOUT_URL, {...requestConfig, redirect: 'manual'}).then(
      response => {
        resolve(response);
      }
    );
  });

  if (response.type === 'opaqueredirect') {
    return;
  }
  const data = await response.json();

  handleError(data, response);
}
