import React from 'react';
import * as PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

//Status icon cancelled, failed, expired
const StatusIconCancelled = (
  <SvgIcon viewBox="0 0 9 9" style={{fontSize: 9}}>
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -372.000000)"
      fill="#EE2737"
    >
      <g id="Group-2" transform="translate(1181.000000, 372.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

//Status icon active
const StatusIconActive = (
  <SvgIcon viewBox="0 0 9 9" style={{fontSize: 9}}>
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -314.000000)"
      fill="#21B59B"
    >
      <g id="Group" transform="translate(1181.000000, 314.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

//Status icon notified
const StatusIconNotified = (
  <SvgIcon viewBox="0 0 9 9" style={{fontSize: 9}}>
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -314.000000)"
      fill="#3385CC"
    >
      <g id="Group" transform="translate(1181.000000, 314.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

//Status icon pending
const StatusIconPending = (
  <SvgIcon viewBox="0 0 9 9" style={{fontSize: 9}}>
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -487.000000)"
      fill="#FF8145"
    >
      <g id="Group-5" transform="translate(1181.000000, 487.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

//Status icon suspended
const StatusIconSuspended = (
  <SvgIcon viewBox="0 0 9 9" style={{fontSize: 9}}>
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -430.000000)"
      fill="#282B3E"
    >
      <g id="Group-3" transform="translate(1181.000000, 430.000000)">
        <circle
          id="Oval-Copy"
          fillOpacity="0.2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5"></circle>
      </g>
    </g>
  </SvgIcon>
);

//Styles wrapper
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  label: {
    paddingLeft: 8,
    textTransform: 'capitalize',
  },
}));

//This component render status with color by status prop
const StatusIndicator = ({status, label, className, ...other}) => {
  const classes = useStyles();

  const getStatusIndicator = status => {
    switch (status) {
      case 'cancelled':
      case 'error':
      case 'expired':
      case 'inactive':
      case 'abandoned':
        return {
          icon: StatusIconCancelled,
          color: 'error',
        };

      case 'active':
      case 'created':
      case 'live':
        return {
          icon: StatusIconActive,
          color: 'success',
        };

      case 'pending':
      case 'converted':
        return {
          icon: StatusIconPending,
          color: 'warning',
        };

      case 'notified':
        return {
          icon: StatusIconNotified,
          color: 'blue',
        };

      case 'suspended':
      default: {
        return {
          icon: StatusIconSuspended,
          color: 'secondary',
        };
      }
    }
  };

  const {icon, color} = getStatusIndicator(status);

  return (
    <Box
      className={clsx(classes.root, className)}
      color={`${color}.main`}
      {...other}
    >
      <span className="d-flex icon">{icon}</span>
      <Typography variant="h6" className={clsx(classes.label, 'text-truncate')}>
        {label}
      </Typography>
    </Box>
  );
};

StatusIndicator.propTypes = {
  /**
   * Status (cancelled, active, pending, suspended)
   */
  status: PropTypes.string,
  /**
   * Status text
   */
  label: PropTypes.string,
  /**
   * Additional class
   */
  className: PropTypes.string,
};

export default StatusIndicator;
