import menus from '../../constants/menus';
import {useCallback, useEffect} from 'react';
import useAuth from '../../hooks/useAuth';
import usePermission from '../../Auth/usePermission';
import {History} from 'history';

interface HomeRedirectProps {
  history: History;
}

const HomeRedirect = ({history}: HomeRedirectProps) => {
  const {user} = useAuth();
  const {filterMenusByPermissions} = usePermission();

  /**
   * Return first menu available depending on user permissions as homepage
   */
  const findHomeRoute = useCallback(() => {
    return filterMenusByPermissions(menus)[0]?.path;
  }, [filterMenusByPermissions]);

  useEffect(() => {
    // Wait for user loading otherwise that will do a wrong redirection to first public route
    if (user) {
      history.push(findHomeRoute());
    }
  }, [findHomeRoute, history, user]);

  return null;
};

export default HomeRedirect;
