import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import clsx from 'clsx';
import Checkbox from '../../Common/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../Common/IconButton';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

/**
 * Entity list item, can be expanded/collapsed
 * @param title Entity name
 * @param roles Roles list
 * @param permissions Permissions list for entity
 * @param onPermissionAssigned
 */
const PermissionGroupList = ({
  title,
  roles,
  permissions,
  onPermissionAssigned,
}) => {
  //Collapsed state
  const [collapsed, setCollapsed] = useState(true);
  const [rolesPermissions, setRolesPermissions] = useState({});

  //Toggle collapsed state
  const toggleCollapsed = () => setCollapsed(!collapsed);

  // Store simplified Ids of current group permissions for easier state manipulation
  useEffect(() => {
    const permissionIds = {};
    for (const role of roles) {
      permissionIds[role.id] = role.permissions
        .filter(r => r.permission_group === title)
        .map(r => r.id);
    }
    setRolesPermissions(permissionIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  //Roles inputs
  const printRolesInputs = (permission, group) => {
    const columnWidth = 100 / roles.length;
    const isPermissionAssigned = role => {
      if (group) {
        //return true when all permissions for the current group are assigned
        return permissions.length === rolesPermissions[role.id]?.length;
      }
      return rolesPermissions[role.id]?.includes(permission.id) || false;
    };

    const handlePermissionAssign = (event, role) => {
      const checked = event.target.checked;

      let newPermissions = [];
      // When it's a group checkbox either we fill all permissions if checked or we keep permissions array empty
      if (group && checked) {
        newPermissions = permissions.map(r => r.id);
      }
      // When a permission checkbox we toggle the permission id
      if (!group) {
        const existingPermissions = rolesPermissions[role.id];
        newPermissions = existingPermissions.includes(permission.id)
          ? [...existingPermissions.filter(id => id !== permission.id)]
          : [...existingPermissions, permission.id];
      }
      setRolesPermissions({
        ...rolesPermissions,
        [role.id]: newPermissions,
      });
      // Should keep other permission groups permissions at this step
      const otherPermissions = role.permissions.filter(
        p => p.permission_group !== title
      );
      onPermissionAssigned(role, [
        ...newPermissions,
        ...otherPermissions.map(r => r.id),
      ]);
    };

    return (
      <div className="role-list">
        {roles.map((role, key) => {
          const checked = isPermissionAssigned(role);
          const indeterminate = !!(
            group &&
            !checked &&
            rolesPermissions[role.id]?.length
          );

          return (
            <div
              key={key}
              className={clsx('role-cell', {main: group})}
              style={{width: `${columnWidth}%`}}
            >
              <Checkbox
                checked={checked}
                // Show minus icon only when it's a group checkbox & it's not checked & the group permission is not empty
                indeterminate={indeterminate}
                onChange={event => handlePermissionAssign(event, role)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  //Right row with roles inputs
  const printRightRow = (permission, key) => {
    return (
      <div className="row gx-0" key={key}>
        <div className="col-lg-4">
          <div className="right-cell">
            <Typography
              variant="body2"
              color="textSecondary"
              className="text-truncate"
            >
              {permission.name}
            </Typography>
          </div>
        </div>
        <div className="col-lg-8">{printRolesInputs(permission, false)}</div>
      </div>
    );
  };

  return (
    <Fragment>
      {/*Entity Row*/}
      <div className="row gx-0">
        <div className="col-lg-4">
          <div
            className="right-cell main text-truncate"
            onClick={toggleCollapsed}
          >
            <Typography variant="body2" className="text-truncate">
              <b>{title}</b>{' '}
              <Typography
                variant="body2"
                component="b"
                style={{color: '#21B59B'}}
              >{`(${permissions.length})`}</Typography>
            </Typography>
            <IconButton size="xs" variant="action" onClick={() => {}}>
              {collapsed ? (
                <ExpandMore style={{fontSize: 20}} />
              ) : (
                <ExpandLess style={{fontSize: 20}} />
              )}
            </IconButton>
          </div>
        </div>
        <div className="col-lg-8">{printRolesInputs(null, title)}</div>
      </div>
      {/*Entity permissions rows*/}
      {collapsed &&
        permissions &&
        permissions.map((permission, index) =>
          printRightRow(permission, index)
        )}
    </Fragment>
  );
};

export default PermissionGroupList;

PermissionGroupList.propTypes = {
  onPermissionAssigned: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
