import React, {ChangeEvent, forwardRef, useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import HintTooltip from './HintTooltip';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {},
    '& .MuiInputBase-input': {
      fontSize: 14,
      paddingBottom: 7,
      paddingTop: 7,
    },
    '& .MuiFormLabel-root': {
      fontSize: 14,
      marginBottom: 7,
      color: theme.palette.secondary.light,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 7px) scale(0.8)',
      color: theme.palette.secondary.light,
    },
    '&.no-shrink': {
      '& .MuiInputBase-root': {
        marginTop: 0,
      },
      '& .MuiInputLabel-formControl': {
        top: -15,
      },
    },
    '&.addon-start': {
      '& .MuiInputLabel-formControl': {
        left: 15,
      },
      '& .MuiInputLabel-animated[data-shrink="true"]': {
        left: 0,
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 1,
        marginTop: -2,
      },
    },
    '& .MuiInputAdornment-root': {
      height: 35,
      paddingLeft: 6,
      paddingBottom: 10,
      marginBottom: -10,
      backgroundColor: theme.palette.background.paper,
      zIndex: 10,
    },
    '& .MuiInputAdornment-root > p': {
      color: theme.palette.secondary.main,
    },
    '& .label-with-hint': {
      '& .MuiButtonBase-root': {
        marginTop: -3,
        marginLeft: 2,
      },
    },
  },
}));

type MaterialInputProps = Omit<TextFieldProps, 'onChange' | 'variant'> & {
  label?: string;
  showLabelHint?: boolean;
  labelHintText?: JSX.Element;
  defaultValue?: string;
  password?: boolean;
  noShrink?: boolean;
  addon?: React.ReactNode;
  addonPosition?: 'start' | 'end';
  disableUnderline?: boolean;
  onChange?: (value: string) => void;
  value?: string | null;
};

const MaterialInput = forwardRef<HTMLInputElement, MaterialInputProps>(
  (props, ref) => {
    const {
      label,
      showLabelHint = false,
      labelHintText,
      value,
      defaultValue = '',
      password = false,
      noShrink = false,
      addon = null,
      addonPosition,
      disableUnderline,
      onChange,
      ...other
    } = props;
    const classes = useStyles();

    //selected value
    const [currentValue, setCurrentValue] = useState<string | undefined | null>(
      defaultValue
    );

    //on select callback
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.target.value);
      if (typeof onChange === 'function') {
        onChange(e.target.value);
      }
    };

    useEffect(() => {
      if (value !== undefined) {
        setCurrentValue(value);
      }
    }, [value]);

    return (
      <TextField
        fullWidth
        type={password ? 'password' : 'text'}
        value={currentValue}
        classes={{
          root: classes.root,
        }}
        className={clsx(
          noShrink && 'no-shrink',
          addon && addonPosition === 'start' && 'addon-start'
        )}
        color="secondary"
        InputLabelProps={{
          shrink: noShrink ? false : !!currentValue,
        }}
        onChange={onInputChange}
        // show placeholder only when the current value is empty
        label={
          noShrink ? (
            !currentValue ? (
              label
            ) : (
              ''
            )
          ) : showLabelHint ? (
            <div className="label-with-hint d-flex">
              {label}
              <HintTooltip id={`hint_${label}`} content={labelHintText} />
            </div>
          ) : (
            label
          )
        }
        InputProps={{
          endAdornment:
            addon && addonPosition === 'end' ? (
              <InputAdornment position="end">{addon}</InputAdornment>
            ) : null,
          startAdornment:
            addon && addonPosition === 'start' ? (
              <InputAdornment position="start">{addon}</InputAdornment>
            ) : null,
          disableUnderline,
        }}
        {...other}
        inputRef={ref}
      />
    );
  }
);

export default MaterialInput;
