import React, {useState} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import Modal from '../../../Widgets/Modal';
import HintTooltip from '../../../Common/HintTooltip';
import {InfoIcon} from '../../../../constants/images';
import {ExpandIcon} from '../../../Common/CollapsableBlock';

/**
 * Propose Penalty after refund popup
 */

const useAccordionStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.text.border}`,
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: theme.spacing(1.5, 0, 0, 0),
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '& p': {
      [theme.breakpoints.up('lg')]: {
        lineHeight: '20px',
      },
    },
  },
  summary: {
    padding: theme.spacing(0, 1.5),
    '&.Mui-expanded': {
      minHeight: 'unset',
      borderRadius: theme.spacing(0.8, 0.8, 0, 0),
      backgroundColor: theme.palette.background.additional,
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(1.5, 0),
      '&.Mui-expanded': {
        margin: theme.spacing(1.5, 0, 1, 0),
      },
    },
  },
  details: {
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.background.additional,
    borderRadius: theme.spacing(0, 0, 0.8, 0.8),
    '& p': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

const ProposePenaltyRefundPopup = props => {
  const {intl, opened, handleClose, onAccept} = props;
  const classes = useAccordionStyles();
  // Accordion expanded
  const [expanded, setExpanded] = useState();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const TextContent = ({
    content,
    component = 'p',
    className = '',
    variant = 'body2',
  }) => (
    <Typography
      variant={variant}
      className={clsx('mb-0', className)}
      component={component}
      dangerouslySetInnerHTML={{__html: content}}
    />
  );

  const NotesRow = () => (
    <div className="d-flex align-items-start align-items-lg-center bg-primary-light rounded justify-content-center p-fan-15 mb-fan-15">
      <Typography
        color="primary"
        variant="body2"
        component="span"
        className="font-weight-bold me-2"
      >
        <FormattedMessage id="propose_penalty_refund_popup.notes_title" />
      </Typography>
      <HintTooltip
        icon={<InfoIcon style={{fontSize: 15}} className="text-danger" />}
        gray={false}
        maxWidth={400}
        content={
          <div className="py-1">
            <Typography variant="body2" className="mb-fan-15">
              <TextContent
                content={intl.formatMessage({
                  id: 'propose_penalty_refund_popup.tooltip.note_1',
                })}
              />
            </Typography>
            <Typography variant="body2" className="mb-fan-15">
              <TextContent
                content={intl.formatMessage({
                  id: 'propose_penalty_refund_popup.tooltip.note_2',
                })}
              />
            </Typography>
            <Typography variant="body2">
              <TextContent
                content={intl.formatMessage({
                  id: 'propose_penalty_refund_popup.tooltip.note_3',
                })}
              />
            </Typography>
          </div>
        }
      />
    </div>
  );

  return (
    <Modal
      opened={opened}
      width={623}
      title={intl.formatMessage({id: 'propose_penalty_refund_popup.title'})}
      cancelTitle={intl.formatMessage({
        id: 'propose_penalty_refund_popup.actions.skip',
      })}
      saveTitle={intl.formatMessage({
        id: 'propose_penalty_refund_popup.actions.add_penalty',
      })}
      contentClassName="px-lg-fan-15"
      headerClassName="justify-content-center text-center pb-lg-1"
      footerClassName="pt-lg-0"
      btnOkClassName="action"
      handleClose={handleClose}
      onOk={onAccept}
    >
      <TextContent
        className="text-center mb-fan-15"
        content={intl.formatMessage({
          id: 'propose_penalty_refund_popup.subtitle',
        })}
      />
      <NotesRow />
      <div className="pb-2">
        {[...Array(6).keys()].map(i => (
          <Accordion
            className={classes.root}
            elevation={0}
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary
              id={`item-${i + 1}`}
              expandIcon={<ExpandIcon />}
              aria-controls={`accordion-item_${i + 1}`}
              className={classes.summary}
            >
              <Typography
                variant="body2"
                className={clsx('me-lg-3', {
                  'font-weight-bold': expanded === i,
                })}
              >
                <FormattedMessage
                  id={`propose_penalty_refund_popup.accordion_title_${i + 1}`}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextContent
                className="pe-lg-3"
                content={intl.formatMessage({
                  id: `propose_penalty_refund_popup.accordion_text_${i + 1}`,
                })}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Modal>
  );
};

export default injectIntl(ProposePenaltyRefundPopup);
