import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import HintTooltip from '../../Common/HintTooltip';
import ArrowLink from '../../Common/ArrowLink';
import {useIntl} from 'react-intl';

interface SaleRowProps {
  label: string;
  value: string | number;
  tooltip?: string;
  labelDefaultColor?: string;
  showTrackingLink?: boolean;
}

const SalesRow = ({
  label,
  value,
  tooltip,
  labelDefaultColor,
  showTrackingLink,
  ...other
}: SaleRowProps) => {
  const intl = useIntl();

  return (
    <div {...other} className="row gx-2 pt-2">
      <div className="col-6">
        <Typography
          variant="body2"
          color={labelDefaultColor ? 'textPrimary' : 'textSecondary'}
        >
          {label}
        </Typography>
      </div>
      <div className="col-6">
        <div className="d-flex align-items-center">
          <Typography variant="body2" component="span" className="d-flex gap-3">
            <span>{value}</span>
            {showTrackingLink && (
              <ArrowLink
                url={`https://www.ups.com/track?loc=fr_FR&tracknum=${value}&requester=ST&fromrecent=1/trackdetails`}
                title={intl.formatMessage({
                  id: 'dashboard.sales.details.view_ups_shipping_info',
                })}
                className="inline-link"
                primary
                targetBlank
              />
            )}
          </Typography>
          {tooltip && (
            <span className="ms-1">
              <HintTooltip
                id="sales_restrictions"
                centered
                gray={false}
                radius={8}
                content={tooltip}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesRow;
