import React from 'react';
import clsx from 'clsx';
import {FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';
import Checkbox from '../../Common/Checkbox';
import HintTooltip from '../../Common/HintTooltip';

/*
 * This component renders a checkbox field for redux-form
 */
const CheckboxField = props => {
  const {
    meta,
    tooltip,
    input: {value, ...restInput},
    classes = {formControlLabel: 'checkbox'},
    size = 'small',
    ...custom
  } = props;
  const {touched, error} = meta;

  return (
    <FormControl error={touched && !!error}>
      <div className="d-flex align-items-center">
        <FormControlLabel
          className={clsx(classes.formControlLabel)}
          control={<Checkbox size={size} checked={!!value} {...restInput} />}
          {...custom}
        />
        {tooltip && restInput.name && (
          <HintTooltip
            className="hint-button"
            id={restInput.name.replace(/[.[\]]/g, '-')}
            content={tooltip}
          />
        )}
      </div>
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
};

CheckboxField.propTypes = {};

export default CheckboxField;
