import {useDispatch} from 'react-redux';
import {PROGRESS_HIDE, PROGRESS_SHOW} from '../../constants/actionTypes';

/**
 * Hook to show/hide progress modal
 */
export default function useProgressModal() {
  const dispatch = useDispatch();

  const show = () => {
    dispatch({
      type: PROGRESS_SHOW,
    });
  };

  const hide = () => {
    dispatch({
      type: PROGRESS_HIDE,
    });
  };

  const toggle = (showModal = true) => {
    showModal ? show() : hide();
  };

  return {
    show,
    hide,
    toggle,
  };
}
