import React from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import {FormattedMessage} from 'react-intl';

const AddNewBank = props => {
  const {isNew, openNewModal} = props;
  return (
    <div className={clsx('card', isNew && 'add-new')}>
      <div
        className="inner d-flex flex-column align-items-center"
        onClick={openNewModal}
      >
        <AddIcon style={{color: '#B8B8B8'}} />
        <Box pt={0.7}>
          <FormattedMessage id="dashboard.user_details.tab_finance.add_bank_account" />
        </Box>
      </div>
    </div>
  );
};

export default AddNewBank;
