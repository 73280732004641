import {call, put} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as userAddressApi from '../connectivity/userAddress.api';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';
import {addAuthToken, handleException} from './utils';

export function* doGetUserAddressDetails(action) {
  try {
    const data = yield call(
      userAddressApi.getUserAddress,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_ADDRESS_DETAILS_SUCCEEDED,
      payload: {addresses: data['hydra:member']},
    });
  } catch (error) {
    yield handleException({
      type: types.API_USER_ADDRESS_DETAILS_FAILED,
      error,
    });
  }
}

// delete user address action
export function* doDeleteUserAddress(action) {
  try {
    yield call(
      userAddressApi.deleteUserAddress,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_ADDRESS_DELETE_SUCCEEDED,
      payload: {id: action.payload['@id']},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.tab_address.delete_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_ADDRESS_DELETE_FAILED,
      error,
    });
  }
}

// set address as default action
export function* doSetAsDefault(action) {
  try {
    const data = yield call(
      userAddressApi.makeAddressAsDefault,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_ADDRESS_DEFAULT_SUCCEEDED,
      payload: {id: action.payload['@id'], data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.tab_address.default_address_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_ADDRESS_DEFAULT_FAILED,
      error,
    });
  }
}

// edit address action
export function* doEditUserAddress(action) {
  try {
    const data = yield call(
      userAddressApi.editUserAddress,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_ADDRESS_EDIT_SUCCEEDED,
      payload: {data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.tab_address.edit_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      type: types.API_USER_ADDRESS_EDIT_FAILED,
      error,
    });
  }
}

// create address action
export function* doCreateUserAddress(action) {
  try {
    const data = yield call(
      userAddressApi.createNewAddress,
      yield addAuthToken(action.payload)
    );
    yield put({
      type: types.API_USER_ADDRESS_CREATE_SUCCEEDED,
      payload: {data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.user_details.tab_address.create_success_message',
      }),
      'success',
      {autoHideDuration: 5000}
    );
  } catch (error) {
    yield handleException({
      error,
      customMessage: error.response?.data?.message,
      type: types.API_USER_ADDRESS_CREATE_FAILED,
    });
  }
}
