import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';

const StaticField = props => {
  const {fullClassName, className = '', title, value} = props;

  return (
    <div
      className={
        fullClassName
          ? fullClassName
          : clsx(
              'd-flex align-items-center justify-content-between',
              className ? className : 'mt-3 pt-2'
            )
      }
    >
      <Typography variant="body2" color="textPrimary">
        {title}
      </Typography>
      <Typography className="me-1" variant="body2">
        {value}
      </Typography>
    </div>
  );
};

StaticField.propTypes = {
  fullClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.any,
};

export default StaticField;
