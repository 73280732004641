import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import StateIndicator from '../StateIndicator';

const StatusStateIndicator = props => {
  const {status, prefix, ...others} = props;
  const colors = {
    green: '#21B59B',
    orange: '#FF8145',
    gray: '#9AA5B1',
    red: '#EE2737',
  };
  const intl = useIntl();
  const [color, setColor] = useState(colors.gray);
  const [label, setLabel] = useState('');

  useEffect(() => {
    setLabel(status ? intl.formatMessage({id: prefix + status}) : '');
    const statusMap = {
      [colors.green]: ['delivered'],
      [colors.error]: [
        'cancelled',
        'delivery-missed',

        'address-not-collected',
        'missed',
      ],
      [colors.orange]: [
        'confirmation-pending',
        'collection-pending',

        'delivery-pending',
      ],
      [colors.gray]: ['annulled', 'terminated', 'on-pause', 'canceled'],
    };
    const paymentMap = {
      [colors.green]: ['paid'],
      [colors.orange]: ['refunded', 'partially-refunded'],
      [colors.gray]: ['payment-pending'],
    };

    const maps = [statusMap, paymentMap];
    let statusColor = null;

    for (const map of maps) {
      for (const color in map) {
        if (map[color].indexOf(status) > -1) {
          statusColor = color;
          break;
        }
      }
      if (statusColor) {
        break;
      }
    }
    setColor(statusColor ?? colors.gray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (!status) {
    return null;
  }

  return <StateIndicator {...{label, color}} {...others} />;
};

StatusStateIndicator.propTypes = {
  status: PropTypes.string,
  prefix: PropTypes.string,
};

StatusStateIndicator.defaultProps = {
  prefix: 'status.',
};

export default StatusStateIndicator;
