import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import BaseInput from './BaseInput';
import theme from '../../constants/theme';
import clsx from 'clsx';

//Arrow left
const ArrowLeft = ({disabled}) => {
  return (
    <SvgIcon viewBox="0 0 8 12" style={{fontSize: 12}}>
      <g
        transform="translate(-1076.000000, -952.000000)"
        fill={disabled ? '#B8B8B8' : '#282B3E'}
        stroke={disabled ? '#B8B8B8' : '#282B3E'}
      >
        <path
          d="M1083.12402,957.725617 L1077.76257,953.108013 C1077.58508,952.960376 1077.30224,952.964615 1077.13082,953.117483 C1076.96362,953.266608 1076.96362,953.503012 1077.13082,953.652115 L1082.17642,957.997668 L1077.13082,962.34322 C1076.95639,962.493473 1076.95639,962.737092 1077.13082,962.887344 C1077.30531,963.037552 1077.58814,963.037552 1077.76257,962.887344 L1083.12402,958.269718 C1083.29845,958.119443 1083.29845,957.875869 1083.12402,957.725617"
          transform="translate(1080.127419, 958.000000) scale(-1, 1) translate(-1080.127419, -958.000000) "
        ></path>
      </g>
    </SvgIcon>
  );
};

//Arrow right
const ArrowRight = ({disabled}) => {
  return (
    <SvgIcon viewBox="0 0 8 12" style={{fontSize: 12}}>
      <g
        transform="translate(-1308.000000, -952.000000)"
        fill={disabled ? '#B8B8B8' : '#282B3E'}
        stroke={disabled ? '#B8B8B8' : '#282B3E'}
      >
        <path
          d="M1315.12402,957.725617 L1309.76257,953.108013 C1309.58508,952.960376 1309.30224,952.964615 1309.13082,953.117483 C1308.96362,953.266608 1308.96362,953.503012 1309.13082,953.652115 L1314.17642,957.997668 L1309.13082,962.34322 C1308.95639,962.493473 1308.95639,962.737092 1309.13082,962.887344 C1309.30531,963.037552 1309.58814,963.037552 1309.76257,962.887344 L1315.12402,958.269718 C1315.29845,958.119443 1315.29845,957.875869 1315.12402,957.725617"
          id="Fill-1"
        ></path>
      </g>
    </SvgIcon>
  );
};

//Pagination styled wrapper
const StyledPagination = styled(({...other}) => <Pagination {...other} />)`
  & ul.pagination {
    margin-bottom: 0;
    align-items: center;
    justify-content: space-between;
  }
  & .page-item {
    padding: 0 0;
  }
  & .page-input {
    margin: 0 10px;
    font-family: 'Averta Semibold';
    > input {
      width: 20px;
      max-width: 40px;
      text-align: center;
    }
  }
  & .page-item .page-link {
    border: none;
    background-color: transparent;
    color: ${theme.palette.secondary.main};
    padding: 10px 0;

    &:focus {
      box-shadow: none;
    }

    &.disabled {
      color: ${theme.palette.secondary.light};
    }

    &.previous {
      padding-right: 10px;
    }

    &.next {
      padding-left: 20px;

      & svg {
        margin-right: 0;
      }
    }

    & svg {
      margin: 0 10px;
    }
  }
`;

/* Pagination for tables pages */
const CustomPagination = props => {
  const {
    pages,
    disabled,
    current = 1,
    showLinksText = true,
    textPrev,
    textNext,
    onChangePage,
  } = props;
  const [input, setInput] = useState(current);

  // This is used to wait 1 second from the last user change inside input page to trigger the search
  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => input > 0 && onChangePage(+input),
      1000
    );
    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  //page number update handler
  // onChangePage is only called if value is > 0 and <= pages
  const onChangeInput = e => {
    let value = e.target.value;
    value =
      value !== '' ? (isNaN(value) ? '' : Math.min(+value, pages)) : value;
    setInput(value);
  };

  const onPreviousClick = () => {
    if (input > 1) {
      setInput(+input - 1);
      onChangePage(+input - 1);
    }
  };

  const onNextClick = () => {
    if (input < pages) {
      setInput(+input + 1);
      onChangePage(+input + 1);
    }
  };

  //check if current page is first
  const isFirst = !input || input === 1;

  //check if current page is last
  const isLast = input === pages;

  // update input value on `current` prop change
  // make sure the minimum value is 1
  useEffect(() => {
    setInput(Math.max(1, current));
    if (current < 1) {
      console.warn(
        `Pagination value should be greater or equal to 1, ${current} provided`
      );
    }
  }, [current]);

  return (
    <StyledPagination aria-label="Pagination">
      <PaginationItem>
        <PaginationLink
          previous
          className={clsx(
            'previous',
            isFirst ? 'disabled' : 'font-weight-bold'
          )}
          onClick={onPreviousClick}
        >
          <ArrowLeft disabled={isFirst} />
          {showLinksText && textPrev}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem className="d-none d-lg-block">
        <BaseInput
          disabled={disabled}
          className="page-input"
          value={(+input).toString()}
          onChange={onChangeInput}
        />
      </PaginationItem>
      <PaginationItem className="d-none d-lg-block">
        {`of ${pages}`}
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          next
          className={clsx('next', isLast ? 'disabled' : 'font-weight-bold')}
          onClick={onNextClick}
        >
          {showLinksText && textNext}
          <ArrowRight disabled={isLast} />
        </PaginationLink>
      </PaginationItem>
    </StyledPagination>
  );
};

CustomPagination.propTypes = {
  /**
   * Number of pages
   */
  pages: PropTypes.number,
  /**
   * Current page
   */
  current: PropTypes.number,
  /**
   * Need show texts beside arrows
   */
  showLinksText: PropTypes.bool,
  /**
   * Previous button text
   */
  textPrev: PropTypes.string,
  /**
   * Next button text
   */
  textNext: PropTypes.string,
  /**
   * Change page callback
   */
  onChangePage: PropTypes.func,
};

export default CustomPagination;
