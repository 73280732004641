import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export type ListTooltipProps = {
  header: string;
  items: string[];
};

const useStyles = makeStyles(theme => ({
  list: {
   display: 'flex',
   flexDirection: 'column',
   listStyle: 'none',
   paddingLeft: 0,
   gap: 10,
   margin: 0,
   '& > li': {
      position: 'relative',
      paddingLeft: 15,
      '&:before': {
         content: '""',
         position: 'absolute',
         left: 0,
         top: 5,
         width: '.3125rem',
         height: '.3125rem',
         backgroundColor: '#FF7575',
         borderRadius: '50%',
      }
   }
  },
}));

const ListTooltip = ({header, items}) => {
   const classes = useStyles();
  return (
    <Box py={1}>
      <Box mb={1}>
        <Typography variant="body2" className="font-weight-bold">
          {header}
        </Typography>
      </Box>
      {items && items.length > 0 && (
        <ul className={classes.list}>
          {items.map((item: string, index: number) => (
            item && <li key={index}>
              <Typography variant="subtitle2">{item}</Typography>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default ListTooltip;
