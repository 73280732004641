import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash.get';
import {FormattedMessage, useIntl} from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import {Close as CloseIcon} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {Collapse} from 'reactstrap';
import Button from '../Common/Button';
import Select from '../Common/Select';
import DatePicker from '../Common/DatePicker';
import {FILTER_ICON} from '../../constants/images';
import BaseInput from '../Common/BaseInput';
import SearchTextField from '../Form/Fields/SearchTextField';
import Chip from '../Common/Chip';
import {Field, reduxForm} from 'redux-form';
import CheckboxGroupField from '../Form/Fields/CheckboxGroupField';
import SelectField from '../Form/Fields/SelectField';
import DateBetweenField from '../Form/Fields/DateBetweenField';
import AutocompleteField from '../Form/Fields/AutocompleteField';
import MultiSelectField from '../Form/Fields/MultiSelectField';
import {inputTypes} from '../../constants/enums';

//Filter styles
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    minWidth: '292px !important',
    overflow: 'hidden',

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 51,
      paddingBottom: 40,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75vh',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
}));

/**
 * Collapsable filter component
 * Items of this Filter should be update for use in FilterForm.js
 */
const CollapsableFilterBlock = ({title, inputType, active, children}) => {
  //Expand/collapse block state
  const [isOpen, setIsOpen] = useState(false);

  //Expand/collapse block
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      className={clsx(
        'card collapsable-block',
        isOpen && 'expanded',
        inputType
      )}
    >
      <div className="card-header" onClick={toggle}>
        <h6
          className={clsx('card-title', {
            'font-weight-bold': isOpen,
            'text-app-primary': active,
          })}
        >
          {title}
        </h6>
        {isOpen ? (
          <RemoveIcon style={{fontSize: 17}} />
        ) : (
          <AddIcon style={{fontSize: 17}} />
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="card-body">{children}</div>
      </Collapse>
    </div>
  );
};

//Select date filter block
//See footnote (3) for 'Creation Date' filter as example
const DateSelectItem = () => {
  const intl = useIntl();
  //Selected option state
  const [option, setOption] = useState(null);

  return (
    <div className="date-selects-wrapper d-flex flex-column">
      {/* Options selector ('is in between' and 'is in last') */}
      <Select
        value={option || ''}
        placeholder={intl.formatMessage({id: 'dashboard.filters.date_range'})}
        items={[
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_between'}),
            value: 'between',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.in_last'}),
            value: 'last',
          },
        ]}
        onChange={value => setOption(value)}
      />
      {/* In between variant' inputs */}
      {option === 'between' && (
        <div className="in-between-inputs">
          {/* Date From */}
          <DatePicker
            placeholder={intl.formatMessage({id: 'actions.from'})}
            onChange={() => {}}
          />
          {/* Date To */}
          <DatePicker
            placeholder={intl.formatMessage({id: 'actions.to'})}
            onChange={() => {}}
          />
        </div>
      )}
      {/* in the last variant' inputs */}
      {option === 'last' && (
        <div className="last-days-inputs d-flex align-items-center">
          <BaseInput className="days-input" onChange={() => {}} />
          <div className="flex-grow-1">
            <Select
              placeholder={intl.formatMessage({id: 'dashboard.filters.days'})}
              items={[
                {
                  label: intl.formatMessage({id: 'dashboard.filters.days'}),
                  value: 'days',
                },
                {
                  label: intl.formatMessage({id: 'dashboard.filters.weeks'}),
                  value: 'weeks',
                },
                {
                  label: intl.formatMessage({id: 'dashboard.filters.months'}),
                  value: 'months',
                },
              ]}
              onChange={() => {}}
            />
          </div>
        </div>
      )}
    </div>
  );
};

//Quantity Select filter item
const QuantitySelectItem = () => {
  const intl = useIntl();
  //Selected option state
  const [option, setOption] = useState(null);

  return (
    <div className="quantity-selects-wrapper">
      {/* Options selector */}
      <Select
        value={option || ''}
        placeholder={intl.formatMessage({
          id: 'dashboard.filters.filter_format',
        })}
        items={[
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_equal'}),
            value: 'is_equal',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_greater'}),
            value: 'is_greater',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_less'}),
            value: 'is_less',
          },
          {
            label: intl.formatMessage({id: 'dashboard.filters.is_between'}),
            value: 'is_between',
          },
        ]}
        onChange={value => setOption(value)}
      />

      {option && (
        <div className="inputs">
          {option === 'is_between' ? (
            <div className="between-wrapper">
              <BaseInput
                className="num-input"
                placeholder={intl.formatMessage({id: 'dashboard.filters.from'})}
                onChange={() => {}}
              />
              <BaseInput
                className="num-input"
                placeholder={intl.formatMessage({id: 'dashboard.filters.to'})}
                onChange={() => {}}
              />
            </div>
          ) : (
            <BaseInput className="num-input" onChange={() => {}} />
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Filter component
 * @param props
 * @constructor
 */
let Filter = props => {
  const {width, opened, items, onClose, onFilter} = props;
  const classes = useStyles();
  const intl = useIntl();
  const values = useSelector(state =>
    get(state, `form.${props.form}.values`, {})
  );

  // Filter tags
  const [filterTags] = useState([]);
  const [activeFilters, setActiveFilters] = useState(0);

  const countActiveFilters = vals => vals && Object.keys(vals).length;

  const clearFilters = () => {
    props.reset();
    // on reset, the form values will be the same as initialValues
    onFilter &&
      onFilter(
        props.initialValues ?? {},
        countActiveFilters(props.initialValues)
      );
  };

  const applyFilters = () => {
    onFilter && onFilter(values, activeFilters);
  };

  useEffect(() => {
    setActiveFilters(countActiveFilters(values));
  }, [values]);

  //Render filter content inside collapsable block
  const renderFilterSubItems = filterItem => {
    if (filterItem.inputType === 'checkboxes') {
      return (
        <Field
          name={filterItem.name}
          component={CheckboxGroupField}
          options={filterItem.subitems}
          formControlLabelClass="checkbox-filter-row"
          checkboxSize="default"
        />
      );
    } else if (filterItem.inputType === 'select') {
      return (
        <Field
          name={filterItem.name}
          component={SelectField}
          items={filterItem.subitems}
        />
      );
    } else if (filterItem.inputType === 'date_between') {
      return <Field name={filterItem.name} component={DateBetweenField} />;
    } else if (filterItem.inputType === 'date_select') {
      return <DateSelectItem />;
    } else if (filterItem.inputType === 'quantity_select') {
      return <QuantitySelectItem />;
    } else if (filterItem.inputType === 'search') {
      return (
        <div className="search-filter-row">
          <Field
            name={filterItem.name}
            component={SearchTextField}
            placeholder={intl.formatMessage({id: 'actions.search'})}
          />
          {filterTags && filterTags.length > 0 && (
            <div className="filter-tags">
              {filterTags.map((item, key) => (
                <Chip key={key} fullWidth label={item} onDelete={() => {}} />
              ))}
            </div>
          )}
        </div>
      );
    } else if (filterItem.inputType === 'autocomplete') {
      return (
        <div className="search-filter-row">
          <Field
            name={filterItem.name}
            component={AutocompleteField}
            label="Text"
            options={filterItem.subitems.map(item => ({
              label: item,
              value: item,
            }))}
          />
        </div>
      );
    } else if (filterItem.inputType === 'multiselect') {
      return (
        <div className="search-filter-row">
          <Field
            name={filterItem.name}
            component={MultiSelectField}
            separated
            placeholder={filterItem.placeholder}
            options={filterItem.subitems.map(item => ({
              label: item,
              value: item,
            }))}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Drawer
      anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={onClose}
    >
      <form className="d-flex flex-column flex-grow-1">
        {/* Filter block content */}
        <div className="filter-content d-flex flex-column flex-fill">
          {/* Filter header */}
          <div className="filter-header border-bottom">
            {/* Close action button */}
            <Button
              link
              light
              title={intl.formatMessage({id: 'actions.close'})}
              onClick={onClose}
              icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
            />
            {/* Filter title */}
            <div className="filter-header-title d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {FILTER_ICON}
                <Typography variant="h5">
                  <FormattedMessage id="actions.filter" />
                </Typography>
              </div>
              {activeFilters > 0 && (
                <div className="filter-counter-indicator text-center">
                  {activeFilters}
                </div>
              )}
              {/*  Use 'invisible' class if you need to hide this indicator */}
            </div>
          </div>
          {/* Filter items collapsable */}
          <div className="filter-items-container flex-fill">
            {items.map((item, key) => (
              <CollapsableFilterBlock
                key={key}
                active={values && Object.keys(values).indexOf(item.name) > -1}
                {...item}
              >
                {renderFilterSubItems(item)}
              </CollapsableFilterBlock>
            ))}
          </div>
          {/* Filter actions */}
          <div className="filter-bottom-actions flex-shrink d-flex align-items-center justify-content-center">
            {/*Clear*/}
            <Button
              link
              title={intl.formatMessage({id: 'actions.clear'})}
              type="button"
              onClick={clearFilters}
            />
            {/*Apply*/}
            <Button
              primary
              title={intl.formatMessage({id: 'actions.apply'})}
              type="button"
              onClick={applyFilters}
            />
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  /**
   * TRUE if filter block is shown
   */
  opened: PropTypes.bool,
  /**
   * Filtration items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Redux-form field name
       */
      name: PropTypes.string.isRequired,
      /**
       * The displayed filter title
       */
      title: PropTypes.string,
      /**
       * Used for choices or something similar
       */
      subitems: PropTypes.array,
      /**
       * The filter item input types
       */
      inputType: PropTypes.oneOf([
        /** Render a group of checkbox filter, subitems is required here */
        inputTypes.CHECKBOXES,
        /** Render a select filter, subitems is required here */
        inputTypes.SELECT,
        /** Render two datepickers, value structure will be an object with two keys: `from` and `to` */
        inputTypes.DATE_BETWEEN,
        /** Not working for now */
        inputTypes.DATE_SELECT,
        /** Render a SearchTextField */
        inputTypes.SEARCH,
        /** Quantity Selector field */
        'quantity_select',
        /** Autocomplete field */
        inputTypes.AUTOCOMPLETE,
        inputTypes.AUTOCOMPLETE2,
        /** Multiselect field */
        'multiselect',
      ]),
    })
  ),
  /**
   * onFilter callback, fired when `clear` or `apply` buttons are clicked
   * the callback parameters will be the filters value
   */
  onFilter: PropTypes.func,
};

// the form name can be overridden by providing a "form" prop to Filter
// useful to avoid having the multiple filters with the same name
Filter = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(Filter);

export default withWidth()(Filter);
