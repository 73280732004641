import React, {Fragment, useEffect, useState} from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import {NavLink, useLocation} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../Common/Button';
import AvatarMenu from './AvatarMenu';
import {NavbarBrand} from 'reactstrap';
import menus from '../../constants/menus';
import {ADMIN_LOGO_IMAGE} from '../../constants/images';
import {OLD_ADMIN_PATH} from '../../constants/urls';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import usePermission from '../../Auth/usePermission';

//Drawer styles
const useStyles = makeStyles(theme => ({
  paper: {
    width: '70%',
    maxWidth: 290,
    backgroundColor: theme.palette.primary.main,
    padding: '25px 20px 30px 16px',
  },
  list: {
    flex: 1,
    paddingTop: 45,
  },
  listItem: {
    padding: '6px 0',

    '&.active': {
      backgroundColor: theme.palette.background.paper,
      marginLeft: -16,
      padding: '8px 0',
      borderTopRightRadius: 100,
      borderBottomRightRadius: 100,
      '& .nav-link': {
        paddingLeft: 18,
      },
    },
    '& .nav-link': {
      padding: '0 3px',
    },
  },
}));

/**
 * Drawer components with control button
 */
const MobileDrawer = props => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {filterMenusByPermissions} = usePermission();
  const menuItems = filterMenusByPermissions(menus);
  //Drawer opened state
  const [opened, setOpened] = useState(false);
  const [checkPathName, setCheckPathName] = useState(false);
  //view data toggler state
  const [viewDataOn, setViewDataOn] = useState(true);
  //view data toggler callback
  const toggleViewData = () => setViewDataOn(!viewDataOn);

  const classes = useStyles();

  useEffect(() => {
    //Not list path ?
    setCheckPathName(
      document.querySelectorAll('.top-block.bg-white').length > 0
    );
  }, [pathname]);

  //Drawer opened state toggler
  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpened(!opened);
  };

  //Check is the menu is active
  const isActive = item => {
    return pathname.includes(item.path);
  };

  return (
    <Fragment>
      {/* Drawer toggler */}
      <div
        id="mobileHeader"
        className={clsx(
          'd-flex align-items-center justify-content-between pb-1',
          checkPathName && 'bg-white'
        )}
      >
        <Button menu title="" className="d-lg-none" onClick={toggleDrawer} />
        <Box pt={2} pr={1.7} className="d-lg-none">
          <AvatarMenu
            className="mobile"
            viewDataOn={viewDataOn}
            viewDataChange={toggleViewData}
          />
        </Box>
      </div>
      {/* Drawer component (shows logo and admin part urls) */}
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        className="d-lg-none"
        open={opened}
        onClose={toggleDrawer}
        elevation={0}
      >
        <NavbarBrand href="/" onClick={e => e.stopPropagation()}>
          <img src={ADMIN_LOGO_IMAGE} alt="" style={{width: 110}} />
        </NavbarBrand>
        <div className={classes.list}>
          {menuItems.map((item, index) => (
            <div
              className={clsx(classes.listItem, isActive(item) && 'active')}
              key={index}
            >
              <NavLink
                className={clsx(
                  'nav-link text-decoration-none',
                  isActive(item) && 'active'
                )}
                activeClassName="active"
                to={item.path}
                onClick={() => setOpened(false)}
              >
                <div className="d-flex align-items-center">
                  <span className="icon-wrapper">
                    <i className={clsx('menu-icon', item.name)} />
                  </span>
                  <FormattedMessage id={`dashboard.menu.${item.name}`} />
                </div>
              </NavLink>
            </div>
          ))}
        </div>
        {/*Back to old admin button*/}
        <div className="pt-2">
          <a className="hidden-link" href={OLD_ADMIN_PATH}>
            <Button
              link
              icon={<KeyboardBackspaceIcon />}
              title={intl.formatMessage({id: 'actions.backOldAdmin'})}
              className="text-white"
            />
          </a>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default MobileDrawer;
