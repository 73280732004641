import {TeamMember} from '../types';
import StatusIndicator from '../../Common/StatusIndicator';
import React from 'react';

type TeamMemberStatusProps = {
  member: TeamMember;
};

const TeamMemberStatus = ({member}: TeamMemberStatusProps) => (
  <StatusIndicator
    className="ms-fan-8"
    status={member.enabled ? 'active' : 'suspended'}
    label={member.enabled ? 'Active' : 'Suspended'}
  />
);

export default TeamMemberStatus;
