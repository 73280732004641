import * as urls from './urls';
import * as permissions from './permissions';

export type MenuType = {
  name: string;
  path: string;
  permissions?: string[];
};

const menus: MenuType[] = [
  {
    name: 'sales',
    path: urls.SALES_LIST_PATH,
    permissions: permissions.SALES_LIST_PERMISSIONS,
  },
  // hidden because not implemented
  // {
  //     name: 'listings',
  //     path: urls.LISTINGS_PATH
  // },
  // {
  //     name: 'events',
  //     path: urls.EVENTS_LIST_PATH
  // },
  // {
  //     name: 'users',
  //     path: urls.USERS_LIST_PATH,
  //     permissions: permissions.USERS_LIST_PERMISSIONS,
  // },
  {
    name: 'transactions',
    path: urls.TRANSACTIONS_PATH,
    permissions: permissions.TRANSACTIONS_LIST_PERMISSIONS,
  },
  {
    name: 'settings',
    path: urls.SETTINGS_PATH,
    permissions: permissions.SETTINGS_PERMISSIONS,
  },
];

export default menus;
