import get from 'lodash.get';
import {SAVE_USER_PREFERENCES_SUCCEEDED} from '../constants/actionTypes';

const defaultState = {};

export default function userPreferencesReducer(state = defaultState, action) {
  switch (action.type) {
    case SAVE_USER_PREFERENCES_SUCCEEDED:
      return {
        ...state,
        [action.payload.key]: {
          ...get(state, [action.payload.key], {}),
          [action.payload.userId]: action.payload.value,
        },
      };
    default:
      return state;
  }
}
