import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {urlPermissions} from '../../constants/permissions';
import usePermission from '../../Auth/usePermission';

/**
 * Handle hiding content depending on permissions props or url of the bloc
 * When using url prop the component will try to find permission associated with the url
 * @param permissions
 * @param url
 * @param props
 * @returns {null|*}
 * @constructor
 */
const PermissionContainer = ({permissions = [], url, ...props}) => {
  const urlPermission = urlPermissions.find(u => u.path === url);
  const {hasPermissions} = usePermission();

  const allPermissions = useMemo(
    () =>
      urlPermission
        ? [...permissions, ...urlPermission.permissions]
        : permissions,
    [permissions, urlPermission]
  );

  if (!hasPermissions(allPermissions)) {
    return null;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default PermissionContainer;

PermissionContainer.propTypes = {
  children: PropTypes.any.isRequired,
  permissions: PropTypes.array,
  url: PropTypes.string,
};
