import {Redirect, Route as DefaultRouter} from 'react-router-dom';
import React from 'react';
import {DASHBOARD_ROOT_PATH} from '../../constants/urls';
import usePermission from '../../Auth/usePermission';

interface RouteProps {
  permissions?: string[];

  [key: string]: any;
}

export default function Route({permissions, ...rest}: RouteProps) {
  const {hasAnyPermission} = usePermission();

  if (!((permissions && hasAnyPermission(permissions)) || !permissions)) {
    return <Redirect to={DASHBOARD_ROOT_PATH} />;
  }

  return <DefaultRouter {...rest} />;
}
