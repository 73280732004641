import React from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

const SnackMessage = React.forwardRef((props, ref) => {
  const {id, message, variant} = props;
  const {closeSnackbar} = useSnackbar();

  const snackClasses = {
    success: 'cco-notification-success',
    error: 'cco-notification-error',
    warning: 'cco-notification-warning',
  };

  const snackClassName = snackClasses[variant] || snackClasses['success'];

  const snackTitles = {
    'forgot-password': 'Recovery Email Sent!',
    'reset-password': 'Your password has been reset!',
  };

  const snackTitle = snackTitles[variant] || variant;

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  const messageIsString = typeof message === 'string';

  return (
    <div ref={ref} className={'cco-notification ' + snackClassName}>
      <div onClick={handleDismiss} className="close-icon">
        <CloseIcon />
      </div>
      <h3 className="title">{snackTitle}</h3>
      {messageIsString && (
        <div className="message" dangerouslySetInnerHTML={{__html: message}} />
      )}
      {!messageIsString && <div className="message">{message}</div>}
    </div>
  );
});

SnackMessage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.any.isRequired,
  variant: PropTypes.string.isRequired,
};

export default SnackMessage;
