import React, {forwardRef, MouseEventHandler} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';

export const textStyles = makeStyles(theme => ({
  root: (props: {addonPosition?: string}) => ({
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(3.5),
      '&.Mui-disabled': {
        background: 'rgba(0, 0, 0, .03)',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#EDEDED !important',
      top: 0,
    },
    '& .MuiInputBase-input': {
      padding: 10,
      fontSize: 14,
      textAlign: props?.addonPosition === 'start' ? 'right' : 'left',
      '&::placeholder': {
        color: theme.palette.secondary.light,
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 10,
    },
    '&.notes': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D8D8D8 !important',
        borderRadius: 8,
      },
      '& .MuiInputBase-input': {
        height: 23,
        padding: theme.spacing(2),
      },
    },
    '& .MuiOutlinedInput-root + .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: -6,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 10,
    },
    '& .MuiInputAdornment-positionStart > p': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root:not(.Mui-error):hover, & .MuiOutlinedInput-root:not(.Mui-error).Mui-focused':
      {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
      },
    '& .MuiInputBase-root': {
      '&.Mui-error': {
        backgroundColor: theme.palette.error.light,
      },
    },
    '& .MuiInputAdornment-positionEnd > p': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  }),
}));

type CustomTextFieldProps = TextFieldProps & {
  addonPosition?: 'start' | 'end';
  addon?: JSX.Element;
  onAddonClick?: MouseEventHandler;
};

const CustomTextField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  (customTextFieldProps, ref) => {
    const {
      addonPosition,
      addon = null,
      onAddonClick,
      ...props
    } = customTextFieldProps;

    const classes = textStyles({addonPosition: addonPosition});

    return (
      <TextField
        fullWidth
        color="secondary"
        classes={classes}
        variant="outlined"
        {...props}
        inputRef={ref}
        InputProps={{
          startAdornment:
            addon && addonPosition === 'start' ? (
              <InputAdornment position="start">{addon}</InputAdornment>
            ) : null,
          endAdornment:
            addon && addonPosition === 'end' ? (
              <InputAdornment
                className={
                  typeof onAddonClick === 'function' ? 'cursor-pointer' : ''
                }
                onClick={onAddonClick}
                position="end"
              >
                {addon}
              </InputAdornment>
            ) : null,
        }}
      />
    );
  }
);

export default CustomTextField;
