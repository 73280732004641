import get from 'lodash.get';
import {useEffect, useState} from 'react';
import {USER_DETAILS_PATH} from '../constants/urls';
import {getResourceId} from '../utils/functions';
import useProfilePictureUrl from './user/useProfilePictureUrl';

/**
 * React hook to get user related information
 *
 * @param {*} user  User Object
 * @return Object   Object containing the following keys: name, initials
 */
export default function useUserInfo(user) {
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');
  const gender = user && user.gender;
  const color = gender === 'mr' ? 'blue' : gender === 'miss' ? 'pink' : 'grey';
  const avatarUrl = useProfilePictureUrl(
    get(user, 'profileImage.sizes.thumbnail')
  );
  const profilePath = USER_DETAILS_PATH.replace(':id', getResourceId(user));
  const [profileUrl, setProfileUrl] = useState('#');

  useEffect(() => {
    let _name = '';
    let _initials = '';
    if (user) {
      _name = `${user.firstname} ${user.lastname}`;
      const parts = ['firstname', 'lastname'];
      for (const i in parts) {
        const val = user[parts[i]];
        if (val && val.trim()) {
          _initials += val.substr(0, 1).toUpperCase();
        }
      }
      setProfileUrl('/admin-dashboard/users/' + user.id);
    }
    setName(_name);
    setInitials(_initials);
  }, [user]);

  return {
    gender,
    name,
    profilePath,
    initials,
    color,
    avatarUrl,
    email: user && user.email,
    profileUrl,
  };
}
