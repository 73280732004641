import clsx from 'clsx';
import PropTypes from 'prop-types';
import useFieldBuilder from '../../hooks/form/useFieldBuilder';
import HintTooltip from '../Common/HintTooltip';
import DynamicField from './Fields/DynamicField';
import {customAttributeType as types} from '../../constants/enums';

const CustomAttributeField = props => {
  const {
    customAttribute,
    builderParams,
    depth,
    maxDepth,
    DynamicFieldProps,
    ...other
  } = props;
  const {layoutGroup, tooltip} = customAttribute;
  const fieldBuilder = useFieldBuilder();
  const extraBuilderParams = [];
  // flag indicating if the field will include another fields
  const isNesting =
    [types.OBJECT, types.COLLECTION].indexOf(customAttribute.type) > -1;
  const hasDepth =
    DynamicFieldProps && DynamicFieldProps.depth && DynamicFieldProps.maxDepth;
  if (isNesting && hasDepth) {
    extraBuilderParams.push(DynamicFieldProps.depth + 1); // +1 because of nesting
    extraBuilderParams.push(DynamicFieldProps.maxDepth);
  }
  const buildProps = fieldBuilder.buildFieldProps.apply(null, [
    customAttribute,
    ...builderParams,
    ...extraBuilderParams,
  ]);

  if (!buildProps) {
    return null;
  }

  const {extra, ...dynamicProps} = buildProps;

  return (
    <div className={layoutGroup}>
      <div className={clsx({'form-group': !(extra && extra.withFormGroup)})}>
        {!(extra && extra.withLabel) && (
          <div className="d-flex">
            <span className="small-label">{dynamicProps.label}</span>
            {tooltip && dynamicProps && (
              <HintTooltip
                className="hint-button pb-1"
                id={dynamicProps.name.replace(/[.[\]]/g, '-')}
                content={tooltip}
              />
            )}
          </div>
        )}
        <DynamicField
          tooltip={tooltip}
          {...other}
          {...dynamicProps}
          // spreaded here to provide the value to DynamicField
          // for example, `depth`, `maxDepth`
          {...DynamicFieldProps}
        />
      </div>
    </div>
  );
};

CustomAttributeField.propTypes = {
  customAttribute: PropTypes.object,
  builderParams: PropTypes.array,
  /**
   * Needed for custom object and collection only
   */
  DynamicFieldProps: PropTypes.object,
};

CustomAttributeField.defaultProps = {
  customAttribute: {},
  builderConfig: [],
  DynamicFieldProps: {},
};

export default CustomAttributeField;
