import React, {useState} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton, {CustomIconButtonProps} from './IconButton';
import {ClickAwayListener, makeStyles, Popover} from '@material-ui/core';
import {Property} from 'csstype';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: (props: {
    radius?: Property.BorderRadius;
    gray: boolean;
    maxWidth?: Property.MaxWidth;
  }) => ({
    fontSize: theme.typography.body2.fontSize,
    borderRadius: props.radius,
    backgroundColor: props.gray
      ? theme.palette.text.border
      : theme.palette.background.paper,
    padding: '.625rem .9375rem',
    boxShadow: theme.shadows[4],
    margin: '11px 0 0',
    zIndex: 1300,
    maxWidth: '90vw',
    [theme.breakpoints.up('lg')]: {
      maxWidth: props.maxWidth ?? 200,
      minWidth: 200,
    },
  }),
}));

type HintTooltipProps = CustomIconButtonProps & {
  id: string;
  content?: JSX.Element;
  icon?: JSX.Element;
  gray?: boolean;
  radius?: Property.BorderRadius;
  centered?: boolean;
  leftTop?: boolean;
  maxWidth?: Property.MaxWidth;
};

const HintTooltip = (props: HintTooltipProps) => {
  const {
    id,
    content,
    icon,
    gray = true,
    radius,
    centered = false,
    leftTop = false,
    maxWidth,
    disabled = false, // Only means visually disabling the IconButton of the Hint. Full disable would prevent the tooltip from working
    ...others
  } = props;

  const classes = useStyles({
    gray,
    ...(radius && {radius: radius ?? '4'}),
    ...(maxWidth && {maxWidth}),
  });

  // ,
  //
  const [trigger, setTrigger] = useState<string | undefined | null>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const popoverProps: any = {
    anchorOrigin: {
      vertical: leftTop ? 'top' : 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: centered ? 'center' : leftTop ? 'right' : 'left',
    },
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
    setTrigger('click');
  };

  const handleHover = (event: React.MouseEvent) => {
    if (!open && !trigger) {
      setTrigger('mouse');
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLeave = () => {
    if (open && trigger === 'mouse') {
      setAnchorEl(null);
      setTrigger(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTrigger(null);
  };

  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <div className="d-inline-flex">
        <IconButton
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          variant="filled"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          size="xs"
          id={id}
          {...others}
          className={disabled ? 'disabled cursor-default' : 'cursor-pointer'}
          onHover={handleHover}
          onMouseLeave={handleLeave}
          onClick={handleClick}
        >
          {icon ?? (
            <SvgIcon viewBox="0 0 12 12" style={{fontSize: 12}}>
              <g
                id="SW_Admin_User_Recipient-View-Profile_Subscription-Default"
                transform="translate(-754.000000, -436.000000)"
              >
                <g
                  id="Group-Copy-6"
                  transform="translate(754.000000, 436.000000)"
                >
                  <circle id="Oval" fill="#EDEDED" cx="6" cy="6" r="6"></circle>
                  <text
                    id="?"
                    fontFamily="Averta-Semibold, Averta"
                    fontSize="8"
                    fontWeight="500"
                    fill="#B8B8B8"
                  >
                    <tspan x="4.8" y="8.5">
                      !
                    </tspan>
                  </text>
                </g>
              </g>
            </SvgIcon>
          )}
        </IconButton>
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          {...popoverProps}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div>{content}</div>
        </Popover>
      </div>
    </ClickAwayListener>
  );
};

export default HintTooltip;
