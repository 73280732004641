import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Field} from 'redux-form';
import InputField from '../../../Form/Fields/InputField';
import TextAreaField from '../../../Form/Fields/TextAreaField';
import HintTooltip from '../../../Common/HintTooltip';
import {formatMoney, normalizeMoneyFormat} from '../../../../utils/functions';

// This will print a row inside AddPenaltyPopup
const PrintRow = props => {
  const {
    className = '',
    title,
    value,
    edit,
    fieldName,
    type,
    currency,
    note,
    appName,
    fieldProps = {},
  } = props;

  return type === 'TextArea' ? (
    <div className="row mb-1">
      <div className="col-12 mb-2">
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </div>
      <div className="col-12">
        <Field
          {...fieldProps}
          name={fieldName}
          component={TextAreaField}
          rows={3}
        />
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        'row align-items-center justify-content-between',
        className
      )}
    >
      <div className={clsx(edit ? 'col-12 mb-2' : 'col')}>
        <div className="d-flex align-items-center">
          <Typography
            component="span"
            variant={edit ? 'subtitle2' : 'body2'}
            color={edit ? 'textSecondary' : 'textPrimary'}
            className="me-1"
          >
            {title}
          </Typography>
          {note && (
            <HintTooltip
              id="title"
              gray={false}
              content={
                <div>
                  <div className="mb-1">
                    <Typography variant="subtitle2">{note}</Typography>
                  </div>
                  <div className="d-flex align-items-center justify-content-between bg-graylight2 px-2 py-1 mt-2 rounded">
                    <Typography
                      variant="caption"
                      component="span"
                      className="text-uppercase font-weight-bold"
                    >
                      {appName}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="span"
                      className="font-weight-bold"
                    >
                      £0.00
                    </Typography>
                  </div>
                </div>
              }
            />
          )}
        </div>
      </div>
      <div className={clsx(edit ? 'col-12' : 'col-auto')}>
        {edit ? (
          <div className="edit-wrapper">
            <Field
              {...fieldProps}
              name={fieldName}
              component={InputField}
              outlined
              addon={currency}
              addonPosition="start"
              format={formatMoney}
              normalize={normalizeMoneyFormat}
            />
          </div>
        ) : (
          <Typography variant="body2">{value}</Typography>
        )}
      </div>
    </div>
  );
};

export default PrintRow;
