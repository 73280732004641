import {call, put} from '@redux-saga/core/effects';
import {getSaleDetails} from '../connectivity/sale.api';
import * as actionTypes from '../constants/actionTypes';
import {addAuthToken, handleException} from './utils';
import {put as apiPut, post as apiPost} from '../connectivity/api';
import * as urls from '../constants/urls';
import {doEnqueueSnackBar} from './snackbar.saga';
import {appIntl} from '../translations/IntlGlobalProvider';
import {getResourceId} from '../utils/functions';
import {truncateAmount} from '../utils/saleHelper';

export function* doGetAdminSaleDetails(action) {
  try {
    const data = yield call(getSaleDetails, yield addAuthToken(action.payload));
    // sort restrictions
    if (data && data.ticket && Array.isArray(data.ticket.restrictions)) {
      data.ticket.restrictions.sort((a, b) => a.label.localeCompare(b.label));
    }

    //Here get seller's payment method
    const sellerId = data && data.seller && data.seller['@id'];
    const sellerPaymentMethods = [];
    if (sellerId) {
      yield put({
        type: actionTypes.API_ADMIN_USER_PAYMENT_METHOD_LIST_REQUESTED,
        payload: {
          userId: sellerId,
          seller: true,
        },
      });
    }

    yield put({
      type: actionTypes.API_ADMIN_SALE_DETAILS_SUCCEEDED,
      payload: {
        sale: {
          ...data,
          fullHandlingFees: truncateAmount(data.fullHandlingFees, 2),
          sellerAmount: truncateAmount(data.sellerAmount, 2),
          totalWithCharges: truncateAmount(data.totalWithCharges, 2),
        },
        sellerPaymentMethods,
      },
    });
  } catch (error) {
    const customMessage =
      error.statusCode === 404 ? action.meta.notFoundMessage : null;
    yield handleException({
      error: {...error, id: action.payload.id},
      type: actionTypes.API_ADMIN_SALE_DETAILS_FAILED,
      customMessage,
    });
  }
}

export function* doSaveSaleNote(action) {
  try {
    const {values: body, token} = yield addAuthToken(action.payload);
    const data = yield apiPut({
      url: `${urls.API_SALES_URL}/${action.payload.id}/note`,
      body,
      token,
    });
    yield put({
      type: actionTypes.API_ADMIN_SALE_NOTE_SUCCEEDED,
      payload: data.result,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({id: 'dashboard.sales.details.note_saved'}),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_NOTE_FAILED,
    });
  }
}

/**
 * Save penalty related to a sale
 * @param action
 * @returns {IterableIterator<IterableIterator<*>|IterableIterator<IterableIterator<*>|*>|Promise<*|undefined>|*>}
 */
export function* doSavePenalty(action) {
  try {
    const {values, sale, token} = yield addAuthToken(action.payload);
    const body = {
      ...values,
      sale: sale['@id'],
    };

    // Call to API
    const data = yield apiPost({
      url: urls.API_V2_SALE_ADD_PENALTY_URL,
      body,
      ldjson: true,
      token,
    });

    //Re-fetch sale details
    yield put({
      type: actionTypes.API_ADMIN_SALE_DETAILS_REQUESTED,
      payload: {
        id: getResourceId(sale),
      },
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_ADD_PENALTY_SUCCEEDED,
      payload: data,
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({id: 'dashboard.sales.penalty.add_success'}),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_ADD_PENALTY_FAILED,
    });
  }
}

/**
 * Cancel sales
 *
 * @param action
 */
export function* doCancelSales(action) {
  try {
    const {sale, token} = yield addAuthToken(action.payload);
    const id = getResourceId(sale);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_SALE_CANCEL_URL.replace(':id', id),
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_CANCEL_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.cancel_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_CANCEL_FAILED,
    });
  }
}

/**
 * Set sales As Cancelled
 *
 * @param action
 */
export function* doSetAsCancelledSales(action) {
  try {
    const {sale, token} = yield addAuthToken(action.payload);
    const id = getResourceId(sale);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_SALE_SET_AS_CANCELLED_URL.replace(':id', id),
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_SET_AS_CANCELLED_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.set_as_cancelled_success_message',
      }),
      'success'
    );

    // Display penalty added message if a penalty was added automatically
    if (data.penaltyAddedAutomatically) {
      yield doEnqueueSnackBar(
        appIntl().formatMessage({
          id: 'dashboard.sales.details.penalty_added_automatically',
        }),
        'success'
      );
    } else if (data.penaltyAddedAutomatically === false) {
      yield doEnqueueSnackBar(
        appIntl().formatMessage({
          id: 'dashboard.sales.details.penalty_not_added_automatically',
        }),
        'warning'
      );
    }
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_SET_AS_CANCELLED_FAILED,
    });
  }
}

/**
 * Set sales As Delivered
 *
 * @param action
 */
export function* doSetAsDeliveredSales(action) {
  try {
    const {sale, token} = yield addAuthToken(action.payload);
    const id = getResourceId(sale);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_SALE_SET_AS_DELIVERED_URL.replace(':id', id),
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_SET_AS_DELIVERED_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.set_as_delivered_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_SET_AS_DELIVERED_FAILED,
    });
  }
}

/**
 * Add a refund for a given sale
 *
 * @param action
 */
export function* doAddRefundForSale(action) {
  try {
    const {sale, values, token} = yield addAuthToken(action.payload);
    const body = {
      ...values,
      sale: sale['@id'],
    };
    // Call to API
    const data = yield apiPost({
      url: urls.API_V2_SALE_ADD_REFUND_URL,
      body,
      ldjson: true,
      token,
    });

    //Re-fetch sale details
    yield put({
      type: actionTypes.API_ADMIN_SALE_DETAILS_REQUESTED,
      payload: {
        id: getResourceId(sale),
      },
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_ADD_REFUND_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.add_refund_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_ADD_REFUND_FAILED,
    });
  }
}

/**
 * Create payment (sellerCharge) for a given sale
 *
 * @param action
 */
export function* doCreatePaymentForSale(action) {
  try {
    const {sale, values, token} = yield addAuthToken(action.payload);
    const body = {
      ...values,
      amount: Number(values.amount),
      sale: sale['@id'],
    };
    // Call to API
    const data = yield apiPost({
      url: urls.API_V2_CREATE_SELLER_CHARGE_URL,
      body,
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_CREATE_SELLER_CHARGE_SUCCEEDED,
      payload: data,
    });
    yield put({
      type: actionTypes.API_ADMIN_SALE_DETAILS_SUCCEEDED,
      payload: {sale: data.sale},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.create_payment_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_CREATE_SELLER_CHARGE_FAILED,
    });
  }
}

/**
 * Pause payout of a sale
 *
 * @param action
 */
export function* doPausePayoutSale(action) {
  try {
    const {sale, token} = yield addAuthToken(action.payload);
    const id = getResourceId(sale);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_SALE_PAUSE_PAYOUT_URL.replace(':id', id),
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_PAUSE_PAYOUT_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.pause_payout_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_PAUSE_PAYOUT_FAILED,
    });
  }
}

/**
 * Resume payout of a sale
 *
 * @param action
 */
export function* doResumePayoutSale(action) {
  try {
    const {sale, token} = yield addAuthToken(action.payload);
    const id = getResourceId(sale);
    // Call to API
    const data = yield apiPut({
      url: urls.API_V2_SALE_RESUME_PAYOUT_URL.replace(':id', id),
      ldjson: true,
      token,
    });

    // Send information to reducer
    yield put({
      type: actionTypes.API_ADMIN_SALE_RESUME_PAYOUT_SUCCEEDED,
      payload: {sale: data},
    });
    yield doEnqueueSnackBar(
      appIntl().formatMessage({
        id: 'dashboard.sales.details.resume_payout_success_message',
      }),
      'success'
    );
  } catch (error) {
    yield handleException({
      error,
      type: actionTypes.API_ADMIN_SALE_RESUME_PAYOUT_FAILED,
    });
  }
}
