import React from 'react';

import Autocomplete from '../../Common/Autocomplete';

export const AutocompleteField = props => {
  const {
    externalLabel,
    label,
    options,
    labelField = 'label',
    valueField = 'value',
    input: {onChange, value},
    meta,
    textFieldProps,
    ...others
  } = props;
  const {touched, error} = meta;
  const extra = {};
  if (!externalLabel && label) {
    extra.label = label;
  }

  if (!props.freeSolo) {
    extra.getOptionLabel = option => {
      if (option) {
        if (typeof option === 'string') {
          const selectedOption = options.find(o => o[valueField] === option);
          if (selectedOption) {
            return selectedOption[labelField];
          }
        } else {
          return option[labelField];
        }
      }
      return '';
    };
    extra.getOptionSelected = (option, val) => option[valueField] === val;
  }

  return (
    <div>
      {externalLabel && label && <span className="small-label">{label}</span>}
      <Autocomplete
        options={options}
        value={value}
        {...others}
        {...extra}
        onChange={(event, val, reason) => {
          if (reason === 'select-option') {
            if (props.freeSolo) {
              onChange(val);
            } else if (val && val[valueField]) {
              onChange(val[valueField]);
            }
          } else if (reason === 'clear' && props.freeSolo) {
            onChange('');
          }
        }}
        textFieldProps={{
          error: touched && error,
          helperText: touched ? error : null,
          ...textFieldProps,
          onChange: event => {
            textFieldProps &&
              textFieldProps.onChange &&
              textFieldProps.onChange(event);
            if (props.freeSolo) {
              onChange(event.target.value);
            }
          },
        }}
      />
    </div>
  );
};

export default AutocompleteField;
