import {isEmail, isEmpty} from 'class-validator';
import {LoginFormData} from './Login';

export default function validate({email, password}: LoginFormData) {
  const errors: {[key: string]: undefined | string} = {};

  if (isEmpty(email)) {
    errors.email = 'This is required';
  } else if (!isEmail(email)) {
    errors.email = 'Invalid format';
  }

  if (isEmpty(password)) {
    errors.password = 'This is required';
  }

  return errors;
}
