import {FormattedMessage, IntlShape, injectIntl} from 'react-intl';
import {FANPASS_LOGO_IMAGE} from '../constants/images';
import {useCallback} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {isEmpty} from 'class-validator';
import VerificationField from './VerificationInputField';
import {
  VerificationFormData,
  VerificationResponse,
  useVerify2FaCodeMutation,
} from '../services/authApi';
import {useDispatch} from 'react-redux';
import {enqueueSnackbarError} from '../actions/snackbarActions';
import {get} from 'lodash';

export type TwoFaCodeVerificationProps = {
  onCancelClick?: () => void;
  onLoginSuccess?: (res: {token: string}) => void;
};

type DecoratedTwoFaCodeVerificationProps = TwoFaCodeVerificationProps & {
  intl: IntlShape;
};

const validate = (
  values: VerificationFormData,
  {intl}: DecoratedTwoFaCodeVerificationProps
) => {
  const errors: {[key: string]: undefined | string} = {};

  if (isEmpty(values.code)) {
    errors.code = intl.formatMessage({id: 'validation.error.required'});
  } else if (values.code && !/^\d{6}$/.test(values.code.trim())) {
    errors.code = intl.formatMessage({id: 'auth.2fa.incorrect_code'});
  }

  return errors;
};

const TwoFaCodeVerification = ({
  invalid,
  handleSubmit,
  onCancelClick,
  onLoginSuccess,
  intl,
}: DecoratedTwoFaCodeVerificationProps &
  InjectedFormProps<VerificationFormData, any>) => {
  const [verify2FaCode, {isLoading}] = useVerify2FaCodeMutation();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: VerificationFormData) => {
      verify2FaCode({
        data: values,
      })
        .then((res: any) => {
          const data = (res as any).data as VerificationResponse;
          if (
            get(res, 'error.data.message') ===
            'Tried to perform two-factor authentication, but two-factor authentication is not in progress.'
          ) {
            dispatch(
              enqueueSnackbarError({
                message: intl.formatMessage({id: 'auth.2fa.system_error'}),
              })
            );
            onCancelClick && onCancelClick();
          } else if (res.error && res.status !== 'FETCH_ERROR') {
            dispatch(
              enqueueSnackbarError({
                message: intl.formatMessage({id: 'auth.2fa.system_error'}),
              })
            );
          } else if (data && data.twoFactorComplete && data.token) {
            onLoginSuccess && onLoginSuccess({token: data.token});
          } else {
            dispatch(
              enqueueSnackbarError({
                message: intl.formatMessage({
                  id: 'auth.2fa.verification_failed',
                }),
              })
            );
          }
        })
        .catch(error => {
          console.warn('Failed to verify 2fa code', error);
          dispatch(
            enqueueSnackbarError({
              message: intl.formatMessage({id: 'auth.2fa.system_error'}),
            })
          );
        });
    },
    [dispatch, intl, onCancelClick, onLoginSuccess, verify2FaCode]
  );

  return (
    <section className="inner_page fa2 vh-100">
      <div className="container text-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container text-center">
            <div className="fa2-wrapper verify">
              <img
                className="logo"
                src={FANPASS_LOGO_IMAGE}
                width="180"
                height="40"
                alt=""
              />
              <p className="fa2-title">
                <FormattedMessage id="auth.2fa.verify.title" />{' '}
              </p>
              <p className="fa2-label">
                <FormattedMessage id="auth.2fa.verify.label" />{' '}
              </p>
              <Field name="code" component={VerificationField} />
              <div>
                <label className="checkbox-container">
                  <FormattedMessage id="auth.2fa.verify.trust.device" />
                  <Field component="input" type="checkbox" name="trustDevice" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="btn-group-actions">
                <button
                  type="submit"
                  className="btn fa2-btn-action"
                  disabled={invalid || isLoading}
                >
                  <FormattedMessage id="auth.2fa.verify.login" />
                </button>
                <button
                  className="btn fa2-btn-action secondary"
                  type="button"
                  onClick={onCancelClick}
                >
                  <FormattedMessage id="auth.2fa.verify.cancel" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default injectIntl(
  reduxForm<VerificationFormData, DecoratedTwoFaCodeVerificationProps>({
    form: 'TwoFactorCodeVerification',
    validate,
  })(TwoFaCodeVerification as any)
);
