import React, {Fragment, useState} from 'react';
import Avatar from '../Common/Avatar';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import NotificationsPopup from './NotificationsPopup';

/* Navbar Avatar menu */
const AvatarMenu = ({viewDataOn, viewDataChange, className = ''}) => {
  const {user, logout} = useAuth();
  const {name, email, initials} = useUserInfo(user);

  //dropdown opened state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //notifications popup state
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  //dropdown open state toggle
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Fragment>
      {/*Notifications popup*/}
      <NotificationsPopup
        opened={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
      />
      {/*Menu*/}
      <Dropdown
        className={clsx('avatar-menu', className)}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle tag="div" caret>
          <Avatar color="blue" size="xs" alt={initials} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="div" className="auth-info">
            <p className="user-name">{name}</p>
            <p className="user-email">{email}</p>
          </DropdownItem>
          <DropdownItem onClick={logout}>
            <span className="label">
              <FormattedMessage id="actions.logout" />
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};

export default AvatarMenu;
