import { TeamMember } from "../types";

/** Get member name */
export const getMemberName = ({ firstname, lastname }: TeamMember): string => [firstname, lastname].filter(v => !!v).join(' ');

export const getErrorMessage = (error: any, defaultErrorMessage: string) => {
    if (error && error.data && error.data.violations && error.data.violations.length > 0) {
        return error.data.violations[0]['message'];
    }
    return defaultErrorMessage;
}
