import React from 'react';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import * as urls from '../../constants/urls';
import {Link} from 'react-router-dom';
import PermissionContainer from '../Common/PermissionContainer';

/* Settings list page */
const SettingsList = () => {
  //Menu items
  const SETTINGS_LIST = [
    // hidden because not implemented
    // {
    //    title: "marketplace_fee",
    //    icon: "marketplace fee.svg",
    //    url: urls.MARKETPLACE_FEE_SETTINGS,
    // },
    {
      title: 'team.title',
      icon: 'Team.svg',
      url: urls.TEAM_MEMBERS_LIST,
    },
    {
      title: 'permission_management',
      icon: 'permission management.svg',
      url: urls.PERMISSION_SETTINGS,
    },
  ];

  return (
    <div className="dashboard-wrapper settings-list">
      <div className="container-fluid">
        <div className="row pb-lg-4">
          <div className="col text-center text-lg-start">
            <Typography variant="h3" className="title mb-lg-2">
              <FormattedMessage id="dashboard.settings.title" />
            </Typography>
          </div>
        </div>
        {/*Settings list items*/}
        <div className="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
          {SETTINGS_LIST.map(({title, icon, url}, index) => (
            <PermissionContainer url={url} key={index}>
              <div className="col">
                <Link to={url} className="text-dark text-decoration-none">
                  <div className="card details-card m-0 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center flex-lg-column">
                        <img src={`${urls.IMG_BASE_URL}${icon}`} alt="" />
                        <Typography
                          variant="h4"
                          className="font-weight-regular text-lg-center pt-lg-4"
                        >
                          <FormattedMessage
                            id={`dashboard.settings.${title}`}
                          />
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </PermissionContainer>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
