import {
  customAttributePermissions,
  customAttributeType as types,
  inputTypes,
} from '../../constants/enums';

const caTextTypes = [types.TEXT_FIELD, types.EMAIL, types.NUMERIC];
const caDateTypes = [types.DATE];
const caCheckboxTypes = [types.BOOLEAN];
const caSelectTypes = [types.DROP_LIST];
const caCheckboxGroupTypes = [types.CHECKBOX];
const caRadioGroupTypes = [types.RADIO];
// const caUnsupportedFilterTypes = [types.COLLECTION, types.COMBINATION, types.IMAGE, types.OBJECT];

const defaultOptions = {
  textType: inputTypes.TEXT,
  dateType: inputTypes.DATEPICKER,
  noFormGroup: true,
  outlined: true,
  namePrefix: '',
};

/**
 * @typedef {Object} FieldBuilderHook
 * @property {function} buildFieldProps Function used to build DynamicField props base on CA
 */

/**
 * Hook to build dynamic field based on Custom Attributes
 * @param {string} permission custom attribute/object permission
 *  must be one of `customAttributePermission`'s value
 * @return {FieldBuilderHook}
 */
export default function useFieldBuilder(
  permission = customAttributePermissions.UPDATABILITY
) {
  if (Object.values(customAttributePermissions).indexOf(permission) < 0) {
    throw new Error(
      `Invalid permission "${permission}", valid values are : ${Object.values(customAttributePermissions).join(', ')}`
    );
  }

  /**
   * Build DynamicField props with `ca`
   * @param {Object|CustomAttribute} ca
   * @param {{textType: string}} options
   * @param {string[]} [unsupportedCaTypes] Custom Attribute types to unsupport (ignored)
   * @return {Object}     DynamicField compliant props with extra props `extra` which
   *                      includes(withFormGroup, withLabel)
   */
  const buildFieldProps = (
    ca,
    options = defaultOptions,
    unsupportedCaTypes = [],
    depth = 1,
    maxDepth = 2
  ) => {
    options = {...defaultOptions, ...options};
    let inputType = options.textType;
    // use selectValue name as label if label is not present
    const items = (ca.selectValues ?? []).map(sv => ({
      ...sv,
      label: sv.label ?? sv.name,
    }));
    let withFormGroup = false;
    let withLabel = false;
    const outlined = options.outlined;
    let extraProps = {
      classes: {},
    };

    if (depth > maxDepth) {
      console.warn(
        `Max depth ${maxDepth} reached, fieldProps wont be built`,
        ca
      );
      return null;
    } else if (unsupportedCaTypes.indexOf(ca.type) > -1) {
      console.warn(
        `Unsupported custom attribute type ${ca.type} from unsupportedCaTypes`,
        ca,
        unsupportedCaTypes
      );
      return null;
    } else if (caTextTypes.indexOf(ca.type) > -1) {
      inputType = options.textType;
      withFormGroup = !outlined;
      withLabel = !outlined;
      extraProps.excludeLabel = outlined;
      extraProps.outlined = outlined;
      extraProps.outlineGroupClass = ''; // override the default mb-0
      if (ca.unit && ca.type === types.TEXT_FIELD) {
        extraProps.addon = <span>{ca.unit}</span>;
        extraProps.addonPosition = 'end';
      }
    } else if (caDateTypes.indexOf(ca.type) > -1) {
      inputType = defaultOptions.dateType;
      withLabel = !outlined;
      extraProps = {
        ...extraProps,
        excludeLabel: outlined,
        outlined,
        InputProps: {
          disableUnderline: outlined,
        },
      };
    } else if (caCheckboxTypes.indexOf(ca.type) > -1) {
      inputType = inputTypes.CHECKBOX;
      withLabel = true;
    } else if (caSelectTypes.indexOf(ca.type) > -1) {
      inputType = inputTypes.SELECT;
      withLabel = !outlined;
      extraProps.outlined = outlined;
      extraProps.excludeLabel = outlined;
    } else if (caCheckboxGroupTypes.indexOf(ca.type) > -1) {
      inputType = inputTypes.CHECKBOXES;
      extraProps.classes = {
        ...extraProps.classes,
        groupClassName: ca.layoutOptionsWrapper,
      };
    } else if (caRadioGroupTypes.indexOf(ca.type) > -1) {
      inputType = inputTypes.RADIO;
      withLabel = true;
      extraProps.radioGroupProps = {
        className: 'row',
      };
      extraProps.radioProps = {
        classes: {
          root: ca.layoutValues,
        },
      };
    } else if (ca.type === types.IMAGE) {
      inputType = inputTypes.IMAGE;
    } else if (ca.type === types.TEXTAREA) {
      inputType = inputTypes.TEXTAREA;
      extraProps.excludeLabel = true;
    } else if (ca.type === types.TIME) {
      inputType = inputTypes.TIME;
      withLabel = !outlined;
      extraProps.excludeLabel = outlined;
      extraProps.outlined = outlined;
    } else if (ca.type === types.DATE_TIME) {
      inputType = inputTypes.DATE_TIME;
      withLabel = !outlined;
      extraProps.excludeLabel = outlined;
      extraProps.outlined = outlined;
    } else if ([types.OBJECT, types.COLLECTION].indexOf(ca.type) > -1) {
      // get updatable custom object
      const userCustomObject = ca[`${permission}CustomObject`];
      // If user custom object is not found, return null
      // The custom attribute field(s) will not be rendered
      if (!userCustomObject) {
        console.warn(
          `${ca.name}[${ca.type}] objectType ${ca.objectType} was not found,
                    it won't be rendered`
        );
        return null;
      }
      withLabel = true;
      extraProps.userCustomObject = userCustomObject;
      inputType =
        ca.type === types.OBJECT
          ? inputTypes.CUSTOM_OBJECT
          : inputTypes.COLLECTION;
    } else {
      console.warn(`Unsupported custom attribute type ${ca.type}`, ca);
      // return null for other unhandled fields
      return null;
    }

    // normalizer
    if (ca.type === types.NUMERIC) {
      extraProps.normalize = value => {
        if (!isNaN(parseFloat(value))) {
          return parseFloat(value);
        }
        return value;
      };
    }

    return {
      ...extraProps,
      name: options.namePrefix + ca.name,
      label: ca.label || ca.name,
      inputType,
      placeholder: ca.placeholder,
      items,
      extra: {
        withFormGroup,
        withLabel,
      },
    };
  };

  return {
    buildFieldProps,
  };
}
