import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  CloseModalIcon,
  NotificationErrorIcon,
  NotificationSuccessIcon,
} from '../../constants/images';
import IconButton from '../Common/IconButton';
import {useDispatch} from 'react-redux';
import {CLOSE_SNACKBAR} from '../../constants/actionTypes';

const NotifierComponent = React.forwardRef((props, ref) => {
  const {
    message: {
      props: {variant, message},
    },
    id,
  } = props;
  const dispatch = useDispatch();

  const closeSnackbar = key => {
    dispatch({
      type: CLOSE_SNACKBAR,
      key,
    });
  };

  return (
    <div ref={ref} className={clsx('fan-notification', variant)}>
      <div className="d-flex align-items-center">
        {variant === 'error' && (
          <NotificationErrorIcon style={{fontSize: 20}} />
        )}
        {variant === 'success' && (
          <NotificationSuccessIcon style={{fontSize: 20}} />
        )}
        <div className="ps-2 ms-1">
          <p className="title">{variant}</p>
          <p className="message" dangerouslySetInnerHTML={{__html: message}} />
        </div>

        <IconButton
          className="ms-auto"
          variant="action"
          size="xs"
          onClick={() => closeSnackbar(id)}
        >
          <CloseModalIcon style={{fontSize: 12}} />
        </IconButton>
      </div>
    </div>
  );
});

NotifierComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.any.isRequired,
};

export default NotifierComponent;
