import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm, Field} from 'redux-form';
import Typography from '@material-ui/core/Typography';
import HintTooltip from '../Common/HintTooltip';
import SelectField from '../Form/Fields/SelectField';
import InputField from '../Form/Fields/InputField';
import RadioGroupField from '../Form/Fields/RadioGroupField';
import CheckboxGroupField from '../Form/Fields/CheckboxGroupField';
import EditableSelect from '../Form/Inputs/EditableSelect';

/* Listing Details Form */
let ListingDetailsForm = props => {
  const {intl} = props;

  const SELLING_TYPES = [
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.selling_options.any',
      }),
      value: 'any',
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.selling_options.pairs',
      }),
      value: 'pairs',
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.selling_options.full',
      }),
      value: 'full',
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.selling_options.avoid_single',
      }),
      value: 'avoid_single',
    },
  ];

  const TICKET_TYPES = [
    {label: intl.formatMessage({id: 'ticket.type.e-ticket'}), value: 'eticket'},
    {label: intl.formatMessage({id: 'ticket.type.mticket'}), value: 'mticket'},
    {label: intl.formatMessage({id: 'ticket.type.paper'}), value: 'paper'},
    {
      label: intl.formatMessage({id: 'ticket.type.membership'}),
      value: 'membership',
    },
  ];

  const YES_NO = [
    {label: intl.formatMessage({id: 'actions.yes'}), value: 1},
    {label: intl.formatMessage({id: 'actions.no'}), value: 0},
  ];

  const FEATURES_OPTIONS = [
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.restricted_view',
      }),
      value: 0,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.full_suite_shared',
      }),
      value: 1,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.child_ticket_16',
      }),
      value: 2,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.includes_food',
      }),
      value: 3,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.child_ticket_18',
      }),
      value: 4,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.includes_limited_food',
      }),
      value: 5,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.junior_ticket',
      }),
      value: 6,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.food_voucher',
      }),
      value: 7,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.senior_ticket',
      }),
      value: 8,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.food_for_purchase',
      }),
      value: 9,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.away_supporters',
      }),
      value: 10,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.includes_pregame_food',
      }),
      value: 11,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.home_supporters',
      }),
      value: 12,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.free_drinks',
      }),
      value: 13,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.early_access',
      }),
      value: 14,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.complimentary_matchday',
      }),
      value: 15,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.vip_lounge_access',
      }),
      value: 16,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.padded_seats',
      }),
      value: 17,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.parking_pass',
      }),
      value: 18,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.standing_only',
      }),
      value: 19,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.full_suite_not_shared',
      }),
      value: 20,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.listings.details.features_disclosures_options.next_players_entrance',
      }),
      value: 21,
    },
  ];

  const ADDRESSES = [
    {
      label:
        '57 school lane woolton, liverpool, L25 7TU liverpool, Great Britain',
      value: 0,
    },
    {
      label: 'Address line 1, Address line 2, City Postcode State Country',
      value: 1,
    },
  ];

  const submit = values => {};

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <div className="card details-card mb-3">
        <div className="card-body">
          {/*How many tickets do you want to sell*/}
          <div className="form-group static row gx-fan-25">
            <div className="col-lg-6">
              <div className="d-flex align-items-center mb-2">
                <Typography variant="subtitle2" className="text-muted me-1">
                  {intl.formatMessage({
                    id: 'dashboard.listings.details.how_many_tickets_to_sell',
                  })}
                  *
                </Typography>
                <HintTooltip
                  gray
                  content={intl.formatMessage({
                    id: 'dashboard.listings.details.how_many_tickets_to_sell_tooltip',
                  })}
                />
              </div>
              <Field
                name="count"
                component={SelectField}
                outlined
                items={[
                  {label: '1', value: 1},
                  {label: '2', value: 2},
                  {label: '3', value: 3},
                  {label: '4', value: 4},
                ]}
              />
            </div>
          </div>
          <div className="row gx-fan-25">
            {/*Section*/}
            <div className="col-lg-6">
              <div className="form-group static">
                <Typography variant="subtitle2" className="text-muted mb-2">
                  {intl.formatMessage({
                    id: 'dashboard.listings.details.section',
                  })}
                  *
                </Typography>
                <Field
                  name="section"
                  component={SelectField}
                  outlined
                  items={[
                    {label: 'Shortside upper', value: 1},
                    {label: 'Label 2', value: 2},
                  ]}
                />
              </div>
            </div>
            {/*Block*/}
            <div className="col-lg-6">
              <div className="form-group static">
                <div className="d-flex align-items-center mb-2">
                  <Typography variant="subtitle2" className="text-muted me-1">
                    {intl.formatMessage({
                      id: 'dashboard.listings.details.block',
                    })}
                  </Typography>
                  <HintTooltip
                    gray
                    content={intl.formatMessage({
                      id: 'dashboard.listings.details.block_tooltip',
                    })}
                  />
                </div>
                <Field
                  name="count"
                  component={SelectField}
                  outlined
                  items={[
                    {label: '10', value: 10},
                    {label: '20', value: 20},
                    {label: '30', value: 30},
                    {label: '40', value: 40},
                  ]}
                />
              </div>
            </div>
          </div>
          {/*Row (optional)*/}
          <div className="form-group static row gx-fan-25">
            <div className="col-lg-6">
              <Field
                name="row"
                component={InputField}
                label={intl.formatMessage({
                  id: 'dashboard.listings.details.row_optional',
                })}
                externalLabel
                outlined
              />
            </div>
          </div>
          {/*Selling type*/}
          <div className="form-group static">
            <Field
              name="selling_type"
              component={RadioGroupField}
              legend={`${intl.formatMessage({id: 'dashboard.listings.details.how_sell_your_tickets'})}*`}
              items={SELLING_TYPES}
              radioGroupProps={{
                className: 'd-flex align-items-center',
              }}
            />
          </div>
          {/*Ticket type*/}
          <div className="form-group static">
            <Field
              name="ticket_type"
              component={RadioGroupField}
              legend={`${intl.formatMessage({id: 'dashboard.listings.details.what_type_ticket'})}*`}
              items={TICKET_TYPES}
              radioGroupProps={{
                className: 'd-flex align-items-center',
              }}
            />
          </div>
          {/*Restrictions*/}
          <div className="form-group">
            <Field
              name="restrictions"
              component={RadioGroupField}
              legend={
                <div className="d-flex align-items-center">
                  <Typography variant="subtitle2" className="text-muted me-1">
                    {intl.formatMessage({
                      id: 'dashboard.listings.details.restrictions_with_tickets',
                    })}
                    *
                  </Typography>
                  <HintTooltip
                    gray
                    content={intl.formatMessage({
                      id: 'dashboard.listings.details.restrictions_with_tickets_tooltip',
                    })}
                  />
                </div>
              }
              items={YES_NO}
              radioGroupProps={{
                className: 'd-flex align-items-center',
              }}
            />
          </div>
          {/*Features / disclosures*/}
          <div className="form-group mb-2 pb-1">
            <Typography variant="subtitle2" className="text-muted mb-2">
              {intl.formatMessage({
                id: 'dashboard.listings.details.select_features_disclosures',
              })}
            </Typography>
            <Field
              name="features"
              component={CheckboxGroupField}
              options={FEATURES_OPTIONS}
              groupClassName="row gx-1"
              controlClassName="col-lg-6 ps-2"
            />
          </div>
          {/*Do you already have your ticket*/}
          <div className="form-group static">
            <Field
              name="have_ticket"
              component={RadioGroupField}
              legend={
                <div className="d-flex align-items-center">
                  <Typography variant="subtitle2" className="text-muted me-1">
                    {intl.formatMessage({
                      id: 'dashboard.listings.details.already_have_ticket',
                    })}
                    *
                  </Typography>
                  <HintTooltip
                    gray
                    content={intl.formatMessage({
                      id: 'dashboard.listings.details.already_have_ticket_tooltip',
                    })}
                  />
                </div>
              }
              items={YES_NO}
              radioGroupProps={{
                className: 'd-flex align-items-center',
              }}
            />
          </div>
          {/*Address for collection*/}
          <div className="form-group mb-0">
            <Typography variant="subtitle2" className="text-muted mb-2">
              {intl.formatMessage({
                id: 'dashboard.listings.details.address_collection',
              })}
            </Typography>
            <EditableSelect
              id="address"
              value={0}
              options={ADDRESSES}
              handleChange={() => {}}
              addTitle={intl.formatMessage({
                id: 'dashboard.listings.details.new_address',
              })}
              onAddClick={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="card details-card">
        <div className="card-body">
          {/*Prices*/}
          <Typography variant="h4" className="mb-3">
            <FormattedMessage id="dashboard.listings.details.price_per_ticket" />
          </Typography>
          <div className="row gx-fan-25">
            <div className="col-lg-6">
              <div className="form-group static">
                <Field
                  name="face_price"
                  component={InputField}
                  label={intl.formatMessage({
                    id: 'dashboard.listings.details.price_face_value',
                  })}
                  externalLabel
                  outlined
                  addon="£"
                  addonPosition="start"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group static">
                <Field
                  name="sell_price"
                  component={InputField}
                  label={intl.formatMessage({
                    id: 'dashboard.listings.details.price_sell',
                  })}
                  externalLabel
                  outlined
                  addon="£"
                  addonPosition="start"
                />
              </div>
            </div>
          </div>
          {/*Summary*/}
          <div className="bg-fan-gray-light rounded mb-2 p-2">
            <div className="row">
              <div className="col-12 col-lg">
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'dashboard.listings.details.tickets_appear_website_at',
                  })}
                </Typography>
              </div>
              <div className="col-12 col-lg-auto">
                <Typography variant="body2" className="font-weight-bold">
                  £132.80
                </Typography>
              </div>
            </div>
          </div>
          <div className="bg-greenlight rounded p-2">
            <div className="row">
              <div className="col-12 col-lg">
                <Typography
                  variant="body2"
                  className="text-app-primary font-weight-bold"
                >
                  {intl.formatMessage({
                    id: 'dashboard.listings.details.amount_seller_receive_per_ticket',
                  })}
                </Typography>
              </div>
              <div className="col-12 col-lg-auto">
                <Typography
                  variant="body2"
                  className="text-app-primary font-weight-bold"
                >
                  £125.00
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const validate = (values, props) => {
  const errors = {};
  return errors;
};

// connect component to redux-form
ListingDetailsForm = reduxForm({
  form: 'ListingDetailsForm',
  validate,
})(ListingDetailsForm);

export default injectIntl(ListingDetailsForm);
