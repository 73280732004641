import React from 'react';
import {useSelector} from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import {Dialog} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * This component is responsible of render the loading spinner
 */
const ProgressDialog = props => {
  const show =
    useSelector(store => store.modalReducer.progressModal.show) || false;

  return (
    <Dialog
      disableEscapeKeyDown={true}
      //disableBackdropClick={true} // Deprecated
      onClose={(event, reason) => {}}
      fullScreen={false}
      open={show}
      classes={{paper: 'cco-react-progress-dialog'}}
      BackdropProps={{
        classes: {root: 'cco-react-progress-backdrop'},
      }}
      PaperProps={{
        elevation: 4,
      }}
    >
      <DialogContent className="progress-dialog-content">
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default ProgressDialog;
