import React, {useEffect} from 'react';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm, Field, initialize, isDirty, getFormValues} from 'redux-form';
import Button from '../../Common/Button';
import InputField from '../../Form/Fields/InputField';
import HiddenEnhancedField from '../../Form/Inputs/HiddenEnhancedField';
import {
  required,
  valuesMismatches,
  email,
} from '../../../validation/validators';
import {TeamMember} from '../types';
import {connect, useDispatch} from 'react-redux';

interface FormValues {
  firstname?: string;
  lastname?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

const validate = (values: FormValues, props: any): Partial<FormValues> => {
  const {intl, isNew, isPasswordDirty, isConfirmPasswordDirty} = props;

  const errors: Partial<FormValues> = {};

  const requiredMessage = intl.formatMessage({id: 'validation.error.required'});
  const emailErrorMessage = intl.formatMessage({id: 'validation.error.email'});
  const passwordMismatchMessage = intl.formatMessage({
    id: 'validation.error.password_mismatch',
  });

  // First name validation
  errors.firstname = required(values.firstname, requiredMessage);

  // Last name validation
  errors.lastname = required(values.lastname, requiredMessage);

  // Email validation
  errors.email =
    required(values.email, requiredMessage) ||
    email(values.email, emailErrorMessage);

  // Password validation, only if adding new member or password is dirty
  if (isNew || isPasswordDirty) {
    errors.password = required(values.password, requiredMessage);
  }

  // Confirm password validation, only if adding new member or confirm password is dirty
  if (isNew || isConfirmPasswordDirty || isPasswordDirty) {
    errors.confirmPassword =
      required(values.confirmPassword, requiredMessage) ||
      valuesMismatches(
        values.password,
        values.confirmPassword,
        passwordMismatchMessage
      );
  }

  return errors;
};

type EditUserFormProps = {
  isNew: boolean;
  toggleModal: () => void;
  member: TeamMember | null;
  onAdd: (values: {
    firstname: string | undefined;
    plainPassword: string | undefined;
    confirmPassword: string | undefined;
    email: string | undefined;
    lastname: string | undefined;
  }) => void;
  onEdit: (values: {
    firstname: string | undefined;
    plainPassword: string | undefined;
    confirmPassword: string | undefined;
    id: any;
    email: string | undefined;
    lastname: string | undefined;
  }) => void;
  isPasswordDirty: boolean;
  isConfirmPasswordDirty: boolean;
} & InjectedIntlProps;

/**
 * Create/Edit team item form
 */
let EditUserForm = ({
  isNew,
  toggleModal,
  member,
  onAdd,
  onEdit,
  handleSubmit,
  intl,
  isPasswordDirty,
  isConfirmPasswordDirty,
}: EditUserFormProps) => {
  const dispatch = useDispatch();

  const submit = (values: FormValues): void => {
    isNew &&
      onAdd({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        plainPassword: values.password,
        confirmPassword: values.confirmPassword,
      });

    const {id} = member || {};

    !isNew &&
      onEdit({
        id: id,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        // Send password only if it was changed
        plainPassword: isPasswordDirty ? values.password : undefined,
        confirmPassword: isConfirmPasswordDirty
          ? values.confirmPassword
          : undefined,
      });
  };

  useEffect(() => {
    if (member) {
      const {id, email, firstname, lastname} = member;
      dispatch(initialize('EditUserForm', {email, firstname, lastname}));
    }
  }, [member, dispatch]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="dialog-header">
        <Typography variant="h4">
          <FormattedMessage
            id={
              isNew
                ? 'dashboard.settings.team.add_team_member'
                : 'dashboard.settings.team.edit_team_member'
            }
          />
        </Typography>
      </div>
      <div className="dialog-content">
        {/*First name*/}
        <div className="form-group static">
          <Field
            name="firstname"
            component={InputField}
            outlined
            externalLabel
            label={intl.formatMessage({
              id: 'dashboard.settings.team.first_name',
            })}
            formGroupClass="inline-item"
          />
        </div>
        {/*Last name*/}
        <div className="form-group static">
          <Field
            name="lastname"
            component={InputField}
            outlined
            externalLabel
            label={intl.formatMessage({
              id: 'dashboard.settings.team.last_name',
            })}
            formGroupClass="inline-item"
          />
        </div>
        {/*Email*/}
        <div className="form-group static">
          <Field
            name="email"
            component={InputField}
            outlined
            externalLabel
            autoComplete="new-username"
            label={intl.formatMessage({
              id: 'dashboard.settings.team.email_address',
            })}
          />
        </div>
        {/*Password*/}
        <div className="form-group static">
          <Field
            name="password"
            component={HiddenEnhancedField}
            outlined
            externalLabel
            showVisibilityIcon={isNew}
            placeholderValues={!isNew}
            canCopy={false}
            label={intl.formatMessage({
              id: 'dashboard.settings.team.password',
            })}
          />
        </div>
        {/*Confirm password*/}
        <div className="form-group mb-1">
          <Field
            name="confirmPassword"
            component={HiddenEnhancedField}
            outlined
            externalLabel
            showVisibilityIcon={isNew}
            placeholderValues={!isNew}
            canCopy={false}
            label={intl.formatMessage({
              id: 'dashboard.settings.team.confirm_password',
            })}
            password
          />
        </div>
      </div>
      <div className="dialog-footer">
        <div
          className={clsx(
            'd-flex',
            'justify-content-center justify-content-lg-end'
          )}
        >
          {/*Cancel*/}
          <Button
            link
            className={clsx('dialog-button')}
            title={intl.formatMessage({id: 'actions.cancel'})}
            onClick={() => toggleModal(false)}
          />
          {/*Save*/}
          <Button
            type="submit"
            primary
            className="ms-1"
            title={intl.formatMessage({
              id: isNew ? 'actions.add' : 'actions.save',
            })}
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isNew: ownProps.isNew,
    isPasswordDirty: isDirty('EditUserForm')(state, 'password'),
    isConfirmPasswordDirty: isDirty('EditUserForm')(state, 'confirmPassword'),
    formValues: getFormValues('EditUserForm')(state),
  };
};

// connect component to redux-form
EditUserForm = reduxForm({
  form: 'EditUserForm',
  validate,
})(EditUserForm);

// connect component to redux-form and redux
EditUserForm = connect(mapStateToProps)(EditUserForm);

export default injectIntl(EditUserForm);
