import {InputBase} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ITheme} from '../../constants/theme';
import {InputBaseProps} from '@material-ui/core/InputBase/InputBase';

//Base input styles
const useStyles = makeStyles((theme: ITheme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    padding: theme.spacing(1),
    height: theme.spacing(2),
    boxShadow: theme.shadows[4],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
    },
  },
}));

function BaseInput(props: InputBaseProps) {
  const classes = useStyles();

  return <InputBase {...props} classes={classes} />;
}

export default BaseInput;
