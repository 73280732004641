import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {inputTypes} from '../../constants/enums';
import useEventAutocomplete from '../event/useEventAutocomplete';
import useCompetitionAutocomplete from '../competition/useCompetitionAutocomplete';
import useUserAutocomplete from '../user/useUserAutocomplete';
import {isEmpty} from '../../utils/functions';
import useFilters from '../common/useFilters';

/**
 * @returns {{filterItems: *, filterFormat: *}}
 */
export default function usePaymentFilter() {
  const [items, setItems] = useState([]);
  const intl = useIntl();
  const {formatFilters} = useFilters();
  const {eventNamesSelector, getEventNames} = useEventAutocomplete();
  const {getCompetitionNames, competitionNamesSelector} =
    useCompetitionAutocomplete();
  const {getUserNames, userNamesSelector} = useUserAutocomplete('transaction');

  useEffect(() => {
    if (items.length === 0) {
      const optionSellerTypeChoices = [
        'isTrustableSeller',
        'isCustomDelivery',
        'isPremiumSeller',
        'isFanpassPro',
        'isInstantConfirm',
      ];

      const optionTransactionTypeChoices = [
        'transaction',
        'sale-penalty',
        'seller-charge',
        'buyer-refund',
      ];

      const choiceMapper = prefix => item => ({
        label: intl.formatMessage({id: prefix + item}),
        value: item,
      });

      //Filters for Payment List
      const paymentFilterItems = [
        {
          name: 'fullname',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.user_name',
          }),
          inputType: inputTypes.AUTOCOMPLETE,
          freeSolo: true,
          textFieldProps: {
            onChange: e => getUserNames(e.target.value),
          },
          useOptionsSelector: true,
          getOptionsSelector: userNamesSelector,
          label: false,
        },
        {
          name: 'email',
          title: intl.formatMessage({
            id: 'dashboard.sales.filter.email_address',
          }),
          inputType: inputTypes.SEARCH,
        },
        {
          name: 'seller',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.option_type',
          }),
          inputType: inputTypes.CHECKBOXES,
          items: optionSellerTypeChoices.map(choiceMapper('option_types.')),
          classes: {checkboxRow: 'checkbox-filter-row'},
        },
        {
          name: 'saleHex',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.sales_id',
          }),
          inputType: inputTypes.SEARCH,
        },
        {
          name: 'resourceType',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.transaction_type',
          }),
          inputType: inputTypes.CHECKBOXES,
          items: optionTransactionTypeChoices.map(
            choiceMapper('dashboard.transactions.types.')
          ),
          classes: {checkboxRow: 'checkbox-filter-row'},
        },
        {
          name: 'eventName',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.event_title',
          }),
          inputType: inputTypes.AUTOCOMPLETE2,
          freeSolo: true,
          onInputChange: e => getEventNames(e.target.value),
          textFieldProps: {
            onChange: e => getEventNames(e.target.value),
          },
          useOptionsSelector: true,
          getOptionsSelector: eventNamesSelector,
          label: false,
        },
        {
          name: 'eventDate',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.event_date',
          }),
          inputType: inputTypes.DATE_SELECT,
        },
        {
          name: 'competitionName',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.competition',
          }),
          inputType: inputTypes.AUTOCOMPLETE,
          freeSolo: true,
          textFieldProps: {
            onChange: e => getCompetitionNames(e.target.value),
          },
          useOptionsSelector: true,
          getOptionsSelector: competitionNamesSelector,
          label: false,
        },
        {
          name: 'amount',
          title: intl.formatMessage({
            id: 'dashboard.transactions.filter.amount',
          }),
          inputType: inputTypes.ADVANCED_NUMBER,
        },
      ];

      setItems(paymentFilterItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFormat = useCallback(
    filters => {
      if (!isEmpty(filters) && !isEmpty(items)) {
        let formatted = {...filters};
        //Format seller
        if (Array.isArray(filters.seller)) {
          delete formatted.seller;
          for (const item of filters.seller) {
            formatted[`sale.seller.${item}[exists]`] = true;
          }
        }

        //Apply formatters for date and number
        formatted = formatFilters(formatted, items);

        //Format amount
        if (!isEmpty(filters['amount'])) {
          if (!isEmpty(formatted['amount[eq]'])) {
            formatted['amount'] = formatted['amount[eq]'];
            delete formatted['amount[eq]'];
          }
          if (
            !isEmpty(formatted['amount[range][min]']) &&
            !isEmpty(formatted['amount[range][max]'])
          ) {
            formatted['amount[between]'] =
              `${formatted['amount[range][min]']}..${formatted['amount[range][max]']}`;
            delete formatted['amount[range][min]'];
            delete formatted['amount[range][max]'];
          }
        }

        //Format competitionName
        if (!isEmpty(filters['competitionName'])) {
          formatted['sale.ticket.sportEvent.competition.name'] =
            filters['competitionName'];
          delete formatted['competitionName'];
        }

        return formatted;
      }
      return {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [items]
  );

  return {
    filterItems: items,
    filterFormat,
  };
}
