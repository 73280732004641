import {put} from 'redux-saga/effects';
import {SAVE_USER_PREFERENCES_SUCCEEDED} from '../constants/actionTypes';

export function* saveUserPreference(action) {
  yield put({
    type: SAVE_USER_PREFERENCES_SUCCEEDED,
    payload: {
      key: action.payload.key,
      value: action.payload.value,
      userId: action.payload.userId,
    },
  });
}
