import React, {useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import SearchForm from '../Form/SearchForm';
import useTheme from '@material-ui/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import {Nav, NavItem, NavLink} from 'reactstrap';
import IconButton from '../Common/IconButton';
import {
  FILTER_ICON,
  PlusRoundedDangerIcon,
  EllipsisIcon,
  EventManualIcon,
  EventDefaultIcon,
  InfoPrimaryRedIcon,
  UpdateIcon,
  PremiumIcon,
} from '../../constants/images';
import {IMG_BASE_URL} from '../../constants/urls';
import DataTable from '../Widgets/DataTable';
import clsx from 'clsx';
import Filter from '../Widgets/Filter';
import {useHistory} from 'react-router-dom';
import * as urls from '../../constants/urls';
import StatusIndicator from '../Common/StatusIndicator';
import Dropdown from '../Common/Dropdown';
import EventMark from './EventMark';

const rows = [
  {
    id: '0',
    event: {
      title: 'Arsenal - Tottenham',
      image: null,
    },
    date_time: {value: '23 Nov 2019 | 14:00'},
    venue: {value: 'Emirates Stadium London, UK'},
    category: {value: 'Premier League'},
    status: {label: 'Active', status: 'active'},
  },
  {
    id: '1',
    event: {
      title: 'Chelsea – Leicester City',
      image: `${IMG_BASE_URL}event.jpeg`,
      premium: true,
      type: 'manual',
      isSeatMissing: true,
      update: true,
      eventName: 'Watford - Chelsea',
    },
    date_time: {
      value: '11 Oct 2019 | 16:00',
      update: true,
      new_value: '11 Oct 2019 | 16:30',
    },
    venue: {
      value: 'Stamford Bridge London, UK',
      update: true,
      new_value: 'The O2 Arena London, UK',
    },
    category: {
      value: 'Premier League',
      update: true,
      new_value: 'World Cup',
    },
    status: {label: 'Inactive', status: 'error'},
  },
  {
    id: '2',
    event: {
      title: 'Man United – Chelsea',
      image: `${IMG_BASE_URL}event.jpeg`,
    },
    date_time: {value: '28 Dec 2019 | 18:45'},
    venue: {value: 'Etihad Stadium Manchester, UK'},
    category: {value: 'Music'},
    status: {label: 'Active', status: 'active'},
  },
];

/* Events list - tab Event */
const ListEventTab = ({intl, onAddClick}) => {
  //Router
  const history = useHistory();
  const theme = useTheme();
  //active tab
  const [activeTab, setActiveTab] = useState('database');
  //switch active tab
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const actions = [
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.set_as_live',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.set_as_offline',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.postpone',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.un_postpone',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.set_as_premium',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.events.list.actions.remove_as_premium',
      }),
      onClick: () => {},
    },
    {
      label: intl.formatMessage({id: 'dashboard.events.list.actions.delete'}),
      onClick: () => {},
    },
  ];

  const bulkActions = [
    intl.formatMessage({
      id: 'dashboard.events.list.bulkActions.set_as_premium',
    }),
    intl.formatMessage({id: 'dashboard.events.list.bulkActions.set_to_on'}),
    intl.formatMessage({id: 'dashboard.events.list.bulkActions.set_to_off'}),
    intl.formatMessage({id: 'dashboard.events.list.bulkActions.postpone'}),
    intl.formatMessage({id: 'dashboard.events.list.bulkActions.un_postpone'}),
  ];

  const printEventIconTooltip = type => {
    const text = intl.formatMessage({
      id:
        type === 'manual'
          ? 'dashboard.events.list.manual_input'
          : 'dashboard.events.list.import',
    });
    return (
      <div className="d-inline-flex pe-1">
        <EventMark
          icon={type === 'manual' ? <EventManualIcon /> : <EventDefaultIcon />}
          text={text}
          iconSize={11}
        />
      </div>
    );
  };

  const printSeatMapMissing = isSeatMissing =>
    isSeatMissing ? (
      <div className="d-flex align-items-center mt-1">
        <InfoPrimaryRedIcon style={{width: 11, height: 11}} />
        <Typography
          color="primary"
          className="ps-1 font-bold-italic text-truncate"
          variant="body2"
        >
          <FormattedMessage id="dashboard.events.list.seat_map_missing" />
        </Typography>
      </div>
    ) : null;

  const printUpdateTitle = (show, title) =>
    show ? (
      <div className="d-flex align-items-center mt-1">
        <div className="d-inline-flex">
          <UpdateIcon style={{width: 11, height: 11, marginBottom: 2}} />
        </div>
        <Typography
          style={{color: theme.palette?.blue?.main}}
          variant="body2"
          className="ps-1 font-bold-italic text-truncate"
        >
          {title}
        </Typography>
      </div>
    ) : null;

  // Header cells items
  const HeaderCells = [
    {
      name: 'event',
      disablePadding: true,
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.event_title',
      }),
      collapse: false,
      renderValue: ({
        title,
        image,
        premium,
        type = 'import',
        isSeatMissing = false,
        update = false,
        eventName = '',
      }) => (
        <div className="d-flex overflow-hidden align-items-center ps-1 ps-lg-0">
          <picture className={clsx('event-picture me-2', {premium})}>
            {premium && (
              <div className="event-mark">
                <EventMark
                  icon={<PremiumIcon />}
                  iconSize={15}
                  text={intl.formatMessage({
                    id: 'dashboard.events.list.premium',
                  })}
                />
              </div>
            )}
            <img
              className="img-fluid rounded-sm"
              src={!image ? `${IMG_BASE_URL}event-placeholder.jpg` : image}
              alt=""
            />
          </picture>
          <div className="overflow-hidden">
            <div className="d-flex align-items-center">
              {printEventIconTooltip(type)}
              <Typography variant="body2" className="text-truncate">
                {title}
              </Typography>
            </div>
            {printSeatMapMissing(isSeatMissing)}
            {printUpdateTitle(
              update,
              `${intl.formatMessage({
                id: 'dashboard.events.list.event_update',
              })}: ${eventName}`
            )}
          </div>
        </div>
      ),
      width: '25%',
    },
    {
      name: 'date_time',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.date_time',
      }),
      collapse: true,
      renderValue: ({value, update, new_value}) => (
        <div>
          <Typography variant="body2" className="text-truncate">
            {value}
          </Typography>
          {update && (
            <div className="mt-1">{printUpdateTitle(update, new_value)}</div>
          )}
        </div>
      ),
    },
    {
      name: 'venue',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.venue',
      }),
      collapse: true,
      renderValue: ({value, update, new_value}) => (
        <div>
          <Typography variant="body2" className="text-truncate">
            {value}
          </Typography>
          {update && (
            <div className="mt-1">{printUpdateTitle(update, new_value)}</div>
          )}
        </div>
      ),
    },
    {
      name: 'category',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.category',
      }),
      collapse: true,
      renderValue: ({value, update, new_value}) => (
        <div>
          <Typography variant="body2" className="text-truncate">
            {value}
          </Typography>
          {update && (
            <div className="mt-1">{printUpdateTitle(update, new_value)}</div>
          )}
        </div>
      ),
    },
    {
      name: 'status',
      label: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.status',
      }),
      collapse: false,
      renderValue: status => <StatusIndicator {...status} />,
      width: '11%',
    },
    {
      name: 'action',
      collapse: false,
      disablePadding: true,
      width: '40px',
      renderValue: () => (
        <Dropdown
          className="justify-content-end"
          menuIcon={
            <EllipsisIcon style={{width: 15, height: 10}} className="my-2" />
          }
          items={actions}
        />
      ),
    },
  ];

  //Filter state
  const [filterOpened, setFilterOpened] = useState(false);

  const filterItems = [
    {
      name: 'title',
      title: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.event_title',
      }),
      inputType: 'autocomplete',
      subitems: [],
    },
    {
      name: 'date_time',
      title: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.date_time',
      }),
      inputType: 'date_between',
    },
    {
      name: 'venue',
      title: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.venue',
      }),
      inputType: 'autocomplete',
      subitems: ['Emirates Stadium', 'Stamford Bridge', 'Etihad Stadium'],
    },
    {
      name: 'competition',
      title: intl.formatMessage({
        id: 'dashboard.events.list.event_tab.competition_name',
      }),
      inputType: 'checkboxes',
      subitems: [
        {
          label: intl.formatMessage({id: 'dashboard.events.premier_league'}),
          value: 'pl',
        },
      ],
    },
  ];

  const handleRowClick = itemId => {
    history.push(urls.EVENT_DETAILS_PATH.replace(':id', itemId));
  };

  return (
    <div className="dashboard-content">
      <div className="row gy-3 gx-2 gx-lg-3 align-items-center justify-content-between search-row pb-1">
        <div className="col-12 col-lg-auto me-auto">
          <Nav pills fill className="d-flex mb-0">
            <NavItem>
              <NavLink
                active={activeTab === 'database'}
                onClick={() => toggle('database')}
              >
                <FormattedMessage id="dashboard.events.list.switcher.database" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'tixstock'}
                onClick={() => toggle('tixstock')}
              >
                <FormattedMessage id="dashboard.events.list.switcher.tixstock" />
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="col col-lg-4">
          {/* Search */}
          <SearchForm
            dispatchAction={true}
            delay={180}
            SearchProps={{
              buttonProps: {
                onClick: () => {},
              },
            }}
          />
        </div>
        {/* Button toolbar */}
        <div className="col-auto">
          {/* Filter button */}
          <IconButton className="m-0" onClick={() => setFilterOpened(true)}>
            {FILTER_ICON}
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {/* Filter on the right side */}
          <Filter
            opened={filterOpened}
            items={filterItems}
            onClose={() => setFilterOpened(false)}
          />
          {/*Table*/}
          <DataTable
            className="events-table"
            canSelect
            empty={false}
            loading={false}
            onRowClick={itemId => handleRowClick(itemId)}
            headCells={HeaderCells.map(item => ({
              ...item,
              numeric: false,
            }))}
            actionButton={{
              variant: 'filled',
              size: 'sm',
              children: <PlusRoundedDangerIcon />,
              onClick: onAddClick,
            }}
            rows={rows}
            onChange={() => {}}
            total={100}
            currentPage={1}
            bulkActions
            bulkProps={{
              actionList: bulkActions,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ListEventTab);
