import moment from 'moment';

export const getYearsBetweenDates = () => {
  // start yea
  const startDate = moment('1900-01-01');
  // today's date
  const today = moment();
  // subtract 18 years from todays's
  const endDate = today.subtract(18, 'years');
  const now = startDate.clone();
  const dates = [];
  // get list of years from 1900 -> (currentyear - 18)
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY'));
    now.add(1, 'year');
  }
  return dates;
};
