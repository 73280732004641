import {put, delay} from 'redux-saga/effects';
import * as types from '../constants/actionTypes';

/**
 * Show Progress Modal
 * @returns {IterableIterator<PutEffect<{type: string}> | ChannelPutEffect<any>>}
 */
export function* doProgressModalShow() {
  yield put({
    type: types.PROGRESS_SHOW,
  });
}

/**
 * Hide Progress Modal
 * @returns {IterableIterator<PutEffect<{type: string}> | ChannelPutEffect<any>>}
 */
export function* doProgressModalHide() {
  yield put({
    type: types.PROGRESS_HIDE,
  });
}

export function* doSetSearch(action) {
  try {
    yield delay(action.delay ?? 200);
    yield put({
      type: action.successType,
      payload: {...action.payload},
    });
  } catch (error) {
    console.warn('Unable to set search', error);
  }
}
